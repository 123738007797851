import React, { useEffect, useState } from 'react'
import DataTableComponent from '../../../components/Tenant/DataTableComponent/DataTableComponent';
import { useGetByBatchIdOfPaymentTargetMutation } from '../../../features/tenant/paymentTarget/paymentTargetApi';
import { useLocation, useNavigate } from 'react-router-dom';
import LinksComponent from '../../../components/Tenant/LinksComponent/LinksComponent';
import ExportCSV from '../../../components/ExportCsv/ExportCsv';
import { months } from './PaymentTargetList';

const PaymentTargetListByBatchId = () => {

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Emp ID ",
      selector: (row) => row.emp_id,
      sortable: true,
    },
    {
      name: "Mobile ",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Name ",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Reporting Manager ",
      selector: (row) => row.l1_manager,
      sortable: true,
      width: "100px",
    },
    {
      name: "Achived amount",
      selector: (row) => row.achived_amount,
      sortable: true,
      width: "100px",
    },
    {
      name: "Target Amount ",
      selector: (row) => row.target_amount,
      sortable: true,
    },

    {
      name: "Month",
      selector: (row) => months[row.month],
      sortable: true,
    },

    {
      name: "Year",
      selector: (row) => row.year,
      sortable: true,
    },

    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const initialRequest = {
    name: "",
    limit: 10,
    offset: 0,
    mobile: "",
    status : ""
  };
  const [request, setRequest] = useState(initialRequest);

  const clearFilters = () => {
    setRequest(initialRequest);
  };
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [batchID, setbatchID] = useState(null);

  useEffect(() => {
    console.log("outer");
    if (
      location.state &&
      location.state.batch_id &&
      request.name === "" &&
      request.mobile === ""
    ) {
      console.log("inner");

      fetchPaymentTrasnferListFunc(location.state.batch_id);
      fetchPaymentTrasnferListForCsvFunc(location.state.batch_id);
    }
  }, [request, limit, offset]);

  useEffect(() => {
    console.log("batchID", location.state);
    if (location.state) {
      setbatchID(location.state.batch_id);
      // fetchPaymentTransferByBatchID({
      //   token: sessionStorage.getItem("access_token"),
      //   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      //   batch_id: location.state.batch_id,
      //   body: request,
      // });

      fetchPaymentTrasnferListFunc(location.state.batch_id);
      fetchPaymentTrasnferListForCsvFunc(location.state.batch_id);
    }
  }, [location]);

  const fetchPaymentTrasnferListFunc = (batch_id) => {
    getByBatchIdOfPaymentTarget({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      batch_id: batch_id,
      body: request,
    });
  };

  const fetchPaymentTrasnferListForCsvFunc = (batch_id) => {
    const newRequest = { ...request };
    delete newRequest.limit;
    delete newRequest.offset;

    getByBatchIdOfPaymentTargetCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      batch_id: batch_id,
      body: request,
    });
  };

  const [
    getByBatchIdOfPaymentTarget,
    {
      data: getByBatchIdOfPaymentTargetData,
      error: getByBatchIdOfPaymentTargetError,
      isLoading: getByBatchIdOfPaymentTargetisLoading,
    },
  ] = useGetByBatchIdOfPaymentTargetMutation();
  const [
    getByBatchIdOfPaymentTargetCsv,
    {
      data: getByBatchIdOfPaymentTargetCsvData,
      error: getByBatchIdOfPaymentTargetCsvError,
      isLoading: getByBatchIdOfPaymentTargetCsvisLoading,
    },
  ] = useGetByBatchIdOfPaymentTargetMutation();

  useEffect(()=>{
    if(getByBatchIdOfPaymentTargetData){
     
    }
    if(getByBatchIdOfPaymentTargetError){

    }
     
  },[getByBatchIdOfPaymentTargetData,getByBatchIdOfPaymentTargetError])

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;

    setRequest((prevState) => {
      return { ...prevState, offset: newOffset };
    });
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest((prevState) => {
      return { ...prevState, limit: newPerPage };
    });
    setLimit(newPerPage);
  };

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Target Payment VS Achivement List
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className="p-3 w-full bg-white shadow rounded-lg">
            {getByBatchIdOfPaymentTargetCsvData &&
              getByBatchIdOfPaymentTargetCsvData?.body?.data && (
                <ExportCSV
                  data={
                    getByBatchIdOfPaymentTargetCsvData &&
                    getByBatchIdOfPaymentTargetCsvData?.body.data
                  }
                  columnsToExport={[
                    "emp_id",
                    "mobile",
                    "name",
                    "l1_manager",
                    "amount",
                    "month",
                    "year",
                    "batch_id",
                   
                  ]}
                />
              )}
           
              </div>
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 justify-end">
                <div className="relative searchin">
                  <input
                    type="search"
                    id="default-search"
                    className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Mobile..."
                    required
                    value={request.category}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return { ...prevState, mobile: newValue };
                      });
                    }}
                  />
                </div>
                <div className="relative searchin">
                  <input
                    type="search"
                    id="default-search"
                    className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Name..."
                    required
                    value={request.name}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return { ...prevState, name: newValue };
                      });
                    }}
                  />
                </div>
                <div className="relative searchin">
                  <input
                    type="search"
                    id="default-search"
                    className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Name..."
                    required
                    value={request.name}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return { ...prevState, name: newValue };
                      });
                    }}
                  />
                </div>
                <div className="relative searchin">
                <select 
                  id="default-search"
                  className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                  placeholder="Name..."
                  onChange={(e) => {

                      const newValue = e.target.value;
                      if(newValue != "-1"){

                        setRequest((prevState) => {
                          return { ...prevState, status: newValue };
                        });
                      }
                    }} >
                    <option  value="-1">all</option>
                    <option value="1">success</option>
                    <option value="2">pending</option>
                    <option value="0">error</option>

                    </select>
                </div>


                <div className="flex flex-row  gap-2 h-10">
                  <div className="relative">
                    {location.state && location.state.batch_id && (
                      <button
                        className="btn btn-outline-primary clear-both float-right "
                        onClick={() => {
                          fetchPaymentTrasnferListFunc(location.state.batch_id);
                        }}
                      >
                        Search
                      </button>
                    )}
                  </div>
                  <div className="relative searchin">
                    <button
                      className="btn btn-outline-primary clear-both float-right whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableComponent
              keyField={(row) => row.SNo}
              columns={columns}
              data={
                getByBatchIdOfPaymentTargetData ? getByBatchIdOfPaymentTargetData.body.data : []
              }
              loading={getByBatchIdOfPaymentTargetisLoading}
              totalRows={
                getByBatchIdOfPaymentTargetData && getByBatchIdOfPaymentTargetData?.body.total
              }
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
            ></DataTableComponent>
          </div>
        </div>
      </div>
    
  )
}

export default PaymentTargetListByBatchId