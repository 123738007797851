import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineEdit, AiOutlineDelete, AiFillEye } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import silver from "../../../assests/tenant/images/Silver.svg";
import Gold from "../../../assests/tenant/images/Gold.svg";
import platinum from "../../../assests/tenant/images/Platinum.svg";
import Rank1 from "../../../assests/tenant/images/Rank1.png";
import { toast } from "react-hot-toast";
import { FaAngleDoubleDown } from "react-icons/fa";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { FaDotCircle } from "react-icons/fa";
import { HiEye } from "react-icons/hi2";
import {
  useFetchMembershipHistoryByAppUserIDMutation,
  useFetchMembershipHistoryByMembershipIdMutation,
  useFetchMembershipHistoryExportByMembershipIdMutation,
} from "../../../features/tenant/membershipHistory/membershipHistoryApi";

function convertToCSV(data) {
  const header = Object.keys(data[0]).join(",") + "\n";

  const csv = data.reduce((acc, row) => {
    const values = Object.values(row)
      .map((value) => {
        if (typeof value === "string") {
          // Escape double quotes and handle special characters
          return `"${value.replace(/"/g, '""')}"`;
        } else {
          return value;
        }
      })
      .join(",");

    return acc + values + "\n";
  }, header);

  return csv;
}

function downloadCSV(data, filename) {
  const csv = convertToCSV(data);

  const blob = new Blob([csv], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}

const stars = [Rank1, Gold, silver, platinum, silver, silver];

const MembershipHistory = () => {
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
      // width: "120px",
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Tier",
      selector: (row) => (
        <div className="flex">
          {/* <div className="w-10 h-20 rounded-full flex justify-center items-center bg-white  ">
            <span className="rounded-full p-2">
              <img
                src={stars[row.current_tier_rank - 1]}
                className="max-h-full max-w-full "
              />
            </span>
          </div>{" "} */}
          {row.current_tier_name}
        </div>
      ),
      sortable: true,
    },

    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
    },

    {
      name: "",
      selector: (row) => (
        // editMembershipPermission &&
        <div>
          <button
            onClick={() =>
              fetchMembershipHistoryAppUser({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                  .tenant_id,
                membership_id: row.membership_id,
                app_user_id: row.app_user_id,
              })
            }
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiFillEye className="mr-0.5" />
            View
          </button>
        </div>
      ),
      width: "110px",
    },
  ];

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const location = useLocation();

  const initialRequest = {
    name: "",
    mobile: "",
  };

  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [
    fetchMembershipHistory,
    {
      data: membershipHistoryData,
      isLoading: membershipHustoryIsLoading,
      error: membershipHistoryError,
    },
  ] = useFetchMembershipHistoryByMembershipIdMutation();

  useEffect(() => {
    if (location.state) {
      if (request.name === "" && request.mobile === "") {
        fetchMembershipHistory({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
          id: location.state.id,
          body: request,
        });
      }
    }
  }, [location, request]);

  const [
    fetchMembershipHistoryAppUser,
    {
      data: membershipHistoryDataAppUser,
      isLoading: membershipHustoryIsLoadingAppUser,
      error: membershipHistoryErrorAppUser,
    },
  ] = useFetchMembershipHistoryByAppUserIDMutation();

  useEffect(() => {
    if (membershipHistoryDataAppUser && membershipHistoryDataAppUser.body) {
      console.log("membershipHistoryDataAppUser", membershipHistoryDataAppUser);
      setModalData(membershipHistoryDataAppUser.body);
      setShowModal(true);
    }

    if (membershipHistoryErrorAppUser) {
      toast.error("Something went wrong", {
        id: "membershipHistoryErrorAppUser",
      });
      console.log(
        "membershipHistoryErrorAppUser",
        membershipHistoryErrorAppUser,
      );
    }
  }, [membershipHistoryDataAppUser, membershipHistoryErrorAppUser]);

  const [
    fetchMembershipHistoryExport,
    {
      data: membershipHistoryExportData,
      isLoading: membershipHistoryExportIsLoading,
      error: membershipHistoryExportError,
    },
  ] = useFetchMembershipHistoryExportByMembershipIdMutation();

  const handleExportCSV = () => {
    if (location && location.state)
      fetchMembershipHistoryExport({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: location.state.id,
      });
  };

  useEffect(() => {
    if (membershipHistoryExportData && membershipHistoryExportData.body) {
      console.log("membershipHistoryExportData", membershipHistoryExportData);
      downloadCSV(membershipHistoryExportData.body, "membership-history");
    }

    if (membershipHistoryExportError) {
      console.log("membershipHistoryExportError", membershipHistoryExportError);
      toast.error("Something went wrong", {
        id: "membershipHistoryExportError",
      });
    }
  }, [membershipHistoryExportData, membershipHistoryExportError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Membership History
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <button
                  className="btn btn-outline-primary clear-both float-left mb-2"
                  onClick={handleExportCSV}
                >
                  Export CSV
                </button>
                <div className="flex  space-x-2 justify-end">
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 placeholder:text-gray-400 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 placeholder:text-gray-400 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                      <button
                        className="py-2 btn btn-outline-primary clear-both float-right  p-2"
                        onClick={() => {
                          fetchMembershipHistory({
                            token: sessionStorage.getItem("access_token"),
                            tenant_id: JSON.parse(
                              sessionStorage.getItem("vendorData"),
                            ).tenant_id,
                            id: location.state.id,
                            body: request,
                          });
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <button
                        className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={membershipHistoryData ? membershipHistoryData.body : []}
              ></DataTableComponent>
              {
                //   addMembershipPermission &&
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  {/* <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div> */}
                </div>
              }
            </div>
          </div>
        </div>
        {showModal && modalData && (
          <ModalComponent
            size={"md"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full rounded-sm p-3">
              <div className="container mx-auto flex flex-col justify-center items-center">
                <h2 className="text-xl text-center font-semibold mb-2">
                  Membership Tier History
                </h2>
                <ul className="mx-auto w-96">
                  {modalData.map((tier, index) => (
                    <li
                      key={index}
                      className={`border-2 flex justify-between p-2 rounded-md shadow-md mb-2 bg-gray-100 ${tier.previous_tier_name
                        ? tier.current_tier_rank < tier.previous_tier_rank
                          ? "border-green-400"
                          : tier.current_tier_rank == tier.previous_tier_rank ? " " : "border-red-400"
                        : ""
                        }`}
                    >
                      <div>
                        <div className="flex justify-start items-center space-x-1">
                          <h3 className="text-lg font-semibold mb-px">
                            {tier.current_tier_name}
                          </h3>
                          <span className="rounded-full w-10 h-10">
                            <img
                              src={stars[tier.current_tier_rank - 1]}
                              className="h-full w-full "
                            />
                          </span>
                        </div>
                        <p className="text-sm text-gray-600 mb-px">
                          {tier.previous_tier_name
                            ? `Previous Tier: ${tier.previous_tier_name}`
                            : "No Previous Tier"}
                        </p>
                        <p className="text-sm text-gray-600 mb-px">
                          Rank: {tier.current_tier_rank}
                        </p>
                        <p className="text-sm text-gray-600 mb-px">
                          Joined on:{" "}
                          {new Date(tier.created_at).toLocaleDateString()}
                        </p>
                      </div>
                      <div className="w-5 h-5">
                        {tier.previous_tier_name ? (
                          tier.current_tier_rank < tier.previous_tier_rank ? (
                            <FaAngleDoubleDown className="w-full rotate-180 h-full text-green-500" />
                          ) : tier.current_tier_rank == tier.previous_tier_rank ? (
                            <FaDotCircle className="w-full  h-full text-gray-500" />
                          ) : (
                            <FaAngleDoubleDown className="w-full  h-full text-red-500" />
                          )
                        ) : (
                          <FaDotCircle className="w-full  h-full text-gray-500" />
                        )}
                      </div>

                      {/* <div className="">
                        <p className="text-base font-semibold">{tier.name}</p>
                        <p className="text-sm text-gray-600">{tier.email}</p>
                        <p className="text-sm text-gray-600">{tier.mobile}</p>
                      </div> */}
                      {/* <p className="text-sm text-gray-600">
                        Status: {tier.status === "1" ? "Active" : "Inactive"}
                      </p> */}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default MembershipHistory;
