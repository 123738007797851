import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchAllReassignWarrantyCountMutation, useFetchAllReassignWarrantyMutation } from "../../../features/tenant/warranty/reAssignWarrantyApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";

const ReassignWarrantyList = () => {
    const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    old_batch_running_code: "",
    product_code: "",
    new_batch_running_code: "",
    limit: 10,
    offset: 0,
    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [prevPage, setPrevPage] = useState(0);

  const [
    fetchAllReassignWarranty,
    {
      data: fetchAllReassignWarrantyData,
      isLoading: fetchAllReassignWarrantyIsLoading,
      isError: fetchAllReassignWarrantyIsError,
      error: fetchAllReassignWarrantyError,
    },
  ] = useFetchAllReassignWarrantyMutation();

  const [
    fetchAllReassignWarrantyCount,
    {
      data: fetchAllReassignWarrantyCountData,
      isLoading: fetchAllReassignWarrantyCountIsLoading,
      isError: fetchAllReassignWarrantyCountIsError,
      error: fetchAllReassignWarrantyCountError,
    },
  ] = useFetchAllReassignWarrantyCountMutation();


  const navigate = useNavigate();

//   useEffect(() => {
//     const hasViewPermission = hasPermission("video");
//     if (!hasViewPermission) {
//       navigate(`/genefied/${getVendorName()}/`);
//     }

//     if (fetchAllReassignWarrantyData) {
//       toast.success("Video Added !");
//       navigate(`/genefied/${getVendorName()}/video`);
//     }
//   }, [fetchAllReassignWarrantyData, fetchAllReassignWarrantyError]);

const handleFetchWarranty = () => {
    fetchAllReassignWarranty({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const handleFetchWarrantyCount = () => {
    const modifiedRequest={
        ...request,
        limit:null,
        offset:null
    }
    fetchAllReassignWarrantyCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };
  useEffect(() => {
    handleFetchWarranty();
    handleFetchWarrantyCount();
  }, []);

  const warrantyColumns = [
    {
      name: "SNo",
      cell: (row, idx) => idx + 1,
      sortable: true,
        width: "80px",
    },
    {
        name: "Product Code",
        cell: (row) => row.product_code,
        sortable: true,
        // width: "120px",
      },
    {
      name: "Old Batch Running Code",
      cell: (row) => row.old_batch_running_code,
      sortable: true,
      // width: "120px",
    },
    {
      name: "New Batch Running Code",
      cell: (row) => row.new_batch_running_code,
      sortable: true,
    },

    {
        name: "Created At",
        selector: (row) => new Date(row.created_at).toLocaleString(),
        sortable: true,
        width: "170px",
      },
  
    {
        name: "Created By Name",
        selector: (row) => row.created_by_name,
        sortable: true,
        width: "170px",
      },

 
  ];

  
  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Reassigned Warranty List
            <LinksComponent />
          </div>
          <div className="p-3">
          <div className="w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Old-BR-Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Old Batch Running Code..."
                        required
                        value={request.old_batch_running_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, old_batch_running_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">New-BR-Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="New Batch Running Code..."
                        required
                        value={request.new_batch_running_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, new_batch_running_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">P-Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Code ..."
                        required
                        value={request.product_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-32 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              start_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-44 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              end_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
            
                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                        onClick={() => {
                            handleFetchWarranty();
                            handleFetchWarrantyCount();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {fetchAllReassignWarrantyIsLoading && <CircleLoaderComponent />}
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={warrantyColumns}
                data={fetchAllReassignWarrantyData?.body}
                loading={fetchAllReassignWarrantyIsLoading}
                totalRows={fetchAllReassignWarrantyCountData?.body?.total}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReassignWarrantyList;
