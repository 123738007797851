import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import { HiOutlinePlus } from "react-icons/hi";
import { Menu } from "@headlessui/react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { toast } from "react-hot-toast";
import { useGetAllWheelCampaignMutation } from "../../../features/tenant/wheelCampaign/wheelCampaignApi";
import { getVendorName } from "../../../utils/getVendorName";

const WheelCampaignList = () => {
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };
  
  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const [
    getWheelCampaign,
    {
      data: getWheelCampaignData,
      isLoading: getWheelCampaignIsLoading,
      isError: getWheelCampaignIsError,
      error: getWheelCampaignError,
    },
  ] = useGetAllWheelCampaignMutation();
  const getAllWheelCampaign = () => {
    getWheelCampaign({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  };

  useEffect(() => {
    getAllWheelCampaign();
  }, []);

  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      width: "120px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => new Date(row.start_date).toLocaleString(),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => new Date(row.end_date).toLocaleString(),
      sortable: true,
    },
    {
      name: "Budget",
      selector: (row) => row.budget,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) =>
        row.role_name !== "Admin" && (
          <div className="flex justify-center space-x-2 m-auto">
            <>
              <button
                onClick={() => navigate(`/genefied/${getVendorName()}/wheel`, { state: row })}
                className="flex justify-center items-center rounded bg-primary px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                <AiOutlineEdit className="mr-1" />
                Edit
              </button>
            </>
            <>
              <button
                onClick={() => navigate(`/genefied/${getVendorName()}/wheel/summary`, { state: row })}
                className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
              >
                <AiOutlineDelete className="mr-1" />
               Details
              </button>
            </>
          </div>
        ),
      width: "20%",
    },
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Wheel Campaigns
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={getWheelCampaignData && getWheelCampaignData.body.wheelCampaigns}
                loading={getWheelCampaignData}
                totalRows={
                    getWheelCampaignData && getWheelCampaignData.body.totalWheelCampaigns
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
               
              </DataTableComponent>
              <div
                className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                id="box_btn"
              >
                <div className="text-right">
                  <Menu className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        onClick={() => navigate(`add`)}
                        className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                        style={{ backgroundColor: `${primaryColor["700"]}` }}
                      >
                        <HiOutlinePlus className="w-full h-full p-3" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WheelCampaignList;
