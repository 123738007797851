import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import "./dash.css";
import {
  useGetAllAppUserTypeInMonthFormatMutation,
  useGetAppUserTypesMutation,
  useGetAppUserTypesDashboardMutation
} from "../../../features/tenant/appUsers/appUsersApi";
import { useFetchScanLeaderBoardByUserTypeIdMutation } from "../../../features/tenant/leaderBoard/leaderBoardApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { GenChart } from "../../../components/Tenant/Charts/GenChart";
import { toast } from "react-hot-toast";
import {
  useFetchProductsCategoriesTotalMrpFromUserPointEntriesMonthWiseMutation,
  useFetchProductsCategoriesTotalScanCountFromUserPointEntriesMonthWiseMutation,
  useFetchProductsCategoriesWithTopPercentageMutation,
  useFetchProductsClassificationTotalCountFromUserPointEntriesMonthWiseMutation,
  useFetchProductsClassificationTotalMrpFromUserPointEntriesMonthWiseMutation,
  useFetchProductsCodeTotalCountFromUserPointEntriesMonthWiseMutation,
  useFetchUserTypeTotalMrpSumFromUserPointEntriesMonthWiseMutation,
} from "../../../features/tenant/products/productsApi";
import {
  useFetchAllQrCodeswithScannedMutation,
  useGetGenerationVsScanningStatsMutation,
} from "../../../features/tenant/qrCode/qrCodeApi";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { StackedBarChart } from "../../../components/Tenant/Charts/StackedBarChart";
import BarcChartStacked from "../../../components/Tenant/Charts/BarChartStacked";
import { useFetchAllPaymentTransferMutation } from "../../../features/tenant/paymentTransfer/paymentTransferApi";
import { useFetchAllCashTransactionsStatsMutation } from "../../../features/tenant/cashTransactions/cashTransactionsApi";
import { useFetchAllQrCodeCountMonthWiseMutation } from "../../../features/tenant/qrCode/qrCodeApi";
import { useFetchQrScanHistoryMonthlyMutation } from "../../../features/tenant/appUserQrScan/appUserQrScanApi";
import IndiaMap from "../../../components/Tenant/Charts/IndiaMap";
import IndiaMapRegistrations from "../../../components/Tenant/Charts/IndiaMapRegistrations";
import { useFetchHeirarchyMutation } from "../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { calculateParentCategoryScores } from "../../../utils/getProductCatgeoryScanningBySubcategory";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { getStartDateEndDate } from "../../../utils/getStartDateEndDate";
import YearMonthWeekBarChart from "../../../components/Tenant/Charts/YearMonthWeekBarChart";
import {
  useGetPointRedemptionStatsMutation,
  useGetRegistrationStatsMutation,
  useGetScanningVsRedemptionStatsMutation,
  useGetUserScanningStatsMutation,
} from "../../../features/tenant/appUserType/appUserTypeApi";
import ScanningVsRedemption from "../../../components/Tenant/Charts/ScanningVsRedemption";
import GenerationVsScanning from "../../../components/Tenant/Charts/GenerationVsScanning";
import {
  useGetAllLocationsByZoneMutation,
  useGetAllZoneMutation,
} from "../../../features/tenant/zones/zonesApi";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import { useGetAllStateMutation } from "../../../features/tenant/locations/locations.api";
import { findMatchingStates } from "../../../utils/findMatchingStates";

const Dashboard = () => {
  const Graphs = JSON.parse(sessionStorage.getItem("vendorData")).graphs || [];
  const months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];
  const yearArr = [
    { id: 24, name: "2023" },
    { id: 25, name: "2024" },
    { id: 26, name: "2025" },
    { id: 27, name: "2026" },
    { id: 28, name: "2027" },
    { id: 29, name: "2028" },
    { id: 30, name: "2029" },
    { id: 31, name: "2030" },
    { id: 32, name: "2031" },
    { id: 33, name: "2032" },
    { id: 34, name: "2033" },
    { id: 35, name: "2034" },
    { id: 36, name: "2035" },
    { id: 37, name: "2036" },
    { id: 38, name: "2037" },
    { id: 39, name: "2038" },
    { id: 40, name: "2039" },
    { id: 41, name: "2040" },
    { id: 42, name: "2041" },
    { id: 43, name: "2042" },
    { id: 44, name: "2043" },
    { id: 45, name: "2044" },
    { id: 46, name: "2045" },
    { id: 47, name: "2046" },
    { id: 48, name: "2047" },
    { id: 49, name: "2048" },
    { id: 50, name: "2049" },
    { id: 51, name: "2050" },
    { id: 52, name: "2051" },
    { id: 53, name: "2052" },
    { id: 54, name: "2053" },
    { id: 55, name: "2054" },
    { id: 56, name: "2055" },
    { id: 57, name: "2056" },
    { id: 58, name: "2057" },
    { id: 59, name: "2058" },
    { id: 60, name: "2059" },
    { id: 61, name: "2060" },
    { id: 62, name: "2061" },
    { id: 63, name: "2062" },
    { id: 64, name: "2063" },
    { id: 65, name: "2064" },
    { id: 66, name: "2065" },
    { id: 67, name: "2066" },
    { id: 68, name: "2067" },
    { id: 69, name: "2068" },
    { id: 70, name: "2069" },
    { id: 71, name: "2070" },
    { id: 72, name: "2071" },
    { id: 73, name: "2072" },
    { id: 74, name: "2073" },
    { id: 75, name: "2074" },
    { id: 76, name: "2075" },
    { id: 77, name: "2076" },
    { id: 78, name: "2077" },
    { id: 79, name: "2078" },
    { id: 80, name: "2079" },
    { id: 81, name: "2080" },
    { id: 82, name: "2081" },
    { id: 83, name: "2082" },
    { id: 84, name: "2083" },
    { id: 85, name: "2084" },
    { id: 86, name: "2085" },
    { id: 87, name: "2086" },
    { id: 88, name: "2087" },
    { id: 89, name: "2088" },
    { id: 90, name: "2089" },
    { id: 91, name: "2090" },
    { id: 92, name: "2091" },
    { id: 93, name: "2092" },
    { id: 94, name: "2093" },
    { id: 95, name: "2094" },
    { id: 96, name: "2095" },
    { id: 97, name: "2096" },
    { id: 98, name: "2097" },
    { id: 99, name: "2098" },
    { id: 100, name: "2099" },
  ];
  const darkColors = [
    "#004EDE",
    "#F66B4E",
    "#1CA2D5",
    "#00A54D",
    "#3B2947",
    "#19abde",
    "#1de4bd",
    "#6df0d1",
    "#29066c",
    "#7d39c0",
    "#da4cb2",
    "#ec548d",
    "#e97369",
    "#f0a58e",
    "#810400",
    "#bf2324",
    "#de542d",
    "#ef7e32",
    "#eabe3b",
  ];
  const [date, setDate] = useState({ id: 25, name: "2024" });
  const [selectedMonth, setSelectedMonth] = useState({
    id: new Date().getMonth() + 1,
    name: months[new Date().getMonth()].name,
  });
  const [selectedMonthForRedVsScan, setSelectedMonthRedVsScan] = useState({
    id: new Date().getMonth() + 1,
    name: months[new Date().getMonth()].name,
  });

  const [selectedMonthForGenVsScan, setSelectedMonthGenVsScan] = useState({
    id: new Date().getMonth() + 1,
    name: months[new Date().getMonth()].name,
  });
  const navigate = useNavigate();
  const [selectedZone, setSelectedZone] = useState();
  const [allZones, setAllZones] = useState(null);
  const [appUsers, setAppUsers] = useState([]);
  const [userTypesArray, setUserTypesArray] = useState();
  const [userTypesArrayInMap, setUserTypesArrayInMap] = useState();


  const [allStates, setAllStates] = useState([]);

  const [selectedState, setSelectedState] = useState();
  const [validStates, setValidStates] = useState([]);

  const [
    getAllZone,
    {
      data: getAllZoneData,
      isLoading: getAllZoneIsLoading,
      error: getAllZoneError,
    },
  ] = useGetAllZoneMutation();

  const [
    getAllState,
    {
      data: getAllStateData,
      isLoading: getAllStateIsLoading,
      isError: getAllStateIsError,
      error: getAllStateError,
    },
  ] = useGetAllStateMutation();

  const [
    getAllLocationsZone,
    {
      data: getAllLocationZoneData,
      isLoading: getAllLocationZoneIsLoading,
      error: getAllLocationZoneError,
    },
  ] = useGetAllLocationsByZoneMutation();

  const [
    fetchUserQrScanHistory,
    { data: qrScanHistoryData, error: qrScanHistoryError },
  ] = useFetchQrScanHistoryMonthlyMutation();

  const [
    getAppUserTypes,
    { data: getAppUserTypesData, error: getAppUserTypesError },
  // ] = useGetAppUserTypesMutation();
  ] = useGetAppUserTypesDashboardMutation();

  const [
    getAppUserTypesInMonthFormat,
    {
      data: getAppUserTypesInMonthFormatData,
      error: getAppUserTypesInMonthFormatError,
    },
  ] = useGetAllAppUserTypeInMonthFormatMutation();

  const [
    getScanLeaderBoardByUserTypeId,
    {
      data: getScanLeaderBoardByUserTypeIdData,
      error: getScanLeaderBoardByUserTypeIdError,
      isLoading: getScanLeaderBoardByUserTypeIdisLoading,
    },
  ] = useFetchScanLeaderBoardByUserTypeIdMutation();

  const [
    fetchAllQrCodeswithScanned,
    {
      data: fetchAllQrCodeswithScannedData,
      isLoading: fetchAllQrCodeswithScannedDataIsLoading,
      isError: fetchAllQrCodeswithScannedDataIsError,
      error: fetchAllQrCodeswithScannedDataError,
    },
  ] = useFetchAllQrCodeswithScannedMutation();

  const [
    fetchProductsCategoriesWithPercentage,
    {
      data: fetchProductsCategoriesWithPercentageData,
      error: fetchProductsCategoriesWithPercentageError,
    },
  ] = useFetchProductsCategoriesWithTopPercentageMutation();

  const [
    fetchProductsCodeTotalCount,
    {
      data: fetchProductsCodeTotalCountData,
      error: fetchProductsCodeTotalCountError,
      isLoading: fetchProductsCodeTotalCountisLoading,
    },
  ] = useFetchProductsCodeTotalCountFromUserPointEntriesMonthWiseMutation();

  const [
    fetchAllQrCodeMonthWise,
    {
      data: allQrCodeMonthWiseData,
      isLoading: allQrCodeMonthWiseDataIsLoading,
      isError: allQrCodeMonthWiseDataIsError,
      error: allQrCodeMonthWiseDataError,
    },
  ] = useFetchAllQrCodeCountMonthWiseMutation();

  useEffect(() => {
    fetchallQrCodeMonthWiseDataFunction();
    getAllZone({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
    const allStateData = JSON.parse(sessionStorage.getItem("zones-states"));
    // console.log("allStateData", allStateData);
    if(allStateData == null){ 
      getAllState({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }else {
      setAllStates(allStateData);
    }
  }, []);


  
  useEffect(() => {
    if (getAllStateData && getAllStateData.body) {
      let allStates = getAllStateData.body.map((x) => {
        return {
          id: x.id,
          name: x.state,
        };
      });
      sessionStorage.setItem("zones-states", JSON.stringify(allStates));
      // console.log("allStates", allStates);
      setAllStates(allStates);
    }
  }, [getAllStateData, getAllStateError]);

  const fetchallQrCodeMonthWiseDataFunction = () => {
    fetchAllQrCodeMonthWise({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  // useEffect(() => {
  //   fetchallQrCodeHistoryMonthWiseDataFunction();
  // }, []);

  const fetchallQrCodeHistoryMonthWiseDataFunction = () => {
    fetchUserQrScanHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };
  const [
    fetchPaymentTransferList,
    {
      data: paymentTransferListData,
      error: paymentTransferListError,
      isLoading: ppaymentTransferListisLoading,
    },
  ] = useFetchAllPaymentTransferMutation();

  const [
    fetchPaymentTransferStats,
    {
      data: paymentTransferStatsData,
      error: paymentTransferStatsError,
      isLoading: ppaymentTransferStatsisLoading,
    },
  ] = useFetchAllCashTransactionsStatsMutation();

  const fetchProductsCategoriesWithPercentageFun = () => {
    fetchProductsCategoriesWithPercentage({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  const fetchAllQrCodeFunction = () => {
    fetchAllQrCodeswithScanned({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  useEffect(() => {
    fetchAllQrCodeFunction();
  }, []);

  // useEffect(() => {
  //   if (Graphs.includes("Product Categories With Percentage")) {
  //     fetchProductsCategoriesWithPercentageFun();
  //   }
  //   if (Graphs.includes("Product Sub Categories With Percentage")) {
  //     fetchProductsCategoriesWithPercentageFun();
  //   }
  // }, []);

  useEffect(() => {
    getAppUserTypesInMonthFormat({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });

    if (Graphs.includes("Total Amount Transacted")) {
      fetchPaymentTransferList({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }

    if (Graphs.includes("Payment Stats")) {
      fetchPaymentTransferStats({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
  }, []);

  useEffect(() => {
    if (userTypesArray && userTypesArray?.user_type) {
      getScanLeaderBoardByUserTypeIdFun();
    }
  }, [userTypesArray, date, selectedZone]);

  const getScanLeaderBoardByUserTypeIdFun = () => {
    // console.log(date, "DATE");

    const val = getStartDateEndDate(date);

    if (!val) {
      return;
    }

    const zoneIds = [];
    if (!selectedZone || selectedZone.id == -1) {
      setSelectedZone(allZones[0]);
    }
    if(selectedZone.id == -2){
      allZones.forEach( (zone) => {
        zoneIds.push(zone.id);
      })
    }else {
      zoneIds.push(selectedZone.id);
    }
    const state = selectedState ? selectedState.name : undefined;

    if (userTypesArray && userTypesArray?.user_type) {
      getScanLeaderBoardByUserTypeId({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        limit: 10,
        offset: 0,
        type: "scan",
        userType: userTypesArray?.user_type,
        startDate: val.startDate,
        endDate: val.endDate,
        zone_id: JSON.stringify(zoneIds),
        state: state
      });
    }
  };
  
  useEffect(() => {
    if (getAppUserTypesData) {
      setAppUsers(getAppUserTypesData.body);
      if (getAppUserTypesData && getAppUserTypesData.body.length) {
        setUserTypesArray(getAppUserTypesData.body[0]);
        setUserTypesArrayInMap(getAppUserTypesData.body[0]);
      }
      sessionStorage.setItem(
        "appUserType",
        JSON.stringify(getAppUserTypesData.body),
      );
    }
  }, [getAppUserTypesData]);

  // useEffect(() => {
  //   if (getAppUserTypesData) {
  //     sessionStorage.setItem(
  //       "appUserType",
  //       JSON.stringify(getAppUserTypesData.body)
  //     );
  //     window.location.reload();
  //   }
  // }, [getAppUserTypesData]);

  const UpdateUsersData = (state= "") => {
    // window.alert(`Update all ${state}`);
    if (allZones && allZones.length == 0) {
      return;
    }

    const zoneIds = [];
    if (!selectedZone || selectedZone.id == -1) {
      setSelectedZone(allZones[0]);
    }

    if(selectedZone.id == -2){
      allZones.forEach( (zone) => {
        zoneIds.push(zone.id);
      })
    }else {
      zoneIds.push(selectedZone.id);
    }

    console.log("selected zones" , JSON.stringify(zoneIds));

    getAppUserTypes({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      zone_id: JSON.stringify(zoneIds),
      state : state
    });
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [
    fetchHeirarchy,
    {
      data: heirarchyData,
      isLoading: heirarchyIsLoading,
      isError: heirarchyIsError,
      error: heirarchyError,
    },
  ] = useFetchHeirarchyMutation();

  useEffect(() => {
    fetchHeirarchy({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  const [productCategoryScanning, setProductCategoryScanning] = useState(null);

  useEffect(() => {
    if (fetchProductsCategoriesWithPercentageData && heirarchyData) {
      // console.log("heirarchyData", heirarchyData);

      const data = calculateParentCategoryScores(
        fetchProductsCategoriesWithPercentageData.body,
        heirarchyData.body,
      );
      setProductCategoryScanning(data);
      //console.log("data", data);
    }
  }, [heirarchyData, fetchProductsCategoriesWithPercentageData]);

  const [
    fetchTotalMrpFromUPEMonthWise,
    {
      data: totalMrpFromUPEMonthWiseData,
      error: totalMrpFromUPEMonthWiseError,
      isLoading: totalMrpFromUPEMonthWiseisLoading,
    },
  ] = useFetchProductsCategoriesTotalMrpFromUserPointEntriesMonthWiseMutation();

  const updateAllGraphsByZoneIdAndState = (zoneIds = [] , state = "") => {

    // console.log("ZoneID & States ",zoneIds , state)

    if (Graphs.includes("Category Wise Total MRP - Product")) {
      fetchTotalMrpFromUPEMonthWise({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });
    }

    if (Graphs.includes("Product Code Wise Scanning - UPE")) {
      fetchProductsCodeTotalCount({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });
    }

    if (Graphs.includes("User Registration")) {
      getRegistrationStats({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });
    }

    if (Graphs.includes("User Scan Vs Redemption")) {
      getScanningVsRedemptionStats({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });
    }

    if (Graphs.includes("User Type Wise Total Mrp - UPE")) {
      fetchUserTypeTotalMrpSumFromUPEMonthWise({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });

    }

    if (Graphs.includes("Category Wise Total Scanning - Product")) {
      fetchTotalScanCountFromUPEMonthWise({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });
    }

    if (Graphs.includes("Classification Wise Total Scanning - Product")) {
      fetchProductClassificationTotalCountFromUPEMonthWise({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });
    }

    if (Graphs.includes("Classification Wise Total MRP - Product")) {
      fetchProductClassificationTotalMrpFromUPEMonthWise({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });
    }

    if (Graphs.includes("User Redemption")) {
      getPointRedemptionStats({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });
    }

    if (Graphs.includes("User Scanning")) {
      getUserScanningStats({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: JSON.stringify(zoneIds),
        state : state
      });
    }
  }

  useEffect(() => {
    if (allZones && allZones.length > 0) {
      // console.log("selected zone: ", selectedZone);

      if (!selectedZone || selectedZone.id == -1) {
        setSelectedZone(allZones[0]);
      }

      getAllLocationsZone({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: selectedZone.id,
      });
    }
  }, [selectedZone, allZones]);


  useEffect(() => {
    if (allZones && allZones.length > 0 && getAllLocationZoneData && getAllLocationZoneData.body) {
      // console.log("selected zone: ", selectedZone);

      const zoneIds = [];
      if (!selectedZone || selectedZone.id == -1) {
        setSelectedZone(allZones[0]);
      }
      setSelectedState(null);
      setValidStates([]);
      // zoneIds.push(selectedZone.id);

      if(selectedZone.id == -2){
        allZones.forEach( (zone) => {
          zoneIds.push(zone.id);
        })
      }else {
        zoneIds.push(selectedZone.id);
      }
  
      // console.log("selected zones" , JSON.stringify(zoneIds));
  

      // console.log("inside", allStates, getAllLocationZoneData.body);
      const matchingData = findMatchingStates(allStates, getAllLocationZoneData.body);
      // console.log("matchingData", matchingData);
      setValidStates(matchingData);

      // toast.dismiss();
      // const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
      // setAppUsers(appUserTypes);
      // if (appUserTypes && appUserTypes.length) {
      //   setUserTypesArray(appUserTypes[0]);
      //   setUserTypesArrayInMap(appUserTypes[0]);
      // }

      // if (!appUserTypes) {
      //   getAppUserTypes({
      //     tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      //     zone_id: JSON.stringify(zoneIds),
      //   });
      // }

      UpdateUsersData();

      getScanLeaderBoardByUserTypeIdFun();

      updateAllGraphsByZoneIdAndState(zoneIds)
     
    }
  }, [selectedZone, allZones , allStates , getAllLocationZoneData]);

  useEffect(() => {
    if(selectedState && selectedZone){

      const zoneIds = [];
      zoneIds.push(selectedZone.id);

      const state = selectedState.name;

      // console.log("valid selected state ",selectedState,  zoneIds, state)

      UpdateUsersData(state);

      getScanLeaderBoardByUserTypeIdFun(state);

      updateAllGraphsByZoneIdAndState(zoneIds, state)

    }
  }, [selectedState , selectedZone])
  

  const [
    fetchTotalScanCountFromUPEMonthWise,
    {
      data: totalScanCountFromUPEMonthWiseData,
      error: totalScanCountFromUPEMonthWiseError,
      isLoading: totalScanCountFromUPEMonthWiseisLoading,
    },
  ] =
    useFetchProductsCategoriesTotalScanCountFromUserPointEntriesMonthWiseMutation();

  const [
    fetchProductClassificationTotalCountFromUPEMonthWise,
    {
      data: productClassificationTotalCountFromUPEMonthWiseData,
      error: productClassificationTCountFromUPEMonthWiseError,
      isLoading: productClassificationTotalCountFromUPEMonthWiseisLoading,
    },
  ] =
    useFetchProductsClassificationTotalCountFromUserPointEntriesMonthWiseMutation();

  const [
    fetchProductClassificationTotalMrpFromUPEMonthWise,
    {
      data: productClassificationTotalMrpFromUPEMonthWiseData,
      error: productClassificationTotalMrpFromUPEMonthWiseError,
      isLoading: productClassificationTotalMrpFromUPEMonthWiseisLoading,
    },
  ] =
    useFetchProductsClassificationTotalMrpFromUserPointEntriesMonthWiseMutation();

  const [
    getRegistrationStats,
    {
      data: getRegistrationStatsData,
      error: getRegistrationStatsError,
      isLoading: getRegistrationStatsisLoading,
    },
  ] = useGetRegistrationStatsMutation();

  const [
    getPointRedemptionStats,
    {
      data: getPointRedemptionStatsData,
      error: getPointRedemptionStatsError,
      isLoading: getPointRedemptionStatsisLoading,
    },
  ] = useGetPointRedemptionStatsMutation();

  const [
    getUserScanningStats,
    {
      data: getUserScanningStatsData,
      error: getUserScanningStatsError,
      isLoading: getUserScanningStatsisLoading,
    },
  ] = useGetUserScanningStatsMutation();

  const [
    getScanningVsRedemptionStats,
    {
      data: getScanningVsRedemptionStatsData,
      error: getScanningVsRedemptionStatsError,
      isLoading: getScanningVsRedemptionStatsisLoading,
    },
  ] = useGetScanningVsRedemptionStatsMutation();

  const [
    getQRGenerationVsScanningStats,
    {
      data: getQRGenerationVsScanningStatsData,
      error: getQRGenerationVsScanningStatsError,
      isLoading: getQRGenerationVsScanningStatsisLoading,
    },
  ] = useGetGenerationVsScanningStatsMutation();

  useEffect(() => {
    if (Graphs.includes("Qr Scan Vs Generations")) {
      getQRGenerationVsScanningStats({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
  }, []);

  const [
    fetchUserTypeTotalMrpSumFromUPEMonthWise,
    {
      data: userTypeTotalMrpSumFromUPEMonthWiseData,
      error: userTypeTotalMrpSumFromUPEMonthWiseError,
      isLoading: userTypeTotalMrpSumFromUPEMonthWiseisLoading,
    },
  ] = useFetchUserTypeTotalMrpSumFromUserPointEntriesMonthWiseMutation();

  useEffect(() => {
    if (getAllZoneData && getAllZoneData.body) {
      // console.log("getAllZoneData", getAllZoneData.body);
      const arr = [];
      arr.push({ id: 0, name: "No Zone Alloted" });
      arr.push({ id: -2, name: "All Zones" });
      getAllZoneData.body.forEach((element) => {
        arr.push({ id: element.id, name: element.zone_name });
      });
      setSelectedZone(arr[1]);
      setAllZones(arr);
    }
    if (getAllZoneError) {
      toast.error("error fetching all zones" , { id : "getAllZoneError"})
      // console.log("getAllZoneError", getAllZoneError);
    }
  }, [getAllZoneData, getAllZoneError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full  ">
          <div className="border-b flex justify-between items-center bg-white border-gray-300 pl-6 py-1 shadow-sm text-xl font-bold">
            <span>
              Dashboard
              <LinksComponent />
            </span>

            <button
              className="btn btn-outline-primary mr-3"
              onClick={() => UpdateUsersData()}
            >
              Refresh
            </button>
          </div>

          <div className="p-3 ">
            <div className="w-full flex justify-start space-x-3 ">
              <div className="mb-6 w-1/5">
                {allZones && allZones.length > 0 && (
                  <div className="w-full">
                    <SelectComponent
                      className="w-full"
                      title={"Select Zone"}
                      selected={selectedZone}
                      setSelected={setSelectedZone}
                      dataArr={allZones}
                    />
                  </div>
                )}
              </div>

              <div className="mb-6 w-1/5">
                {validStates && validStates.length > 0 && (
                  <div className="w-full">
                    <SelectComponent
                      className="w-full"
                      title={"Select State"}
                      selected={selectedState}
                      setSelected={setSelectedState}
                      dataArr={validStates}
                    />
                  </div>
                )}
              </div>
              
            </div>

            {Graphs.includes("Total Users") && (
              <div className="clear-both">
                <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-4 mb-3">
                  {appUsers &&
                    appUsers.map((e, i) => {
                      return (
                        <div
                          className="flex items-center px-3 py-3 rounded-xl shadow-xl"
                          style={{
                            background: `linear-gradient(45deg, ${darkColors[i]}, ${darkColors[i]})`,
                          }}
                          onClick={() =>
                            navigate(
                              `/genefied/${getVendorName()}/user/${e.name.toLowerCase()}`,
                            )
                          }
                          key={i}
                        >
                          <div
                            className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 p-1 mr-3 rounded-full bg-white"
                            // style={{
                            //   background: `linear-gradient(45deg,${darkColors[i]}, ${darkColors[i]})`,
                            //   border: `2px solid ${darkColors[i]}`,
                            // }}
                            // style={{ backgroundColor: `${primaryColor["300"]}` }}
                          >
                            <img
                              src={`${e.user_type_logo}`}
                              className="res-img w-auto"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <span className="block text-2xl font-bold">
                              {e.count}
                            </span>
                            <span className="block capitalize">{e.name}</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {Graphs.includes("Payment Stats") && (
              <div className="clear-both">
                <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 mb-3">
                  {paymentTransferStatsData &&
                    paymentTransferStatsData.body &&
                    Object.keys(paymentTransferStatsData?.body).map((e, i) => {
                      return (
                        <div
                          className="flex items-center px-3 py-3 rounded-xl shadow-xl"
                          style={{
                            background: `linear-gradient(45deg, ${darkColors[i]}, ${darkColors[i]})`,
                          }}
                        >
                          <div className="flex flex-shrink-0 items-center justify-center h-16 w-16 p-1 mr-3 rounded-full bg-white"></div>
                          <div className="text-white">
                            <span className="block text-2xl font-bold">
                              {"₹" + paymentTransferStatsData.body[e]}
                            </span>

                            <span className="block capitalize">
                              {e == "0"
                                ? "Failed"
                                : e == "1"
                                ? "Success"
                                : e == "2"
                                ? "Pending"
                                : "Not Initiated"}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            <div className="clear-both">
              <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 mb-3">
                {Graphs.includes("Total Amount Transacted") && (
                  <div className="">
                    {paymentTransferListData &&
                      paymentTransferListData.body &&
                      paymentTransferListData.body.length > 0 && (
                        <div
                          className="flex items-center px-3 py-3 rounded-xl shadow-xl"
                          style={{
                            background: `linear-gradient(45deg, ${darkColors[0]}, ${darkColors[0]})`,
                          }}
                        >
                          <div className="flex flex-shrink-0 items-center justify-center h-16 w-16 p-1 mr-3 rounded-full bg-white">
                            <svg
                              className="h-12 w-12 text-green-500"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                          <div className="text-white">
                            <span className="block text-2xl font-bold">
                              {"₹" +
                                paymentTransferListData.body.reduce(
                                  (acc, x) => {
                                    return (
                                      Number(acc) +
                                      Number(x.total_uploaded_amount_transfer)
                                    );
                                  },
                                  0,
                                )}
                            </span>

                            <span className="block capitalize">
                              Total Amount Transacted
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                )}
                {Graphs.includes("Total Upload Transaction Amount") && (
                  <div className="">
                    {paymentTransferListData &&
                      paymentTransferListData.body &&
                      paymentTransferListData.body.length > 0 && (
                        <div
                          className="flex items-center px-3 py-3 rounded-xl shadow-xl"
                          style={{
                            background: `linear-gradient(45deg, ${darkColors[0]}, ${darkColors[0]})`,
                          }}
                        >
                          <div className="flex flex-shrink-0 items-center justify-center h-16 w-16 p-1 mr-3 rounded-full bg-white">
                            <svg
                              className="h-12 w-12 text-green-500"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                          <div className="text-white">
                            <span className="block text-2xl font-bold">
                              {"₹" +
                                paymentTransferListData.body.reduce(
                                  (acc, x) => {
                                    return (
                                      Number(acc) +
                                      Number(x.total_uploaded_amount_left)
                                    );
                                  },
                                  0,
                                )}
                            </span>

                            <span className="block capitalize">
                              Total Upload Transaction Amount
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>

            <section className="grid grid-cols-2 gap-4">
              {Graphs.includes("Top Scanners") && (
                <div className="flex-1 items-center bg-white shadow-sm rounded-lg ">
                  <div>
                    <div className=" bg1 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block whitespace-nowrap">
                        Top Scanners
                      </span>
                    </div>
                  </div>

                  <div className="flextop_d">
                    <div className="">
                      {appUsers && appUsers.length > 0 && (
                        <SelectComponent
                          className="capitalize "
                          title={"User Types"}
                          selected={userTypesArray}
                          setSelected={setUserTypesArray}
                          dataArr={appUsers}
                        />
                      )}{" "}
                    </div>

                    <div className="">
                      <InputComponent
                        name={"Month"}
                        type={"month"}
                        value={date}
                        setValue={setDate}
                        required={true}
                      />
                    </div>
                    <div className="">
                      <SelectComponentWithSearch
                        title={"Year"}
                        selected={date}
                        setSelected={setDate}
                        dataArr={yearArr}
                      />
                    </div>
                  </div>

                  <div className="min-w-full px-3 py-3  rounded-lg overflow-auto odsbox">
                    {getScanLeaderBoardByUserTypeIdisLoading ? (
                      <>
                        {" "}
                        <LoaderComponent />{" "}
                      </>
                    ) : (
                      <table className="w-full  ">
                        <thead>
                          <tr>
                            <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                              Name
                            </th>
                            <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                              Mobile
                            </th>

                            <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                              Total Scan
                            </th>
                          </tr>
                        </thead>
                        {getScanLeaderBoardByUserTypeIdData?.body?.data
                          ?.length ? (
                          getScanLeaderBoardByUserTypeIdData?.body?.data.map(
                            (e, key) => {
                              return (
                                <tbody className="overflow-y-auto" key={key}>
                                  <tr>
                                    <td className="px-2 py-2 border border-gray-200  text-sm">
                                      {e.name}
                                    </td>
                                    <td className="px-2 py-2 border border-gray-200  text-sm">
                                      {e.mobile}
                                    </td>
                                    <td className="px-2 py-2 border border-gray-200  text-sm">
                                      {e.total}
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            },
                          )
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan={3}>
                                <span className="block text-gray-500 w-full p-2 text-center">
                                  <svg
                                    className="h-14 w-14 text-gray-200 m-auto"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                    <circle cx="12" cy="7" r="4" />
                                  </svg>
                                  "No Users Found"
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    )}
                  </div>
                </div>
              )}
              {Graphs.includes("Top Scanners For Retailers") && (
                <div className="flex-1 items-center bg-white shadow-sm rounded-lg ">
                  <div>
                    <div className=" bg1 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block">
                        Top Scanners
                      </span>
                    </div>
                  </div>
                  <div className="flextop_d">
                    <div className="">
                      {appUsers && appUsers.length > 0 && (
                        <SelectComponent
                          className="capitalize "
                          title={"User Types"}
                          selected={userTypesArray}
                          setSelected={setUserTypesArray}
                          dataArr={appUsers}
                        />
                      )}{" "}
                    </div>

                    <div className="">
                      <InputComponent
                        name={"Month"}
                        type={"month"}
                        value={date}
                        setValue={setDate}
                        required={true}
                      />
                    </div>
                    <div className="">
                      <SelectComponentWithSearch
                        title={"Year"}
                        selected={date}
                        setSelected={setDate}
                        dataArr={yearArr}
                      />
                    </div>
                  </div>
                  <div className="min-w-full px-3 py-3  rounded-lg overflow-auto odsbox">
                    {getScanLeaderBoardByUserTypeIdisLoading ? (
                      <>
                        {" "}
                        <LoaderComponent />{" "}
                      </>
                    ) : (
                      <table className="w-full  ">
                        <thead>
                          <tr>
                            <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                              Outlet Id
                            </th>
                            <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                              Outlet Name
                            </th>
                            <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                              Mobile
                            </th>

                            <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                              Total Scan
                            </th>
                          </tr>
                        </thead>
                        {getScanLeaderBoardByUserTypeIdData?.body?.data
                          ?.length ? (
                          getScanLeaderBoardByUserTypeIdData?.body?.data.map(
                            (e, key) => {
                              return (
                                <tbody className="overflow-y-auto" key={key}>
                                  <tr>
                                    <td className="px-2 py-2 border border-gray-200  text-sm">
                                      {e?.firm_id}
                                    </td>
                                    <td className="px-2 py-2 border border-gray-200  text-sm">
                                      {e?.firm_name}
                                    </td>
                                    <td className="px-2 py-2 border border-gray-200  text-sm">
                                      {e.mobile}
                                    </td>
                                    <td className="px-2 py-2 border border-gray-200  text-sm">
                                      {e.total}
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            },
                          )
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan={3}>
                                <span className="block text-gray-500 w-full p-2 text-center">
                                  <svg
                                    className="h-14 w-14 text-gray-200 m-auto"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                    <circle cx="12" cy="7" r="4" />
                                  </svg>
                                  "No Users Found"
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    )}
                  </div>
                </div>
              )}
              {/* {Graphs.includes("Top Scanners For Retailers") && (
                <div className="flex-1 items-center bg-white shadow-sm rounded-lg ">
                  <div>
                    <div className=" bg1 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block">
                        Top Scanners
                      </span>
                    </div>
                  </div>
                  <div className="flex slc_dvd rond">
                    {appUsers && appUsers.length > 0 && (
                      <SelectComponent
                        className="capitalize "
                        title={"User Types"}
                        selected={userTypesArray}
                        setSelected={setUserTypesArray}
                        dataArr={appUsers}
                      />
                    )}{" "}
                  </div>
                  <div className="min-w-full px-3 py-3  rounded-lg overflow-auto odsbox">
                    <table className="w-full  ">
                      <thead>
                        <tr>
                          <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                            Outlet Id
                          </th>
                          <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                            Outlet Name
                          </th>
                          <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                            Mobile
                          </th>

                          <th className="px-2 py-2 border border-gray-200 bg-orange-500 text-left text-sm font-semibold text-white  tracking-wider">
                            Total Scan
                          </th>
                        </tr>
                      </thead>
                      {getScanLeaderBoardByUserTypeIdData?.body?.data
                        ?.length ? (
                        getScanLeaderBoardByUserTypeIdData?.body?.data.map(
                          (e) => {
                            return (
                              <tbody className="overflow-y-auto">
                                <tr>
                                  <td className="px-2 py-2 border border-gray-200  text-sm">
                                    {e?.firm_id}
                                  </td>
                                  <td className="px-2 py-2 border border-gray-200  text-sm">
                                    {e?.firm_name}
                                  </td>
                                  <td className="px-2 py-2 border border-gray-200  text-sm">
                                    {e.mobile}
                                  </td>
                                  <td className="px-2 py-2 border border-gray-200  text-sm">
                                    {e.total_scan}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          },
                        )
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <span className="block text-gray-500 w-full p-2 text-center">
                                <svg
                                  className="h-14 w-14 text-gray-200 m-auto"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  {" "}
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                  <circle cx="12" cy="7" r="4" />
                                </svg>
                                "No Users Found"
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              )} */}
              {Graphs.includes("All Qr Codes with Scanned") && (
                <div className="flex-1 items-center  bg-white shadow rounded-lg">
                  <div className="bg2 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                    <span className="text-white text-lg font-semibold d-block">
                      All QR Codes with Scanned Status
                    </span>
                  </div>

                  {fetchAllQrCodeswithScannedDataIsLoading ? (
                    <LoaderComponent />
                  ) : (
                    fetchAllQrCodeswithScannedData?.body &&
                    Object.values(fetchAllQrCodeswithScannedData?.body) && (
                      <div>
                        <div className="grid grid-cols-3 gap-4 px-3 py-3">
                          <div className="bg-blue-50 border border-blue-200  text-center rounded-md px-2 py-3">
                            <span className="border-2 border-blue-500 inline-block">
                              <svg
                                className="qrwidth m-auto text-blue-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                                />
                              </svg>
                            </span>

                            <h2 className="mt-1 text-lg font-bold">
                              {
                                fetchAllQrCodeswithScannedData?.body.totalqrs
                              }
                            </h2>
                            <span>All</span>
                          </div>

                          <div className="bg-orange-100 border border-orange-200  text-center rounded-md px-2 py-3">
                            <span className="border-2 border-orange-500 inline-block">
                              <svg
                                className="qrwidth m-auto text-orange-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                                />
                              </svg>
                            </span>
                            <h2 className="mt-1 text-lg font-bold">
                              {" "}
                              {
                                fetchAllQrCodeswithScannedData?.body.scannedqrs
                              }
                            </h2>
                            <span> Scanned</span>
                          </div>

                          <div className="bg-green-100 border border-green-200  text-center rounded-md px-2 py-3">
                            <span className="border-2 border-green-500 inline-block">
                              <svg
                                className="qrwidth m-auto text-green-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                                />
                              </svg>
                            </span>
                            <h2 className="mt-1 text-lg font-bold">
                              {" "}
                              {
                                fetchAllQrCodeswithScannedData?.body.totalqrs - 
                                fetchAllQrCodeswithScannedData?.body.scannedqrs
                              }
                            </h2>
                            <span>Remaining</span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              {Graphs.includes("User Registration") && (
                <div className="flex-1 items-center  bg-white shadow rounded-lg">
                  {/* {getAppUserTypesInMonthFormatData?.body?.length > 0 && (
                    <CardLineChart data={getAppUserTypesInMonthFormatData} />
                  )} */}
                  <div className="relative">
                    <div className="bg3 px-3 py-2.5 rounded-t-md flex justify-between items-center  heightb">
                      <span className="text-white text-lg font-semibold d-block">
                        App User Registrations
                      </span>
                    </div>

                    <div className="px-3 py-3 flex-auto">
                      <YearMonthWeekBarChart
                        data={
                          getRegistrationStatsData?.body
                            ? getRegistrationStatsData.body
                            : []
                        }
                        type={"Total Registrations"}
                        field={"user_type"}
                        isLoading={getRegistrationStatsisLoading}
                      />
                    </div>
                  </div>
                </div>
              )}
              {Graphs.includes("User Scan Vs Redemption") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  <>
                    <div className="bg4 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block w-3/4 leading-5">
                        User Scanning Vs Redeemption
                      </span>
                    </div>
                    <div className="">
                      <ScanningVsRedemption
                        data={
                          getScanningVsRedemptionStatsData?.body
                            ? getScanningVsRedemptionStatsData.body
                            : []
                        }
                        isLoading={getScanningVsRedemptionStatsisLoading}
                      />
                    </div>
                  </>
                </div>
              )}

              {Graphs.includes("User Type Wise Total Mrp - UPE") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  <>
                    <div className="bg-blue-500 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block">
                        User Type Wise Total Product Mrp
                      </span>
                    </div>
                    <div>
                      <YearMonthWeekBarChart
                        data={
                          userTypeTotalMrpSumFromUPEMonthWiseData?.body
                            ? userTypeTotalMrpSumFromUPEMonthWiseData?.body
                            : []
                        }
                        type={"Product Total Mrp"}
                        field={"user_type"}
                        isLoading={userTypeTotalMrpSumFromUPEMonthWiseisLoading}
                      />
                    </div>
                  </>
                </div>
              )}

              {Graphs.includes("Category Wise Total MRP - Product") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  <>
                    <div className="bg6 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block">
                        Sub Category Wise Total Mrp
                      </span>
                    </div>
                    <div>
                      <YearMonthWeekBarChart
                        data={
                          totalMrpFromUPEMonthWiseData?.body
                            ? totalMrpFromUPEMonthWiseData?.body
                            : []
                        }
                        type={"Total MRP"}
                        field={"category_name"}
                        isLoading={totalMrpFromUPEMonthWiseisLoading}
                      />
                    </div>
                  </>
                </div>
              )}

              {Graphs.includes("Classification Wise Total MRP - Product") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  <>
                    <div className="bg-blue-500 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block">
                        Classification Wise Total Mrp
                      </span>
                    </div>
                    <div>
                      <YearMonthWeekBarChart
                        data={
                          productClassificationTotalMrpFromUPEMonthWiseData?.body
                            ? productClassificationTotalMrpFromUPEMonthWiseData?.body
                            : []
                        }
                        type={"Total MRP"}
                        field={"classification"}
                        isLoading={
                          productClassificationTotalMrpFromUPEMonthWiseisLoading
                        }
                      />
                    </div>
                  </>
                </div>
              )}

              {Graphs.includes("Category Wise Total Scanning - Product") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  <>
                    <div className="bg-amber-500 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block">
                        Sub Category Wise Total Scan Count
                      </span>
                    </div>
                    <div>
                      <YearMonthWeekBarChart
                        data={
                          totalScanCountFromUPEMonthWiseData?.body
                            ? totalScanCountFromUPEMonthWiseData?.body
                            : []
                        }
                        type={"Scan Count"}
                        field={"category_name"}
                        isLoading={totalScanCountFromUPEMonthWiseisLoading}
                      />
                    </div>
                  </>
                </div>
              )}

              {Graphs.includes(
                "Classification Wise Total Scanning - Product",
              ) && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  <>
                    <div className="bg-blue-500 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block">
                        Classification Wise Total Scan Count
                      </span>
                    </div>
                    <div>
                      <YearMonthWeekBarChart
                        data={
                          productClassificationTotalCountFromUPEMonthWiseData?.body
                            ? productClassificationTotalCountFromUPEMonthWiseData?.body
                            : []
                        }
                        type={"Scan Count"}
                        field={"classification"}
                        isLoading={
                          productClassificationTotalCountFromUPEMonthWiseisLoading
                        }
                      />
                    </div>
                  </>
                </div>
              )}

              {Graphs.includes("Product Code Wise Scanning - UPE") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  <>
                    <div className="bg6 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                      <span className="text-white text-lg font-semibold d-block">
                        Product Code Wise Scanning
                      </span>
                    </div>
                    <div>
                      <YearMonthWeekBarChart
                        data={
                          fetchProductsCodeTotalCountData?.body
                            ? fetchProductsCodeTotalCountData?.body
                            : []
                        }
                        type={"Total Scan"}
                        field={"product_code"}
                        isLoading={fetchProductsCodeTotalCountisLoading}
                      />
                    </div>
                  </>
                </div>
              )}

              {Graphs.includes("User Scanning") && (
                // <div className="flex-1 items-center bg-white shadow rounded-lg ">
                //   {appUsers && appUsers?.length > 0 && (
                //     <BarChart data={appUsers} />
                //   )}
                // </div>

                <div className="flex-1 items-center  bg-white shadow rounded-lg">
                  <div className="bg2 px-3 py-2.5 rounded-t-md flex justify-between items-center  heightb">
                    <span className="text-white text-lg font-semibold d-block">
                      User Scanning
                    </span>
                  </div>

                  <div className="px-3 py-3 flex-auto">
                    <YearMonthWeekBarChart
                      data={
                        getUserScanningStatsData?.body
                          ? getUserScanningStatsData.body
                          : []
                      }
                      type={"Total Scanning"}
                      field={"user_type"}
                      isLoading={getUserScanningStatsisLoading}
                    />
                  </div>
                </div>
              )}

              {Graphs.includes("Activated Warranty") && (
                <div className="flex-1 items-center  bg-white  rounded-lg">
                  <div className="bg6 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                    <span className="text-white text-lg font-semibold d-block">
                      Activated Warranty
                    </span>
                  </div>

                  <div className="px-3 py-3">
                    {appUsers && appUsers?.length > 0 && (
                      <StackedBarChart allData={appUsers} />
                    )}
                  </div>
                </div>
              )}
              {Graphs.includes("User Redemption") && (
                // <div className="flex-1 items-center  bg-white  rounded-lg">
                //   <div className="bg7 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                //     <span className="text-white text-lg font-semibold d-block w-3/4">
                //       Points Redeemption
                //     </span>
                //     <div className="flex-1 items-center rond ">
                //       <SelectComponent
                //         // title={"Months"}
                //         selected={selectedMonth}
                //         setSelected={setSelectedMonth}
                //         dataArr={months}
                //       />
                //     </div>
                //   </div>

                //   {appUsers && appUsers?.length > 0 && (
                //     <>
                //       <DoughnutChart
                //         value={appUsers}
                //         monthIndex={selectedMonth.id}
                //       />
                //     </>
                //   )}
                // </div>
                <div className="flex-1 items-center  bg-white shadow rounded-lg">
                  <div className="bg7 px-3 py-2.5 rounded-t-md flex justify-between items-center  heightb">
                    <span className="text-white text-lg font-semibold d-block">
                      Point Redemptions
                    </span>
                  </div>

                  <div className="px-3 py-3 flex-auto">
                    <YearMonthWeekBarChart
                      data={
                        getPointRedemptionStatsData?.body
                          ? getPointRedemptionStatsData.body
                          : []
                      }
                      type={"Total Redemptions"}
                      field={"user_type"}
                      isLoading={getPointRedemptionStatsisLoading}
                    />
                  </div>
                </div>
              )}

              {/* {Graphs.includes("Product Sub Categories With Percentage") && (
                <div className="flex-1 items-center  bg-white shadow rounded-lg">
                  <div className="bg8 px-3 py-2.5 rounded-t-md flex justify-between items-center  heightb">
                    <span className="text-white text-lg font-semibold d-block">
                      Product Sub Category Scanning
                    </span>
                  </div>
                  <div className="flex-1 items-center bg-white  rounded-lg">
                    {fetchProductsCategoriesWithPercentageData?.body && (
                      <>
                        <div>
                          <CategorySubCategoryScanningChart
                            result={
                              fetchProductsCategoriesWithPercentageData?.body
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>

                </div>
              )} */}

              {/* {Graphs.includes("Product Categories With Percentage") && (
                <div className="flex-1 items-center  bg-white shadow rounded-lg">
                  <div className="bg8 px-3 py-2.5 rounded-t-md flex justify-between items-center  heightb">
                    <span className="text-white text-lg font-semibold d-block">
                      Product Category Scanning
                    </span>
                  </div>

                  <div className="px-3 py-3 max-h-96 overflow-auto">
                    {Object.keys(productCategoryScanning || {}).length ? (
                      Object.keys(productCategoryScanning).map((key, index) => {
                        const value = productCategoryScanning[key];
                        return (
                          <div
                            key={index}
                            className="d-flex gap-2 items-center justify-between border border-gray-200 mb-2"
                          >
                            <span className="font-medium block pl-3">
                              {key}
                            </span>
                            <span className="bg-blue-700 text-white font-bold px-3 py-2 block">
                              {parseFloat(value).toFixed(2)}
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <span className="block text-gray-500">
                        "No Categories Found"
                      </span>
                    )}
                  </div>
                </div>
              )} */}

              {Graphs.includes("Payment Transfer") && (
                <div className="flex-1 items-start max-w-xl p-3 bg-white  rounded-lg">
                  <h2 className="text-black text-xl font-semibold pb-2">
                    Payment Transfer Stats
                  </h2>
                  {paymentTransferListData && paymentTransferListData.body && (
                    <BarcChartStacked data={paymentTransferListData.body} />
                  )}
                </div>
              )}
              {Graphs.includes("Qr Generations") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  {appUsers &&
                    appUsers?.length > 0 &&
                    allQrCodeMonthWiseData &&
                    allQrCodeMonthWiseData.body && (
                      <>
                        <div className="bg9 px-3 py-2.5 rounded-t-md flex justify-between items-center  heightb">
                          <span className="text-white text-lg font-semibold d-block">
                            QR Generations
                          </span>
                        </div>
                        <GenChart
                          // value={appUsers}
                          gen={allQrCodeMonthWiseData.body}
                        />
                      </>
                    )}
                </div>
              )}
              {Graphs.includes("Qr Scan Vs Generations") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  <div className="flex-1 items-center bg-white  rounded-lg">
                    {appUsers && appUsers?.length > 0 && (
                      <>
                        <div className="bg4 px-3 py-2.5 rounded-t-md flex justify-between items-center heightb">
                          <span className="text-white text-lg font-semibold d-block w-3/4 leading-5">
                            QR Generation Vs Scanning
                          </span>
                        </div>
                        <div className="">
                          <GenerationVsScanning
                            data={
                              getQRGenerationVsScanningStatsData?.body
                                ? getQRGenerationVsScanningStatsData.body
                                : []
                            }
                            isLoading={getQRGenerationVsScanningStatsisLoading}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              {Graphs.includes("Qr Scanning State Wise") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  {appUsers &&
                    appUsers?.length > 0 &&
                    allQrCodeMonthWiseData &&
                    allQrCodeMonthWiseData.body && (
                      <>
                        <div className="bg9 px-3 py-2.5 rounded-t-md flex justify-between items-center  heightb">
                          <span className="text-white text-lg font-semibold d-block">
                            QR Scanning State Wise
                          </span>
                        </div>
                        <IndiaMap />
                      </>
                    )}
                </div>
              )}
              {Graphs.includes("Users State Wise") && (
                <div className="flex-1 items-center bg-white  rounded-lg">
                  {appUsers && appUsers?.length > 0 && (
                    <>
                      <div className="bg9 px-3 py-2.5 rounded-t-md flex justify-between items-center  heightb">
                        <span className="text-white text-lg font-semibold d-block">
                          Users State Wise
                        </span>
                        <div className="flex slc_dvd rond">
                          {appUsers && appUsers.length > 0 && (
                            <SelectComponent
                              className="capitalize "
                              title={"User Types"}
                              selected={userTypesArrayInMap}
                              setSelected={setUserTypesArrayInMap}
                              dataArr={appUsers}
                            />
                          )}{" "}
                        </div>
                      </div>
                      <IndiaMapRegistrations
                        userTypeName={userTypesArrayInMap.name.toLowerCase()}
                      />
                    </>
                  )}
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
