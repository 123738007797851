import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import {
  useFetchCateloguesMutation,
  useDeleteCatelogueMutation,
} from "../../../../features/tenant/eCatalogue/eCatalogueApi";
import { toast } from "react-hot-toast";
import { GrDocumentPdf } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import { hasPermission } from "../../../../utils/hasPermission";
import { getVendorName } from "../../../../utils/getVendorName";
import ImageModal from "../../../../components/Tenant/ImageModal/ImageModal";

const ECatalogue = () => {
  const navigate = useNavigate();

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [showImageModal, setShowImageModal] = useState(false);

  const [
    fetchAllCatalogue,
    {
      data: fetchAllCatalogueData,
      isLoading: fetchAllCatalogueIsLoading,
      isError: fetchAllCatalogueIsError,
      error: fetchAllCatalogueError,
    },
  ] = useFetchCateloguesMutation();

  const [
    deleteCatalogue,
    {
      data: deleteCatalogueData,
      isLoading: deleteCatalogueIsLoading,
      isError: deleteCatalogueIsError,
      error: deleteCatalogueError,
    },
  ] = useDeleteCatelogueMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("E-catalogueList");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    fetchAllCatalogue({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  }, [limit, offset]);

  console.log("fetchAllCatalogueData", fetchAllCatalogueData);

  console.log("object is loading", fetchAllCatalogueIsLoading);
  if (fetchAllCatalogueIsLoading) {
    toast.loading("Loading E-Catalogue ...", {
      id: "fetchAllCata",
    });
  }
  if (fetchAllCatalogueData) {
    toast.dismiss();
  }

  const addECatalogue = hasPermission("addE-catalogue");

  const handleDelete = (ecatalogue) => {
    // console.log("object",e.target);

    deleteCatalogue({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      catalogueId: ecatalogue.id,
    });
  };
  console.log("deleteCatalogueData", deleteCatalogueData);

  useMemo(() => {
    if (deleteCatalogueData) {
      toast.success(`${deleteCatalogueData.message}`, {
        id: "deleteCatalogueDataMessage",
      });
      fetchAllCatalogue({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        limit: limit,
        offset: offset,
      });
    }
  }, [deleteCatalogueData]);

  if (deleteCatalogueIsError) {
    toast.success(`Ops,something went wrong !!!`, {
      id: "deleteCatalogueErrorMessage",
    });
  }

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Catelogue Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Catelogue Type",
      cell: (row) => row.type,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => row.status,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <div className="my-2 rounded-full">
          <img
            src={ row.image}
            alt="gift-images"
            // className="rounded-full "
            onClick={() =>
              setShowImageModal(
                 row.image,
              )
            }
          />
        </div>
      ),
    },
    {
      name: "Created By",
      cell: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <a
            href={ row?.files[0]}
            target="__blank"
            // onClick={() => {setShowPdf(!showPdf)
            // setCurrentPdf(row.files[0])
            // }}
            className="flex text-center justify-center items-center rounded bg-primary px-3
             text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <div className="w-40 h-40"> */}
            <GrDocumentPdf className="w-40 h-30 text-white" /> &nbsp; &nbsp;{" "}
            <span className="w-full">View</span>
            {/* </div> */}
          </a>
        </div>
      ),
      minWidth: "150px",
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleDelete(row)}
            className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
          >
            <AiOutlineDelete className="mr-1" />
            Delete
          </button>
        </div>
      ),
      minWidth: "130px",
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            E-Catalogue
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={
                  fetchAllCatalogueData && fetchAllCatalogueData?.body?.data
                }
                // loading={loading}
                totalRows={
                  fetchAllCatalogueData && fetchAllCatalogueData?.body?.total
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>
              {addECatalogue && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                      {/* <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute -top-12 right-0 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-blue-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                          onClick={() => navigate(`add`)}
                          className="p-2 font-semibold hover:text-gray-600 "
                        >
                          Add New Gift
                        </div>
                      </Menu.Items>
                    </Transition> */}
                    </Menu>
                  </div>
                </div>
              )}
              {showImageModal && (
                <ImageModal
                  show={showImageModal ? true : false}
                  setShow={setShowImageModal}
                  size="md"
                  data={showImageModal}
                >
                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                    <img
                      src={showImageModal}
                      className=" w-full object-cover object-center group-hover:opacity-75 h-40 max-w-full max-h-full"
                    />
                  </div>
                </ImageModal>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ECatalogue;
