import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useLocation } from "react-router-dom";
import {
  useGetAllAppCampaignDataMutation,
  useGetAllAppCampaignDatasMutation,
} from "../../../features/tenant/appCampaign/appCampaignApi";

const ViewCampaign = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const color = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .primary_color;
  const [campaign, setCampaign] = useState();

  const location = useLocation();

  useEffect(() => {
    console.log(location.state, "STAte");
    setCampaign(location.state);
  }, [location.state]);

  const [templates, setTemplates] = useState([]);

  const [column, setColumn] = useState([]);
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Video Title",
      selector: (row) => row.video_title,
    },
  ];

  const [
    getAppCampaignDatass,
    {
      data: getAppCampaignData,
      isLoading: getAppCampaignDataIsLoading,
      isError: getAppCampaignDataIsError,
      error: getAppCampaignDataError,
    },
  ] = useGetAllAppCampaignDatasMutation();

  const getAllAppCampaignData = () => {
    getAppCampaignDatass({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
      id: campaign.id,
    });
  };
  useEffect(() => {
    if (openTab === 2) {
      getAllAppCampaignData();
    }
  }, [openTab]);

  useEffect(() => {
    const allTemp = [];
    if (getAppCampaignData?.body?.allCampaigns?.length) {
      for (let i = 0; i < getAppCampaignData?.body?.allCampaigns?.length; i++) {
        allTemp.push(getAppCampaignData?.body?.allCampaigns[i].template_data);
      }
    }
    console.log(allTemp, "ALL ETEENI");
    if (allTemp.length) {
      const myKeys = Object.keys(allTemp[0]);
      const array = [];
      myKeys.map((key, i) => {
        array.push({
          name: key,
          selector: (row) => {
            if (row && row[key]) {
              return row[key];
            } else {
              return "";
            }
          },
        });
      });

      // allTemp.map((x,index)=>{
      //     Object.keys(x).map((key,i) => {
      //         array.push({
      //           name: key,
      //           selector: (row) =>Object.values(x)[i] ,
      //         });
      //       });
      //   })

      console.log("array", array);

      setColumn(array);
    }

    setTemplates(allTemp);
  }, [getAppCampaignData]);

  useEffect(() => {
    console.log(column, "all TEmplates");
  }, [column]);
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Campaign
          <LinksComponent />
        </div>

        <div className="p-3">
       
        <div className=" p-3 w-full bg-white shadow rounded-lg">

        <ul
          className="flex flex-wrap tbds mb-4"
          role="tablist"
        >
          <li className="">
            <span
              className={
                "text-xs font-semibold capitalize " +
                (openTab === 1
                  ? "text-" + color + "-600 border-b-2 border-" + color + "-600" 
                  : "text-black-300 bg-white border-b-2 border-transparent")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            ><svg class="h-6 w-6 mr-1"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <line x1="9" y1="9" x2="10" y2="9" />  <line x1="9" y1="13" x2="15" y2="13" />  <line x1="9" y1="17" x2="15" y2="17" /></svg>
              Details
            </span>
          </li>
          <li className="">
            <span
              className={
                "text-xs font-semibold capitalize " +
                (openTab === 2
                  ? "text-" + color + "-600 border-b-2 border-" + color + "-600" 
                  : "text-black-300 bg-white border-b-2 border-transparent")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            ><svg class="h-6 w-6 mr-1 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect width="6" height="6" x="14" y="5" rx="1" />  <line x1="4" y1="7" x2="10" y2="7" />  <line x1="4" y1="11" x2="10" y2="11" />  <line x1="4" y1="15" x2="20" y2="15" />  <line x1="4" y1="19" x2="20" y2="19" /></svg>
              Participants List
            </span>
          </li>
        </ul>

    
        <div className="flex-auto">
            <div className="tab-content tab-space">
              <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                {campaign && (
                  <div className=" w-full ">
<div className="flex">
  <div className="cl_left w-40  border-r-2 border-r-gray-200 pr-3">
  <div className="circlebox mb-3">
                      {/* <span>Image&nbsp;&nbsp;:&nbsp;&nbsp;</span> */}
                      { campaign && campaign.image ? 
                       <img src= {campaign?.image} alt="" srcset="" className="h-52" />
                       : 
                       <img className="h-52" src= "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJoAAACaCAMAAABmIaElAAAAMFBMVEXm5uaztbTp6enR0tK+wL/CxMOwsrHf39/i4uK2uLfV1dW7vbzc3NzOz87X2NjHyMf7lNGNAAADV0lEQVR4nO2ZDW/jIAxAiQmYYAz//9+eIWm75aN3m9KQk/xUdR2l2puhYIMxiqIoiqIoiqIoiqIoiqIoiqIoiqIoygGwS28rAaLbJXaXA/bDLp57u6V9sUrqrHYUtBq2vmbgjtVc3xGF6XhAp86T7TBoEra+ZvhODS/XAcPlsXqN79TGR6/C4ZrBjZN/cizW5J5M8Qqz9BefA8srVjk6Wv7fB5I+b3aw/HsqNiEmW+jALn9cze6KyVxq2UZ9krm418f2UeOvSRAA30ctw+K0GEK+ixpWITAyz2S+mfZLuIWat00mUvuODtRySLCb+Xa9mi+t+ZWBeFd/h7J266AWvps93EJ3tZaVrbLwlntvMrnro1ZXUlzPqxrJ2DtqE+6ULi33xlWWebka1QCtx86PtSd1VnM7Este7m6gFrZq4SZqZlO73CJqs8R6ru0Nc5dvqNlkGrf4hvr2F1cWU+vYe8n1I2w287bhw7rY6rCH1hH9tpn7UnOPTYXaISmaEw1+VAR+Ob1y63498rVZBRcXh4vqDdQG3/JvAMzWZmw5OORt5dKnNrDr2mC3Uxc1X/BrRRU2GW4/NalDWcqVpgeGe9Whe3VclfPEMefIR8X7BdX7NgN6yvmh75kH/Pak6IITNtmVfiznvb3k7E8muht/hGNz1ZHz/q3UOy4S+/+5LlL/vmK2kwaTy97ZfMRw/tJbM1eZM/Pu/Xwyz2m0tMkztSbK4bmlvt52OQzhfLWUSwnygFRckoKgxAxY5uBg5poNSRvYqa77dogmyzfTRAlgSFH6SWcec5jONhM1O6UyJVcCBZyMK0jyErHWnBCHbAlGxjGmVoRKsyVkZ8YALslH5SXlLNvV+duCqBXIBWIBjDzJPw+RI1lLuV0lg0hIW3bzgAYCwto2q8mHXHKSqGcYP6HG1SuWRCyRmdVcihGfaiTbmAvf1HCjdv416UtNNNJgHAcZPxGkhxoZiuDsPJnCBOwgjoYSDrOajH6QeXj+lVAxOUKy8jCFLKPsVsyQiNoNT4qyfUmtQgxQalxCAWAqEkYqjPJ2YEByjKGcrjavGzC/EGRoXPySc8+P+Vrj0bBccrw+uvz8MOjGS27rfoNu2oqiKIqiKIqiKIqiKIqiKIqiKIqiKL/kDydsH767LRYSAAAAAElFTkSuQmCC" alt="" srcset="" />
 
                      }
                      </div>

                      <div className="datebox text-center">
                      <span className="text-gray-500 text-sm  flex items-center dashed_br">
                      <svg class="h-4 w-4 text-gray-500 mr-1"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />  <line x1="16" y1="2" x2="16" y2="6" />  <line x1="8" y1="2" x2="8" y2="6" />  <line x1="3" y1="10" x2="21" y2="10" /></svg>
                      Start Date&nbsp;&nbsp;:&nbsp;&nbsp;{new Date(campaign?.start_date).toLocaleString()}</span>

                        <span className="text-gray-500 text-sm  flex items-center dashed_br">
                      <svg class="h-4 w-4 text-gray-500 mr-1"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />  <line x1="16" y1="2" x2="16" y2="6" />  <line x1="8" y1="2" x2="8" y2="6" />  <line x1="3" y1="10" x2="21" y2="10" /></svg>
                        End Date&nbsp;&nbsp;:&nbsp;&nbsp;{new Date(campaign?.end_date).toLocaleString()}</span>

                        </div>
  </div>

  <div className="cl_right w-full pl-4">
  <div className="border-b border-gray-200 flex pb-2.5 mb-2.5">
  {" "}
                        <span className="text-gray-400 text-md font-medium  block">Title&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                        <span className="flex text-gray-800 text-md font-medium  gap-1">{campaign?.title}</span>
    </div>

    <div className="border-b border-gray-200 flex pb-2.5 mb-2.5">
    <span className="text-gray-400 text-md font-medium  block">Type&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                     <span className="flex text-gray-800 text-md font-medium  gap-1">{campaign?.type}</span>
      </div>

      <div className="border-b border-gray-200 flex pb-2.5 mb-2.5">
      <span className="text-gray-400 text-md font-medium  block">Video Url&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                        <a className="flex text-gray-800 text-md font-medium  gap-1" href={campaign?.video_link} target="_blanck">
                          {" "}
                          {campaign?.video_link}
                        </a>{" "}
        </div>

        <div className="border-b border-gray-200 flex pb-2.5 mb-2.5">
        <span className="text-gray-400 text-md font-medium  block">Web Url&nbsp;&nbsp;:&nbsp;&nbsp;{" "}</span>
                        <a className="flex text-gray-800 text-md font-medium  gap-1" href={campaign?.web_link} target="_blanck">{campaign?.web_link}</a>{" "}
          </div>

          <div className="border-b border-gray-200 flex pb-2.5 mb-2.5">
          <span className="text-gray-400 text-md font-medium  block">Can User Hide&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                        <span className="flex text-gray-800 text-md font-medium  gap-1">{campaign &&  campaign.can_user_hide == true ? "Yes" : "No"}</span>
                   
            </div>

            <div className="border-b border-gray-200 flex pb-2.5 mb-2.5">
            {" "}
                        <span className="text-gray-400 text-md font-medium  block">Description&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                        <span className="flex text-gray-800 text-md font-medium  gap-1">{campaign?.description}</span>
                     
              </div>



                  
  </div>
</div>





                   
                     
                  </div>
                )}
              </div>
              <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                <div className="bg-gray-300 w-full border-opacity-40 rounded-lg p-2">
                  {templates && templates.length>0 && column && column.length>0 && (
                    <div>
                      <DataTableComponent
              keyField={(row) => row.SNo}
                        columns={column}
                        data={templates}
                        // loading={loading}
                        totalRows={getAppCampaignData?.body?.totalCampaignData}
                        handlePerRowsChange={handlePerRowsChange}
                        handlePageChange={handlePageChange}
                      >
                        <div className="flex m-2 space-x-2 justify-end">
                          <form className="w-52 h-10 border-2 border-blue-200 ">
                            <div className="relative">
                              <input
                                type="search"
                                id="default-search"
                                className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                                placeholder="Search..."
                                required
                              />
                              <button className="absolute inset-y-0 right-2 flex items-center pr-3 pointer-events-none">
                                <svg
                                  aria-hidden="true"
                                  className="w-5 h-5 text-gray-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    strokeLinejoin="round"
                                    stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </form>
                          <form className="w-52 h-10 border-2 border-blue-200">
                            <div className="relative">
                              <input
                                type="search"
                                id="default-search"
                                className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                                placeholder="Search..."
                                required
                              />
                              <div className="absolute inset-y-0 right-2 flex items-center pr-3 pointer-events-none">
                                <svg
                                  aria-hidden="true"
                                  className="w-5 h-5 text-gray-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    strokeLinejoin="round"
                                    stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </form>
                        </div>
                      </DataTableComponent>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          </div></div>






       
      </div>
    </div>
  );
};

export default ViewCampaign;
