import React, { useEffect } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import {
  useDeleteReferAndEarnRewardsMutation,
  useGetAllReferAndEarnRewardsMutation,
  useUpdateStatusOfReferAndEarnRewardsMutation,
} from "../../../features/tenant/ReferAndEarnRewards/referAndEarnRewards";
import { toast } from "react-hot-toast";
import { AiOutlineEdit } from "react-icons/ai";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

const ReferAndEarnRewards = () => {
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const addRewardsPermission = hasPermission("addReferAndEarn");

  const [
    getAllReferAndEarnRewards,
    {
      data: getAllReferAndEarnRewardsData,
      isLoading: getAllReferAndEarnRewardsIsLoading,
      isError: getAllReferAndEarnRewardsIsError,
      error: getAllReferAndEarnRewardsError,
    },
  ] = useGetAllReferAndEarnRewardsMutation();

  const [
    updateStatusOfReferAndEarnRewards,
    {
      data: updateStatusOfReferAndEarnRewardsData,
      isLoading: updateStatusOfReferAndEarnRewardsIsLoading,
      isError: updateStatusOfReferAndEarnRewardsIsError,
      error: updateStatusOfReferAndEarnRewardsError,
    },
  ] = useUpdateStatusOfReferAndEarnRewardsMutation();

  const [
    deleteReferAndEarnRewards,
    {
      data: deleteReferAndEarnRewardsData,
      isLoading: deleteReferAndEarnRewardsIsLoading,
      isError: deleteReferAndEarnRewardsIsError,
      error: deleteReferAndEarnRewardsError,
    },
  ] = useDeleteReferAndEarnRewardsMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("referAndEarn");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    getAllReferAndEarnRewards({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (getAllReferAndEarnRewardsIsError && getAllReferAndEarnRewardsError) {
      toast.dismiss();
      toast.error("Oop!, something went Wrong ", {
        id: "something went Wrong",
      });
    }
  }, [getAllReferAndEarnRewardsData, getAllReferAndEarnRewardsError]);

  const handleStatusChange = (row, status) => {
    updateStatusOfReferAndEarnRewards({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: row.id,
    });
  };

  useEffect(() => {
    if (
      updateStatusOfReferAndEarnRewardsData &&
      !updateStatusOfReferAndEarnRewardsIsError
    ) {
      toast.dismiss();
      toast.success(updateStatusOfReferAndEarnRewardsData.message, {
        id: updateStatusOfReferAndEarnRewardsData.message,
      });
      getAllReferAndEarnRewards({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }

    if (
      updateStatusOfReferAndEarnRewardsError &&
      updateStatusOfReferAndEarnRewardsIsError
    ) {
      toast.dismiss();
      console.log(
        "updateStatusOfReferAndEarnRewardsError",
        updateStatusOfReferAndEarnRewardsError
      );
      toast.error("Not Able Update The Status", {
        id: "notAbleUpdateTheStatus",
      });
    }
  }, [
    updateStatusOfReferAndEarnRewardsData,
    updateStatusOfReferAndEarnRewardsError,
  ]);

  const handleDelete = (row) => {
    deleteReferAndEarnRewards({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: row.id,
    });
  };

  useEffect(() => {
    if (deleteReferAndEarnRewardsData && !deleteReferAndEarnRewardsIsError) {
      toast.dismiss();
      toast.success(deleteReferAndEarnRewardsData.message, {
        id: deleteReferAndEarnRewardsData.message,
      });
      getAllReferAndEarnRewards({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }

    if (deleteReferAndEarnRewardsError && deleteReferAndEarnRewardsIsError) {
      toast.dismiss();

      toast.error("Not Able delete the reward", {
        id: "notAbledeleteThereward",
      });
    }
  }, [deleteReferAndEarnRewardsData, deleteReferAndEarnRewardsError]);

  let columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      // width: "70px",
      textAlign: "left",
    },
    {
      name: "Reward Type",
      selector: (row) => (row.reward_type == 1 ? "Points" : "Cash"),
      sortable: true,
    },
    {
      name: "Reward",
      selector: (row) =>
        row.reward_type == 1 ? row.reward.points : row.reward.cash,
      sortable: true,
    },
  ];

  const updateRewardsStatusPermission = hasPermission(
    "updateReferAndEarnStatus"
  );
  if (updateRewardsStatusPermission) {
    columns = [
      ...columns,
      {
        name: "Status",
        selector: (row) =>
          row.status == "1" ? (
            <>
              {" "}
              <button
                onClick={() => {
                  handleStatusChange(row, "2");
                }}
                className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                {/* <AiOutlineEdit className="mr-1" /> */}
                Active
              </button>
            </>
          ) : (
            <button
              onClick={() => {
                handleStatusChange(row, "1");
              }}
              className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              {/* <AiOutlineEdit className="mr-1" /> */}
              InActive
            </button>
          ),
        sortable: true,
        // width: "70px",
      },
    ];
  }

  const editRewardsPermission = hasPermission("editReferAndEarn");
  if (editRewardsPermission) {
    columns = [
      ...columns,
      {
        name: "Action",
        cell: (row) => (
          // editAppUserTypePermission && (
          <div className="">
            <button
              onClick={() =>
                navigate(`add`, {
                  state: row,
                })
              }
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <AiOutlineEdit className="mr-1" />
              edit
            </button>
          </div>
        ),
        // ),
        //   ),
        minWidth: "100px",
      },
    ];
  }

  const deleteRewardsPermission = hasPermission("deleteReferAndEarn");
  if (deleteRewardsPermission) {
    columns = [
      ...columns,
      {
        name: "Action",
        selector: (row) => (
          <>
            {" "}
            <button
              onClick={() => {
                handleDelete(row);
              }}
              className="flex justify-center items-center rounded bg-red-600 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-red-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-red-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-red-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              {/* <AiOutlineEdit className="mr-1" /> */}
              DELETE
            </button>
          </>
        ),
        sortable: true,
        // width: "70px",
      },
    ];
  }

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Refer And Earn Rewards Setup
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
            <DataTableComponent
              keyField={(row) => row.SNo}
              columns={columns}
              data={
                getAllReferAndEarnRewardsData &&
                getAllReferAndEarnRewardsData.body
              }
              //   loading={productPointsIsLoading}
              //   totalRows={productsData && productsData.body.total_products}
              // handlePerRowsChange={handlePerRowsChange}
              // handlePageChange={handlePageChange}
            >
              <div className="flex m-2 space-x-2 justify-end"></div>
            </DataTableComponent>
            {addRewardsPermission && (
              <div
                className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                id="box_btn"
              >
                <div className="text-right">
                  <Menu className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        onClick={() => navigate(`add`)}
                        className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                        style={{ backgroundColor: `${primaryColor["700"]}` }}
                      >
                        <HiOutlinePlus className="w-full h-full p-3" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              </div>
            )}
          </div></div>
        </div>
      </div>
    </>
  );
};

export default ReferAndEarnRewards;
