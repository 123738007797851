import React, { useEffect, useState } from "react";
import { HiOutlinePlus } from "react-icons/hi2";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useFetchProductsMutation } from "../../../features/tenant/products/productsApi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { useNavigate } from "react-router-dom";
import { Menu } from "@headlessui/react";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";

const WarrantyOnProducts = () => {
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);
  const [
    fetchProducts,
    {
      data: productsData,
      isLoading: productsIsLoading,
      isError: productsIsError,
      error: productsError,
    },
  ] = useFetchProductsMutation();

  const [
    fetchProductsForCsv,
    {
      data: productsForCsvData,
      isLoading: productsForCsvIsLoading,
      isError: productsForCsvIsError,
      error: productsForCsvError,
    },
  ] = useFetchProductsMutation();


  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const addWarrantyToProductPermission = hasPermission("addWarrantyOnProducts");
  const initialRequest = {
    product_code: "",
    name: "",
    brand_name: "",
    category_name: "",
    limit: 10,
    offset: 0,
  };
  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (
      request.product_code === "" &&
      request.name === "" &&
      request.brand_name === "" &&
      request.category_name === ""
    ) {
      fetchProductsFunction();
      fetchProductsForCsvFunction();
    }
  }, [request]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  useEffect(() => {
    const hasViewPermission = hasPermission("warrantyOnProducts");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    fetchProductsFunction();
    fetchProductsForCsvFunction();
  }, []);

  useEffect(() => {
  
    if(productsData ){
      setMyList(productsData && productsData.body.products ? productsData.body.products : []);
      setTotalRows(productsData && productsData.body.total_products ? productsData.body.total_products : 0);
    }
  }, [productsData]);

  const fetchProductsFunction = () => {
    fetchProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchProductsForCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchProductsForCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
      textAlign: "left",
    },
    {
      name: "Brand name",
      selector: (row) => row.brand_name,
      sortable: true,
      width: "130px",
    },
    {
      name: "Product name",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Product code",
      selector: (row) => row.product_code,
      sortable: true,
      width: "150px",
    },
    {
      name: "Image",
      selector: (row) => (
        <div className="w-12 h-12 my-2 rounded-full">
        {row.images && row.images[0] ? ( // Check if row.images exists and has at least one element
          <img
            src={ row.images[0]}
            alt={row.images[0]}
            className="rounded-full"
            onClick={() =>
              setShowImageModal(
                 row.images[0]
              )
            }
          />
        ) : (
          <span>No Image</span> // Display a message or placeholder for no image
        )}
      </div>
      ),
    },
    {
      name: "Activation Period ",
      selector: (row) =>
        row.warranty_activation_period &&
        row.warranty_activation_period + " days",
      sortable: true,
      width: "230px",
      // width: "70px",
    },
    {
      name: "Primary warranty ",
      selector: (row) => row.primary_warranty_on,
      sortable: true,
      width: "150px",
      // width: "70px",
    },
    {
      name: "Primary warranty period ",
      selector: (row) => row.primary_warranty_period,
      sortable: true,
      width: "230px",
      
      // width: "70px",
    },
    {
      name: "Secondary warranty ",
      selector: (row) => row.secondary_warranty_on,
      sortable: true,
      // width: "70px",
      width: "230px",
    },
    {
      name: "Secondary warranty period ",
      selector: (row) => row.secondary_warranty_period,
      sortable: true,
      width: "230px",
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Product With Warranty List
            <LinksComponent />
          </div>
          <div className="p-3">
            {productsForCsvData && productsForCsvData?.body?.products && (
              <ExportCSV
                data={productsForCsvData && productsForCsvData?.body?.products}
                columnsToExport={[
                  "brand_name",
                  "name",
                  "product_code",
                  "unit_name",
                  "qty",
                  "warranty_activation_period",
                ]}
              />
            )}
            <div className="w-full bg-white shadow rounded-lg">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
            <div className="flex space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Brand Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Brand Name..."
                        required
                        value={request.brand_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, brand_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Category Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Category Name..."
                        required
                        value={request.category_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, category_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Product Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Code ..."
                        required
                        value={request.product_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Product Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row w-52 gap-2 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="py-2 btn btn-outline-primary clear-both float-right mb-2"
                        onClick={() => {
                          fetchProductsFunction();
                          fetchProductsForCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right mb-2"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
                </div>



              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                // title={"Product With Warranty List"}
                data={myList}
                //   loading={productPointsIsLoading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
               
                
              </DataTableComponent>
              {addWarrantyToProductPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}

{showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="md"
            data={showImageModal}
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarrantyOnProducts;
