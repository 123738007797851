import React, { useEffect, useState } from "react";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useNavigate } from "react-router-dom";
import { useDeletePGCredentialsMutation, useFetchPGCredentialsQuery } from "../../../features/tenant/payoutGatewayCredentials/payoutGatewayCredentialsApi";
import { BiHide } from "react-icons/bi";
import { MdVisibility } from "react-icons/md";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { hasPermission } from "../../../utils/hasPermission";


const PayementGateway = () => {
  const navigate = useNavigate();

  const [toggleHide, setToggleHide] = useState(false);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const {
    data: fetchPGCredentialsData,
    isLoading: fetchPGCredentialsIsLoading,
    isError: fetchPGCredentialsIsError,
    error: fetchPGCredentialsError,
    refetch : fetchPGCredentialsRefetch
  } = useFetchPGCredentialsQuery({
    token: sessionStorage.getItem("access_token"),
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });

  const [
    deletePGCredentials,
    {
      data: deletePGCredentialsData,
      isLoading: deletePGCredentialsIsLoading,
      isError: deletePGCredentialsIsError,
      error: deletePGCredentialsError,
    },
  ] = useDeletePGCredentialsMutation();

  const addPaymentGatewayPermission = hasPermission("addPaymentGateway");
  const deletePaymentGatewayPermission = hasPermission("deletePaymentGateway");



  useEffect(()=>{
    if(fetchPGCredentialsData && fetchPGCredentialsIsError == false){
      toast.dismiss();
      toast.success(fetchPGCredentialsData.message,{
        id : "fetchPGCredentialsData"
       })
    }
  },[fetchPGCredentialsData])
 if(fetchPGCredentialsIsLoading){
   toast.dismiss()
   toast.loading("Loading...",{
    id : "fetchPGCredentialsIsLoading"
   })
 }

 if(fetchPGCredentialsIsError){
  toast.dismiss()
   toast.loading("Ops Something wrong...",{
    id : "fetchPGCredentialsIsError"
   })
 }

 const handleDeletePGCredential = (row)=>{
  deletePGCredentials({
    token: sessionStorage.getItem("access_token"),
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    id : row.id
  })
 }

 useEffect(()=>{
  if(deletePGCredentialsData && deletePGCredentialsIsError== false){
    toast.dismiss();
    toast.success("deleted successfully",{
      id : "deletePGCredentialsData-delete"
     })
    fetchPGCredentialsRefetch();
  }
 },[deletePGCredentialsData])

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => idx + 1,
      sortable: true,
      width: "90px",
      textAlign: "left",
    },
    {
      name: "Type",
      cell: (row) => row.type,
      sortable: true,
    },
    {
      name: "Client ID",
      cell: (row) => {
        return (
          <>
            <input
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2  "
              type={toggleHide ? "password" : "text"}
              value={row.client_id}
            />
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Client Secret",
      cell: (row) => {
        return (
          <>
            <input
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2  "
              type={toggleHide ? "password" : "text"}
              value={row.client_secret}
            />
          </>
        );
      },
      sortable: true,
    },
    {
      
      name: "Created At ",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action ",
      cell: (row) => {
        return toggleHide ? (
          <BiHide
            className=" cursor-pointer"
            onClick={() => setToggleHide(!toggleHide)}
          />
        ) : (
          <MdVisibility
            className=" cursor-pointer"
            onClick={() => setToggleHide(!toggleHide)}
          />
        );
      },
      sortable: true,
    },
    {
      name: "",
      cell: (row) => {
        if(deletePaymentGatewayPermission){

        return (  <button
          onClick={() => handleDeletePGCredential(row)}
          className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
        >
          <AiOutlineDelete className="mr-1" />
          Delete
        </button>)
        }else{
          return <></>
        }
      },
      sortable: true,
    },
  ];
  console.log("object", fetchPGCredentialsData);
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center ">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Payment Gateway Credentials
            <LinksComponent />
          </div>
          <div className=" p-3">
            <div className="p-3 bg-white shadow rounded-lg">
            
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                // title={"Payment Gateway Credentials"}
                data={fetchPGCredentialsData && fetchPGCredentialsData.body}
                //   loading={productsIsLoading}
                //   totalRows={productsData && productsData.body.total_products}
                //   handlePerRowsChange={handlePerRowsChange}
                //   handlePageChange={handlePageChange}
              >
               
              </DataTableComponent>

              <div
                className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                id="box_btn"
              >
                {
                  addPaymentGatewayPermission && (<div className="text-right">
                  <Menu className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        onClick={() => navigate(`add`)}
                        className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                        style={{ backgroundColor: `${primaryColor["700"]}` }}
                      >
                        <HiOutlinePlus className="w-full h-full p-3" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>)
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayementGateway;
