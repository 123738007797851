import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  useAddVersionMutation,
  useDeleteVersionMutation,
  useGetAllVersionMutation,
  useUpdateHWSandSWSMutation,
} from "../../../features/tenant/AppVersions/AppVersionApi";
import toast from "react-hot-toast";

const AppVersion = () => {
  console.log("version");
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [version, setVersion] = useState("");
  const [showModal, setShowModal] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);


  const initialRequest = {
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);

  const [
    AddVersion,
    {
      data: AddVersionData,
      error: AddVersionError,
      isLoading: AddVersionIsLoading,
      isError: AddVersionIsError,
    },
  ] = useAddVersionMutation();

  const [
    DeleteVersion,
    {
      data: DeleteVersionData,
      error: DeleteVersionError,
      isLoading: DeleteVersionIsLoading,
      isError: DeleteVersionIsError,
    },
  ] = useDeleteVersionMutation();
  const [
    UpdateHWSandSWS,
    {
      data: UpdateHWSandSWSData,
      error: UpdateHWSandSWSError,
      isLoading: UpdateHWSandSWSIsLoading,
      isError: UpdateHWSandSWSIsError,
    },
  ] = useUpdateHWSandSWSMutation();
  const [
    getAllVersion,
    {
      data: getAllVersionData,
      error: getAllVersionError,
      isLoading: getAllVersionIsLoading,
      isError: getAllVersionIsError,
    },
  ] = useGetAllVersionMutation();

  useEffect(() => {
    getAllVersionFunction();
    // fetchAppUserByUserTypeCsvFunction();
  }, [limit, offset]);

  useEffect(() => {
    if (AddVersionData) {
      toast.success(AddVersionData.message, {
        id: "AddVersion",
      });
      getAllVersionFunction();
    }
    if (AddVersionError) {
      toast.error(AddVersionData.data.message, {
        id: "errorAdDverrsion",
      });
    }
  }, [AddVersionData, AddVersionError]);
  useEffect(() => {
    if (getAllVersionData) {
      setMyList(getAllVersionData.body && getAllVersionData.body.data ? getAllVersionData.body.data : [])
      setTotalRows(getAllVersionData.body && getAllVersionData.body.total ? getAllVersionData.body.total : 0)
    }
  
  }, [getAllVersionData]);

  useEffect(() => {
    if (DeleteVersionData) {
      toast.success(DeleteVersionData.message, {
        id: "DeleteVersionData",
      });
      getAllVersionFunction();
    }
    if (DeleteVersionError) {
      toast.success(DeleteVersionError.data.message, {
        id: "AddVerDeleteVersionErrorsion",
      });
    }
  }, [DeleteVersionData, DeleteVersionError]);

  useEffect(() => {
    if (UpdateHWSandSWSData) {
      toast.success(UpdateHWSandSWSData.message, {
        id: "UpdateHWSandSWSData",
      });
      getAllVersionFunction();
    }
    if (UpdateHWSandSWSError) {
      toast.success(UpdateHWSandSWSError.data.message, {
        id: "AddVerDeleteVersionErrorsion",
      });
    }
  }, [UpdateHWSandSWSData, UpdateHWSandSWSError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (version.length == 0) {
      toast.error("Please enter the version", {
        id: "version",
      });
      return;
    }
    AddVersion({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        version: version,
      },
    });
  };

  const handleShowModal = (type, data) => {
    if (type === "add") {
      setVersion("");
      setShowModal(true);
    }
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  const handleDelete = (id) => {
    DeleteVersion({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: id,
    });
  };
  const handleUpdateHWVandSWV = (id, type) => {
    UpdateHWSandSWS({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: id,
      body: {
        type: type,
      },
    });
  };

  const getAllVersionFunction = () => {
    request["limit"] = limit;
    request["offset"] = offset;
    getAllVersion({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  //   const getAllVersionCsvFunction = () => {
  //     getAllVersion({
  //       token: sessionStorage.getItem("access_token"),
  //       tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //       body: request,
  //     });
  //   };
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const columns = [
    {
      name: "id",
      cell: (row) => row.id,
      width: "10%",
    },
    {
      name: "version",
      cell: (row) => row.version,
      width: "20%",
    },
    {
      name: "Hightest Working Version",
      cell: (row) =>
        row.hwv ? (
          <>
            <button
              onClick={() => {
                handleUpdateHWVandSWV(row.id, "hwv");
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Active
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              handleUpdateHWVandSWV(row.id, "hwv");
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            InActive
          </button>
        ),
      width: "20%",
    },
    {
      name: "Lowest Working Version",
      cell: (row) =>
        row.swv ? (
          <>
            <button
              onClick={() => {
                handleUpdateHWVandSWV(row.id, "swv");
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Active
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              handleUpdateHWVandSWV(row.id, "swv");
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            InActive
          </button>
        ),
      width: "20%",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <button
            className="flex justify-center items-center rounded bg-red-600 px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-red-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </button>
        </>
      ),
      width: "15%",
    },
    {
      name: "",
      cell: (row) => row.created,
      width: "15%",
    },
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            App Versions
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <div className="">
                <DataTableComponent
              keyField={(row) => row.SNo}
                  columns={columns}
                  data={myList}
                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                >
                  {/* <div className="flex m-2 space-x-2 justify-end"></div> */}
                </DataTableComponent>
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => handleShowModal("add")}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            size={"sm"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-4">
              <div className="w-full flex justify-center ">
                <div className="flex items-center justify-center w-full mb-6">
                  <InputComponent
                    name={"Enter Version*"}
                    placeholder={"Version"}
                    type={"text"}
                    value={version}
                    setValue={setVersion}
                    required={true}
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleSubmit(e)}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default AppVersion;
