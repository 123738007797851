import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import {
  useFetchPointsScanHistoryCountMutation,
  useFetchPointsScanHistoryMutation,
  useRevertUserPointEnteryMutation,
} from "../../../../features/tenant/rewardify/pointsScanHistory/pointsScanHistoryApi";
import ExportCSV from "../../../../components/ExportCsv/ExportCsv";
import { getLoginUserData } from "../../../../utils/getLoginUserData";
import { TiTimes } from "react-icons/ti";
import toast from "react-hot-toast";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";
import CustomExport from "../../../../components/CustomExport/CustomExport";
import { CircleLoaderComponent } from "../../../../components/Loader/CircleLoaderComponent";
import { IoLocationSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

function objectsAreEqual(obj1, obj2) {
  console.log("ob1", obj1);
  console.log("ob2", obj2);
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}


const PointsHistoryRetailers = () => {
  const zoneId = getLoginUserData().zone_id;
  const [
    fetchPointsScanHistory,
    { data: pointsScanHistoryData, error: pointsScanHistoryError ,isLoading: pointsScanHistoryIsLoading, },
  ] = useFetchPointsScanHistoryMutation();
  const [
    fetchPointsScanHistoryCount,
    {
      data: fetchPointsScanHistoryCountData,
      error: fetchPointsScanHistoryCountError,
      isLoading: fetchPointsScanHistoryCountIsLoading,
    },
  ] = useFetchPointsScanHistoryCountMutation();
  const [
    fetchPointsScanHistoryCsv,
    { data: pointsScanHistoryCsvData, error: pointsScanHistoryCsvError },
  ] = useFetchPointsScanHistoryMutation();
  const [
    revertUserPointEntery,
    { data: revertUserPointEnteryData, error: revertUserPointEnteryError },
  ] = useRevertUserPointEnteryMutation();

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const initialRequest = {
    user_type_id: userTypeId,
    name: "",
    limit: 10,
    offset: 0,
    product_code: "",
    product_name: "",
    mobile: "",
    start_date: "",
    end_date:"",
    zoneId,
  };

  const initialRequestCount = {
    user_type_id: userTypeId,
    name: "",
    product_code: "",
    batch_running_code: "",
    product_name: "",
    mobile: "",
    start_date: "",
    end_date:"",
    zoneId,
  };
  const [request, setRequest] = useState(initialRequest);
  const [requestCount, setRequestCount] = useState(initialRequestCount);


  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  useEffect(()=>{
    fetchPointsScanHistoryCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: requestCount,
    });

  },[requestCount])
  
  useEffect(() => {
    if (
      request.name === "" &&
      request.product_code === "" &&
      request.product_name === "" &&
      request.mobile === "" &&
      request.start_date === "" &&
      request.end_date ===""
    ) {
      fetchPointsScanHistoryFunction();
      // fetchCsvPointsScanHistoryFunction();
    }
    let tempRequest = { ...request };
    delete tempRequest.limit;
    delete tempRequest.offset;

    if (!objectsAreEqual(tempRequest, requestCount)) {
      setRequestCount(tempRequest);
    }
  }, [request]);

  // useEffect(() => {
  //   fetchPointsScanHistoryFunction();
  //   // fetchCsvPointsScanHistoryFunction();
  // }, [limit, offset]);

  const fetchPointsScanHistoryFunction = () => {
    fetchPointsScanHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchCsvPointsScanHistoryFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchPointsScanHistoryCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      zone_id: zoneId,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if(pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data){
      setCsvData(pointsScanHistoryCsvData.body.data);
    }

    if (pointsScanHistoryCsvError) {
      setCsvData(null);
    }
  }, [pointsScanHistoryCsvData , pointsScanHistoryCsvError])

  useEffect(() => {
    if (pointsScanHistoryData) {
      console.log("success scan history", pointsScanHistoryData);
      setMyList( pointsScanHistoryData &&  pointsScanHistoryData.body && pointsScanHistoryData.body.data ? pointsScanHistoryData.body.data : []  );
    }
  }, [pointsScanHistoryData, pointsScanHistoryError]);

  useEffect(() => {
    if (fetchPointsScanHistoryCountData) {
      setTotalRows( fetchPointsScanHistoryCountData && fetchPointsScanHistoryCountData.body && fetchPointsScanHistoryCountData.body.total ? fetchPointsScanHistoryCountData.body.total : 0  );
    }
  }, [fetchPointsScanHistoryCountData, fetchPointsScanHistoryCountError]);


  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const handleReject = (e, row) => {
    const remark = window.prompt("please add remarks");
    if (!remark) {
      toast.error("Please add a remark");
      return;
    }
    if (remark && remark.length == 0) {
      toast.error("Please add a remark");
      return;
    }
    console.log("remark", remark);

    revertUserPointEntery({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        remark: remark,
      },
      app_user_id: row.app_user_id.toString(),
      qr_id: row.qr_id,
    });
  };

  useEffect(() => {
    if (revertUserPointEnteryData) {
      toast.dismiss();

      toast.success(revertUserPointEnteryData.message, {
        id: "revertUserPointEnteryData",
      });
      fetchPointsScanHistoryFunction();
    }
    if (revertUserPointEnteryError) {
      toast.dismiss();

      toast.error(revertUserPointEnteryError.data.message, {
        id: "revertUserPointEnteryError",
      });
    }
  }, [revertUserPointEnteryData, revertUserPointEnteryError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },

    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },

    {
      name: "Phone",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "FSR",
      cell: (row) => row.fsr,
      sortable: true,
      width: "120px",
    },
    {
      name: "ASM",
      cell: (row) => row.asm,
      sortable: true,
      width: "120px",
    },
    {
      name: "Shop Id",
      cell: (row) => row.firm_id,
      sortable: true,
      width: "120px",
    },
    {
      name: "Shop Name",
      cell: (row) => row.firm_name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Platform",
      cell: (row) => row.platform,
      sortable: true,
      width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Category Name",
      cell: (row) => row.category_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Product Name",
      cell: (row) => row.product_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "150px",
    },
    {
      name: "Product Classification",
      cell: (row) => row.classification,
      sortable: true,
      width: "150px",
    },
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_running_code,
      sortable: true,
      width: "150px",
    },
    {
      name: "Scanned At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      width: "120px",
      sortable: true,
    },
    {
      name: "Method",
      cell: (row) => row.method,
      sortable: true,
    },
    {
      name: "City",
      cell: (row) => row.city,
      sortable: true,
    },
    {
      name: "Pincode",
      cell: (row) => row.pincode,
      sortable: true,
    },
    {
      name: "District",
      cell: (row) => row.district,
      sortable: true,
    },
    {
      name: "State",
      cell: (row) => row.state,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <div className="flex space-x-1 ">
            {row.lat && row.log ? (
              <div className="text-center">
                <Link
                  to={`https://maps.google.com/?q=${row.lat},${row.log}`}
                  target="_blank"
                >
                  <IoLocationSharp size={35} color="blue" className="m-auto" />
                  View Location
                </Link>{" "}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ),
      width: "250px",
    },
    {
      name: "",
      cell: (row) => (
        <div>
          {row.is_reverted == false ? (
            <div className="flex space-x-1 ">
              <button
                onClick={(e) => handleReject(e, row)}
                className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
              >
                Revert
              </button>
            </div>
          ) : (
            <div className=" text-red-500 ">Reverted</div>
          )}
        </div>
      ),
      width: "250px",
    },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Points History
            <LinksComponent />
          </div>

          <div className="p-3">
            {/* {pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data && (
              <ExportCSV
                data={
                  pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data
                }
                columnsToExport={[
                  "name",
                  "mobile",
                  "user_type",
                  "points",
                  "product_name",
                  "product_code",
                  "qr_id",
                  "batch_running_code",
                  "method",
                ]}
              />
            )} */}

<div className="mb-2">
                  <CustomExport fetchData={fetchCsvPointsScanHistoryFunction} data={csvData} defaultColumnExport={[
                      "name",
                      "mobile",
                      "user_type",
                      "points",
                      "product_name",
                      "product_code",
                      "qr_id",
                      "batch_running_code",
                      "method",
                  ]} 
                    filename="point_history"
                    />
                </div>

            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-100 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Name
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile ..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="flex-col slc_dvd topslt">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        User Types
                      </label>
                      <div className="relative searchin">
                        {appUsers && appUsers.length > 0 && (
                          <SelectComponent
                            className="capitalize"
                            //title={"User Types"}
                            selected={userTypesArray}
                            setSelected={setUserTypesArray}
                            dataArr={appUsers}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        P-Name
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Name..."
                        required
                        value={request.product_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      P-Code
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Product Code ..."
                      required
                      value={request.product_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, product_code: newValue };
                        });
                      }}
                    />
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.start_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              start_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.end_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              end_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-52 gap-2 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>

                      <button
                        className="btn btn-outline-primary clear-both float-right  p-2"
                        onClick={() => {
                          fetchPointsScanHistoryFunction();
                          // fetchCsvPointsScanHistoryFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>
                      <button
                        className="btn btn-outline-primary clear-both float-right  whitespace-nowrap p-2"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
              {pointsScanHistoryIsLoading && <CircleLoaderComponent />}
                <DataTableComponent
              keyField={(row) => row.SNo}
                  columns={columns}
                  // title={""}
                  data={
                    myList
                  }
                  // loading={loading}
                  totalRows={
                    totalRows
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                >
                  <div className="flex m-2 space-x-2 justify-end"></div>
                </DataTableComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PointsHistoryRetailers;
