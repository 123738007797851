import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { useFetchAllOtpsMutation } from "../../../features/tenant/appOtps/appOtpsApi";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import CustomExport from "../../../components/CustomExport/CustomExport";
import { useFetchDistinctQrScanHistoryCountMutation, useFetchDistinctQrScanHistoryMutation } from "../../../features/tenant/appUserQrScan/appUserQrScanApi";


const DistinctQrScanHistory = () => {
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    product_code: "",
    product_name: "",
    batch_code: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (
      request.product_code === "" &&
      request.product_name === "" &&
      request.batch_code === ""
    ) {
      fetchdistinctQrScanHistoryFunction();
    }
  }, [request]);
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const [
    distinctQrScanHistory,
    {
      data: distinctQrScanHistoryData,
      error: distinctQrScanHistoryError,
      isLoading: distinctQrScanHistoryIsLoading,
    },
  ] = useFetchDistinctQrScanHistoryMutation();

  const [
    distinctQrScanHistoryCount,
    {
      data: distinctQrScanHistoryCountData,
      error: distinctQrScanHistoryCountError,
      isLoading: distinctQrScanHistoryCountIsLoading,
    },
  ] = useFetchDistinctQrScanHistoryCountMutation();
  // useGetAllMappingDetailsCountMutation

  const [
    distinctQrScanHistoryCsv,
    {
      data: distinctQrScanHistoryCsvData,
      error: distinctQrScanHistoryCsvError,
      isLoading: distinctQrScanHistoryCsvIsLoading,
    },
  ] = useFetchDistinctQrScanHistoryMutation();

  useEffect(() => {
    // const hasViewPermission = hasPermission("brandList");
    // if (!hasViewPermission) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }
    fetchdistinctQrScanHistoryFunction();
    fetchdistinctQrScanHistoryCountFunction();
    // fetchdistinctQrScanHistoryCsvFunction();
  }, [limit, offset, request]);

  const fetchdistinctQrScanHistoryFunction = () => {
    distinctQrScanHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchdistinctQrScanHistoryCountFunction = () => {
    distinctQrScanHistoryCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchdistinctQrScanHistoryCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    distinctQrScanHistoryCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (
      distinctQrScanHistoryCsvData &&
      distinctQrScanHistoryCsvData?.body?.qrsList
    ) {
      setCsvData(distinctQrScanHistoryCsvData?.body?.qrsList);
    }

    if (distinctQrScanHistoryCsvError) {
      setCsvData(null);
    }
  }, [distinctQrScanHistoryCsvData, distinctQrScanHistoryCsvError]);

  const columns = [
    {
      name: "S.No",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
    },
    {
      name: "Qr Id",
      cell: (row) => row.qr_id,
      sortable: true,
    },
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_code,
      sortable: true,
    },
    {
      name: "Product Name",
      cell: (row) => row.product_name,
      sortable: true,
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
    },

    {
      name: "Total Scans",
      cell: (row) => row.total_scans,
      sortable: true,
    }
  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Distinct Qr Scan History
            <LinksComponent />
          </div>

          <div className="p-3">

            <div className="mb-2">
              <CustomExport
                fetchData={fetchdistinctQrScanHistoryCsvFunction}
                data={csvData}
                defaultColumnExport={[
                  "qr_id",
                  "batch_code",
                  "product_code",
                  "product_name",
                  "total_scans",
                ]}
                filename="ditinct_qr_scan_list"
              />
            </div>

            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 justify-end">
                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Product Name
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Product Name"
                      required
                      value={request.product_name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, product_name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Product Code
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Product Code"
                      required
                      value={request.product_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            product_code: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Batch Running Code
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Batch Running Code"
                      required
                      value={request.batch_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            batch_code: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                </div>


                <div className="flex flex-row  gap-2 h-10">
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>
                    <button
                      className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                      onClick={() => {
                        fetchdistinctQrScanHistoryFunction();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>
                    <button
                      className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={distinctQrScanHistoryData?.body?.qrsList}
                loading={distinctQrScanHistoryIsLoading}
                totalRows={distinctQrScanHistoryCountData?.body?.totalCount}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                striped
                highlightOnHover
                pointerOnHover
                pagination
              ></DataTableComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DistinctQrScanHistory;
