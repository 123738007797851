import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import {
  useDeleteBatchMutation,
  useFetchAllBatchMutation,
} from "../../../features/tenant/batch/batchApi";
import { toast } from "react-hot-toast";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { HiOutlinePlus } from "react-icons/hi";

const monthArr = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const BatchList = () => {
  const navigate = useNavigate();

  const addBatchPermission = hasPermission("addBatch");
  const editBatchPermission = hasPermission("editBatch");
  const deleteBatchPermission = hasPermission("deleteBatch");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => (offset) +  Number(idx + 1),
      sortable: true,
    },
    {
      name: "Brand",
      cell: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Batch Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Month",
      cell: (row) => monthArr[row.month],
      sortable: true,
      width: "100px",
    },
    {
      name: "Year",
      cell: (row) => row.year,
      sortable: true,
      width: "90px",
    },
    {
      name: "Created By",
      cell: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) =>
        editBatchPermission && (
          <div>
            <button
              onClick={() =>
                navigate("add", {
                  state: row,
                })
              }
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <AiOutlineEdit className="mr-1" />
              Edit
            </button>
          </div>
        ),
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) =>
        deleteBatchPermission && (
          <div>
            <button
              onClick={() => handleDelete(row.batch_id)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          </div>
        ),
      minWidth: "100px",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [fetchBatchList, { data: batchListData, error: batchListError }] =
    useFetchAllBatchMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("batchList");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchBatchListFunction("");
  }, []);

  const fetchBatchListFunction = (e) => {
    fetchBatchList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
      batchListName: e,
    });
  };

  const [
    deleteBatch,
    {
      data: deleteBatchData,
      isLoading: deleteBatchIsLoading,
      isError: deleteBatchIsError,
      error: deleteBatchError,
    },
  ] = useDeleteBatchMutation();

  useEffect(() => {
    if (batchListError) {
      console.log("batch list error", batchListError);
    }

    if (deleteBatchData) {
      toast.success("Batch Deleted", {
        id: "deleteBatchSuccess",
      });
    }

    if (deleteBatchError) {
      toast.error(deleteBatchError.data.message, {
        id: "deleteBatchError",
      });
    }
  }, [deleteBatchData, deleteBatchError, batchListData, batchListError]);

  const handleDelete = (batchId) => {
    if (window.confirm("Are you sure you want to delete")) {
      deleteBatch({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        batchId: batchId,
      });
    }
    return;
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };


  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Batch List
            <LinksComponent />
          </div>

          <div className="p-3">
        <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={batchListData?.body?.batches}
                loading={loading}
                totalRows={batchListData?.body?.total_batches}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex  space-x-2 justify-end">
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Batch List Name..."
                        required
                        onChange={(e) => {
                          if (!e.target.value) {
                            fetchBatchListFunction(e.target.value);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            fetchBatchListFunction(event.target.value);
                          }
                        }}
                      />
                      <div className="absolute inset-y-0 right-2 flex items-center pr-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            strokeLinejoin="round"
                            stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </DataTableComponent>
              {addBatchPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                      {/* <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute -top-12 right-0 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-blue-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                          onClick={() => navigate(`add`)}
                          className="p-2 font-semibold hover:text-gray-600 "
                        >
                          Add New Batch
                        </div>
                      </Menu.Items>
                    </Transition> */}
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BatchList;
