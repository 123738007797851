import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useDeleteAppVideoMutation, useGetAppVideoMutation } from "../../../../features/tenant/appVideo/appVideoApi";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";

const VideoList = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState();
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = (url) => {
    setCurrentUrl(getId(url));
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [
    getAllVideo,
    {
      data: getAllVideoData,
      isLoading: getAllVideoLoading,
      isError: getAllVideoIsError,
      error: getAllVideoError,
    },
  ] = useGetAppVideoMutation();

  const getAll = () => {
    getAllVideo({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  const videoColumns = [
    {
      name: "SNo",
      cell: (row, idx) => idx + 1,
      sortable: true,
      //   width: "80px",
    },
    {
      name: "Type",
      cell: (row) => row.type,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Title",
      cell: (row) => row.title,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleOpenModal(row.link)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >
            View
          </button>
        </div>
      ),
      sortable: true,
      width: "120px",
    },

    {
      name: "",
      cell: (row) => (
        <button
          onClick={() => handleDeleteVideo(row)}
          className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
        >
          <AiOutlineDelete className="mr-1" />
          Delete
        </button>
      ),
      minWidth: "100px",
    },
  ];

  const [
    deleteVideoById,
    {
      data: deleteVideo,
      isError: deleteVideoIsError,
      error: deleteVideoError,
    },
  ] = useDeleteAppVideoMutation();

  const handleDeleteVideo = (data) => {
    if (window.confirm("are you sure you want to delete this video")) {
      deleteVideoById({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: data.id,
      });
     
    }
    return;
  };

  useEffect(() => {
    if (deleteVideo) {
      toast.dismiss();
      getAll();
      toast.success("Video Deleted Successfully", {
        id: "Video Deleted Successfully",
      });
    }
    if (deleteVideoError) {
      toast.dismiss();
      toast.error("Something went wrong", {
        id: "delete-product-error",
      });
    }
  }, [deleteVideoIsError, , deleteVideoError, deleteVideo]);

  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          VideoList View
          <LinksComponent />
        </div>
        <div className="p-3">
          <div className="p-3 w-full bg-white shadow rounded-lg">
            <DataTableComponent
              keyField={(row) => row.SNo}
              columns={videoColumns}
              // title={"Videos"}
              data={getAllVideoData && getAllVideoData.body}
              // loading={loading}
              // totalRows={totalRows}
              //   handlePerRowsChange={handlePerRowsChange}
              //   handlePageChange={handlePageChange}
            ></DataTableComponent>
            <div
              className="fixed bottom-0 right-10 w-16 h-16 mr-10 mb-6 cursor-pointer"
              id="box_btn"
            >
              <div className="text-right">
                <Menu className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      onClick={() => navigate("add")}
                      className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                      style={{ backgroundColor: `${primaryColor["700"]}` }}
                    >
                      <HiOutlinePlus className="w-full h-full p-3" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div>
          {/* <iframe src="https://www.youtube.com/embed/DrtqG-JoaD4" width={1000} height={500} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full" ></iframe> */}
          {/* width={1000} height={500} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full" */}
        </div>
      </div>
      {showModal && (
        <ModalComponent
          size={"3xl"}
          data={"nadnandand"}
          show={showModal}
          setShow={setShowModal}
        >
          <div className="w-full">
            <div className="mx-auto max-w-2xl px-2 py-2 lg:max-w-7xl lg:px-8">
              <h2 className="sr-only"> Video</h2>

              <div className="w-full">
                <iframe
                  className="m-auto mt-5 w-full"
                  src={"//www.youtube.com/embed/" + currentUrl}
                  frameborder="0"
                  height={400}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
                ></iframe>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default VideoList;
