import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";

import { toast } from "react-hot-toast";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import {
  useAddBrandMutation,
  useDeleteBrandMutation,
  useFetchAllBrandsMutation,
  useUpdateBrandMutation,
} from "../../../features/tenant/brands/brandsApi";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

const BrandList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [brandName, setBrandName] = useState(null);

  const addBrandPermission = hasPermission("addBrand");
  const editBrandPermission = hasPermission("editBrand");
  const deleteBrandPermission = hasPermission("deleteBrand");

  const [selectedBrandId, setSelectedBrandId] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const [fetchBrandList, { data: brandsData, error: brandsError }] =
    useFetchAllBrandsMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("brandList");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchBrandListFunction("");
  }, []);

  const fetchBrandListFunction = (e) => {
    fetchBrandList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
      brandName:e
    });
  };

  const [addBrand, { data: addbrandData, error: addBrandError }] =
    useAddBrandMutation();

  const [updateBrand, { data: updateBrandData, error: updateBrandError }] =
    useUpdateBrandMutation();

  useEffect(() => {
    if (addbrandData) {
      toast.success("Brand Added", {
        id: "brandAddSucess",
      });

      setBrandName(null);
      setShowModal(!showModal);
      fetchBrandList({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
    if (addBrandError) {
      toast.error("Some Error Occured", {
        id: "brandAddError",
      });
    }
  }, [addbrandData, addBrandError]);

  const [deleteBrand, { data: deleteBrandData, error: deleteBrandError }] =
    useDeleteBrandMutation();

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete")) {
      deleteBrand({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: id,
      });
    }
    fetchBrandListFunction("");
    return;
  };

  useEffect(() => {
    if (deleteBrandData) {
      toast.success("Brand Deleted Successfully", {
        id: "brandDeleteSucess",
      });
      fetchBrandList({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
      return;
    }

    if (updateBrandData) {
      fetchBrandList({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
      setBrandName(null);
      setShowModal(!showModal);
      return;
    }
  }, [deleteBrandData, deleteBrandError, updateBrandData, updateBrandError]);

  const handleAddBrand = (e) => {
    e.preventDefault();
    if (!brandName) {
      toast.error("Please enter Brand name", {
        id: "brandNameError",
      });
      return;
    }

    addBrand({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        name: brandName,
      },
    });
    fetchBrandListFunction("");
  };

  const handleUpdateBrand = (e) => {
    e.preventDefault();
    if (!brandName) {
      toast.error("Please enter Brand name", {
        id: "brandNameError",
      });
      return;
    }

    updateBrand({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: selectedBrandId,
      body: {
        name: brandName,
      },
    });
  };

  const handleShowModal = (type, data) => {
    if (type === "add") {
      // const flag = hasPermission("addBrand");
      // console.log("object has permission", flag);
      // if (!flag) {
      //   alert("You are not allowed to do this");
      //   return;
      // }
      setShowModal(true);
      setSelectedBrandId(-1);
      setBrandName(null);
      // console.log("add Brand permission " , hasPermission("addBrand"));
    }

    if (type === "edit") {
      setSelectedBrandId(Number(data.id));
      setBrandName(data.name);
      setShowModal(true);
    }
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => (offset) +  Number(idx + 1),
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Created By",
      cell: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) =>
        editBrandPermission && (
          <div>
            <button
              onClick={() => handleShowModal("edit", row)}
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <AiOutlineEdit className="mr-1" />
              Edit
            </button>
          </div>
        ),
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) =>
        deleteBrandPermission && (
          <div>
            <button
              onClick={() => handleDelete(row.id)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          </div>
        ),
      minWidth: "100px",
    },
  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Brand List
            <LinksComponent />
          </div>

          <div className="p-3">
        <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={brandsData?.body?.brands}
                loading={loading}
                totalRows={brandsData?.body?.total_brands}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex  space-x-2 justify-end">
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Brand Name..."
                        required
                        onChange={(e) => {
                          if (!e.target.value) {
                            fetchBrandListFunction(e.target.value);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            fetchBrandListFunction(event.target.value);
                          }
                        }}
                      />
                      <div className="absolute inset-y-0 right-2 flex items-center pr-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            strokeLinejoin="round"
                            stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </DataTableComponent>
              {addBrandPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => handleShowModal("add")}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                      {/* <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute -top-12 right-0 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-blue-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                          onClick={() => navigate(`add`)}
                          className="p-2 font-semibold hover:text-gray-600 "
                        >
                          Add New Batch
                        </div>
                      </Menu.Items>
                    </Transition> */}
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            size={"md"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-3">
              <div className="w-full flex justify-center ">
                <div className="mb-6 w-full">
                  <InputComponent
                    name={"Brand Name"}
                    type={"text"}
                    value={brandName}
                    placeholder={"Enter your brand name"}
                    setValue={setBrandName}
                    required={true}
                  />
                </div>
              </div>
              <div className="flex justify-center">
                {selectedBrandId === -1 ? (
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={(e) => handleAddBrand(e)}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={(e) => handleUpdateBrand(e)}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default BrandList;
