import { Menu, Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import CatalogueName from "../../../assests/tenant/images/CatalogueName.png";
import CatalogueType from "../../../assests/tenant/images/CatalogueType.png";
import TotalGifts from "../../../assests/tenant/images/TotalGifts.png";
import UserTypes from "../../../assests/tenant/images/UserTypes.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useFetchAllCataloguesMutation,
  useFetchCatalogueByIdMutation,
  useUpdateStatusOfGiftInCatalogueByIdMutation,
} from "../../../features/tenant/giftCatalogue/giftCatalogueApi";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import toast from "react-hot-toast";

const GiftCatalogue = () => {
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);

  const [
    updateGiftStatusInCatalogue,
    { data: updateGiftStatusInCatalogueData, error: updateGiftStatusInCatalogueError , isLoading : updateGiftStatusInCatalogueIsLoading }
  ] = useUpdateStatusOfGiftInCatalogueByIdMutation();


  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Brand",
      cell: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Gift Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
    },
    {
      name: "Value",
      cell: (row) => row.value,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) =>
        row.images.length > 0 && (
          <div className="w-12 h-12 my-2 rounded-full">
          <img
          // style={{ width: "40px", padding: "10px" }}
            src={ row.images[0]}
            alt="gift-images"
            className="rounded-full"
            onClick={() =>
              setShowImageModal(
                 row.images[0],
              )
            }
          />
          </div>
        ),
      maxWidth: "120px",
    },
    {
      name: "Gift Status",
      cell: (r) =>
        r.gift_status === "2" ? (
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => handleUpdate(r)}
          >
            In-Active
          </button>
        ) : (
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => handleUpdate(r)}
          >
            Active
          </button>
        ),
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "Created By",
      cell: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [catalogueId, setCatalogueId] = useState(null);
  const location = useLocation();
  const [
    fetchCatalogueById,
    {
      data: catalogueData,
      isLoading: catalogueIsLoading,
      isError: cataloguesIsError,
      error: cataloguesError,
    },
  ] = useFetchCatalogueByIdMutation();

  useEffect(() => {
    console.log(location.state);
    const Id = location.state.catalogue_id;
    // console.log("objecttttt", Id);
    setCatalogueId(Id);
    fetchCatalogueById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: location.state.catalogue_id,
    });
  }, [location.pathname]);

  useEffect(() => {
    if (catalogueData) {
      console.log("catalogue data ", catalogueData.body);
      setData(catalogueData.body);
    }

    if (cataloguesError) {
      console.log("catalogue error ", cataloguesError);
    }

    // if (qrListDataIsLoading) {
    //   setLoading(qrListDataIsLoading);
    // }
  }, [catalogueData, catalogueIsLoading, cataloguesError]);

  const getUserTypeNames = (user_type_id) => {
    const userTypes = JSON.parse(sessionStorage.getItem("appUserType"));
    console.log("object", user_type_id);

    let name = "";

    userTypes.forEach((each) => {
      if (each.user_type_id == user_type_id) {
        name = each.name;
      }
    });

    return name;
  };


  const handleUpdate = (data) => {
    
    let status = "1";
    if (data.gift_status == "1") {
      status = "2";
    } else status = "1";

    console.log(data , status);

    updateGiftStatusInCatalogue({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: data.gc_id,
      status: status,
    });
  };



  useEffect(() => {
    toast.dismiss();
    if (updateGiftStatusInCatalogueData) {
      toast.success("Gift Status Updated Successfully.");
      fetchCatalogueById({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: location.state.catalogue_id,
      });
    }

    if(updateGiftStatusInCatalogueError){
      toast.error("Please try again later.");

    }
  }, [updateGiftStatusInCatalogueData, updateGiftStatusInCatalogueError , updateGiftStatusInCatalogueIsLoading]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Gift Catalogues
            <LinksComponent />
          </div>

<div className="p-3">
<div className="grid grid-cols-4 gap-2 mb-2">
            <div className="w-full box_gct bg_g1">
              <div className="circle_gft">
              <img src={CatalogueName} className="max-h-full max-w-full "/>
              </div>
<span className="text-gray-400 text-xs  block">Catalogue Name</span>
<span className="flex text-black text-sm  gap-1 ">
                {" "}{location.state?.catalogue_name}
                </span>
            </div>

            <div className="w-full box_gct bg_g2">
            <div className="circle_gft">
              <img src={CatalogueType} className="max-h-full max-w-full "/>
              </div>
<span className="text-gray-400 text-xs  block"> Catalogue Type</span>
<span className="flex text-black text-sm  gap-1"> {" "}{location.state?.catalogue_type}</span>
              </div>

              <div className="w-full box_gct bg_g3">
              <div className="circle_gft">
              <img src={TotalGifts} className="max-h-full max-w-full "/>
              </div>
<span className="text-gray-400 text-xs  block"> Total Gifts</span>
<span className="flex text-black text-sm  gap-1">{" "}
                  {location.state?.gift_count}{" "}</span>
                </div>

                <div className="w-full box_gct bg_g4">
                <div className="circle_gft">
              <img src={UserTypes} className="max-h-full max-w-full "/>
              </div>
<span className="text-gray-400 text-xs  block">User Types</span>
<span className="flex text-black text-sm  gap-1 ">
                  { data.length > 0 && 
                    data[0].user_types.map((id) => (
                      <span> {getUserTypeNames(id)} </span>
                    ))
                    }
                </span>

                </div>
           </div>
  <div className=" bg-white shadow rounded-lg">
  

          <div className="w-full p-3 ">
            <div className="">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                title={data.length > 0 && data[0].catalogue_name}
                data={data}
              >
             
              </DataTableComponent>
            </div>
          </div>
  </div>
</div>


         
         
        </div>
        {showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="md"
            data={showImageModal}
          >
            <div className="p-3 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg  xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}
      </div>
    </>
  );
};

export default GiftCatalogue;
