import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { getVendorName } from "../../../utils/getVendorName";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import { useFetchAllWebFeedbackMutation, useFetchWebFeedbackByIdMutation } from "../../../features/tenant/webFeedbackApi/webFeedbackApi";

const WebFeedback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialRequest = {
    user_name: "",
    user_mobile: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };
  const [showImageModal, setShowImageModal] = useState(false);
  useEffect(() => {
    if (
      request.user_name === "" &&
      request.user_mobile === ""
    ) {
      fetchWebFeedbackListFunction();
      fetchWebFeedbackListCsvFunction();
    }
  }, [request]);

  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);


  const onButtonDownloadClick = (url) => {
    // const filename = activateWebFeedbackData?.body?.webFeedback_pdf;
    // fetch(filename).then(
    //   (response) => {
    //     response.blob().then((blob) => {
    //       console.log("blob", blob);
    //       // Creating new object of PDF file
    //       const fileURL = window.URL.createObjectURL(blob);
    //       // console.log("fileurl", fileURL);
    //       // Setting various property values
    //       let alink = document.createElement("a");
    //       alink.href = fileURL;
    //       alink.download = filename;
    //       alink.click();
    //     });
    //   }
    // );

    window.open(url, '_blank');

    // const filename = url.split('/').pop();
    // const tenantName = JSON.parse(sessionStorage.getItem("vendorData")).tenant_name;

    // let alink = document.createElement("a");
    // alink.href = url;

    // console.log("filename", filename);

    // alink.setAttribute('download', `${tenantName}-${filename}`);

    // alink.click();
  };

  const [
    fetchWebFeedbackList,
    { data: webFeedbackListData, error: webFeedbackListError }
  ] = useFetchAllWebFeedbackMutation();
  const [
    fetchWebFeedbackListCsv,
    { data: webFeedbackListCsvData, error: webFeedbackListCsvError }
  ] = useFetchAllWebFeedbackMutation();

  const [fetchWebFeedbackById, { data: webFeedbackData, error: webFeedbackError }] =
  useFetchWebFeedbackByIdMutation();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  useEffect(() => {
    const hasViewWebFeedbackPermission = hasPermission("appWebFeedback");

    if (!hasViewWebFeedbackPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
  }, []);

  useEffect(() => {
    let userTypeId = 0;
    const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));

    // console.log(appUserTypes);
    // if (appUserTypes != undefined) {
    //   for (let index = 0; index < appUserTypes.length; index++) {
    //     const element = appUserTypes[index];
    //     if (
    //       element.user_type.toLocaleLowerCase() === userType.toLocaleLowerCase()
    //     ) {
    //       userTypeId = element.user_type_id;
    //     }
    //   }
    // }
    fetchWebFeedbackListFunction();
    fetchWebFeedbackListCsvFunction();
  }, [location.pathname, limit, offset]);

  const exportToCSV = () => {
    const csvRows = [];
    const headers = ["S.No", "Name", "Mobile", "Status", "User Type", "Created Date"];

    if (webFeedbackListCsvData?.body?.feedbacks.length > 0 && webFeedbackListCsvData?.body?.feedbacks[0].data) {
      webFeedbackListCsvData?.body?.feedbacks[0].data.forEach((field) => headers.push(field.name));
    }

    csvRows.push(headers.join(","));

    webFeedbackListCsvData?.body?.feedbacks.forEach((row, idx) => {
      const rowData = [
        idx + 1,
        row.user_name,
        row.user_mobile,
        row.status === 1 ? "Active" : row.status === 3 ? "Claimed" : row.status === 2 ? "Pending" : "Invalid",
        row.user_type,
        new Date(row.created_at).toLocaleString(),
      ];

      if (row.data) {
        row.data.forEach((field) => rowData.push(field.value || "N/A"));
      }

      csvRows.push(rowData.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "WebFeedback_List.csv";
    link.click();
  };


  const fetchWebFeedbackListFunction = () => {
    fetchWebFeedbackList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchWebFeedbackListCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchWebFeedbackListCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (webFeedbackListData) {
      console.log("webFeedback list data ", webFeedbackListData.body);
     
      setMyList(webFeedbackListData?.body?.feedbacks ? webFeedbackListData.body.feedbacks : []);
      setTotalRows(webFeedbackListData?.body?.totalFeedbacks ? webFeedbackListData.body.totalFeedbacks : 0);
    }

    if (webFeedbackListError) {
      console.log("webFeedback list error", webFeedbackListError);
    }
  }, [webFeedbackListData, webFeedbackListError]);

  const handleShowWebFeedback = (data) => {
    // console.log(data);
    // setModalData(data);
    fetchWebFeedbackById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: data.id,
    });
  };

  const [selectedTab, setSelectedTab] = useState("product");

  useEffect(() => {
    if (webFeedbackData) {
      console.log("webFeedback by id data", webFeedbackData);
      setModalData(webFeedbackData.body);
      setShowModal(true);
    }

    if (webFeedbackError) {
      console.log("webFeedback by id error", webFeedbackError);
      toast.error("Some error occurred", { id: "webFeedbackErrorById" });
    }
  }, [webFeedbackData, webFeedbackError]);







  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "75px",
    },
    {
      name: "Name",
      selector: (row) => <h2>{row.user_name}</h2>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.user_mobile,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? (
          <span className="text-green-600"> Active </span>
        ) : row.status == 3 ? (
          <span className="text-red-600">Claimed</span>
        ) : row.status == "2" ? (
          <span className="text-yellow-600">Pending </span>
        ) : (
          <span className="text-gray-600">Invalid</span>
        ),
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];
  
  // Add dynamic columns from the first feedback if `myList` is not empty
  if (myList.length > 0 && myList[0].data) {
    myList[0].data.forEach((field) => {
      columns.push({
        name: field.name,
        selector: (row) => {
          const matchingField = row.data.find((d) => d.name === field.name);
          return matchingField ? matchingField.value : "N/A";
        },
        sortable: true,
        minWidth: "320px",
      });
    });
  }
  
  columns.push({
    name: "",
    selector: (row) => (
      <button
        className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
        onClick={() => handleShowWebFeedback(row)}
      >
        View
      </button>
    ),
    sortable: true,
    minWidth: "120px",
  });

  

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            WebFeedback List
            {/* {userType.charAt(0).toUpperCase() + userType.slice(1)} List */}
            <LinksComponent />
          </div>

          <div className="p-3">
            {webFeedbackListCsvData && webFeedbackListData?.body?.feedbacks && (
              <button
              onClick={exportToCSV}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-3"
            >
              Export to CSV
            </button>
            )}
            <div className="w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.user_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, user_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Phone</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Phone..."
                        required
                        value={request.user_mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, user_mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                 

                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                        onClick={() => {
                          fetchWebFeedbackListFunction();
                          fetchWebFeedbackListCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div></div>
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                // title={""}
                data={myList}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex space-x-2 justify-end"></div>
              </DataTableComponent>
            </div>
          </div>
        </div>
        {showModal && modalData && (
          <ModalComponent
            size={"4xl"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
              <div className="flex ">
               

                <div className="w-full bg-gray-50 p-4">
                  <div class="w-full">
                  

                    <div id="tab-contents">
              
                        <div id="second" class="px-1 py-3">
                          <div class="grid grid-cols-2 gap-4">
                            <p class="text-gray-400 text-md   block  border-b border-gray-200 pb-2">
                              Name
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData.user_name}
                              </span>
                            </p>

                            <p className="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              {" "}
                              Contact
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData.user_mobile}
                              </span>
                            </p>

                            <p className="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              App User ID
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData.app_user_id}
                              </span>
                            </p>
                            <p className="text-gray-400 text-md  block  border-b border-gray-200 pb-2">
                              {" "}
                              User Type
                              <span className="flex text-black text-md font-weight-medium  gap-1">
                                {modalData.user_type}
                              </span>
                            </p>
                   
                   






            
                          </div>

                        
                        </div>
                      

                      <div className="secondary-info w-full mt-4">
                    <h3 className="text-lg font-semibold mb-2">Additional Details</h3>
                    <div className="flex w-full flex-wrap space-x-2">
                      {modalData?.data.map((field, index) => (
                        <div key={index} className="border p-2 m-2 rounded">
                          {field.type === "file" ? (
                            <span href="#" className="group">
                              <p><strong>{field.label}:</strong></p>
                              <div className="overflow-hidden">
                                <img
                                  src={field.value}
                                  alt={field.label}
                                  className="max-w-full max-h-full"
                                  onClick={() => setShowImageModal(field.value)}
                                />
                              </div>
                            </span>
                          ) : (
                            <p><strong>{field.label}:</strong> {field.value}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                    </div>
                  </div>
                </div>
              </div>

            
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default WebFeedback;
