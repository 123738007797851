import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi";
import { useFetchAllErrorLogsMutation } from "../../../features/tenant/errorLogs/errorLogsApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

export default function ErrorLogs() {
  const navigate = useNavigate();
  const location = useLocation();

  const [tableDataItems, setTableDataItems] = useState([]);

  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);


  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [data, setData] = useState(null);

  const [
    fetchAllErrorLogs,
    { data: errorLogData, error: errorLogError },
  ] = useFetchAllErrorLogsMutation();


  useEffect(() => {

    const hasViewPermission = hasPermission("errorLogs");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchAllErrorLogs({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      token: sessionStorage.getItem("access_token"),
      limit : limit , 
      offset : offset
    });
  }, [limit , offset]);

  useEffect(() => {
    if (errorLogData && errorLogData.body && errorLogData.body.data) {
      console.log("error log data", errorLogData);
      const arr = [...errorLogData.body.data];
      arr.sort((a, b) => a.id - b.id);
      setTableDataItems(arr);
      setTotalRows(errorLogData && errorLogData.body.count ? errorLogData.body.count : 0);
    }

    if (errorLogError) {
      console.log("error log error", errorLogError);
    }
  }, [errorLogData, errorLogError]);

  const columns = [
    {
      name: "S.No",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
   
    {
      name: "Type",
      selector: (row) => row.type,
      // sortable: true,
    },
    {
        name: "Message  ",
        selector: (row) => row.message,
      },
    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            setModalData(r.error);
            setData(r);
            setShowModal(true);
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

//   const handleDelete = (id) => {
//     toast(
//       (t) => (
//         <span>
//           <h6>Are you sure you want to delete this ?</h6>
//           <div className="mt-3 d-flex">
//             <button
//               className="btn btn-outline-danger btn-sm ml-auto mr-1"
//               onClick={() => {
//                 toast.dismiss();
//                 deleteEmailTemplate({
//                   token: sessionStorage.getItem("access_token"),
//                   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
//                   id : id
//                 })
//               }}
//             >
//               Yes
//             </button>
//             <button
//               className="btn btn-outline-primary btn-sm mr-auto ml-1"
//               onClick={() => {
//                 toast.dismiss(t.id);
//               }}
//             >
//               No
//             </button>
//           </div>
//         </span>
//       ),
//       {
//         id: "deleteWorkflow",
//       },
//     );
//     return;
//   };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Error Logs
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={tableDataItems}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
             
            </div>
          </div>
          {showModal && modalData && (
            <ModalComponent
              size={"2xl"}
              data={"Html Template"}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full">
                <div className="w-full flex justify-center ">
                  <div className=" w-full errormsg">
                    {
                      data && Object.keys(data).length >0 && Object.keys(data).map(x=>{
                        return <p>{x} : {JSON.stringify(data[x])}</p>
                      })
                    }
                    
                  </div>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
}
