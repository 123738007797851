import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// import { dynamicForm } from "../programOptions/exampleJsonObjects/dynamicFormsObj";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const formTypes = [
  { id: 1, name: "Payment" },
  { id: 2, name: "Warranty" },
  { id: 3, name: "KYC" },
  { id: 4, name: "Login" },
  { id: 5, name: "Registration" },
  { id: 6, name: "Profile" },
  { id: 7, name: "Panel-AddUser" },
  { id: 8, name: "App-AddUser" },
  { id: 9, name: "Enquiry" },
  { id: 10, name: "WheelCampaign" },
  { id: 11, name: "App-Feedback" },
  { id: 12, name: "Report-Qr" },
];


const languages = [
  { id: 1, name: "english" },
  { id: 2, name: "mandarin" },
  { id: 3, name: "hindi" },
  { id: 4, name: "spanish" },
  { id: 5, name: "french" },
  { id: 6, name: "arabic" },
  { id: 7, name: "bengali" },
  { id: 8, name: "portuguese" },
  { id: 9, name: "russian" },
  { id: 10, name: "urdu" },
  { id: 11, name: "indonesian" },
  { id: 12, name: "german" }
];
//if form type is warranty name & phone/mobile are required types

export default function AddForm() {
  //   const elementsRaw = dynamicForm.elements;
  const [elements, setElements] = useState([]);

  const [lableName, setLableName] = useState("");
  const [name, setName] = useState("");
  const [inputLength, setInputLength] = useState("");
  const [inputType, setInputType] = useState("");
  const [required, setRequired] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [userTypes, setUsersTypes] = useState([]);
  const [newUserValue, setNewUserValue] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState({
    industry_id: null,
    industry_name: null,
  });
  const [formType, setFormType] = useState(0);
  const [language, setLanguage] = useState("");

  // Preview Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isUpdate, setIsUpdate] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setIsUpdate(true);
      setTemplateName(location.state.template_name);
      setElements(Object.values(location.state.template));
      setFormType(Number(location.state.form_type));
      setLanguage(location.state.language);
      handleCompanyIndustryChange({
        target: { value: location.state.industry_id },
      });
      console.log(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/industry`,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log("indust get ", response.data.body);
        setIndustryList(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // setElements(elementsRaw);
    const handleNewElementAddition = () => {
      let newElementObj = {
        label: lableName,
        name: name,
        type: inputType,
        required: required,
        maxLength: inputLength,
        options: userTypes,
      };

      let oldOptions = elements;
      oldOptions.push(newElementObj);
      setElements(oldOptions);
      console.log("added");

      console.log(oldOptions);
    };
    if (lableName !== "" && inputType !== "" && inputLength !== "") {
      handleNewElementAddition();
      setName("");
      setLableName("");
      setInputLength("");
      setRequired(false);
      setInputType("");
    }
  }, [reRender]);

  const handleInputTypeChange = (e) => {
    setInputType(e.target.value);
  };

  const removeFromUserTypes = (e, key) => {
    console.log(key);
    let oldValues = [...userTypes];
    oldValues = oldValues.filter((user) => user != key);
    setUsersTypes(oldValues);
  };

  const cancelAddProductSubCategory = () => {
    navigate("/formTemplate");
  };

  const handleCompanyIndustryChange = (e) => {
    let name = "";
    industryList.forEach((industry) => {
      if (industry.industry_id == e.target.value) {
        name = industry.name;
      }
    });
    setCompanyIndustry({ industry_id: e.target.value, name: name });
  };
  const handleCompanyFormtypeChange = (e) => {
    console.log(e.target.value);
    setFormType(e.target.value);
  };

  const handleLanguageChange = (e) => {
    console.log(e.target.value);
    setLanguage(e.target.value);
  };

  const handleDeleteElement = (e, eIdx) => {
    let oldElements = elements;
    oldElements = oldElements.filter((ele, idx) => idx !== eIdx);
    setElements(oldElements);
    setReRender((old) => !old);
  };

  const addFormTemplate = () => {
    toast.dismiss();
    var data = JSON.stringify({
      template_name: templateName,
      form_type: formType,
      industry_id: Number(companyIndustry.industry_id),
      industry_name: companyIndustry.name,
      language:language,
      template: { ...elements },
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/admin/formtemplate/add`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        toast.success("Template Added Successfully", {
          id: "templateAddedSuccess",
        });
        setCompanyIndustry(0);
        setTemplateName("");
        setTimeout(() => {
          navigate("/formTemplate");
        }, 300);
      })
      .catch(function (error) {
        toast.error("Some error occurred.", { id: "templateAddError" });
        console.log(error);
      });
  };

  const updateFormTemplate = () => {
    toast.dismiss();
    if (location.state && location.state.id) {
      var data = JSON.stringify({
        template_name: templateName,
        // form_type: formType,
        // industry_id: Number(companyIndustry.industry_id),
        // industry_name: companyIndustry.name,
        template: { ...elements },
      });

      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/admin/formtemplate/${location.state.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          console.log(response.data);
          toast.success("Template Updated Successfully", {
            id: "templateUpdatedSuccess",
          });
          setCompanyIndustry(0);
          setTemplateName("");
          setTimeout(() => {
            navigate("/formTemplate");
          }, 300);
        })
        .catch(function (error) {
          toast.error("Some error occurred.", { id: "templateUpdateError" });
          console.log(error);
        });
    } else {
      toast.error("Cannot perform this action ", { id: "updateTemplateError" });
    }
  };

  return (
    <>
      <div className="page-content">
        <div className="card p-4">
          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Preview
                  </Typography>
                  {elements &&
                    elements.map((element, idx) => {
                      return element.type === "select" ? (
                        <div className="col-md-12 my-2" key={idx}>
                          <div className="d-flex my-1">
                            <span className="p-2">
                              {element.name} : {element.label}{" "}
                            </span>
                          </div>
                          <select className="my-2 py-1">
                            <option value={"Select Options"}>
                              Select Options
                            </option>

                            {element.options.map((opt) => (
                              <option value={opt}>{opt}</option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className="col-md-12 my-2" key={idx}>
                          <div className="d-flex my-1">
                            <span className="p-2">
                              {element.name} : {element.label}{" "}
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type={element.type}
                            maxLength={element.maxLength}
                            placeholder={element.label}
                            required={element.required}
                          />
                        </div>
                      );
                    })}
                </Box>
              </Fade>
            </Modal>
          </div>

          <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
              <h4 className="card-title"> {isUpdate && "Update "} {templateName}</h4>
            </div>
          </div>

          <div className="cmxform" id="signupForm">
            <div className="frmds">
              <div>
                <label className="text-capitalize font-weight-bold">
                  New Input
                </label>
              </div>
              <div className="d-flex form_feed ">
                <label>
                  <input
                    type="text"
                    className="border  rounded-lg shadow-sm shadow-black "
                    placeholder="Add Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                <label>
                  <input
                    type="text"
                    className="border  rounded-lg shadow-sm shadow-black "
                    placeholder="Add Label Name"
                    value={lableName}
                    onChange={(e) => setLableName(e.target.value)}
                  />
                </label>
                <label>
                  <input
                    type="number"
                    className="border  rounded-lg shadow-sm shadow-black "
                    placeholder="Max Length"
                    value={inputLength}
                    onChange={(e) => setInputLength(e.target.value)}
                  />
                </label>
                <label>
                  <select
                    className=" file:rounded-lg shadow-sm shadow-black "
                    value={inputType}
                    onChange={(e) => handleInputTypeChange(e)}
                  >
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={""}
                    >
                      Select Type
                    </option>
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={"date"}
                    >
                      Date
                    </option>
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={"number"}
                    >
                      Number
                    </option>
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={"file"}
                    >
                      File
                    </option>
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={"checkbox"}
                    >
                      Checkbox
                    </option>
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={"text"}
                    >
                      Text
                    </option>
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={"select"}
                    >
                      Options
                    </option>
                  </select>
                </label>

                {inputType === "select" ? (
                  <label>
                    {userTypes.map((key) => (
                      <label key={key}>
                        <span
                          className="px-2 py-2 mx-2 btn btn-outline-danger rounded-lg shadow-sm shadow-black "
                          onClick={(e) => removeFromUserTypes(e, key)}
                        >
                          {key}
                        </span>
                      </label>
                    ))}

                    <input
                      type="text"
                      className="border  rounded-lg shadow-sm shadow-black wdt1"
                      placeholder="Add new option"
                      value={newUserValue}
                      onChange={(e) => setNewUserValue(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.keyCode == 13) {
                          console.log(newUserValue);
                          let oldUserTypes = [...userTypes];
                          oldUserTypes.push(newUserValue);
                          setUsersTypes(oldUserTypes);
                          setNewUserValue("");
                        }
                      }}
                    />
                  </label>
                ) : (
                  <label></label>
                )}
                <label className="d-flex">
                  <input
                    type="checkbox"
                    className=" py-1 rounded-lg shadow-sm shadow-black px-2"
                    checked={required ? "checked" : ""}
                    onChange={() => setRequired(!required)}
                  />{" "}
                  <span className="px-1 py-1 ">Required</span>
                </label>

                <button
                  type="button"
                  className="btn btn-outline-primary "
                  onClick={() => setReRender(!reRender)}
                >
                  Add
                </button>
              </div>

              {elements.map((element, idx) => {
                return element.type === "select" ? (
                  <div className="show_wt1  " key={idx}>
                    <div className="d-flex my-1 justify-between">
                      <span className="text_show">
                        {" "}
                        {element.name} : {element.label}
                      </span>
                      <span
                        className="close_show text-danger"
                        onClick={(e) => handleDeleteElement(e, idx)}
                      >
                        x
                      </span>{" "}
                    </div>
                    <select className="my-2 py-1">
                      <option value={"Select Options"}>Select Options</option>

                      {element.options.map((opt) => (
                        <option value={opt}>{opt}</option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="show_wt1 " key={idx}>
                    <div className="d-flex my-1 justify-between">
                      <span className="text_show">
                        {" "}
                        {element.name} : {element.label}{" "}
                      </span>
                      <span
                        className="close_show  text-danger"
                        onClick={(e) => handleDeleteElement(e, idx)}
                      >
                        x
                      </span>
                    </div>
                    <input
                      className="form-control rounded-lg shadow-sm shadow-black py-2 px-2"
                      type={element.type}
                      maxLength={element.maxLength}
                      placeholder={element.label}
                      required={element.required}
                    />
                  </div>
                );
              })}
            </div>

            <div className="row frmds ">
              <div className="col-md-4 my-2">
                <label>Industry </label>
                <select
                  className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black px-2"
                  value={companyIndustry.industry_id}
                  onChange={handleCompanyIndustryChange}
                  disabled={isUpdate == true}
                >
                  <option
                    className="rounded-lg shadow-sm shadow-black px-2"
                    value={0}
                  >
                    Select Industry
                  </option>
                  {industryList.map((industry, idx) => (
                    <option
                      key={industry.industry_id}
                      value={industry.industry_id}
                      className="rounded-lg shadow-sm shadow-black px-2"
                    >
                      {industry.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4 my-2">
                <label className="">Form Type </label>
                <select
                  className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black px-2"
                  value={formType}
                  onChange={handleCompanyFormtypeChange}
                  disabled={isUpdate == true}
                >
                  {formTypes.map((formType) => (
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={formType.id}
                    >
                      {formType.name}
                    </option>
                  ))}

                  {/* <option value={2}>Warranty </option>
                        <option value={3}> KYC </option>
                        <option value={4}>Login </option>
                        <option value={5}>Registration </option> */}
                </select>
              </div>

              <div className="col-md-4 my-2">
                <label className="">Language </label>
                <select
                  className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black px-2"
                  value={language}
                  onChange={handleLanguageChange}
                  disabled={isUpdate == true}
                  required
                >
                  {languages.map((l) => (
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={l.name}
                    >
                      {l.name}
                    </option>
                  ))}

                  {/* <option value={2}>Warranty </option>
                        <option value={3}> KYC </option>
                        <option value={4}>Login </option>
                        <option value={5}>Registration </option> */}
                </select>
              </div>
              <div className="col-md-4 my-2">
                <label>Template Name </label>

                <input
                  type="text"
                  className="form-control rounded-lg shadow-sm shadow-black px-2"
                  placeholder="Template Name"
                  value={templateName}
                  disabled={isUpdate == true}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex  mt-3 gap-3 ">
              {isUpdate ? (
                <button
                  type="submit"
                  className="btn btn-outline-primary btnall"
                  onClick={updateFormTemplate}
                >
                  Update
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-primary btnall"
                  onClick={addFormTemplate}
                >
                  Save
                </button>
              )}
              <button
                className="btn btn-outline-secondary btnall"
                onClick={handleOpen}
              >
                Preview
              </button>

              <button
                type="cancel"
                className="btn btn-outline-danger btnall"
                onClick={cancelAddProductSubCategory}
              >
                Cancel
              </button>
            </div>
          </div>

          <div className="mt-5 text-secondary">
            {
              <p>
                * phone , name , date of purchase are required for all warranty
                forms.
              </p>
            }
          </div>
        </div>
      </div>
    </>
  );
}
