import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetAllDreamGiftsCheckoutMutation,
  useUpdateRedeemedDreamGiftsStatusMutation,
} from "../../../features/tenant/redeemedDreamGiftsStatus/redeemedGiftsStatusApi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { toast } from "react-hot-toast";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useGetRedeemedGiftsStatusQuery } from "../../../features/tenant/redeemedGiftsStatus/redeemedGiftsStatusApi";
import { useMemo } from "react";
import { useGetAllZoneMutation } from "../../../features/tenant/zones/zonesApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";


const DreamGiftsRedeemRequest = () => {
  const [popUpBtnToggle, setPopUpBtnToggle] = useState(false);
  const [selectStatus, setSelectStatus] = useState(1);
  const [currentDreamGiftId, setCurrentDreamGiftId] = useState(null);
  const [progressBtnToggle, setProgressBtnToggle] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const navigate = useNavigate();
  const [showAddressModal, setshowAddressModal] = useState(false);

  const [giftDetailPopUpToggle, setGiftDetailPopUpToggle] = useState(false);
  const [giftDetails, setGiftDetails] = useState(null);

  const [row, setRow] = useState([]);
  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const {
    data: getRedeemedGiftsStatusData,
    error: getRedeemedGiftsStatusError,
  } = useGetRedeemedGiftsStatusQuery({
    token: sessionStorage.getItem("access_token"),
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });


  const [allZones, setAllZones] = useState([]);
  const [userZone, setUserZone] = useState({
    name: "All Zones",
    id: 0,
  });
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType")) || [];
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    name: "",
    mobile: "",
    user_type_id: userTypeId,
    limit: 10,
    offset: 0,
    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
    setUserZone({
      name: "All Zones",
      id: 0,
    });
    setUserTypesArray({
      name: "All",
      id: null,
    })
  };

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  const [
    getAllZone,
    {
      data: getAllZoneData,
      isLoading: getAllZoneIsLoading,
      isError: getAllZoneIsError,
      error: getAllZoneError,
    },
  ] = useGetAllZoneMutation();

  const [
    getAllDreamGiftsCheckout,
    {
      data: getAllDreamGiftsCheckoutData,
      error: getAllDreamGiftsCheckoutError,
    },
  ] = useGetAllDreamGiftsCheckoutMutation();

  const [
    updateRedeemedDreamGiftsStatus,
    {
      data: updateRedeemedDreamGiftsStatusData,
      error: updateRedeemedDreamGiftsStatusError,
    },
  ] = useUpdateRedeemedDreamGiftsStatusMutation();

  const handleGetAllDreamGiftsCheckout = () => {
    getAllDreamGiftsCheckout({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      zoneId: userZone.id,
    });
  };

  useEffect(() => {
    getAllZone({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    const hasViewPermission = hasPermission("dreamGiftsRedeemRequestList");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    handleGetAllDreamGiftsCheckout();
  }, [request , limit, offset]);

  useEffect(() => {
    if (getAllZoneData) {
      const allZone = getAllZoneData.body.map((x) => {
        return {
          id: x.id,
          name: x.zone_name,
        };
      });

      setAllZones(allZone);
    }
    if (getAllZoneError) {
      toast.dismiss();
      toast.error(getAllZoneError.data.message, {
        id: "errorAtGetallzonedata",
      });
    }
  }, [getAllZoneData, getAllZoneError]);

  useEffect(() => {
    if (getAllDreamGiftsCheckoutData && getAllDreamGiftsCheckoutData.body) {

      setMyList(getAllDreamGiftsCheckoutData.body && getAllDreamGiftsCheckoutData.body.data ? getAllDreamGiftsCheckoutData.body.data : []);
      setTotalRows(getAllDreamGiftsCheckoutData.body && getAllDreamGiftsCheckoutData.body.total ? getAllDreamGiftsCheckoutData.body.total : 0);
      // toast.success(getAllDreamGiftsCheckoutData.message, {
      //   id: "getAllDreamGiftsCheckoutData",
      // });
    }

    if (getAllDreamGiftsCheckoutError) {
      toast.error("Something went wrong.", {
        id: "getAllDreamGiftsCheckoutError",
      });
    }
  }, [getAllDreamGiftsCheckoutData, getAllDreamGiftsCheckoutError]);

  const handleUpdateRedeemedDreamGiftsStatus = (e) => {
    updateRedeemedDreamGiftsStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        status: `${selectStatus}`,
        dream_gift_id: `${currentDreamGiftId}`,
      },
    });
  };
  useEffect(() => {
    if (updateRedeemedDreamGiftsStatusData) {
      toast.dismiss();
      toast.success(`${updateRedeemedDreamGiftsStatusData.message}`, {
        id: `updateRedeemedGiftsStatusData`,
      });
      handleGetAllDreamGiftsCheckout();
      setPopUpBtnToggle(false);
    }

    if (updateRedeemedDreamGiftsStatusError) {
      toast.dismiss();
      toast.error(`${updateRedeemedDreamGiftsStatusError.data.message}`, {
        id: `updateRedeemedGiftsStatusError`,
      });
      setPopUpBtnToggle(false);
    }
  }, [updateRedeemedDreamGiftsStatusData, updateRedeemedDreamGiftsStatusError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Points",
      cell: (row) => row.value,
      sortable: true,
      width: "120px",
    },
    {
      name: "Catalogue name",
      cell: (row) => row.catalogue_name,
      sortable: true,
    },
    // {
    //   name: "Target Type",
    //   cell: (row) =>
    //     TypeArr.map((x) => {
    //       if (x.id == row.target_type) {
    //         return x.name;
    //       } else {
    //         return "";
    //       }
    //     }),
    //   sortable: true,
    //   // width: "120px",
    // },
    {
      name: "User Type",
      cell: (row) => <span className="capitalize">{row.user_type}</span>,
      sortable: true,
      width: "120px",
    },
    {
      name: "",
      cell: (row) => {
        if (row.gift) {
          return (
            <div>
              <button
                onClick={() => {
                  // console.log("giftssss", row.gift);
                  setGiftDetailPopUpToggle(true);
                  setGiftDetails([row.gift]);
                }}
                className="flex justify-center items-center rounded bg-green-900 px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                View gifts
              </button>
            </div>
          );
        } else {
          return "";
        }
      },
      sortable: true,
    },
    {
      name: "",
      cell: (row) => {
        if (row.address && Object.keys(row.address).length > 0) {
          return (
            <div>
              <button
                onClick={() => {
                  setshowAddressModal(row.address);
                }}
                className="flex justify-center items-center rounded bg-blue-900 px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                View address
              </button>
            </div>
          );
        } else {
          return "";
        }
      },
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          onClick={() => {

            if(Number(row.status) == 0){
              toast.error("This request has aleady been rejected. you cannot change the status.", {id : "rejected-status"});
              return;
            }
            // handleStatusChange(row, "1");
            setCurrentDreamGiftId(row.dream_gift_id);
            setSelectStatus(Number(row.status));
            setPopUpBtnToggle(true);
          }}
          className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        >
          {row.status_name}
        </button>
      ),

      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          {Number(row.status) == 0 ? "" :
            <button
              onClick={() => {
                // handleStatusChange(row, "2");
                setCurrentStatus(Number(row.status));
                setProgressBtnToggle(true);
                setRow(row.statusUpdateDetails);
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              {/* <AiOutlineEdit className="mr-1" /> */}
              View progress
            </button>
          }
        </>
      ),
      sortable: true,
    },

    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      minWidth : "150px"
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Dream Gifts Redeem Request
            <LinksComponent />
          </div>

          <div className="w-full p-3 ">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 ">
                <div className="w-52">
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Name
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Name..."
                      required
                      value={request.name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52">
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Mobile
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Mobile No..."
                      required
                      value={request.mobile}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, mobile: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52">
                  <div className="relative searchin topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Zones
                    </label>
                    <div className="relative searchin">
                      <SelectComponent
                        placeholder={"Select Zone*"}
                        selected={userZone}
                        setSelected={setUserZone}
                        dataArr={allZones}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="w-full  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.startDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          startDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="w-full  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.endDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          endDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 ">
                  <div className="flex-col slc_dvd topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      User Types
                    </label>
                    <div className="relative searchin">
                      {appUsers && appUsers.length > 0 && (
                        <SelectComponent
                          className="capitalize"
                          //title={"User Types"}
                          selected={userTypesArray}
                          setSelected={setUserTypesArray}
                          dataArr={appUsers}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    &nbsp;
                  </label>
                  <div className="relative searchin gap-2 flex">
                    <button
                      className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                      onClick={() => {
                        handleGetAllDreamGiftsCheckout();
                        // handleFetchRedemptionHistoryCsv();
                      }}
                    >
                      Search
                    </button>

                    <button
                      className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                // title={""}
                data={myList}
                // loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>
              {popUpBtnToggle && (
                <ModalComponent
                  size={"md"}
                  show={popUpBtnToggle}
                  setShow={setPopUpBtnToggle}
                >
                  <div className="w-full p-4">
                    <div className="w-full flex justify-center ">
                      <select
                        type="text"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-auto capitalize"
                        // placeholder="Add new UserType"
                        value={selectStatus}
                        onChange={(e) =>
                          setSelectStatus(Number(e.target.value))
                        }
                      >
                        <option key={0} value={Number(0)}>
                          {"Reject"}
                        </option>
                        <option key={1} value={Number(1)}>
                          {"Pending"}
                        </option>
                        {getRedeemedGiftsStatusData &&
                          getRedeemedGiftsStatusData.body.map((x, index) => {
                            if (index == 0 || index == 1) {
                              return <></>;
                            } else {
                              return (
                                <option key={index} value={Number(index)}>
                                  {x}
                                </option>
                              );

                            }
                          })}
                      </select>
                    </div>
                    <div className="w-full flex justify-center mt-4 ">
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        onClick={handleUpdateRedeemedDreamGiftsStatus}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </ModalComponent>
              )}
              {progressBtnToggle && (
                <ModalComponent
                  size={"sm"}
                  show={progressBtnToggle}
                  setShow={setProgressBtnToggle}
                >
                  <div className="w-full p-4">
                    <div className="flex justify-start pl-4 ">
                      <ol class="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400">
                        {getRedeemedGiftsStatusData.body.map((x, index) => {
                          if (index == 0) {
                            return <></>;
                          }
                          if (index <= currentStatus) {
                            return (
                              <li class="mb-10 ml-7 flex items-center">
                                <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                                  <svg
                                    class="w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 16 12"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M1 5.917 5.724 10.5 15 1.5"
                                    />
                                  </svg>
                                </span>
                                <h3 class="font-medium leading-tight capitalize fntchng">
                                  {x}

                                  {row &&
                                    row.length > 0 &&
                                    row
                                      .filter((y) => +y.status === index)
                                      .map((filteredRow) => (
                                        <span key={filteredRow.id} className="">
                                          {new Date(
                                            filteredRow.created_at
                                          ).toLocaleString()}
                                        </span>
                                      ))}
                                </h3>
                              </li>
                            );
                          } else {
                            return (
                              <li class="mb-10 ml-7 flex items-center">
                                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                                  <svg
                                    class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 16"
                                  >
                                    <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
                                  </svg>
                                </span>
                                <h3 class="font-medium leading-tight capitalize">
                                  {x}
                                </h3>
                                {/* <p class="text-sm">Step details here</p> */}
                              </li>
                            );
                          }
                        })}

                        {/* <li class="mb-10 ml-6">
                        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                          <svg
                            class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 20"
                          >
                            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                          </svg>
                        </span>
                        <h3 class="font-medium leading-tight">Review</h3>
                        <p class="text-sm">Step details here</p>
                      </li>
                      <li class="ml-6">
                        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                          <svg
                            class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 20"
                          >
                            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z" />
                          </svg>
                        </span>
                        <h3 class="font-medium leading-tight">Confirmation</h3>
                        <p class="text-sm">Step details here</p>
                      </li> */}
                      </ol>
                    </div>
                  </div>
                </ModalComponent>
              )}

              {showAddressModal && Object.keys(showAddressModal).length > 0 && (
                <ModalComponent
                  size={"md"}
                  show={
                    showAddressModal && Object.keys(showAddressModal).length > 0
                      ? true
                      : false
                  }
                  setShow={setshowAddressModal}
                >
                  <div className="w-full">
                    <div className="w-full">
                      <div className="p-3 bg-white rounded-lg shadow-md  mx-auto">
                        <h2 className="text-lg text-center font-bold mb-4">
                          Address Details
                        </h2>
                        <div className="mb-2">
                          <span className="font-semibold">Address: </span>
                          {showAddressModal?.address ? showAddressModal.address : showAddressModal?.data?.address}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">Pincode: </span>
                          {showAddressModal.pincode}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">District: </span>
                          {showAddressModal.district}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">City: </span>
                          {showAddressModal.city}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">State: </span>
                          {showAddressModal.state}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">Country: </span>
                          {showAddressModal.country}
                        </div>


                      </div>
                    </div>
                  </div>
                </ModalComponent>
              )}

              {giftDetailPopUpToggle && (
                <ModalComponent
                  size={"md"}
                  show={giftDetailPopUpToggle}
                  setShow={setGiftDetailPopUpToggle}
                >
                  <div className="w-full ">
                    <div className="w-full p-4">
                      {giftDetails &&
                        giftDetails.map((x) => {
                          return (
                            <div className="flex gap-3 items-center bg-gray-50 border border-gray-100 rounded-sm overflow-hidden p-2 mb-2">
                              <div className="w-1/5 border bg-white border-gray-100 rounded-sm overflow-hidden p-2">
                                <img
                                  src={x.images[0]}
                                  alt={"error loading image"}
                                />
                              </div>
                              <div className="w-4/5">
                                <span className="capitalize block">
                                  <span className="text-gray-400">Name</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.gift_name}
                                </span>
                                <span className="capitalize block">
                                  <span className="text-gray-400">Brand</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.brand}
                                </span>
                                <span className="capitalize block">
                                  <span className="text-gray-400">Point</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.points}
                                </span>
                                {/* <span className="capitalize block">
                                  <span className="text-gray-400">Value</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.value}
                                </span> */}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </ModalComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DreamGiftsRedeemRequest;
