import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useCreateChangeAppUserMutation,
  useGetChangeAppUserCountMutation,
  useGetChangeAppUserMutation,
} from "../../../features/tenant/userTypeChange/userTypeChnageApi";
import { useGetUserProfileMutation } from "../../../features/tenant/ManualRewarding/ManualRewardingApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { HiOutlinePlus } from "react-icons/hi2";
import { Menu } from "@headlessui/react";
import toast from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

const UserTypeChangeList = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const passwordUsers = JSON.parse(sessionStorage.getItem("companyProfile"))
    .login_options.Password.users;
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    old_user_type: "",
    mobile: "",
    name: "",
    new_user_type: "",
    limit: 10,
    offset: 0,
    startDate: "",
    endDate: "",
  };

  const [
    getUserProfile,
    {
      data: getUserProfileData,
      error: getUserProfileError,
      isLoading: getUserProfileisLoading,
    },
  ] = useGetUserProfileMutation();
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  console.log(appUserTypes);
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [request, setRequest] = useState(initialRequest);
  const [showModal, setShowModal] = useState(false);
  const clearFilters = () => {
    setRequest(initialRequest);
  };
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [mobile, setMobile] = useState(null);
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "No User",
    id: null,
  });

  const [
    fetchAllUserTypeChange,
    {
      data: fetchAllUserTypeChangeData,
      isLoading: fetchAllUserTypeChangeIsLoading,
      isError: fetchAllUserTypeChangeIsError,
      error: fetchAllUserTypeChangeError,
    },
  ] = useGetChangeAppUserMutation();

  const [
    addUserTypeChange,
    {
      data: addUserTypeChangeData,
      isLoading: addUserTypeChangeIsLoading,
      isError: addUserTypeChangeIsError,
      error: addUserTypeChangeError,
    },
  ] = useCreateChangeAppUserMutation();

  useEffect(() => {
    if (addUserTypeChangeData) {
      toast.success("User Type chnaged Successfully");
      setUser(null);
      setMobile(null);
      setShowModal(false);
    }

    if (addUserTypeChangeError) {
      toast.error(" Error in changing User Type ");
    }
  }, [addUserTypeChangeData, addUserTypeChangeError]);

  const [
    fetchAllUserTypeChangeCount,
    {
      data: fetchAllUserTypeChangeCountData,
      isLoading: fetchAllUserTypeChangeCountIsLoading,
      isError: fetchAllUserTypeChangeCountIsError,
      error: fetchAllUserTypeChangeCountError,
    },
  ] = useGetChangeAppUserCountMutation();

  useEffect(() => {
    if (fetchAllUserTypeChangeCountData?.body?.total) {
      setTotalUser(fetchAllUserTypeChangeCountData?.body?.total);
    }
  }, [fetchAllUserTypeChangeCountData]);
  const navigate = useNavigate();

  const handleGetMobileDetails = (e) => {
    getUserProfile({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      mobile: mobile,
    });
  };
  const handleMobileChange = (e) => {
    setMobile(e.target.value.trim());
  };

  const handleAddUserTypeChange = (e) => {
    e.preventDefault();
    if (!mobile) {
      toast.error("Please enter mobile number");
      return;
    }
    if (mobile.length != 10) {
      toast.error("Please enter 10 digit mobile number");
      return;
    }
    if (!user) {
      toast.error("Please enter a valid mobile number");
      return;
    }
    if (!user.name) {
      toast.error("User without name cannot be changed");
      return;
    }
    console.log(userTypesArray, "Please select a user type");
    if (!userTypesArray.user_type_id) {
      toast.error("Please select a user type");
      return;
    }
    if (!userTypesArray.user_type) {
      toast.error("Please select a user type");
      return;
    }

    if (
      userTypesArray.user_type.toLowerCase() == user.user_type.toLowerCase()
    ) {
      toast.error("Please select a different user type");
      return;
    }

    console.log(userTypesArray);

    const body = {
      mobile,
      new_user_type: userTypesArray.user_type,
      new_user_type_id: userTypesArray.user_type_id,
      old_user_type: user.user_type,
      old_user_type_id: user.user_type_id,
      app_user_id: user.id,
      name: user.name,
    };
    if (
      passwordUsers.includes(userTypesArray.user_type) &&
      !user?.user_id &&
      userId == null
    ) {
      toast.error("Please Enter User Id");
      return;
    }
    if (
      passwordUsers.includes(userTypesArray.user_type) &&
      !user?.password &&
      password == null
    ) {
      toast.error("Please Enter new password");
      return;
    }
    if (passwordUsers.includes(userTypesArray.user_type) && !user?.user_id) {
      body.user_id = userId;
    }
    if (passwordUsers.includes(userTypesArray.user_type) && !user?.password) {
      body.password = password;
    }
    if (window.confirm("Are you sure you want to update this user type")) {
      addUserTypeChange({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: body,
      });
    }
    return;
  };
  useEffect(() => {
    if (
      request.old_user_type == "" &&
      request.mobile == "" &&
      request.name == "" &&
      request.new_user_type == ""
    ) {
      handleFetcUserTypeChange();
      handleFetcUserTypeChangeCount();
    }
  }, [request]);

  useEffect(() => {
    const mobileRegex = /^[6-9]\d{9}$/;

    if (mobile && typeof mobile === "string") {
      const trimmedMobile = mobile.trim();

      if (trimmedMobile.length === 10 && mobileRegex.test(trimmedMobile)) {
        handleGetMobileDetails();
      }
    }
  }, [mobile]);

  useEffect(() => {
    if (getUserProfileData) {
      setUser(getUserProfileData?.body);
    }
  }, [getUserProfileError, getUserProfileData]);
  useEffect(() => {
    const hasViewPermission = hasPermission("viewChangeUserType");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    if (fetchAllUserTypeChangeData) {
      setUserData(fetchAllUserTypeChangeData?.body?.appUsersList);
      navigate(`/genefied/${getVendorName()}/changed-users`);
    }
  }, [fetchAllUserTypeChangeData, fetchAllUserTypeChangeError]);

  const handleFetcUserTypeChange = () => {
    fetchAllUserTypeChange({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const handleFetcUserTypeChangeCount = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchAllUserTypeChangeCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };
  useEffect(() => {
    handleFetcUserTypeChange();
    handleFetcUserTypeChangeCount();
  }, [limit, offset]);

  const warrantyColumns = [
    {
      name: "SNo",
      cell: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Old User Type",
      cell: (row) => row.old_user_type,
      sortable: true,
      // width: "120px",
    },
    {
      name: "New User Type",
      cell: (row) => row.new_user_type,
      sortable: true,
    },

    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      width: "170px",
    },

    {
      name: "Created By Name",
      selector: (row) => row.created_by_name,
      sortable: true,
      width: "170px",
    },
  ];

  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Chnaged Users List
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile ..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Name
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name ..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Old-Usertype
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Old User Type..."
                        required
                        value={request.old_user_type}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, old_user_type: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        New-Usertype
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="New User Type..."
                        required
                        value={request.new_user_type}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, new_user_type: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-32 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              start_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-44 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              end_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>
                      <button
                        className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                        onClick={() => {
                          handleFetcUserTypeChange();
                          handleFetcUserTypeChangeCount();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>
                      <button
                        className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {fetchAllUserTypeChangeIsLoading && <CircleLoaderComponent />}
              <DataTableComponent
                keyField={(row) => row.SNo}
                columns={warrantyColumns}
                data={userData}
                loading={fetchAllUserTypeChangeIsLoading}
                totalRows={totalUser}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
              <div
                className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                id="box_btn"
              >
                <div className="text-right">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        onClick={() => setShowModal(true)}
                        className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                        style={{
                          backgroundColor: `${primaryColor["700"]}`,
                        }}
                      >
                        <HiOutlinePlus className="w-full h-full p-3" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ModalComponent
          size={"sm"}
          data={"nadnandand"}
          show={showModal}
          setShow={setShowModal}
        >
          <div className="w-full p-4">
            <div className="w-full flex-col justify-center">
              {/* Mobile Input */}
              <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                Mobile
              </label>
              <div className="items-center justify-center w-full mb-6">
                <input
                  className="w-full px-1 text-sm p-2.5 shadow-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                  name={"Mobile"}
                  type={"text"}
                  placeholder={"Enter Mobile"}
                  value={mobile}
                  onChange={handleMobileChange}
                  required={true}
                />
              </div>

              {/* User Type Selection */}
              <div className="items-center justify-center w-full mb-6">
                <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                  User Types
                </label>
                <div className="relative searchin">
                  {appUsers && appUsers.length > 0 && (
                    <SelectComponent
                      className="capitalize"
                      selected={userTypesArray}
                      setSelected={setUserTypesArray}
                      dataArr={appUsers}
                    />
                  )}
                </div>
              </div>
              <>
                {passwordUsers.includes(userTypesArray.user_type) &&
                 
                    <>
                      {/* User ID Input (If user_id is missing) */}
                      { (
                        <div className="items-center justify-center w-full mb-6">
                          <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                            User Id
                          </label>
                          <input
                            className="w-full px-1 text-sm p-2.5 shadow-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            name="user_id"
                            type="text"
                            placeholder="Enter User ID"
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            required
                          />
                        </div>
                      )}

                      {/* Password Input (If password is missing) */}
                      {(
                        <div className="items-center justify-center w-full mb-6">
                          <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                            Password
                          </label>
                          <input
                            className="w-full px-1 text-sm p-2.5 shadow-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      )}
                    </>
                  }
              </>
              {/* Show User Profile Details */}
              {getUserProfileData && (
                <div className="mb-6 p-4 border rounded-lg shadow-md bg-gray-100">
                  <h2 className="text-lg font-semibold mb-2">User Details</h2>
                  <p>
                    <strong>ID:</strong> {getUserProfileData?.body?.id}
                  </p>
                  <p>
                    <strong>Name:</strong>{" "}
                    {getUserProfileData?.body?.name || "N/A"}
                  </p>
                  <p>
                    <strong>User Type:</strong>{" "}
                    {getUserProfileData?.body?.user_type}
                  </p>
                  <p>
                    <strong>Mobile:</strong>{" "}
                    {getUserProfileData?.body?.mobile || "N/A"}
                  </p>
                  <p>
                    <strong>User ID:</strong>{" "}
                    {getUserProfileData?.body?.user_id || "N/A"}
                  </p>
                  {getUserProfileData.profile_pic && (
                    <div className="mt-2">
                      <img
                        src={getUserProfileData.profile_pic}
                        alt="Profile"
                        className="w-20 h-20 rounded-full border"
                      />
                    </div>
                  )}
                </div>
              )}

              {/* Add Button */}
              {getUserProfileData && (
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    onClick={(e) => handleAddUserTypeChange(e)}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>
          </div>
        </ModalComponent>
      )}
    </>
  );
};

export default UserTypeChangeList;
