import React, { useEffect, useState } from "react";
import { useFetchAllNotInitiatedPaymentMutation } from "../../../features/tenant/paymentTransfer/paymentTransferApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";

const paymentStatus = ["failure", "success", "pending", "Not Initiated"];

const NotInitiatedPayment = () => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const clearFilters = () => {
    setRequest(initialRequest);
  };
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const currentDate = new Date().toISOString().split("T")[0];

  const initialRequest = {
    limit: 10,
    offset: 0,
    mobile: "",
    start_date: "",
    end_date:"",
  };
  const [request, setRequest] = useState(initialRequest);

  const [
    fetchAllNotInitiatedPayment,
    {
      data: fetchAllNotInitiatedPaymentData,
      error: fetchAllNotInitiatedPaymentError,
      isLoading: fetchAllNotInitiatedPaymentLoading,
    },
  ] = useFetchAllNotInitiatedPaymentMutation();

  const [
    fetchAllNotInitiatedPaymentCSV,
    {
      data: fetchAllNotInitiatedPaymentCSVData,
      error: fetchAllNotInitiatedPaymentCSVError,
      isLoading: fetchAllNotInitiatedPaymentCSVLoading,
    },
  ] = useFetchAllNotInitiatedPaymentMutation();

  //   const [
  //     fetchPaymentTransferByBatchIDCsv,
  //     {
  //       data: paymentTransferListCsvData,
  //       error: paymentTransferListCsvError,
  //       isLoading: ppaymentTransferListCsvisLoading,
  //     },
  //   ] = useFetchPaymentTransferByBatchIDCSVMutation();

  const fetchPaymentTrasnferListFunc = () => {
    fetchAllNotInitiatedPayment({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,

      body: request,
    });
  };

  const fetchPaymentTrasnferListForCsvFunc = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchAllNotInitiatedPaymentCSV({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,

      body: modifiedRequest,
    });
  };

  useEffect(() => {
    console.log("outer");
    if (request) {
      console.log("inner");

      fetchPaymentTrasnferListFunc();
      fetchPaymentTrasnferListForCsvFunc();
    }
  }, [request, limit, offset]);
  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Emp ID ",
      selector: (row) => row.emp_id,
      sortable: true,
    },
    {
      name: "Mobile ",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Name ",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Reporting Manager ",
      selector: (row) => row.l1_manager,
      sortable: true,
      width: "100px",
    },
    {
      name: "Transfer Id  ",
      selector: (row) => row.transfer_id,
      sortable: true,
      width: "200px",
    },
    {
      name: "Reference Id",
      selector: (row) => row.reference_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Amount ",
      selector: (row) => row.amount,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => (
        <span
          className={`text-sm p-1 capitalize rounded-md font-bold bg-${
            row.payment_status == "0"
              ? "red"
              : row.payment_status == "1"
              ? "blue"
              : "slate"
          }-700 text-white animate-pulse duration-100`}
        >
          {paymentStatus[row.payment_status]}
        </span>
      ),
      sortable: true,
      width: "170px",
    },
    {
      name: "Active Transfer Mode",
      selector: (row) => row.active_tm,
      sortable: true,
      width: "170px",
    },
    {
      name: "Active bank details ",
      selector: (row) => {
        return (
          <>
            {row.active_tm == "upi" && (
              <>
                {" "}
                Beneficiary Name : {row.active_bd.bene_name} <br /> UPI :{" "}
                {row.active_bd.upi_id}{" "}
              </>
            )}
            {row.active_tm == "banktransfer" && (
              <>
                {" "}
                Beneficiary Name : {row.active_bd.bene_name} <br /> Account NO :{" "}
                {row.active_bd.bank_account} <br /> IFSC : {row.active_bd.ifsc}{" "}
              </>
            )}
          </>
        );
      },
      width: "200px",
    },
    {
      name: "Payout Transfer Mode",
      selector: (row) => row.transfer_mode,
      sortable: true,
      width: "170px",
    },
    {
      name: "Payout bank details ",
      selector: (row) => {
        return (
          <>
            {row.transfer_mode == "upi" && (
              <>
                {" "}
                Beneficiary Name : {row.bene_details.name} <br /> UPI :{" "}
                {row.bene_details.vpa}{" "}
              </>
            )}
            {row.transfer_mode == "banktransfer" && (
              <>
                {" "}
                Beneficiary Name : {row.bene_name} <br /> Account NO :{" "}
                {row.bene_details.bankAccount} <br /> IFSC :{" "}
                {row.bene_details.ifsc}{" "}
              </>
            )}
          </>
        );
      },
      width: "200px",
    },
    {
      name: "Payout Status",
      selector: (row) => {
        return row.cttb_status == "1" ? (
          <div className=" text-green-600 capitalize ">Success</div>
        ) : row.cttb_status == "2" ? (
          <div className=" text-gray-600  capitalize ">Pending</div>
        ) : row.cttb_status == "0" ? (
          <div className=" text-red-600  capitalize ">Error</div>
        ) : (
          <div>Not Initiated</div>
        );
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Payout Response Message",
      selector: (row) => row.response_message,
      sortable: true,
      width: "170px",
    },
    {
      name: "Is Bank Added ",
      selector: (row) => (row.is_active_bank ? "Yes" : "No"),
      sortable: true,
      width: "170px",
    },
    {
      name: "Is KYC Done ",
      selector: (row) => (row.is_kyc_done ? "Yes" : "No"),
      sortable: true,
      width: "170px",
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Payment Transfer List By Batch Id
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className="p-3 w-full bg-white shadow rounded-lg">
            {fetchAllNotInitiatedPaymentCSVData &&
              fetchAllNotInitiatedPaymentCSVData?.body?.data && (
                <ExportCSV
                  data={
                    fetchAllNotInitiatedPaymentCSVData &&
                    fetchAllNotInitiatedPaymentCSVData?.body.data
                  }
                  columnsToExport={[
                    "emp_id",
                    "mobile",
                    "name",
                    "l1_manager",
                    "amount",
                    "month",
                    "year",
                    "transfer_id",
                    "reference_id",
                    "upi_mobile",
                    "batch_id",
                    "active_tm",
                    "active_bd",
                    "transfer_mode",
                    "bene_details",
                    "cttb_status",
                    "response_message",
                    "is_active_bank",
                    "is_kyc_done",
                  ]}
                />
              )}

            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 justify-end">
                <div className="relative searchin">
                  <input
                    type="number"
                    id="default-search"
                    className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Mobile Search"
                    required
                    value={request.mobile}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      console.log("request ", request);

                      setRequest((prevState) => {
                        return { ...prevState, mobile: newValue };
                      });
                    }}
                  />
                </div>
                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <input
                      type="date"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Start Date"
                      required
                      value={request.start_date}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        console.log("request ", request);

                        setRequest((prevState) => {
                          return { ...prevState, start_date: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <input
                      type="date"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="End Date"
                      required
                      value={request.end_date}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, end_date: newValue };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="relative searchin">
                <div className="flex flex-row  gap-2 h-10">
                  <div className="relative">
                    <button
                      className="btn btn-outline-primary clear-both float-right "
                      onClick={() => {
                        fetchPaymentTrasnferListFunc();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <div className="relative searchin">
                    <button
                      className="btn btn-outline-primary clear-both float-right whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableComponent
              keyField={(row) => row.SNo}
              columns={columns}
              data={
                fetchAllNotInitiatedPaymentData
                  ? fetchAllNotInitiatedPaymentData.body.data
                  : []
              }
              loading={fetchAllNotInitiatedPaymentLoading}
              totalRows={
                fetchAllNotInitiatedPaymentData &&
                fetchAllNotInitiatedPaymentData?.body.total
              }
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
            ></DataTableComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotInitiatedPayment;
