import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";

import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiEye, HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";
import {
  useDeleteSchemeByIdMutation,
  useFetchAllSchemeMutation,
} from "../../../features/tenant/scheme/schemeApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useFetchAllCataloguesMutation } from "../../../features/tenant/giftCatalogue/giftCatalogueApi";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import { GrDocumentPdf } from "react-icons/gr";

const SchemeList = () => {
  const addSchemePermission = hasPermission("addScheme");
  const viewSchemeDetailsPermission = hasPermission("viewSchemeDetails");
  const deleteSchemePermission = hasPermission("deleteScheme");

  const [showImageModal, setShowImageModal] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([])

  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => (row.scheme_start),
      sortable: true,
      // width: "120px",
    },
    {
      name: "End Date",
      selector: (row) => (row.scheme_end),
      sortable: true,
    },
    {
      name: "Redeem Start Date",
      selector: (row) => (row.redeem_start),
      sortable: true,
      width: "140px",
    },
    {
      name: "Redeem End Date",
      selector: (row) => (row.redeem_end),
      sortable: true,
      width: "140px",
    },
    {
      name: "Type",
      selector: (row) =>
        row.type == 1
          ? "All Products"
          : row.type == 2
            ? "Classification"
            : "Catgeory-Subcategory",
      sortable: true,
      width: "180px",
    },
    {
      name: "Image",
      cell: (row) =>
        row.image ? (
          <div className="my-2 rounded-md">
            <img
              className="rounded-md"
              src={
                row.image
              }
              onClick={() =>
                setShowImageModal(

                  row.image,
                )
              }
              alt="gift-images"
            />
          </div>
        ) : (
          "NA"
        ),
      maxwidth: "120px",
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <a
            href={row?.pdf}
            target="__blank"
            // onClick={() => {setShowPdf(!showPdf)
            // setCurrentPdf(row.files[0])
            // }}
            className="flex text-center justify-center items-center rounded bg-primary px-3
             text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >

            <GrDocumentPdf className=" text-white" /> &nbsp; &nbsp;{" "}
            <span className="w-full">pdf</span>
          </a>
        </div>
      ),
      minwidth: "150px",
    },
    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() => handleShowModal(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <HiEye className="mr-1" />
            View
          </button>
        </div>
      ),
      minwidth: "50px",
    },
    {
      name: "",
      selector: (row) =>
        viewSchemeDetailsPermission &&
        (
          <div>
            <button
              onClick={() =>
                navigate(`details/${row.id}`, {
                  state: row,
                })
              }
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Details
            </button>
          </div>
        ),
      minwidth: "40px",
    },
    {
      name: "",
      selector: (row) =>
        deleteSchemePermission && (
          <div>
            <button
              onClick={() => handleDelete(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          </div>
        ),
      minwidth: "120px",
    },
  ];

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [giftCataloguesList, setGiftCataloguesList] = useState([]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const initialRequest = {
    name: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    console.log("request", request);
    if (
      request.name === ""
    ) {
      fetchAllSchemeFunc()
      // fetchProductsForCsvFunction();
    }
  }, [request]);

  const [
    fetchAllScheme,
    { data: schemeData, isLoading: schemeIsLoading, error: schemeError },
  ] = useFetchAllSchemeMutation();

  const [
    fetchCatalogues,
    {
      data: cataloguesData,
      isLoading: cataloguesIsLoading,
      isError: cataloguesIsError,
      error: cataloguesError,
    },
  ] = useFetchAllCataloguesMutation();

  useEffect(() => {
    // const hasSchemeView = hasPermission("scheme");

    // if (!hasSchemeView) {
    //   navigate(`/genefied/${getVendorName()}/`);

    fetchAllSchemeFunc();

  }, [limit, offset]);


  const fetchAllSchemeFunc = () => {
    // }
    fetchAllScheme({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body : request
    });
  }

  useEffect(() => {
    fetchCatalogues({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (cataloguesData && cataloguesData.body) {
      const arr = [...cataloguesData.body];
      arr.sort((a, b) => a.catalogue_id - b.catalogue_id);
      setGiftCataloguesList(arr);
    }
  }, [cataloguesData]);

  const [
    deleteScheme,
    {
      data: deleteSchemeData,
      isLoading: deleteSchemeIsLoading,
      error: deleteSchemeError,
    },
  ] = useDeleteSchemeByIdMutation();

  const handleDelete = (data) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deleteScheme({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: data.id,
                });
                fetchAllSchemeFunc();
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteScheme",
      },
    );
    return;
  };

  useEffect(() => {
    if (deleteSchemeError) {
      toast.error("Something went wrong", {
        id: "deleteSchemeError",
      });
    }
  }, [deleteSchemeError]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const handleShowModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const getUserTypeNames = (user_type_id) => {
    const userTypes = JSON.parse(sessionStorage.getItem("appUserType"));
    let name = "";
    userTypes.forEach((each) => {
      if (each.user_type_id == user_type_id) {
        name = each.name;
      }
    });
    return name;
  };

  const getGiftCatalogueNameById = (id) => {
    if (!id) {
      return "N/A";
    }
    let name = "";
    giftCataloguesList.forEach((each) => {
      if (each.catalogue_id == id) {
        name = each.catalogue_name;
      }
    });
    return name;
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (schemeData && schemeData.body) {
      setData(schemeData.body.data);
      if (Number(totalRows) != Number(schemeData.body.total)) {
        setTotalRows(schemeData.body.total);
      }
    }
  }, [schemeData])


  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            All Schemes
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Scheme Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                        onClick={() => {
                          fetchAllSchemeFunc();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                // title={"Scheme List"}
                data={data}
                loading={schemeIsLoading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
              {addSchemePermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`manage`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showModal && modalData && (
          <ModalComponent
            size={"lg"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
              <div className="flex justify-between items-center bg-gray-100 px-4 py-3 rounded-t-lg">

                <div>

                  <h2 className=" text-xl text-gray-900 font-semibold lg:leading-6 leading-7  capitalize">
                    {modalData?.name}
                  </h2>
                </div>

                <div className="w-full flex space-x-2 justify-end">


                  <div className="text-xs text-gray-600  leading-normal font-semibold   text-center bg-white border-dashed border-2 border-red-600 px-2 py-1">
                    <span className="block leading-normal">Start Date {" "}</span>
                    <span className="whitespace-nowrap"> {(modalData?.scheme_start)}</span>
                  </div>
                  <div className="text-xs text-gray-600  leading-normal font-semibold   text-center bg-white border-dashed border-2 border-red-600 px-2 py-1">
                    <span className="block leading-normal">End Date {" "}</span>
                    <span className="whitespace-nowrap"> {(modalData?.scheme_end)}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">



                <div className="py-3">

                  <ul className="timelinebox ml-4 border-l-2 border-gray-300">
                    <li className="pl-4 mb-3">
                      <label className="text-gray-400 mb-1 text-xs leading-none block">User Types{" "}</label>
                      <span className="flex leading-none text-black text-sm  gap-1"> {modalData?.user_types.map((id) => (
                        <span className="capitalize">
                          {" "}
                          {getUserTypeNames(id)}{" "}
                        </span>
                      ))}</span>
                    </li>

                    <li className="pl-4 mb-3">
                      <label className="text-gray-400 mb-1 text-xs leading-none block">States On</label>
                      <span className="flex leading-none text-black text-sm gap-1 max-h-16 overflow-y-scroll py-2">
                        {modalData?.states.join(", ")}
                      </span>
                    </li>

                    {modalData?.type != "1" ? <>
                      <li className="pl-4 mb-3">
                        <label className="text-gray-400 mb-1 text-xs leading-none block">Product Codes On</label>
                        <span className="flex leading-none text-black text-sm  gap-1 max-h-16 overflow-y-scroll py-2">{modalData?.products.join(", ")}</span>
                      </li>

                    </>
                      : <>
                        <li className="pl-4 mb-3">
                          <label className="text-gray-400 mb-1 text-xs leading-none block">Product Codes On</label>
                          <span className="flex leading-none text-black text-sm  gap-1">All Active Products</span>
                        </li>
                      </>
                    }

                    <li className="pl-4 mb-3">
                      <label className="text-gray-400 mb-1 text-xs leading-none block">Gift Catalogue</label>
                      <span className="flex leading-none text-black text-sm  gap-1">{" "}
                        {getGiftCatalogueNameById(
                          modalData?.gift_catalogue_id,
                        )}</span>
                    </li>

                    <li className="pl-4 mb-3">
                      <label className="text-gray-400 mb-1 text-xs leading-none block">Redeem Start Date</label>
                      <span className="flex leading-none text-black text-sm  gap-1">{" "}
                        {(modalData?.redeem_start)}</span>
                    </li>
                    <li className="pl-4 mb-3">
                      <label className="text-gray-400 mb-1 text-xs leading-none block">Redeem End Date</label>
                      <span className="flex leading-none text-black text-sm  gap-1">{" "}
                        {(modalData?.redeem_end)}</span>
                    </li>
                  </ul>
                </div>

                <div className="w-52  overflow-hidden  p-3 text-center  flex justify-center items-center">
                  <span>  {modalData.image && (
                    <img
                      className="w-full"
                      alt={modalData.image}
                      src={
                        modalData.image
                      }
                    />
                  )}</span>
                </div>
              </div>

            </div>
          </ModalComponent>
        )}
        {showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="lg"
            data={showImageModal}
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}
      </div>
    </>
  );
};

export default SchemeList;
