import { rootApi } from "../../api/rootApi";

export const appUsersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserByUserType: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/zone?zoneId=${params.zoneId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchUserByUserTypeCount: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/zone/count?zoneId=${params.zoneId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllDeletedUserByUserType: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/zone/deleted?zoneId=${params.zoneId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchAppUser: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/profile?type=${params.type}&&app_user_id=${params.app_user_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updateAppUserData: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/app/profile/tenant?type=${params.type}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateAppUserStatus: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/app/profile/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),

    getAppUserTypes: builder.mutation({
      query: (params) => {
        // console.log("getAppUserTypes state ", params);
        return {
          method: "GET",
          url: `/app/appUserType${params.query ? `${params.query}` : ""}${params.zone_id ? `?zone_id=${params.zone_id}` : ""}${params.state ? `&state=${params.state}` : ""}
          `,
          headers: {
            slug: Number(params.tenant_id),
          },
        };
      },
    }),

    getAppUserTypesDashboard: builder.mutation({
      query: (params) => {
        // console.log("getAppUserTypes state ", params);
        return {
          method: "GET",
          url: `/tenant/appUserType/dashboard${params.query ? `${params.query}` : ""}${params.zone_id ? `?zone_id=${params.zone_id}` : ""}${params.state ? `&state=${params.state}` : ""}
          `,
          headers: {
            slug: Number(params.tenant_id),
          },
        };
      },
    }),

    getAllAppUserTypeInMonthFormat: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/appUserType/month`,
          headers: {
            slug: Number(params.tenant_id),
          },
        };
      },
    }),
    updateKycStatus: builder.mutation({
      query: (params) => {
        // console.log("object-00988900--->",params);
        return {
          method: "PUT",
          url: `/app/kycStatus/${params.id}`,
          headers: {
            slug : params.tenant_id,
          },
          body : params.body
        };
      },
    }),
    fetchallRegistrationsHistoryStateWise: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/state`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),


    bulkUpdateAppUsers: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/app/bulk-update`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    removeAppUser: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/app/remove/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),


    getAllRemovedAppUser: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/removed`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

   
    
  }),
});

export const {
  useFetchUserByUserTypeMutation,
  useFetchUserByUserTypeCountMutation,
  useFetchAllDeletedUserByUserTypeMutation,
  useFetchAppUserMutation,
  useGetAppUserTypesMutation,
  useGetAppUserTypesDashboardMutation,
  useUpdateAppUserStatusMutation,
  useGetAllAppUserTypeInMonthFormatMutation,
  useUpdateKycStatusMutation,
  useFetchallRegistrationsHistoryStateWiseMutation,
  useBulkUpdateAppUsersMutation,
  useRemoveAppUserMutation,
  useGetAllRemovedAppUserMutation,
  useUpdateAppUserDataMutation
} = appUsersApi;
