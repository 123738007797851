import React, { useState } from "react";
import { HiEye, HiOutlinePlus } from "react-icons/hi";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDeletePaymentTransferByBatchIDMutation,
  useFetchAllPaymentTransferMutation,
  useInitiatePaymentTransferByBatchIDMutation,
} from "../../../features/tenant/paymentTransfer/paymentTransferApi";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const PaymentTransferList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const [sheetName, setSheetName] = useState(null);

  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "File Name",
      selector: (row) => row?.file_name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Batch",
      selector: (row) => row.batch_id,
      sortable: true,
      width: "110px",
    },
    {
      name: "Month",
      selector: (row) => months[row.month],
      sortable: true,
    },

    {
      name: "Year",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Total ",
      selector: (row) => row.total_count,
      sortable: true,
    },
    {
      name: "Failed ",
      selector: (row) => row.failure_cnt,
      sortable: true,
    },
    {
      name: "Pending ",
      selector: (row) => row.pending_cnt,
      sortable: true,
    },
    {
      name: "Success ",
      selector: (row) => row.success_cnt,
      sortable: true,
    },
    {
      name: "Not Initiated ",
      selector: (row) => row.not_initiated_cnt,
      sortable: true,
    },

    {
      name: "File Name",
      selector: (row) => row?.file_name,
      sortable: true,
      width: "110px",
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() =>
              navigate("batch", {
                state: row,
              })
            }
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <HiEye className="mr-1" />
            View
          </button>
        </div>
      ),
      minWidth: "100px",
      button: true,
    },

    {
      name: "",
      selector: (row) => (
        <div>
          {row.success_cnt > 0 || row.failure_cnt > 0 || row.pending_cnt > 0 ? (
            <button
              onClick={() => toast.error("This payment is already done ")}
              className={`flex justify-center items-center rounded bg-green-300 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-gray-800 shadow-[0_4px_9px_-4px_#ccc] transition duration-150 ease-in-out hover:bg-gray-400 hover:shadow-[0_8px_9px_-4px_rgba(204,204,204,0.3),0_4px_18px_0_rgba(204,204,204,0.2)] focus:bg-gray-400 focus:shadow-[0_8px_9px_-4px_rgba(204,204,204,0.3),0_4px_18px_0_rgba(204,204,204,0.2)] focus:outline-none focus:ring-0 active:bg-gray-500 active:shadow-[0_8px_9px_-4px_rgba(204,204,204,0.3),0_4px_18px_0_rgba(204,204,204,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(204,204,204,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(204,204,204,0.2),0_4px_18px_0_rgba(204,204,204,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(204,204,204,0.2),0_4px_18px_0_rgba(204,204,204,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(204,204,204,0.2),0_4px_18px_0_rgba(204,204,204,0.1)]
              `}
            >
              Payment Done
            </button>
          ) : (
            <button
              onClick={() => initiatePaymentFunc(row.batch_id, row?.file_name)}
              disabled={disableBtn}
              className={`${
                disableBtn
                  ? "flex justify-center items-center rounded bg-gray-400 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  : "flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              }  `}
            >
              Make Payment
            </button>
          )}
        </div>
      ),
      minWidth: "150px",
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDeleteWrapper(r)}
        >
          Delete
        </button>
      ),
      minWidth: "100px",

      button: true,
    },
  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  useEffect(() => {
    fetchPaymentTransferList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, [location]);

  const [
    fetchPaymentTransferList,
    {
      data: paymentTransferListData,
      error: paymentTransferListError,
      isLoading: ppaymentTransferListisLoading,
    },
  ] = useFetchAllPaymentTransferMutation();

  const [
    initiatePayment,
    {
      data: initiatePaymentData,
      error: initiatePaymentError,
      isLoading: initiatePaymentisLoading,
    },
  ] = useInitiatePaymentTransferByBatchIDMutation();

  const initiatePaymentFunc = (batchId, fileName) => {
    toast.loading("Initiating payment...");
    console.log("shive", fileName);

    if (
      window.confirm("do you want to make payment for the sheet : " + fileName)
    ) {
      setDisableBtn(true);
      initiatePayment({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        batch_id: batchId,
      });
    } else {
      toast.dismiss();
    }
  };

  useEffect(() => {
    if (initiatePaymentData) {
      toast.dismiss();
      console.log("initiatePaymentData", initiatePaymentData);
    }

    if (initiatePaymentError) {
      toast.dismiss();
      console.log("initiatePaymentError", initiatePaymentError);
      toast.error("Something went wrong", {
        id: "initiatePaymentError",
      });
    }
  }, [initiatePaymentData, initiatePaymentError, initiatePaymentisLoading]);

  const [
    deletePaymentBatch,
    {
      data: deletePaymentBatchData,
      error: deletePaymentBatchError,
      isLoading: deletePaymentBatchisLoading,
    },
  ] = useDeletePaymentTransferByBatchIDMutation();

  const deletePaymentFunc = (batchId) => {
    toast.loading("Initiating payment...");
    deletePaymentBatch({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      batch_id: batchId,
    });
  };

  const handleDeleteWrapper = (r) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deletePaymentFunc(r.batch_id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteWorkflow",
      }
    );
    return;
  };
  useEffect(() => {
    if (deletePaymentBatchData) {
      toast.dismiss();
      fetchPaymentTransferList({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
      console.log("deletePaymentBatchData", deletePaymentBatchData);
      toast.success("Deleted Successfully", {
        id: "deletePaymentBatch",
      });
    }

    if (deletePaymentBatchError) {
      toast.dismiss();
      console.log("deletePaymentBatchError", deletePaymentBatchError);
      toast.error("Something went wrong", {
        id: "deletePaymentBatchError",
      });
    }
  }, [
    deletePaymentBatchData,
    deletePaymentBatchError,
    deletePaymentBatchisLoading,
  ]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (paymentTransferListData && paymentTransferListData.body) {
      console.log("paymentTransferListData", paymentTransferListData);
    }

    if (paymentTransferListError) {
      console.log("paymentTransferListError", paymentTransferListError);
      toast.error("Something went wrong", { id: "paymentTransferListError" });
    }
  }, [paymentTransferListData, paymentTransferListError]);

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          All Payment Transfer List 2
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className="p-3 w-full bg-white shadow rounded-lg">
            <DataTableComponent
              keyField={(row) => row.SNo}
              columns={columns}
              data={paymentTransferListData ? paymentTransferListData.body : []}
              loading={ppaymentTransferListisLoading}
              totalRows={
                paymentTransferListData
                  ? paymentTransferListData.body.length
                  : 0
              }
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
            ></DataTableComponent>
            {true && (
              <div
                className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                id="box_btn"
              >
                <div className="text-right">
                  <Menu className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        onClick={() => navigate(`add`)}
                        className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                        style={{ backgroundColor: `${primaryColor["700"]}` }}
                      >
                        <HiOutlinePlus className="w-full h-full p-3" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentTransferList;
