import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi";
import { useFetchAllErrorLogsMutation } from "../../../features/tenant/errorLogs/errorLogsApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { useGetRequestQuotationFormDataMutation } from "../../../features/tenant/Ultimatrue/ultimatrueApi";

export default function RequestProjectQuotation() {
  const navigate = useNavigate();
  const location = useLocation();

  const [tableDataItems, setTableDataItems] = useState([]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [
    fetchAllRequestProjectQuotationFormData,
    { data: requestProjectQuotationData, error: requestProjectQuotationError },
  ] = useGetRequestQuotationFormDataMutation();

  useEffect(() => {
    fetchAllRequestProjectQuotationFormData({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      token: sessionStorage.getItem("access_token"),
      limit: limit,
      offset: offset,
    });
  }, [limit, offset]);

  useEffect(() => {
    if (requestProjectQuotationData && requestProjectQuotationData.body) {
      console.log("requestProjectQuotationData", requestProjectQuotationData);
      const arr = [...requestProjectQuotationData.body];
      //   arr.sort((a, b) => a.id - b.id);
      setTableDataItems(arr);
    }

    if (requestProjectQuotationError) {
      console.log("requestProjectQuotationError", requestProjectQuotationError);
    }
  }, [requestProjectQuotationData, requestProjectQuotationError]);

  const columns = [
    {
      name: "S.No",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },

    // {
    //   name: "Customer Name",
    //   selector: (row) => row.type,
    //   // sortable: true,
    // },
    {
      name: "Company Name  ",
      selector: (row) => row.company_name,
    },
    {
      name: "Company Representative",
      selector: (row) => row.company_representative,
      sortable: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            setModalData(r);
            setShowModal(true);
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  //   const handlePageChange = (pageNumber) => {
  //     const newOffset = (pageNumber - 1) * limit;
  //     setOffset(newOffset);
  //     setPrevPage(pageNumber - 1);
  //   };

  //   const handlePerRowsChange = (newPerPage, page) => {
  //     setLimit(newPerPage);
  //   };

  //   const handleDelete = (id) => {
  //     toast(
  //       (t) => (
  //         <span>
  //           <h6>Are you sure you want to delete this ?</h6>
  //           <div className="mt-3 d-flex">
  //             <button
  //               className="btn btn-outline-danger btn-sm ml-auto mr-1"
  //               onClick={() => {
  //                 toast.dismiss();
  //                 deleteEmailTemplate({
  //                   token: sessionStorage.getItem("access_token"),
  //                   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //                   id : id
  //                 })
  //               }}
  //             >
  //               Yes
  //             </button>
  //             <button
  //               className="btn btn-outline-primary btn-sm mr-auto ml-1"
  //               onClick={() => {
  //                 toast.dismiss(t.id);
  //               }}
  //             >
  //               No
  //             </button>
  //           </div>
  //         </span>
  //       ),
  //       {
  //         id: "deleteWorkflow",
  //       },
  //     );
  //     return;
  //   };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Request Project Quotation
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={tableDataItems}
                totalRows={
                  requestProjectQuotationData &&
                  requestProjectQuotationData?.body?.count
                }
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
          {showModal && modalData && (
            <ModalComponent
              size={"2xl"}
              data={"Html Template"}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full">
                <div className="w-full flex justify-center ">
                  <div className=" w-full errormsg">
                  <div className="bg-gray-100 p-4 rounded-lg shadow-md">
      <h2 className="text-lg font-bold mb-2">Project Details</h2>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="font-bold">Company Name:</p>
          <p>{modalData.company_name}</p>
        </div>
        <div>
          <p className="font-bold">Company Representative:</p>
          <p>{modalData.company_representative}</p>
        </div>
        <div>
          <p className="font-bold">Mobile Number:</p>
          <p>{modalData.mobile_number}</p>
        </div>
        <div>
          <p className="font-bold">Project Name:</p>
          <p>{modalData.project_name}</p>
        </div>
        <div>
          <p className="font-bold">Number of Stops:</p>
          <p>{modalData.number_of_stops}</p>
        </div>
        <div>
          <p className="font-bold">Control System:</p>
          <p>{modalData.control_system}</p>
        </div>
        <div>
          <p className="font-bold">Inverter Type:</p>
          <p>{modalData.inverter_type}</p>
        </div>
        <div>
          <p className="font-bold">Inverter Power:</p>
          <p>{modalData.inverter_power}</p>
        </div>
        <div>
          <p className="font-bold">Number of Panels:</p>
          <p>{modalData.number_of_panels}</p>
        </div>
        <div>
          <p className="font-bold">Machine Type:</p>
          <p>{modalData.machine_type}</p>
        </div>
        <div>
          <p className="font-bold">Machine Model:</p>
          <p>{modalData.machine_model}</p>
        </div>
        <div>
          <p className="font-bold">Machine Power:</p>
          <p>{modalData.machine_power}</p>
        </div>
        <div>
          <p className="font-bold">Brake Voltage:</p>
          <p>{modalData.brake_voltage}</p>
        </div>
        <div>
          <p className="font-bold">External Door Type:</p>
          <p>{modalData.external_door_type}</p>
        </div>
        <div>
          <p className="font-bold">Internal Door Type:</p>
          <p>{modalData.internal_door_type}</p>
        </div>
        <div>
          <p className="font-bold">Cam Voltage:</p>
          <p>{modalData.cam_voltage}</p>
        </div>
        <div>
          <p className="font-bold">Auto Door Operating Voltage:</p>
          <p>{modalData.auto_door_operating_voltage}</p>
        </div>
        <div>
          <p className="font-bold">Auto Door Operating System:</p>
          <p>{modalData.auto_door_operating_system}</p>
        </div>
        <div>
          <p className="font-bold">Comments:</p>
          <p>{modalData.comments}</p>
        </div>
        <div>
          <p className="font-bold">App User ID:</p>
          <p>{modalData.app_user_id}</p>
        </div>
        <div>
          <p className="font-bold">App User Name:</p>
          <p>{modalData.app_user_name || 'Not provided'}</p>
        </div>
      </div>
    </div>
                  </div>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
}
