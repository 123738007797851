import { rootApi } from "../../api/rootApi";

export const lancerApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    
    getLancerAddressApi: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/lancer/qr-address`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug : params.tenant_id,
          }
        };
      },
    }),



  }),
});

export const { useGetLancerAddressApiMutation} = lancerApi;
