import { rootApi } from "../../api/rootApi";

export const vendorProductsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchvendorProducts: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/assigned-product`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    countVendorProducts: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/assigned-product/count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    addVendorProduct: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/assigned-product/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            "Content-Type": "application/json",
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    deleteVendorProduct: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/assigned-product/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            "Content-Type": "application/json",
            slug: params.tenant_id,
          },
        };
      },
    }),

   
  }),
});

export const {
    useFetchvendorProductsMutation,
    useCountVendorProductsMutation,
    useAddVendorProductMutation,
    useDeleteVendorProductMutation
} = vendorProductsApi;
