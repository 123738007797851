import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { hasPermission } from "../../../utils/hasPermission";
import { FaGift, FaWallet } from "react-icons/fa6";

import { toast } from "react-hot-toast";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import {
  useFetchAllSchemePointEntriesCountMutation,
  useFetchAllSchemePointEntriesMutation,
  useFetchAllSchemePointSummaryMutation,
} from "../../../features/tenant/schemePointEntries/schemePointEntries";
import {
  useFetchAllSchemeRedemptionsCountMutation,
  useFetchAllSchemeRedemptionsMutation,
  useUpdateSchemeRedemptionsStatusMutation,
} from "../../../features/tenant/schemePointRedemptions/schemePointRedemptions";
import { TiTick, TiTimes } from "react-icons/ti";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import CustomExport from "../../../components/CustomExport/CustomExport";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useGetRedeemedGiftsStatusForSchemeQuery, useUpdateRedeemedGiftsStatusMutation, useUpdateSchemeRedeemedGiftsStatusMutation } from "../../../features/tenant/redeemedGiftsStatus/redeemedGiftsStatusApi";
import { useFetchSchemeWalletOfAppUserBySchemeIdMutation } from "../../../features/tenant/schemeWallet/schemeWalletApi";
import { CircularProgress } from "@mui/material";

const offerTypeObj = [
  { id: 1, name: "Point Entries", checked: true },
  { id: 2, name: "Point Summary", checked: false },
  { id: 3, name: "Redemption", checked: false },
];

const SchemeDetailsList = () => {
  const { zone_id: zoneId, zone_name: zoneName } = getLoginUserData();

  const [giftDetailPopUpToggle, setGiftDetailPopUpToggle] = useState(false);
  const [giftDetails, setGiftDetails] = useState(null);

  const [popUpBtnToggle, setPopUpBtnToggle] = useState(false);
  const [selectStatus, setSelectStatus] = useState(1);
  const [currentRefNo, setCurrentRefNo] = useState("");

  const [showSchemeWalletModal, setShowSchemeWalletModal] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);
  const [showAddressModal, setshowAddressModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);

  const [offerType, setOfferType] = useState(offerTypeObj);
  const [type, setType] = useState("Point Entries");


  const [csvDataPointEntries, setCsvDataPointEntries] = useState(null);
  const [csvDataPointSummary, setCsvDataPointSummary] = useState(null);
  const [csvDataPointRedemptions, setCsvDataPointRedemptions] = useState(null);

  const handleChangeOfferType = (idx, name) => {
    let newData = [...offerType];
    for (let i = 0; i < newData.length; i++) {
      // const element = newData[i];
      newData[i].checked = false;
    }
    newData[idx].checked = true;
    setType(newData[idx].name);
    setOfferType(newData);
  };

  const columnsPoints = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Phone",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Platform",
      cell: (row) => row.platform,
      sortable: true,
      width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Category Name",
      cell: (row) => row.category_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Product Name",
      cell: (row) => row.product_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "150px",
    },
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_running_code,
      sortable: true,
      width: "150px",
    },
    {
      name: "Scanned At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      width: "120px",
      sortable: true,
    },
    {
      name: "Pincode",
      cell: (row) => row.pincode,
      sortable: true,
    },
    {
      name: "District",
      cell: (row) => row.district,
      sortable: true,
    },
    {
      name: "State",
      cell: (row) => row.state,
      sortable: true,
    },
  ];

  const hasAcceptGiftRequestPermission = true;
  const hasRejectGiftRequestPermission = true;
  const columnsRedemptions = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Phone",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Platform",
      cell: (row) => row.platform,
      sortable: true,
      width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Gift",
      cell: (row) => (
        <div>
          <button
            onClick={() => {
              setGiftDetailPopUpToggle(true);
              setGiftDetails(row.gift.gift);
            }}
            className="flex justify-center items-center rounded bg-primary px-2 pb-1 pt-1 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <FaGift size={30} />
          </button>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Address",
      cell: (row) => row.address,
      sortable: true,
      width: "120px",
    },
    {
      name: "City",
      cell: (row) => row.city,
      sortable: true,
      width: "120px",
    },
    {
      name: "Pincode",
      cell: (row) => row.pincode,
      sortable: true,
      width: "120px",
    },
    {
      name: "District",
      cell: (row) => row.district,
      sortable: true,
      width: "120px",
    },
    {
      name: "State",
      cell: (row) => row.state,
      sortable: true,
      width: "120px",
    },
    {
      name: "Remarks",
      cell: (row) => row.remarks,
      sortable: true,
      width: "120px",
    },
    {
      name: "Redeemed At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      width: "120px",
      sortable: true,
    },
    {
      name: "Approval Status",
      cell: (row) => (
        <div>
          {row.status == "3" && (
            <div className="flex space-x-1 ">
              {hasAcceptGiftRequestPermission && (
                <button
                  onClick={() => handleShowGiftStatusModal(row, "1")}
                  className="flex justify-center items-center rounded bg-success  px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(20,164,77,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)]"
                >
                  <TiTick className="mr-1" />
                  Approve
                </button>
              )}
              {hasRejectGiftRequestPermission && (
                <button
                  onClick={(e) => handleShowGiftStatusModal(row, "2")}
                  className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
                >
                  <TiTimes className="mr-1" />
                  Reject
                </button>
              )}
            </div>
          )}
          {row.status == "2" && (
            <div className=" text-red-600 ">Rejected - Points reverted to scheme wallet.</div>
          )}
          {row.status == "1" && (
            <div className=" text-green-600 ">Approved</div>
          )}
        </div>
      ),
      width: "250px",
    },

    {
      name: "Gift Status",
      cell: (row) =>
        row.status == "1" && (row.redemption_type == "1" && (
          <button
            onClick={() => {
              setCurrentRefNo(row.ref_no);
              setSelectStatus(Number(row?.statusUpdateDetails[row.statusUpdateDetails.length - 1].status));
              setPopUpBtnToggle(true);
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-1 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <AiOutlineEdit className="mr-1" /> */}
            {(row?.statusUpdateDetails[row.statusUpdateDetails.length - 1].status_name)}
          </button>
        )),

      sortable: true,
    }
  ];

  const columnsSummary = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      maxWidth: "210px",
    },
    {
      name: "Phone",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "State ",
      cell: (row) => row.state,
      sortable: true,
      width: "120px",
    },
    {
      name: "District ",
      cell: (row) => row.district,
      sortable: true,
      width: "120px",
    },
    {
      name: "Total Scans",
      cell: (row) => row.total_scans,
      sortable: true,
      width: "120px",
    },
    {
      name: "Total Points",
      cell: (row) => row.total_points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Wallet",
      cell: (row) => (
        <div>
          <button
            onClick={() => {
              setShowSchemeWalletModal(true);
              getSchemeWalletBySchemeIdFunc(row);
            }}
            className="flex justify-center items-center rounded bg-green-500 px-2 pb-1 pt-1 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(34,197,94,0.3),0_4px_18px_0_rgba(34,197,94,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(34,197,94,0.3),0_4px_18px_0_rgba(34,197,94,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(34,197,94,0.3),0_4px_18px_0_rgba(34,197,94,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(34,197,94,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(34,197,94,0.2),0_4px_18px_0_rgba(34,197,94,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(34,197,94,0.2),0_4px_18px_0_rgba(34,197,94,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(34,197,94,0.2),0_4px_18px_0_rgba(34,197,94,0.1)]"

          >
            <FaWallet size={30} />
          </button>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
  ];

  const location = useLocation();

  const [
    fetchAllSchemePointEntries,
    {
      data: allSchemePointEntriesData,
      isLoading: allSchemePointEntriesisLoading,
      error: allSchemePointEntriesError,
    },
  ] = useFetchAllSchemePointEntriesMutation();

  const [
    fetchAllSchemePointEntriesForCsv,
    {
      data: allSchemePointEntriesForCsvData,
      isLoading: allSchemePointEntriesForCsvisLoading,
      error: allSchemePointEntriesForCsvError,
    },
  ] = useFetchAllSchemePointEntriesMutation();

  const [
    fetchAllSchemePointEntriesCount,
    {
      data: allSchemePointEntriesCountData,
      isLoading: allSchemePointEntriesCountisLoading,
      error: allSchemePointEntriesCountError,
    },
  ] = useFetchAllSchemePointEntriesCountMutation();

  const [
    fetchAllSchemePointEntriesSummaryForCsv,
    {
      data: allSchemePointEntriesSummaryForCsvData,
      isLoading: allSchemePointEntriesSummaryForCsvisLoading,
      error: allSchemePointEntriesSummaryForCsvError,
    },
  ] = useFetchAllSchemePointSummaryMutation();

  const [
    fetchAllSchemePointEntriesSummary,
    {
      data: allSchemePointEntriesSummaryData,
      isLoading: allSchemePointEntriesSummaryisLoading,
      error: allSchemePointEntriesSummaryError,
    },
  ] = useFetchAllSchemePointSummaryMutation();

  const [
    fetchAllSchemeRedemptions,
    {
      data: allSchemeGiftRedemptionsData,
      isLoading: allSchemeGiftRedemptionsisLoading,
      error: allSchemeGiftRedemptionsError,
    },
  ] = useFetchAllSchemeRedemptionsMutation();

  const [
    fetchAllSchemeRedemptionsForCsv,
    {
      data: allSchemeGiftRedemptionsCsvData,
      isLoading: allSchemeGiftRedemptionsCsvisLoading,
      error: allSchemeGiftRedemptionsCsvError,
    },
  ] = useFetchAllSchemeRedemptionsMutation();


  const [
    updateSchemeGiftRedemptionStatus,
    {
      data: schemeGiftRedemptionStatusData,
      isLoading: schemeGiftRedemptionStatusisLoading,
      error: schemeGiftRedemptionStatusError,
    },
  ] = useUpdateSchemeRedemptionsStatusMutation();


  const [
    getSchemeWalletBySchemeId,
    {
      data: schemeWalletBySchemeIdData,
      isLoading: schemeWalletBySchemeIdisLoading,
      error: schemeWalletBySchemeIdError,
    },
  ] = useFetchSchemeWalletOfAppUserBySchemeIdMutation();


  const getSchemeWalletBySchemeIdFunc = (row) => {
    // console.log("row", row);
    getSchemeWalletBySchemeId({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      scheme_id: location.state.id,
      app_user_id: row.id,
    });
  };


  useEffect(() => {
    if (schemeWalletBySchemeIdError) {
      toast.error("Something went wrong fetching wallet details", {
        id: "schemeWalletBySchemeIdError"
      })
      // console.log("schemeWalletBySchemeIdError", schemeWalletBySchemeIdError);
    }
  }, [schemeWalletBySchemeIdError])


  const {
    data: getRedeemedGiftsStatusData,
    error: getRedeemedGiftsStatusError,
  } = useGetRedeemedGiftsStatusForSchemeQuery({
    token: sessionStorage.getItem("access_token"),
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const initialRequest = {
    product_code: "",
    scheme_id: location.state.id,
    brand_name: "",
    category_name: "",
    batch_running_code: "",
    mobile: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const [limit2, setLimit2] = useState(10);
  const [offset2, setOffset2] = useState(0);
  const [prevPage2, setPrevPage2] = useState(0);
  const initialRequest2 = {
    scheme_id: location.state.id,
    limit: 10,
    offset: 0,
  };
  const [request2, setRequest2] = useState(initialRequest2);
  const clearFilters2 = () => {
    setRequest2(initialRequest2);
  };


  const [limit3, setLimit3] = useState(10);
  const [offset3, setOffset3] = useState(0);
  const [prevPage3, setPrevPage3] = useState(0);
  const initialRequest3 = {
    scheme_id: location.state.id,
    limit: 10,
    offset: 0,
  };
  const [request3, setRequest3] = useState(initialRequest3);
  const clearFilters3 = () => {
    setRequest3(initialRequest3);
  };

  useEffect(() => {

    if (type == "Point Summary") {
      setTableData([]);
      fetchSchemePointEntriesSummaryFunction();
    }

    if (type == "Redemption") {
      setTableData([]);
      fetchSchemeGiftRedemptionsFunction();
    }
  }, [request2, type]);

  useEffect(() => {
    if (type === "Point Entries" && request.product_code === "" &&
      request.product_code === "" &&
      request.mobile === "" &&
      request.brand_name === "" &&
      request.category_name === "" &&
      request.batch_running_code === "") {
      setTableData([]);
      // console.log("request and type")
      fetchSchemePointEntriesFunction();
      countSchemePointEntriesFunction();
    }
  }, [type])


  useEffect(() => {
    if (type === "Point Entries") {
      // console.log("limit offset and type")
      fetchSchemePointEntriesFunction();
    }
  }, [limit, offset, type])



  const fetchSchemePointEntriesFunctionForCsv = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchAllSchemePointEntriesForCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
        .tenant_id,
      body: modifiedRequest,
    });
  };

  const fetchSchemePointEntriesFunction = () => {
    fetchAllSchemePointEntries({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
        .tenant_id,
      body: request,
      zoneId: zoneId,
    });
  };
  const countSchemePointEntriesFunction = () => {
    fetchAllSchemePointEntriesCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
        .tenant_id,
      body: request,
    });
  };

  const fetchSchemePointEntriesSummaryFunctionForCsv = () => {
    const modifiedRequest = {
      ...request3,
      limit: null,
      offset: null,
    };
    fetchAllSchemePointEntriesSummaryForCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const fetchSchemePointEntriesSummaryFunction = () => {
    fetchAllSchemePointEntriesSummary({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
        .tenant_id,
      body: request3,
    });
  };

  const fetchSchemeGiftRedemptionsFunction = () => {
    fetchAllSchemeRedemptions({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
        .tenant_id,
      body: request2,
      zoneId: zoneId,
    });
  };
  const fetchSchemeGiftRedemptionsFunctionForCsv = () => {
    const modifiedRequest = {
      ...request2,
      limit: null,
      offset: null,
    };
    fetchAllSchemeRedemptionsForCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      zoneId: zoneId,
    });
  };

  useEffect(() => {
    toast.dismiss();
    if (
      allSchemePointEntriesData &&
      allSchemePointEntriesData.body &&
      type == "Point Entries"
    ) {
      // console.log("type", type);
      setTableData(allSchemePointEntriesData.body.data);
    } else if (
      allSchemePointEntriesSummaryData &&
      allSchemePointEntriesSummaryData.body &&
      type == "Point Summary"
    ) {
      // console.log("type", type);
      setRowsCount(0);
      setTableData(allSchemePointEntriesSummaryData.body.data);
    } else if (
      allSchemeGiftRedemptionsData &&
      allSchemeGiftRedemptionsData.body &&
      type == "Redemption"
    ) {
      // console.log("type", type);
      setTableData(allSchemeGiftRedemptionsData.body.data);
      setRowsCount(allSchemeGiftRedemptionsData.body.total);
    }

    if (allSchemePointEntriesError && allSchemePointEntriesError.data && type == "Point Entries") {
      toast.error(
        allSchemePointEntriesError.data.message
          ? allSchemePointEntriesError.data.message
          : "Something went wrong.",
        {
          id: "allSchemePointEntriesError",
        },
      );
    }

    if (allSchemeGiftRedemptionsError && allSchemeGiftRedemptionsError.data && type == "Redemption") {
      toast.error(
        allSchemeGiftRedemptionsError.data.message
          ? allSchemeGiftRedemptionsError.data.message
          : "Something went wrong.",
        {
          id: "allSchemeGiftRedemptionsError",
        },
      );
    }

    if (allSchemePointEntriesSummaryError && allSchemePointEntriesSummaryError.data && type == "Point Summary") {
      toast.error(
        allSchemePointEntriesSummaryError.data.message
          ? allSchemePointEntriesSummaryError.data.message
          : "Something went wrong.",
        {
          id: "allSchemePointSummaryError",
        },
      );
    }
  }, [
    allSchemePointEntriesData,
    allSchemePointEntriesError,
    allSchemePointEntriesSummaryData,
    allSchemePointEntriesSummaryError,
    allSchemeGiftRedemptionsData,
    allSchemeGiftRedemptionsError,
  ]);


  useEffect(() => {
    toast.dismiss();
    if (
      allSchemePointEntriesForCsvData &&
      allSchemePointEntriesForCsvData.body &&
      type == "Point Entries"
    ) {
      // console.log("type", type);
      setCsvDataPointEntries(allSchemePointEntriesForCsvData.body.data);
    } else if (
      allSchemePointEntriesSummaryForCsvData &&
      allSchemePointEntriesSummaryForCsvData.body &&
      type == "Point Summary"
    ) {
      // console.log("type", type);
      setCsvDataPointSummary(allSchemePointEntriesSummaryForCsvData.body.data);
    } else if (
      allSchemeGiftRedemptionsCsvData &&
      allSchemeGiftRedemptionsCsvData.body &&
      type == "Redemption"
    ) {
      // console.log("type", type);
      setCsvDataPointRedemptions(allSchemeGiftRedemptionsCsvData.body.data);
    }

    if (allSchemePointEntriesForCsvError && type == "Point Entries") {
      setCsvDataPointEntries(null)
      toast.error(
        allSchemePointEntriesForCsvError.data.message
          ? allSchemePointEntriesForCsvError.data.message
          : "Something went wrong.",
        {
          id: "allSchemePointEntriesForCsvError",
        },
      );
    }

    if (allSchemeGiftRedemptionsCsvError && type == "Redemption") {
      setCsvDataPointRedemptions(null);
      toast.error(
        allSchemeGiftRedemptionsCsvError.data.message
          ? allSchemeGiftRedemptionsCsvError.data.message
          : "Something went wrong.",
        {
          id: "allSchemeGiftRedemptionsCsvError",
        },
      );
    }

    if (allSchemePointEntriesSummaryForCsvError && type == "Point Summary") {
      setCsvDataPointSummary(null);
      toast.error(
        allSchemePointEntriesSummaryForCsvError.data.message
          ? allSchemePointEntriesSummaryForCsvError.data.message
          : "Something went wrong.",
        {
          id: "allSchemePointEntriesSummaryForCsvError",
        },
      );
    }
  }, [
    allSchemePointEntriesForCsvData,
    allSchemePointEntriesForCsvError,
    allSchemePointEntriesSummaryForCsvData,
    allSchemePointEntriesSummaryForCsvError,
    allSchemeGiftRedemptionsCsvData,
    allSchemeGiftRedemptionsCsvError,
    type

  ]);


  useEffect(() => {
    if (allSchemePointEntriesCountData && allSchemePointEntriesCountData.body && type == "Point Entries") {
      setRowsCount(allSchemePointEntriesCountData.body.total);
    }
  }, [allSchemePointEntriesCountData])

  const [showModal, setShowModal] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [giftStatus, setGiftStatus] = useState(null);

  const handleShowGiftStatusModal = (row, status) => {
    setGiftStatus(status);
    setSelectedRow(row);
    setRemarks("");
    setShowModal(true);
  }

  const handleUpdateSchemeRedemptionStatus = () => {

    if (!selectedRow || !giftStatus || !remarks) {
      return;
    }
    const body = {
      scheme_id: selectedRow.scheme_id,
      ref_no: selectedRow?.ref_no,
      status: giftStatus,
      remarks: remarks,
    }
    updateSchemeGiftRedemptionStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: body,
    })
  }


  useEffect(() => {
    if (schemeGiftRedemptionStatusData) {
      // console.log("schemeGiftRedemptionStatusData", schemeGiftRedemptionStatusData);
      setGiftStatus(null);
      setSelectedRow(null);
      setRemarks(null);
      setShowModal(false);
      fetchSchemeGiftRedemptionsFunction();
    }

    if (schemeGiftRedemptionStatusError) {
      toast.error("Something went wrong", { id: "schemeGiftRedemptionStatusError" })
      console.log("schemeGiftRedemptionStatusError", schemeGiftRedemptionStatusError)
    }
  }, [schemeGiftRedemptionStatusData, schemeGiftRedemptionStatusError])



  const [
    updateRedeemedGiftsStatus,
    {
      data: updateRedeemedGiftsStatusData,
      error: updateRedeemedGiftsStatusError,
    },
  ] = useUpdateSchemeRedeemedGiftsStatusMutation();

  const handleUpdateRedeemedGiftsStatus = () => {
    updateRedeemedGiftsStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        status: selectStatus,
        ref_no: currentRefNo,
      },
    });
  };


  useEffect(() => {
    if (updateRedeemedGiftsStatusData) {
      toast.dismiss();
      // toast.success(`${updateRedeemedGiftsStatusData.message}`, {
      //   id: `updateRedeemedGiftsStatusData`,
      // });
      // console.log("updateRedeemedGiftsStatusData", updateRedeemedGiftsStatusData)
      fetchSchemeGiftRedemptionsFunction();
      setPopUpBtnToggle(false);
    }

    if (updateRedeemedGiftsStatusError) {
      toast.dismiss();
      // toast.error(`${updateRedeemedGiftsStatusError.data.message}`, {
      //   id: `updateRedeemedGiftsStatusError`,
      // });
      toast.error(`Something went wrong.`, {
        id: `updateRedeemedGiftsStatusError`,
      });
      // console.log("updateRedeemedGiftsStatusError", updateRedeemedGiftsStatusError)
      setPopUpBtnToggle(false);
    }
  }, [updateRedeemedGiftsStatusData, updateRedeemedGiftsStatusError]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const handlePageChange2 = (pageNumber) => {
    const newOffset2 = (pageNumber - 1) * limit2;
    setOffset2(newOffset2);
    setRequest2({ ...request2, offset: newOffset2 });
    setPrevPage2(pageNumber - 1);
  };

  const handlePerRowsChange2 = (newPerPage, page) => {
    setRequest2({ ...request2, limit: newPerPage });
    setLimit2(newPerPage);
  };

  const getUserTypeNames = (user_type_id) => {
    const userTypes = JSON.parse(sessionStorage.getItem("appUserType"));
    let name = "";
    userTypes.forEach((each) => {
      if (each.user_type_id == user_type_id) {
        name = each.name;
      }
    });
    return name;
  };


  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Scheme Details
            <LinksComponent />
          </div>

          <div className="p-3">
            <div
              className="border border-gray-600 rounded-t-md p-3 mb-2"
              style={{
                backgroundColor: primaryColor["50"],
              }}
            >
              <div className="flex justify-between gap-2">
                {/* <h4 className="w-10">
                  <span className="text-gray-400 text-sm  block">
                    Id :
                  </span>
                  <span className="flex text-black text-sm  gap-1 ">
                    {location.state?.id}
                  </span>
                </h4> */}

                <h4>
                  <span className="text-gray-400 text-sm  block">
                    Scheme Name :
                  </span>
                  <span className="flex text-black text-sm  gap-1">
                    {location.state?.name}
                  </span>
                </h4>

                <h4>
                  <span className="text-gray-400 text-sm block">
                    Start Date :
                  </span>
                  <span className="flex text-black text-sm  gap-1 ">
                    {location.state?.scheme_start.slice(
                      0,
                      10,
                    )}
                  </span>
                </h4>

                <h4>
                  <span className="text-gray-400 text-sm  block">
                    End Date :
                  </span>
                  <span className="flex text-black text-sm  gap-1 ">
                    {location.state?.scheme_end.slice(
                      0,
                      10,
                    )}
                  </span>
                </h4>

                <h4>
                  <span className="text-gray-400 text-sm  block">
                    Redeem Start :
                  </span>
                  <span className="flex text-black text-sm  gap-1 ">
                    {location.state?.redeem_start.slice(
                      0,
                      10,
                    )}
                  </span>
                </h4>

                <h4>
                  <span className="text-gray-400 text-sm  block">
                    Redeem End :
                  </span>
                  <span className="flex text-black text-sm  gap-1 ">
                    {location.state?.redeem_end.slice(
                      0,
                      10,
                    )}
                  </span>
                </h4>
              </div>

              <div className="flex justify-between mt-3 gap-2">
                <h4 className="max-w-sm">
                  <span className="text-gray-400 text-sm  block">
                    States:
                  </span>
                  <span className="flex text-black text-sm  gap-1 ">
                    {location.state?.states.join(", ")}
                  </span>
                </h4>

                <h4>
                  <span className="text-gray-400 text-sm  block">
                    User Types:
                  </span>
                  <span className="flex text-black text-sm  gap-1">
                    {location.state?.user_types.map(
                      (id) => (
                        <span className="capitalize">
                          {" "}
                          {getUserTypeNames(id)}{" "}
                        </span>
                      ),
                    )}
                  </span>
                </h4>

                <h4>
                  <span className="text-gray-400 text-sm  block">
                    Created By :
                  </span>
                  <span className="flex text-black text-sm  gap-1">
                    {location.state?.created_by_name}
                  </span>
                </h4>
                <h4>
                  <span className="text-gray-400 text-sm  block">
                    Created At :
                  </span>
                  <span className="flex text-black text-sm  gap-1 ">
                    {location.state?.created_at.slice(
                      0,
                      10,
                    )}
                  </span>
                </h4>
              </div>
            </div>
            <div className="w-full my-3">
              {type == "Point Entries" ? (
                <CustomExport
                  fetchData={fetchSchemePointEntriesFunctionForCsv}
                  data={csvDataPointEntries}
                  defaultColumnExport={[
                    "name",
                    "mobile",
                    "user_type",
                    "points",
                    "scheme_id"
                  ]}
                  filename={`schemePointEntries${location.state?.name}`}
                />
              ) : type == "Point Summary" ? (
                <CustomExport
                  fetchData={fetchSchemePointEntriesSummaryFunctionForCsv}
                  data={csvDataPointSummary}
                  defaultColumnExport={[
                    "name",
                    "email",
                    "mobile",
                    "",
                  ]}
                  filename={`schemeSummary${location.state?.name}`}
                />
              ) : type == "Redemption" ? (
                <CustomExport
                  fetchData={fetchSchemeGiftRedemptionsFunctionForCsv}
                  data={csvDataPointRedemptions}
                  defaultColumnExport={[
                    "name",
                    "email",
                    "mobile",
                    "user_type",
                    "gender",
                  ]}
                  filename={`schemeRedemptions${location.state?.name}`}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="flex max-w-md space-x-3 ">
              {offerType.map((each, idx) => (
                <div
                  key={each.id}
                  className={`p-2 rounded-t-md w-full capitalize  text-base font-medium leading-5 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2  text-center " +
                                      ${each.checked
                      ? "  text-white "
                      : " text-black hover:bg-white/[0.12] hover:text-gray-800 "
                    }`}
                  style={{
                    backgroundColor: `  ${each.checked
                      ? primaryColor["600"]
                      : primaryColor["100"]
                      }`,
                  }}
                  onClick={() =>
                    handleChangeOfferType(idx, each.name)
                  }
                >
                  {each.name}
                </div>
              ))}
            </div>
            <div className="p-3 w-full bg-white shadow rounded-lg">
              {/* Point Entries */}
              <>

                {type == "Point Entries" &&
                  <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-2">
                    <div className="flex  space-x-2 justify-between">

                      <div className="w-52 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">C-Name</label>
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            placeholder="Category Name..."
                            required
                            value={request.category_name}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest((prevState) => {
                                return { ...prevState, category_name: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-52 h-10 ">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">P-Code</label>
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            placeholder="Product Code ..."
                            required
                            value={request.product_code}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest((prevState) => {
                                return { ...prevState, product_code: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-52 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Mobile</label>
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            placeholder="Mobile..."
                            required
                            value={request.mobile}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest((prevState) => {
                                return { ...prevState, mobile: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="w-52 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Batch Running Code</label>
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            placeholder="Batch Running Code..."
                            required
                            value={request.batch_running_code}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest((prevState) => {
                                return { ...prevState, batch_running_code: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>


                      <div className="flex flex-row  gap-2 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                          <button
                            className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                            onClick={() => {
                              fetchSchemePointEntriesFunction();
                              countSchemePointEntriesFunction();
                            }}
                          >
                            Search
                          </button>
                        </div>
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                          <button
                            className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                            onClick={() => {
                              clearFilters();
                            }}
                          >
                            Clear Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {

                  type == "Point Entries" &&
                  <DataTableComponent
              keyField={(row) => row.SNo}
                    columns={columnsPoints}
                    data={tableData}
                    loading={allSchemePointEntriesisLoading}
                    totalRows={rowsCount}
                    handlePerRowsChange={handlePerRowsChange}
                    handlePageChange={handlePageChange}
                  >
                    <div className="flex m-2 space-x-2 justify-end"></div>
                  </DataTableComponent>
                }
              </>


              {/* Point SUmmary */}
              <>
                {/* {type == "Point Summary" &&
                  <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-2">
                    <div className="flex  space-x-2 justify-end">
                      <div className="w-52 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            placeholder="Name..."
                            required
                            value={request3.name}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest3((prevState) => {
                                return { ...prevState, name: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-52 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Mobile</label>
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            placeholder="Mobile..."
                            required
                            value={request3.mobile}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest3((prevState) => {
                                return { ...prevState, mobile: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-row  gap-2 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                          <button
                            className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                            onClick={() => {
                              fetchSchemePointEntriesSummaryFunction();
                            }}
                          >
                            Search
                          </button>
                        </div>
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                          <button
                            className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                            onClick={() => {
                              clearFilters3();
                            }}
                          >
                            Clear Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                } */}
                {
                  type == "Point Summary" &&
                  <DataTableComponent
              keyField={(row) => row.SNo}
                    columns={columnsSummary}
                    data={tableData}
                    loading={allSchemePointEntriesSummaryisLoading}
                    totalRows={rowsCount}
                  // handlePerRowsChange={handlePerRowsChange}
                  // handlePageChange={handlePageChange}
                  >
                    <div className="flex m-2 space-x-2 justify-end"></div>
                  </DataTableComponent>
                }
              </>


              {/*  */}
              <>
                {type == "Redemption" &&
                  <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-2">
                    <div className="flex  space-x-2 justify-end">
                      <div className="w-52 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            placeholder="Name..."
                            required
                            value={request2.name}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest2((prevState) => {
                                return { ...prevState, name: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="w-52 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Mobile</label>
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                            placeholder="Mobile..."
                            required
                            value={request2.mobile}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest2((prevState) => {
                                return { ...prevState, mobile: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-row  gap-2 h-10">
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                          <button
                            className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                            onClick={() => {
                              fetchSchemeGiftRedemptionsFunction()
                            }}
                          >
                            Search
                          </button>
                        </div>
                        <div className="relative searchin">
                          <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                          <button
                            className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                            onClick={() => {
                              clearFilters2();
                            }}
                          >
                            Clear Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  type == "Redemption" &&
                  <DataTableComponent
              keyField={(row) => row.SNo}
                    columns={columnsRedemptions}
                    data={tableData}
                    totalRows={rowsCount}
                    handlePerRowsChange={handlePerRowsChange2}
                    handlePageChange={handlePageChange2}
                  >
                    <div className="flex m-2 space-x-2 justify-end"></div>
                  </DataTableComponent>
                }
              </>
            </div>


            {showModal && (
              <ModalComponent
                size={"md"}
                data={"nadnandand"}
                show={showModal}
                setShow={setShowModal}
              >
                <div className="w-full p-3">
                  <div className="w-full flex justify-center ">
                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"Remarks"}
                        type={"textarea"}
                        value={remarks}
                        placeholder={"Enter Remarks"}
                        setValue={setRemarks}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">

                    <button
                      type="submit"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      onClick={(e) => handleUpdateSchemeRedemptionStatus(e)}
                    >
                      Submit
                    </button>

                  </div>
                </div>
              </ModalComponent>
            )}

            {giftDetailPopUpToggle && (
              <ModalComponent
                size={"md"}
                show={giftDetailPopUpToggle}
                setShow={setGiftDetailPopUpToggle}
              >
                <div className="w-full ">
                  <div className="w-full p-4">
                    {giftDetails &&
                      giftDetails.map((x) => {
                        return (
                          <div className="flex gap-3 items-center bg-gray-50 border border-gray-100 rounded-sm overflow-hidden p-2 mb-2">
                            <div className="w-1/5 border bg-white border-gray-100 rounded-sm overflow-hidden p-2">
                              <img
                                src={
                                  x.images[0]
                                }
                                alt={
                                  "error loading image"
                                }
                              />
                            </div>
                            <div className="w-4/5">
                              <span className="capitalize block">
                                <span className="text-gray-400">
                                  name
                                </span>
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                {x.name}
                              </span>
                              <span className="capitalize block">
                                <span className="text-gray-400">
                                  brand
                                </span>
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                {x.brand}
                              </span>
                              <span className="capitalize block">
                                <span className="text-gray-400">
                                  point
                                </span>
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                {x.points}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </ModalComponent>
            )}


            {popUpBtnToggle && (
              <ModalComponent
                size={"md"}
                show={popUpBtnToggle}
                setShow={setPopUpBtnToggle}
              >
                <div className="w-full p-4">
                  <div className="w-full flex justify-center ">
                    <select
                      type="text"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-auto capitalize"
                      placeholder="Add new UserType"
                      value={selectStatus}
                      onChange={(e) =>
                        setSelectStatus(Number(e.target.value))
                      }
                    >
                      {getRedeemedGiftsStatusData &&
                        getRedeemedGiftsStatusData.body.map((x, index) => {
                          return (
                            <option key={index} value={Number(index)}>
                              {x}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="w-full flex justify-center mt-4 ">
                    <button
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      onClick={handleUpdateRedeemedGiftsStatus}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </ModalComponent>
            )}



            {showImageModal && (
              <ImageModal
                show={showImageModal ? true : false}
                setShow={setShowImageModal}
                size="xl"
                data={showImageModal}
              >
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img
                    src={showImageModal}
                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                  />
                </div>
              </ImageModal>
            )}


            {showSchemeWalletModal && (
              <ModalComponent
                size={"sm"}
                show={showSchemeWalletModal}
                setShow={setShowSchemeWalletModal}
              >
                <div className="w-full p-2">
                  <div className="max-w-md mx-auto bg-gray-50 shadow-lg rounded-md overflow-hidden">
                    {schemeWalletBySchemeIdisLoading ? <> <CircularProgress /> </> :
                      schemeWalletBySchemeIdData && schemeWalletBySchemeIdData.body && <>
                        <div className="p-3">
                          <h2 className="text-2xl font-semibold text-gray-800">Wallet Details</h2>
                          <div className="mt-2.5">
                            <div className="flex justify-between py-2 border-b border-gray-200">
                              <span className="font-medium text-gray-600">Wallet Id:</span>
                              <span>{schemeWalletBySchemeIdData.body.id}</span>
                            </div>
                            <div className="flex justify-between py-2 border-b border-gray-200">
                              <span className="font-medium text-gray-600">User Type:</span>
                              <span>{schemeWalletBySchemeIdData.body.user_type}</span>
                            </div>
                            <div className="flex justify-between py-2 border-b border-gray-200">
                              <span className="font-medium text-gray-600">Points Earned:</span>
                              <span>{schemeWalletBySchemeIdData.body.point_earned}</span>
                            </div>
                            <div className="flex justify-between py-2 border-b border-gray-200">
                              <span className="font-medium text-gray-600">Points Redeemed:</span>
                              <span>{schemeWalletBySchemeIdData.body.point_redeemed}</span>
                            </div>
                            <div className="flex justify-between py-2 border-b border-gray-200">
                              <span className="font-medium text-gray-600">Points Balance:</span>
                              <span>{schemeWalletBySchemeIdData.body.point_balance}</span>
                            </div>
                            <div className="flex justify-between py-2 border-b border-gray-200">
                              <span className="font-medium text-gray-600">Status:</span>
                              <span>{schemeWalletBySchemeIdData.body.status === '1' ? 'Active' :
                                schemeWalletBySchemeIdData.body.status === '2' ? 'Inactive' :
                                  schemeWalletBySchemeIdData.body.status === '3' ? 'Freezed' : ""}</span>
                            </div>
                            <div className="flex justify-between py-2 border-b border-gray-200">
                              <span className="font-medium text-gray-600">Created At:</span>
                              <span>{new Date(schemeWalletBySchemeIdData.body.created_at).toLocaleString()}</span>
                            </div>
                            <div className="flex justify-between py-2 border-b border-gray-200">
                              <span className="font-medium text-gray-600">Updated At:</span>
                              <span>{new Date(schemeWalletBySchemeIdData.body.updated_at).toLocaleString()}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </ModalComponent>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SchemeDetailsList;
