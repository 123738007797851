import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useFetchAllQrCodeWithoutGenerationCountMutation, useFetchAllQrCodeWithoutGenerationMutation } from "../../../features/tenant/qrCode/qrCodeApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import ViewQr from "../../../components/Tenant/ViewQR/ViewQr";
import { getVendorName } from "../../../utils/getVendorName";
import { objectsAreEqual } from "../../../utils/objectsAreEqual";
import { toast } from "react-hot-toast";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import { useGetReturnListMutation } from "../../../features/supplyBeam/inventory/returnListApi";

const ReturnList = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    product_code: "",
    product_name: "",
    batch_running_code: "",
    limit: 10,
    offset: 0,
    startDate: "",
    endDate:"",
  };
  const initialRequestCount = {
    product_code: "",
    product_name: "",
    batch_running_code: "",

    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);
  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);
  const [requestCount, setRequestCount] = useState(initialRequestCount);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
    },
    {
      name: "Ref",
      cell: (row) => row.ref,
      sortable: true,
      width: "130px",
    },
    {
      name: "Received At",
      cell: (row) => row.received_at,
      sortable: true,
    },
    {
      name: "Total Count",
      cell: (row) => row.total_count,
      sortable: true,
      width: "130px",
    },
    {
      name: "Returned From",
      cell: (row) => row.returned_from,
      sortable: true,
    },
    {
      name: "Created By",
      cell: (row) => row.created_by,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() =>
              navigate("/genefied/"+getVendorName()+"/supply-beam/return-info", {
                state: row,
              })
            }
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >
            View
          </button>
        </div>
      ),
      minWidth: "100px",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [
    dataListApi,
    {
      data: dataListRes,
      isLoading: dataListIsLoading,
      error: dataListError,
    },
  ] = useGetReturnListMutation ();

  const [
    dataCountApi,
    {
      data: dataCountRes,
      isLoading: dataCountIsLoading,
      error: dataCountError,
    },
  ] = useFetchAllQrCodeWithoutGenerationCountMutation();

  const fetchListFunction = () => {
    dataListApi({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };
 

  const [
    qrLiCsvDataFun,
    {
      data: qrLiCsvData,
      isLoading: qrLiCsvDataIsLoading,
      error: qrLiCsvDataError,
    },
  ] = useFetchAllQrCodeWithoutGenerationMutation();

  const generateCSV = (data) => {
    if (data.body.qrsList.length === 0) {
      return "";
    }
  
    const header = [
      "Id",
      "Batch Running Code",
      "Unique Code",
      "Scratch Code",
      "Product Code",
    ];
    const rows = data.body.qrsList.map((item, index) => [
      index + 1,
      item.batch_running_code,
      item.unique_code,
      item.scratch_code,
      item.product_code,
    ]);
  
    const csvContent = [header, ...rows]
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");
  
    return `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
  };

  // useEffect(()=>{
  //   qrListDataCountFun({
  //     token: sessionStorage.getItem("access_token"),
  //     tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //     body: requestCount,
  //   });
  // },[requestCount])
  
  useEffect(() => {
    if (qrLiCsvData?.body?.qrsList?.length > 0) {
      let qrList2 = [...qrLiCsvData.body.qrsList];
      const chunkSize = 1000000; // 10 lakh entries

      let qrList = qrList2.map(x => ({
        ...x,
        unique_code: `http://gverify.me/?${x.unique_code}`
      }));

      const modifiedBody = {
        ...qrLiCsvData.body,
        qrsList: qrList // qrList is the modified array
      };
      
      // Update qrLiCsvData with the new modifiedBody

      // qrLiCsvData.body = modifiedBody;
      const newData={body:modifiedBody}

      if (qrList.length <= chunkSize) {
        const csvDataUri = generateCSV(newData);
        downloadCSV(csvDataUri, "qr_data.csv");
      } else {
        // Divide data into chunks
        for (let i = 0; i < qrList.length; i += chunkSize) {
          const chunk = qrList.slice(i, i + chunkSize);
          const chunkData = { body: { qrsList: chunk } };
          const csvDataUri = generateCSV(chunkData);
          downloadCSV(csvDataUri, `qr_data_chunk_${i / chunkSize + 1}.csv`);
        }
      }
    }
  }, [qrLiCsvData]);
  
  const downloadCSV = (dataUri, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = dataUri;
    downloadLink.download = fileName;
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
  
    downloadLink.click();
  
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    if (qrLiCsvDataError) {
      toast.error("Something went wrong", { id: "qrLiCsvDataError" });
    }
  }, [qrLiCsvDataError]);

  const qrListDownLoad = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    qrLiCsvDataFun({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };


  useEffect(() => {
  
      fetchListFunction();

      let tempRequest = { ...request };
      delete tempRequest.limit;
      delete tempRequest.offset;
  
      if (!objectsAreEqual(tempRequest, requestCount)) {
        setRequestCount(tempRequest);
      }
    
  }, [request,limit,offset]);

  const [showQr, setShowQr] = useState(false);
  const [qrData, setQrData] = useState(null);

  useEffect(() => {
    if (dataListRes) {
      setMyList(dataListRes && dataListRes.body ? dataListRes.body.data : [])
    }
  }, [dataListRes]);

  // useEffect(() => {
  //   if (qrListDataCount) {
      
  //      setTotalRows(qrListDataCount && qrListDataCount.body ? qrListDataCount.body.total_qr : 0)
  //   }
  // }, [qrListDataCount]);

  const handleShowQr = (data) => {
    let obj = {
      link: data.unique_code.includes("gverify.me") 
        ? data.unique_code
        : `http://gverify.me/?${data.unique_code}`, 
      ...data,
    };
  
    setQrData(obj);
    setShowQr(true);
  };
  

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };


  const handleBatchShowQr = (data) => {
    let obj = {
      link: `${data.unique_code}`,
      ...data,
    };

    // Ultimatrue Specific Changes
    if(JSON.parse(sessionStorage.getItem("vendorData")).tenant_id == 17){
      obj.link =  `${process.env.REACT_APP_QR_URL}genefied/verifyQr?qrCode=${data.unique_code}`
    }

    setQrData(obj);
    setShowQr(true);
  };

  const handleWebGenefiedQr = (data) => {
    

    let obj = {
      link: `${process.env.REACT_APP_QR_URL}genefied/verifyQr?qrCode=${data.unique_code}`,
      // link: `${data.unique_code}`,
      ...data,
    };


    setQrData(obj);
    setShowQr(true);
  };
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Return List
            <LinksComponent />
          </div>

          <div className="p-3">

          <div className="flex gap-2">
          
          {/* <button
            className="btn btn-outline-primary clear-both float-right  mb-2"
            onClick={() => {
              qrListDownLoad();
            }}
          >
            Export Csv
          </button> */}
         </div>
            <div className=" bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex space-x-2">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Start Date</label>
                    <input
                      type="date"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Start Date"
                      value={request.startDate}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            startDate: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">End Date</label>
                    <input
                      type="date"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="End Date"
                      value={request.endDate}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            endDate: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-row gap-2  w-56 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all "
                        onClick={() => {
                          fetchListFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                // title={"Product List"}
                data={myList}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
        {showQr && <ViewQr data={qrData} show={showQr} setShow={setShowQr} />}
      </div>
    </>
  );
};

export default ReturnList;
