import React, { Fragment, useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import {
  useFetchUserByUserTypeCountMutation,
  useFetchUserByUserTypeMutation,
  useUpdateAppUserStatusMutation,
} from "../../../features/tenant/appUsers/appUsersApi";
import { toast } from "react-hot-toast";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import { HiOutlinePlus } from "react-icons/hi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";

import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import CustomExport from "../../../components/CustomExport/CustomExport";

const AgricultureUsersList = () => {
  const { zone_id: zoneId, zone_name: zoneName } = getLoginUserData();
    const { userType } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [allUsers,setAllUsers]=useState([]);
  const [fetchAppUserByUserType, { data: appUsersData,  isLoading: appUsersDataLoading ,error: appUsersError }] =
    useFetchUserByUserTypeMutation();
const tenant_name=  JSON.parse(sessionStorage.getItem("vendorData")).tenant_name;
    const [
      fetchUserByUserTypeCount,
      {
        data: fetchUserByUserTypeCountData,
        error: fetchUserByUserTypeCountError,
      },
    ] = useFetchUserByUserTypeCountMutation();

    useEffect(() => {
      if (appUsersDataLoading) {
        toast.dismiss();
        toast.loading("loading ...", {
          id: "delete loading ",
        });
      }
      if (appUsersData) {
        toast.dismiss();
        setAllUsers(appUsersData.body.appUsersList)

      }
  
    }, [appUsersDataLoading,appUsersData]);
  const [newRow, setNewRow] = useState();
  const [
    fetchAppUserByUserTypeCsv,
    { data: appUsersCsvData, error: appUsersCsvError },
  ] = useFetchUserByUserTypeMutation();

  const [
    updateAppUserStatus,
    {
      data: updateAppUserStatusData,
      error: updateAppUserStatusError,
      isLoading: updateAppUserStatusIsLoading,
      isError: updateAppUserStatusIsError,
    },
  ] = useUpdateAppUserStatusMutation();


  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [prevPage, setPrevPage] = useState(0);
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [remarks, setRemarks] = useState();
  const initialRequest = {
    user_type: userType,
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  useEffect(() => {
    if (updateAppUserStatusData) {
      toast.success("Status Updated Successfully!");
      fetchAppUserByUserTypeFunction();
      // fetchAppUserByUserTypeCsvFunction();
      return;
    }

    if (updateAppUserStatusError) {
      toast.error("Error in Updating Status");
      return;
    }
  }, [updateAppUserStatusData, updateAppUserStatusError]);

  useEffect(() => {
    fetchAppUserByUserTypeFunction();
    countAppUserByUserTypeFunction();
    // fetchAppUserByUserTypeCsvFunction();
  }, [location.pathname, limit, offset, userTypesArray]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
    setRequest({ ...request, offset: newOffset });
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
    setRequest({ ...request, limit: newPerPage });
  };


  const fetchAppUserByUserTypeFunction = () => {
    // request["limit"] = limit;
    // request["offset"] = offset;
    fetchAppUserByUserType({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      zoneId: zoneId,
    });
  };

  const countAppUserByUserTypeFunction = () => {
    // request["limit"] = limit;
    // request["offset"] = offset;
    fetchUserByUserTypeCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      zoneId: zoneId,
    });
  };

  const fetchAppUserByUserTypeCsvFunction = () => {
    const modifiedRequest = {
        ...request,
        limit: null,
        offset: null,
      };
    fetchAppUserByUserTypeCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      zoneId: zoneId,
    });
  };


  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (appUsersCsvData && appUsersCsvData?.body?.appUsersList) {
      setCsvData(appUsersCsvData.body.appUsersList);
    }

    if (appUsersCsvError) {
      setCsvData(null);
    }
  }, [appUsersCsvData, appUsersCsvError])

  const clearFilters = () => {
    setRequest(initialRequest);
  };
  useEffect(() => {
    if (
      request.mobile === "" &&
      request.startDate === "" &&
      request.endDate === currentDate
    ) {
      fetchAppUserByUserTypeFunction();
      // fetchAppUserByUserTypeCsvFunction();
    }
  }, [request, userTypeId]);






  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;



const columns = [
  {
    name: "SNo",
    cell: (row, idx) => offset + Number(idx + 1),
    sortable: true,
    width: "80px",
  },
  {
    name: "Name",
    cell: (row) => <div>{row.name}</div>,
    sortable: true,
  },
  {
    name: "Email",
    cell: (row) => row.email,
    sortable: true,
  },
  {
    name: "Mobile",
    cell: (row) => row.mobile,
    sortable: true,
  },
  {
    name: "UserType",
    cell: (row) => row.user_type,
    sortable: true,
  },
  {
    name: "Gender",
    cell: (row) => row.gender,
    sortable: true,
  },
  {
    name: "City",
    cell: (row) => row.city,
    sortable: true,
  },
  {
    name: "Pincode",
    cell: (row) => row.pincode,
    sortable: true,
  },
  {
    name: "District",
    cell: (row) => row.district,
    sortable: true,
  },
  {
    name: "State",
    cell: (row) => row.state,
    sortable: true,
  },
  {
    name: "Status",
    cell: (row) => (row.status == "1" ? "Active" : "InActive"),
  }
];


  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            All Users
            <LinksComponent />
          </div>

          <div className="p-3 ">
            <div className="flex justify-between items-start">
              {/* {appUsersCsvData && appUsersCsvData?.body?.appUsersList && (
                <ExportCSV
                  data={appUsersCsvData && appUsersCsvData?.body?.appUsersList}
                  columnsToExport={[
                    "name",
                    "email",
                    "mobile",
                    "user_type",
                    "gender",
                    "city",
                    "pincode",
                    "district",
                    "state"
                  ]}
                />
              )} */}

<div className="mb-2">
              <CustomExport fetchData={fetchAppUserByUserTypeCsvFunction} data={csvData} defaultColumnExport={[
                 "name",
                 "email",
                 "mobile",
                 "user_type",
                 "gender",
                 "city",
                 "pincode",
                 "district",
                 "state"
              ]}
                filename="all_users"
              />
            </div>
            </div>

            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 ">
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile No
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Mobile..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="w-52 ">
                    <div className="flex-col slc_dvd topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">User Types</label>
                    <div className="relative searchin">
                      {appUsers && appUsers.length > 0 && (
                        <SelectComponent
                          className="capitalize"
                          //title={"User Types"}
                          selected={userTypesArray}
                          setSelected={setUserTypesArray}
                          dataArr={appUsers}
                        />
                      )}</div>
                    </div>
                  </div> */}

                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        From Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>

                    <div className="flex flex-row  gap-2 ">
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right  pdbtn_all"
                          onClick={() => {
                            fetchAppUserByUserTypeFunction();
                            // fetchAppUserByUserTypeCsvFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-3">
                <DataTableComponent
              keyField={(row) => row.SNo}
                  columns={columns}
                  data={allUsers.length&&allUsers}
                  totalRows={fetchUserByUserTypeCountData && fetchUserByUserTypeCountData.body.total}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
            </div>
          </div>
        </div>

        {showModal1 && (
          <ModalComponent
            size={"xl"}
            data={"nadnandand"}
            show={showModal1}
            setShow={setShowModal1}
          >
            <div className="w-full p-4">
              <div className="w-full m-auto  justify-center ">
                <div className="flex gap-1 ">
                  <div className="pb-1  bottom-2 w-full mb-2">
                    <InputComponent
                      name={"Remarks"}
                      type={"text"}
                      value={remarks}
                      setValue={setRemarks}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-3">
                <button
                  className="text-white bg-blue-700 hover-bg-blue-800 focus-ring-4 focus-outline-none focus-ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={() => {
                    setShowModal1(false);
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}

      
      </div>
    </>
  );
};

export default AgricultureUsersList;
