import React, { useEffect, useState } from 'react'
import { useAqualiteQrSyncStatsByBatchNoMutation } from '../../../features/tenant/Aqualite/aqualiteApi';
import { CircleLoaderComponent } from '../../../components/Loader/CircleLoaderComponent';
import DataTableComponent from '../../../components/Tenant/DataTableComponent/DataTableComponent';
import LinksComponent from '../../../components/Tenant/LinksComponent/LinksComponent';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { getVendorName } from '../../../utils/getVendorName';


const QrSyncStatsByBatch = () => {

    const [
        aqualiteQrSyncStats,
        {
            data: aqualiteQrSyncStatsData,
            error: aqualiteQrSyncStatsError,
            isLoading: aqualiteQrSyncStatsIsLoading,
        },
    ] = useAqualiteQrSyncStatsByBatchNoMutation();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log("location: ", location.state)
        if (!location.state || !location.state.batch_no) {
            navigate(`/genefied/${getVendorName()}/`);
        }
    }, [location.pathname]);

    const [myList, setMyList] = useState([])
    const [totalRows, setTotalRows] = useState(0);

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const initialRequest = {
        limit: 10,
        offset: 0,
        product_code: "",
        batch_running_code: "",
        product_name: "",
        start_date: "",
        end_date: "",
        status: ""
    };

    const [request, setRequest] = useState(initialRequest);

    const clearFilters = () => {
        setRequest(initialRequest);
    };

    useEffect(() => {
        if (
            request.product_code === "" &&
            request.product_name === "" &&
            request.batch_running_code === "" &&
            request.start_date === "" &&
            request.end_date === ""
        ) {
            fetchAqualiteQrSyncStatsFunc();
        }

    }, [request]);


    const fetchAqualiteQrSyncStatsFunc = () => {
        aqualiteQrSyncStats({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
            batch_no: location.state.batch_no,
            body: request,
        });
    };

    useEffect(() => {
        toast.dismiss();
        if (aqualiteQrSyncStatsData && aqualiteQrSyncStatsData.body) {
            setMyList(aqualiteQrSyncStatsData.body.data ? aqualiteQrSyncStatsData.body.data : []);
            setTotalRows(aqualiteQrSyncStatsData.body.total_count ? aqualiteQrSyncStatsData.body.total_count : 0)
        }

        if (aqualiteQrSyncStatsError) {
            toast.error("Something went wrong", { id: "aqualiteQrSyncStatsError" })
        }
    }, [aqualiteQrSyncStatsData, aqualiteQrSyncStatsError]);



    const handlePageChange = (pageNumber) => {
        const newOffset = (pageNumber - 1) * limit;
        setOffset(newOffset);
        setRequest({ ...request, offset: newOffset });
        // setPrevPage(pageNumber - 1);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setRequest({ ...request, limit: newPerPage });
        setLimit(newPerPage);
    };

    const columns = [
        {
            name: "SNo",
            cell: (row, idx) => offset + Number(idx + 1),
            sortable: true,
            width: "80px",
        },
        {
            name: "Batch Running Code",
            cell: (row) => row.batch_running_code,
            sortable: true,
            width: "150px",
        },
        {
            name: "Unique Code",
            cell: (row) => row.unique_code,
            sortable: true,
            width: "150px",
        },
        {
            name: "Product Code",
            cell: (row) => row.product_code,
            sortable: true,
            width: "150px",
        },
        {
            name: "Product Name",
            cell: (row) => row.product_name,
            sortable: true,
            width: "150px",
        },
        {
            name: "Status",
            cell: (row) => (<span
                className={`text-sm p-1 capitalize rounded-sm font-bold text-white animate-pulse duration-0 ${row.status == "1"
                        ? "bg-green-700" // Success
                        : row.status == "0"
                            ? "bg-red-700" // Failed
                            : "bg-orange-700" // Processing
                    }`}
            >
                {row.status == "1"
                    ? "Success"
                    : row.status == "0"
                        ? "Failed"
                        : "Processing"}
            </span>),
            sortable: true,
            width: "150px",
        },
        {
            name: "Reason",
            cell: (row) => row.reasons,
            sortable: true,
            width: "150px",
        },
        {
            name: "Created At",
            cell: (row) => new Date(row.created_at).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
            sortable: true,
            width: "200px",
        },
    ];

    return (
        <>
            <div className="flex w-full bg-gray-200 h-full justify-center">
                <div className="w-full ">
                    <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
                        Qr Sync Stats By Batch
                        <LinksComponent />
                    </div>

                    <div className="p-3">

                        <div className=" w-full bg-white shadow rounded-lg">
                            <div className="border-b border-gray-300 bg-gray-100 rounded-t-lg py-3 px-3 w-full">
                                <div className="flex space-x-1 justify-end  w-full max-w-full">

                                    <div className="w-52 h-10 ">
                                        <div className="relative searchin">
                                            <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                Batch Running Code
                                            </label>
                                            <input
                                                type="search"
                                                id="default-search"
                                                className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                                                placeholder="Batch Running Code..."
                                                required
                                                value={request.batch_running_code}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setRequest((prevState) => {
                                                        return { ...prevState, batch_running_code: newValue };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="w-52 h-10 ">
                                        <div className="relative searchin">
                                            <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                Product Name
                                            </label>
                                            <input
                                                type="search"
                                                id="default-search"
                                                className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                                                placeholder="Product Name..."
                                                required
                                                value={request.product_name}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setRequest((prevState) => {
                                                        return { ...prevState, product_name: newValue };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-52 h-10 ">
                                        <div className="relative searchin">
                                            <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                Product Code
                                            </label>
                                            <input
                                                type="search"
                                                id="default-search"
                                                className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                                                placeholder="Product Code..."
                                                required
                                                value={request.product_code}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setRequest((prevState) => {
                                                        return { ...prevState, product_code: newValue };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-28 h-10">
                                        <div className="relative searchin">
                                            <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                Status
                                            </label>
                                            <select
                                                className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                                                name="Qr Status"
                                                value={request.status}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setRequest((prevState) => {
                                                        return { ...prevState, status: newValue };
                                                    });
                                                }}
                                                id=""
                                            >
                                                <option selected value={""}>
                                                    All
                                                </option>
                                                <option value={"0"}> Failed</option>
                                                <option value={"1"}>
                                                    Success
                                                </option>
                                                <option value={"2"}> Processing</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="w-32 h-10 ">
                                        <div className="relative searchin">
                                            <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                Start Date
                                            </label>
                                            <input
                                                type="date"
                                                id="default-search"
                                                className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                                                placeholder="Start Date"
                                                value={request.start_date}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setRequest((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            start_date: newValue,
                                                        };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-32 h-10 mr-4">
                                        <div className="relative searchin">
                                            <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                End Date
                                            </label>
                                            <input
                                                type="date"
                                                id="default-search"
                                                className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                                                placeholder="End Date"
                                                value={request.end_date}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setRequest((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            end_date: newValue,
                                                        };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-56 gap-2 h-10">
                                        <div className="relative searchin">
                                            <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                &nbsp;
                                            </label>

                                            <button
                                                className="btn btn-outline-primary clear-both float-right  p-2 px-1.5"
                                                onClick={() => {
                                                    fetchAqualiteQrSyncStatsFunc();
                                                }}
                                            >
                                                Search
                                            </button>
                                        </div>
                                        <div className="relative searchin">
                                            <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                &nbsp;
                                            </label>
                                            <button
                                                className="btn btn-outline-primary clear-both float-right  whitespace-nowrap p-2 px-1.5"
                                                onClick={() => {
                                                    clearFilters();
                                                }}
                                            >
                                                Clear Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {aqualiteQrSyncStatsIsLoading && <CircleLoaderComponent />}
                                <DataTableComponent
              keyField={(row) => row.SNo}
                                    columns={columns}
                                    data={myList}
                                    totalRows={totalRows}
                                    handlePerRowsChange={handlePerRowsChange}
                                    handlePageChange={handlePageChange}
                                >
                                    <div className="flex m-2 space-x-2 justify-end"></div>
                                </DataTableComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QrSyncStatsByBatch