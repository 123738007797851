import React, { useEffect } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { HiOutlinePlus } from "react-icons/hi";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { hasPermission } from "../../../../utils/hasPermission";
import { getVendorName } from "../../../../utils/getVendorName";
import {
  useFetchCouponsBrandMutation,
  useUpdateCouponByBrandMutation,
} from "../../../../features/tenant/staticCoupons/staticCouponsApi";

const StaticCouponsList = () => {
  const navigate = useNavigate();

  const [
    fetchcouponBrands,
    {
      data: couponBrandList,
      isLoading: couponBrandIsLoading,
      error: couponBrandError,
    },
  ] = useFetchCouponsBrandMutation();

  const [
    updateCouponBrand,
    {
      data: updatecouponBrandList,
      isLoading: updatecouponBrandIsLoading,
      error: updatecouponBrandError,
    },
  ] = useUpdateCouponByBrandMutation();

  const hasAddcouponPermission = hasPermission("addStaticCoupon");
  const hasDeletecouponPermission = hasPermission("deleteStaticCoupon");
  const hasViewcouponListPermission = hasPermission("staticCouponListByBrand");

  useEffect(() => {
    const hasViewPermission = hasPermission("staticCouponBrands");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchcouponBrands({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (couponBrandList) {
      console.log(couponBrandList);
    }
  }, [couponBrandList, couponBrandError]);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      // width: "10%",
      textAlign: "left",
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
      // width: "40%",
    },
    {
      name: "Coupon Qty",
      selector: (row) => row.count,
      sortable: true,
      // width: "20%",
    },
    {
      name: "",
      selector: (row) =>
        hasViewcouponListPermission && (
          <button
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            onClick={() =>
              navigate(row.brand, {
                state: row,
              })
            }
          >
            View
          </button>
        ),
      sortable: true,
      // width: "30%",
    },
  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const updateHandler = (data) => {
    let status = "-1";

    if (data.status == "1") {
      status = "2";
    } else if (data.status == "2") {
      status = "1";
    }
    updateCouponBrand({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        brand_name: data.brand,
        status: status,
      },
    });
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Static coupons
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={couponBrandList && couponBrandList.body}
                loading={couponBrandIsLoading}
                totalRows={couponBrandList && couponBrandList.body.length}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              >
               
              </DataTableComponent>
              {hasAddcouponPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaticCouponsList;
