import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiEye, HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";
import {
  useDeleteScratchableRewardByIdMutation,
  useFetchAllScratchableRewardBySchemeIdMutation,
} from "../../../features/tenant/scratchableReward/scratchableRewardAPI";
import {
  useDeletePurchaseOrderMutation,
  useFetchAllPurchaseOrdersMutation,
} from "../../../features/tenant/purchaseOrder/purchaseOrderApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";

const PurchaseOrderList = () => {
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "OrderID",
      selector: (row) => row.order_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Order Value",
      selector: (row) => row.order_value,
      sortable: true,
    },

    {
      name: "Order Date",
      selector: (row) =>
        new Date(row.order_date).toLocaleDateString() +
        " , " +
        new Date(row.order_date).toLocaleTimeString(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Bill Value ",
      selector: (row) => row.bill_value,
      sortable: true,
      width: "120px",
    },
    {
      name: "Bill Date ",
      selector: (row) =>
        row.bill_date &&
        new Date(row.bill_date).toLocaleDateString() +
          " , " +
          new Date(row.bill_date).toLocaleTimeString(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Carry Taken ",
      selector: (row) => row.carry_taken_value,
      sortable: true,
      width: "140px",
    },
    {
      name: "Carry Forward ",
      selector: (row) => row.carry_forward_value,
      sortable: true,
      width: "140px",
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "Last Updated At",
      selector: (row) => new Date(row.last_updated_at).toLocaleDateString(),
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() => handleShowModal(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <HiEye className="mr-1" />
            View
          </button>
        </div>
      ),
      minWidth: "120px",
    },
    // {
    //   name: "",
    //   selector: (row) => (
    //     <div>
    //       <button
    //         // onClick={() => handleShowModal(row)}
    //         className="flex justify-center items-center rounded bg-gray-400 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#6b7280] transition duration-150 ease-in-out hover:bg-gray-500 hover:shadow-[0_8px_9px_-4px_rgba(107,114,128,0.3),0_4px_18px_0_rgba(107,114,128,0.2)] focus:bg-gray-500 focus:shadow-[0_8px_9px_-4px_rgba(107,114,128,0.3),0_4px_18px_0_rgba(107,114,128,0.2)] focus:outline-none focus:ring-0 active:bg-gray-600 active:shadow-[0_8px_9px_-4px_rgba(107,114,128,0.3),0_4px_18px_0_rgba(107,114,128,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(107,114,128,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(107,114,128,0.2),0_4px_18px_0_rgba(107,114,128,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(107,114,128,0.2),0_4px_18px_0_rgba(107,114,128,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(107,114,128,0.2),0_4px_18px_0_rgba(107,114,128,0.1)]"
    //       >
    //         Un-Settled
    //       </button>
    //     </div>
    //   ),
    //   minWidth: "160px",
    // },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() =>
              navigate("bill", {
                state: row,
              })
            }
            className="flex justify-center items-center rounded bg-green-500 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#34d399] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(52,211,153,0.3),0_4px_18px_0_rgba(52,211,153,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(52,211,153,0.3),0_4px_18px_0_rgba(52,211,153,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(52,211,153,0.3),0_4px_18px_0_rgba(52,211,153,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(52,211,153,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(52,211,153,0.2),0_4px_18px_0_rgba(52,211,153,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(52,211,153,0.2),0_4px_18px_0_rgba(52,211,153,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(52,211,153,0.2),0_4px_18px_0_rgba(52,211,153,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            Update Bill
          </button>
        </div>
      ),
      minWidth: "160px",
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() =>
              navigate("add", {
                state: row,
              })
            }
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            Manage
          </button>
        </div>
      ),
      minWidth: "140px",
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() => handleDelete(row)}
            className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
          >
            <AiOutlineDelete className="mr-1" />
            Delete
          </button>
        </div>
      ),
      minWidth: "140px",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleShowModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const [
    fetchAllPurchaseOrder,
    { data: allPurchaseOrderData, error: allPurchaseOrderError },
  ] = useFetchAllPurchaseOrdersMutation();

  useEffect(() => {
    // const hasMembershipView = hasPermission("Membership");

    // if (!hasMembershipView) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }

    fetchAllPurchaseOrder({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: 20,
      offset: 0,
    });
  }, []);

  console.log(allPurchaseOrderData);

  const [
    deletePurchaseOrder,
    { data: deletePurchaseOrderData, error: deletePurchaseOrderError },
  ] = useDeletePurchaseOrderMutation();

  useEffect(() => {
    if (deletePurchaseOrderData) {
      console.log("deletePurchaseOrderData", deletePurchaseOrderData);
    }

    if (deletePurchaseOrderError) {
      console.log("deletePurchaseOrderError", deletePurchaseOrderError);
    }
  }, [deletePurchaseOrderData, deletePurchaseOrderError]);

  const handleDelete = (data) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deletePurchaseOrder({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: data.id,
                });
                fetchAllPurchaseOrder({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  limit: 20,
                  offset: 0,
                });
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteScratchableReward",
      },
    );
    return;
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Purchase Order List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                // title={"Product List"}
                data={allPurchaseOrderData ? allPurchaseOrderData.body : []}
                // loading={loading}
                // totalRows={totalRows}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              ></DataTableComponent>
              {
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() =>
                            navigate(`add`, {
                              state: null,
                            })
                          }
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        {showModal && modalData && (
          <ModalComponent
            size={"2xl"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
<h2 className="bg-blue-700 text-white rounded-t-md p-2.5 text-center text-lg font-semibold">Purchase Details</h2>

<div className="px-4">
<div className="border-b border-gray-200">
  <div className="grid grid-cols-2 gap-2  pb-3 pt-3">
   <div>
   <span className="text-gray-400 text-sm  block">Mobile</span>
    <span className="flex text-black text-sm  gap-1">{modalData?.mobile}</span>
   </div>

   <div>
   <span className="text-gray-400 text-sm  block">Order Id</span>
    <span className="flex text-black text-sm  gap-1">{modalData?.order_id}</span>
   </div>
  </div>
</div>

<div className="border-b border-gray-200">
  <div className="grid grid-cols-2 gap-2  pb-3 pt-3">
   <div>
   <span className="text-gray-400 text-sm  block">Order Value</span>
    <span className="flex text-black text-sm  gap-1">{modalData?.order_value}</span>
   </div>

   <div>
   <span className="text-gray-400 text-sm  block">Scheme Name</span>
    <span className="flex text-black text-sm  gap-1">{modalData?.scheme_name}</span>
   </div>
   
  </div>
</div>

<div className="border-b border-gray-200">
  <div className="grid grid-cols-2 gap-2  pb-3 pt-3">
   <div>
   <span className="text-gray-400 text-sm  block">Order Date{" "}</span>
    <span className="flex text-black text-sm  gap-1">{new Date(modalData?.order_date).toLocaleString()}</span>
   </div>

   <div>
   <span className="text-gray-400 text-sm  block">Bill Value</span>
    <span className="flex text-black text-sm  gap-1">{modalData?.bill_value}</span>
   </div>
   
  </div>
</div>

<div className="border-b border-gray-200">
  <div className="grid grid-cols-2 gap-2  pb-3 pt-3">
   <div>
   <span className="text-gray-400 text-sm  block">Bill Date{" "}</span>
    <span className="flex text-black text-sm  gap-1">{new Date(modalData?.bill_date).toLocaleString()}</span>
   </div>

   <div>
   <span className="text-gray-400 text-sm  block">Carry Forward Value{" "}</span>
    <span className="flex text-black text-sm  gap-1">{modalData?.carry_forward_value}</span>
   </div>
   
  </div>
</div>

<div className="border-b border-gray-200">
  <div className="grid grid-cols-2 gap-2  pb-3 pt-3">
   <div>
   <span className="text-gray-400 text-sm  block">Assigned Coupons</span>
    <span className="flex text-black text-sm  gap-1">{modalData?.assigned_coupons}</span>
   </div>

   <div>
   <span className="text-gray-400 text-sm  block">Scratchable Coupons{" "}</span>
    <span className="flex text-black text-sm  gap-1">{modalData?.scratchable_coupons}</span>
   </div>
   
  </div>
</div>

<div className="border-b border-gray-200">
  <div className="grid grid-cols-2 gap-2  pb-3 pt-3">
   <div>
   <span className="text-gray-400 text-sm  block">Scratched Coupons</span>
    <span className="flex text-black text-sm  gap-1">{modalData?.scratched_coupons}</span>
   </div>

   
  </div>
</div>

</div>

          
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default PurchaseOrderList;
