import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { BsCheckLg } from "react-icons/bs";
import { HiChevronUpDown } from "react-icons/hi2";

// const dataArr = [
//   { id: 1, name: 'Wade Cooper' },
//   { id: 2, name: 'Arlene Mccoy' },
//   { id: 3, name: 'Devon Webb' },
//   { id: 4, name: 'Tom Cook' },
//   { id: 5, name: 'Tanya Fox' },
//   { id: 6, name: 'Hellen Schmidt' },
// ]

export default function SelectComponentWithSearchLancer({
  title,
  selected,
  setSelected,
  placeholder,
  dataArr,
  required = false,
  onType, // Add this prop
}) {
  const [query, setQuery] = useState("");

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    
    if (onType) {
      onType(inputValue); // Call the function when the user types
    }
  };

  const filteredData =
    query === ""
      ? dataArr
      : dataArr.filter((data) =>
          data.name.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="w-full">
      <Combobox value={selected} onChange={setSelected}>
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 mb-0">
          {title} {required && " * "}
        </Combobox.Label>
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md">
            <Combobox.Input
              className="shadow-sm capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              displayValue={(item) => item?.name || ""}
              placeholder={placeholder}
              onChange={handleInputChange} // Track user input
            />
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-2 text-base shadow-lg">
              {filteredData.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2.5 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData.map((each, idx) => (
                  <Combobox.Option
                    key={idx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2.5 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={each}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block capitalize truncate ${selected ? "font-medium" : "font-normal"}`}>
                          {each.name}
                        </span>
                        {selected && (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            ✔
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
