/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { getVendorName } from "../../../utils/getVendorName";
import {
  useFetchAllWarrantyClaimsMutation,
  useFetchWarrantyClaimByWarrantyIdMutation,
} from "../../../features/tenant/warranty/warrantyClaimApi";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";

const WarrantyClaimList = () => {
  const { userType } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);

  const [warrantyList, setwarrantyList] = useState([]);
  const initialRequest = {
    name: "",
    product_code: "",
    product_name: "",
    phone: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };
  useEffect(() => {
    if (
      request.product_code === "" &&
      request.name === "" &&
      request.product_name === "" &&
      request.phone === ""
    ) {
      fetchWarrantyClaimListFunction();
      fetchWarrantyClaimListCsvFunction();
    }
  }, [request]);

  const [
    fetchWarrantyClaimList,
    { data: warrantyClaimListData, error: warrantyClaimListError },
  ] = useFetchAllWarrantyClaimsMutation();

  const [
    fetchWarrantyClaimByWarrantyId,
    {
      data: warrantyClaimByWarrantyIdData,
      error: warrantyClaimByWarrantyIdError,
    },
  ] = useFetchWarrantyClaimByWarrantyIdMutation();

  const [
    fetchWarrantyClaimListCsv,
    { data: warrantyClaimListCsvData, error: warrantyClaimListCsvError },
  ] = useFetchAllWarrantyClaimsMutation();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  useEffect(() => {
    const hasViewWarrantyClaimListPermission =
      hasPermission("warrantyClaimList");

    if (!hasViewWarrantyClaimListPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchWarrantyClaimListFunction();
    fetchWarrantyClaimListCsvFunction();
  }, [location.pathname]);

  const fetchWarrantyClaimListFunction = () => {
    fetchWarrantyClaimList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchWarrantyClaimListCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchWarrantyClaimListCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (warrantyClaimListData) {
      console.log("warranty list data ", warrantyClaimListData.body);
      setwarrantyList(warrantyClaimListData.body);
    }

    if (warrantyClaimListError) {
      console.log("warranty list error", warrantyClaimListError);
    }
  }, [warrantyClaimListData, warrantyClaimListError]);

  const handleShowWarranty = (data) => {
    console.log("warranty claim id", data);
    fetchWarrantyClaimByWarrantyId({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      warranty_id: data.warranty_id,
    });
  };

  useEffect(() => {
    if (warrantyClaimByWarrantyIdData) {
      console.log(
        "warrantyClaimByWarrantyIdData",
        warrantyClaimByWarrantyIdData,
      );
      if (warrantyClaimByWarrantyIdData && warrantyClaimByWarrantyIdData.body) {
        setModalData(warrantyClaimByWarrantyIdData.body);
        setShowModal(true);
      }
    }

    if (warrantyClaimByWarrantyIdError) {
      console.log(
        "warrantyClaimByWarrantyIdError ",
        warrantyClaimByWarrantyIdError,
      );
    }
  }, [warrantyClaimByWarrantyIdData, warrantyClaimByWarrantyIdError]);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "75px",
    },
    {
      name: "Name",
      selector: (row) => <h2>{row.name}</h2>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Product Code",
      selector: (row) => row.product_code,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.product_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? "Active" : row.status == 3 ? "Claimed" : "",
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "Claim Date",
      selector: (row) => new Date(row.claim_date).toLocaleString(),
      sortable: true,
    },
    {
      name: "Platform Name",
      selector: (row) => row.platform,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => (
        <button
          className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          onClick={() => handleShowWarranty(row)}
        >
          View
        </button>
      ),
      sortable: true,
      minWidth: "120px",
    },
  ];

  const onButtonDownloadClick = (filename) => {
    // const filename = activateWarrantyData?.body?.warranty_pdf;
    fetch(`${process.env.REACT_APP_BASE_URL}/images/${filename}`).then(
      (response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // console.log("fileurl", fileURL);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
        });
      },
    );
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Warranty Claim List
            <LinksComponent />
          </div>

          <div className="p-3">
            {warrantyClaimListCsvData &&
              warrantyClaimListCsvData?.body?.warrantyClaimList && (
                <ExportCSV
                  data={
                    warrantyClaimListCsvData &&
                    warrantyClaimListCsvData?.body?.warrantyClaimList
                  }
                  columnsToExport={[
                    "name",
                    "phone",
                    "product_code",
                    "product_name",
                    "user_type",
                    "claim_date",
                  ]}
                />
              )}
            <div className="w-full bg-white shadow rounded-lg">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 justify-between">
                <div className="w-52 h-10">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Name..."
                      required
                      value={request.name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-52 h-10">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Phone</label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Phone..."
                      required
                      value={request.phone}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, phone: newValue };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-52 h-10 ">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Product Code</label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Product Code ..."
                      required
                      value={request.product_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, product_code: newValue };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-52 h-10">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Product Name</label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Product Name..."
                      required
                      value={request.product_name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, product_name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row  gap-2 h-10">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                    <button
                      className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                      onClick={() => {
                        fetchWarrantyClaimListFunction();
                        fetchWarrantyClaimListCsvFunction();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                    <button
                      className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div></div>
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                // title={""}
                data={warrantyClaimListData?.body?.warrantyClaimList}
                loading={loading}
                totalRows={warrantyClaimListData?.body?.total}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>

        {showModal && modalData && (
          <ModalComponent
            size={"6xl"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
              <div className="flex justify-center p-8 space-x-4">
                <div
                  className="w-1/3 p-4 rounded-xl border-2 "
                  style={{ border: `2px solid ${primaryColor["700"]}` }}
                >
                  <h2 className="text-xl font-bold mb-4">Product Data</h2>

                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Product ID:</strong>{" "}
                    {modalData?.warranty_data?.product_id}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Category:</strong>{" "}
                    {modalData?.warranty_data?.category_name}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Brand:</strong>{" "}
                    {modalData?.warranty_data?.brand_name}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Product Name:</strong>{" "}
                    {modalData?.warranty_data?.product_name}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Product Code:</strong>{" "}
                    {modalData?.warranty_data?.product_code}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Desc :</strong>{" "}
                    {modalData?.warranty_data?.description}
                  </p>
                  <div className="mt-4">
                    <h3 className="text-lg font-medium mb-2">
                      Product Images:
                    </h3>
                    {modalData?.warranty_data?.images &&
                      modalData?.warranty_data?.images.map((image, index) => (
                        <img
                          key={index}
                          src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
                          alt={`Warranty Image ${index}`}
                          onClick={() =>
                            setShowImageModal(
                               image,
                            )
                          }
                          className="w-32 h-32 object-cover mb-2"
                        />
                      ))}
                  </div>
                  {/* Add more fields as needed */}
                </div>
                <div
                  className="w-1/3 p-4 rounded-xl border-2 "
                  style={{ border: `2px solid ${primaryColor["700"]}` }}
                >
                  <h2 className="text-xl font-bold mb-4">Warranty Data</h2>

                  {/* <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Warranty ID:</strong> {modalData?.warranty_data?.id}
                  </p>
                   */}
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Name:</strong> {modalData?.warranty_data?.name}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Phone:</strong> {modalData?.warranty_data?.phone}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>User Type:</strong>{" "}
                    {modalData?.warranty_data?.user_type}
                  </p>
                  {/* <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Product Name:</strong>{" "}
                    {modalData?.warranty_data?.product_name}
                  </p> */}
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Duration:</strong>{" "}
                    {modalData?.warranty_data?.duration}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Start Date:</strong>{" "}
                    {modalData?.warranty_data?.start_date}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>End Date:</strong>{" "}
                    {modalData?.warranty_data?.end_date}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Warranty On:</strong>{" "}
                    {modalData?.warranty_data?.primary_warranty_on}
                  </p>
                 
                  <div className="mt-4">
                    <h3 className="text-lg font-medium mb-2">
                      Warranty Images:
                    </h3>
                    {modalData?.warranty_data?.warranty_image &&
                      modalData?.warranty_data?.warranty_image.map(
                        (image, index) => (
                          <img
                            key={index}
                            src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
                            alt={`Warranty Image ${index}`}
                            onClick={() =>
                              setShowImageModal(
                                 image,
                              )
                            }
                            className="w-32 h-32 object-cover mb-2"
                          />
                        ),
                      )}
                  </div>
                  {modalData?.warranty_data?.warranty_pdf && (
                    <div className="mt-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-black-900 hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => {
                          onButtonDownloadClick(modalData?.warranty_pdf);
                        }}
                      >
                        Download Warranty Pdf
                      </button>
                    </div>
                  )}
                  {/* Add more fields as needed */}
                </div>
                <div
                  className="w-1/3 p-4 rounded-xl border-2"
                  style={{ border: `2px solid ${primaryColor["700"]}` }}
                >
                  <h2 className="text-xl font-bold mb-4">Claim Data</h2>

                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Claim ID:</strong> {modalData?.claim_data?.id}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Warranty ID:</strong>{" "}
                    {modalData?.claim_data?.warranty_id}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>App User ID:</strong>{" "}
                    {modalData?.claim_data?.app_user_id}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>User Type:</strong>{" "}
                    {modalData?.claim_data?.user_type}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Description:</strong>{" "}
                    {modalData?.claim_data?.description}
                  </p>
                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Claim Date:</strong>{" "}
                    {modalData?.claim_data?.claim_date}
                  </p>

                  <p className="text-sm  block mt-1 border-b border-gray-200 pb-2">
                    <strong>Platform:</strong> {modalData?.claim_data?.platform}
                  </p>
                  <div className="mt-4">
                    <h3 className="text-lg font-medium mb-2">Claim Images:</h3>
                    {modalData?.claim_data?.images &&
                      modalData?.claim_data?.images.map((image, index) => (
                        <img
                          key={index}
                          src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
                          alt={`Claim Image ${index}`}
                          className="w-32 h-32 object-cover mb-2"
                          onClick={() =>
                            setShowImageModal(
                               image,
                            )
                          }
                        />
                      ))}
                  </div>
                  {/* Add more fields as needed */}
                </div>
              </div>
            </div>
          </ModalComponent>
        )}

        {showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="md"
            data={showImageModal}
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}
      </div>
    </>
  );
};

export default WarrantyClaimList;
