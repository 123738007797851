import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useFetchAllBatchMutation } from "../../../features/tenant/batch/batchApi";
import { useAddQrCodeMutation } from "../../../features/tenant/qrCode/qrCodeApi";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { useFetchProductsMutation } from "../../../features/tenant/products/productsApi";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";
import { useGetLancerAddressApiMutation } from "../../../features/tenant/Lancer/LancerApi";
import toast from "react-hot-toast";
import SelectComponentWithSearchLancer from "../../../components/SelectComponentWithSearchLancer/SelectComponentWithSearchLancer";

const LancerQrGeneration = () => {
  const tenantData = JSON.parse(sessionStorage.getItem("vendorData"));
  const [batchList, setbatchList] = useState([]);
  const [lancerAddressList, setLancerAddressList] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState({
    id: -1,
    name: "",
  });
  const initialRequest = {
    product_code: "",
    name: "",
    limit: 10,
    offset: 0,
  };
  const [selectedLancerAddress, setSelectedLancerAddress] = useState({
    id: -1,
    name: "",
  });
  const [qrType, setQrType] = useState(
    JSON.parse(sessionStorage.getItem("companyProfile")).qr_type
  );

  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    id: -1,
    name: "",
  });
  const [qty, setQty] = useState(null);

  const [request, setRequest] = useState(initialRequest);

  const navigate = useNavigate();

  const [fetchBatchList, { data: batchListData, error: batchListError }] =
    useFetchAllBatchMutation();

  const [
    fetchProducts,
    {
      data: productsData,
      isLoading: productsIsLoading,
      isError: productsIsError,
      error: productsError,
    },
  ] = useFetchProductsMutation();

  const [
    fetchLancer,
    {
      data: lancerData,
      isLoading: lancerIsLoading,
      isError: lancerIsError,
      error: lancerError,
    },
  ] = useGetLancerAddressApiMutation();

  useEffect(() => {
    fetchBatchList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    fetchProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  }, [request]);

  useEffect(() => {
    if (lancerData?.body?.addresses) {
      const data = lancerData.body.addresses.map(
        ({ id, place_name, print_address1, print_address2 }) => ({
          id: id,
          name: place_name,
          address1: print_address1,
          address2: print_address2,
        })
      );

      setLancerAddressList(data);
    }
  }, [lancerData]);

  useEffect(() => {
    if (tenantData.tenant_name == "lancer-footwear") {
      fetchLancer({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
  }, []);

  useEffect(() => {
    if (selectedProduct.id != -1) {
      request.name = selectedProduct.name;
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (productsData) {
      if (productsData?.body?.products) {
        console.log(productsData.body.products);
        const data = productsData?.body?.products.map(
          ({ name, product_code, color, size, ...rest }) => {
            return {
              ...rest,
              product_name:name,
              name: `${name}  ${color} ${size} (${product_code})`,
              product_code,
              color,
              size
            };
          }
        );
        console.log(data, "data");
        setProductList(data);
      }
    }
  }, [productsData]);

  const [
    addQrCode,
    {
      data: QrCodeData,
      isLoading: QrCodeIsLoading,
      isError: QrCodeIsError,
      error: QrCodeError,
    },
  ] = useAddQrCodeMutation();

  useEffect(() => {
    toast.dismiss();

    if (batchListData?.body?.batches) {
      setbatchList(batchListData?.body?.batches);
      console.log(batchListData);
    }
    if (batchListError) {
      console.log("batch list error", batchListError);
    }
  }, [batchListData, batchListError]);

  const handleGenerate = (e) => {
    e.preventDefault();
    toast.dismiss();
    console.log(selectedProduct, "selected product");
    if (selectedProduct.id === -1) {
      toast.error("select product", { id: "select-product" });
      return;
    }

    if (selectedBatch.id === -1) {
      toast.error("select batch", { id: "select-batch" });
      return;
    }

    if (qty <= 0) {
      toast.error("Quantity should be more than 0", { id: "qty-error" });
      return;
    }

    if (qrType == "2" && selectedProduct.qty === 0) {
      toast.error("Product Qty is Invalid", { id: "invalid-product-qty" });
      return;
    }

    if (qrType == "2" && qty > 0 && qty % selectedProduct.qty !== 0) {
      toast.error(
        "Invalid Qr count, count must be in multiples of product quantity",
        { id: "invalid-qty" }
      );
      return;
    }

    const data = {
      batch_id: selectedBatch.batch_id,
      batch_code: selectedBatch.name,
      product_code: selectedProduct.product_code,
      product_name: selectedProduct.product_name,
      qty: qty,
    };

    if (tenantData.tenant_name == "lancer-footwear") {
      if (selectedLancerAddress.id == -1) {
        toast.error("Please select a address");
        return;
      }
      data["print_address_id"] = selectedLancerAddress.id;
    }

    addQrCode({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: data,
    });
  };

  useEffect(() => {
    const id = "qrGenerate";
    if (QrCodeIsLoading) {
      toast.loading("Generating QR", { id: id });
    }

    if (QrCodeData) {
      toast.success("Generated Successfully", {
        id: id,
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/printQr`);
      }, 200);
    }

    if (QrCodeError) {
      toast.error("Some Error Occurred", {
        id: id,
      });
    }
  }, [QrCodeData, QrCodeError, QrCodeIsLoading]);

  return (
    <>
      {productsIsLoading && <CircleLoaderComponent />}

      <>
        <div className="flex w-full bg-gray-200 h-full justify-center">
          <div className="w-full ">
            <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
              Qr Code Generation
              <LinksComponent />
            </div>
            <div className="p-3">
              <div className=" p-3 w-full bg-white shadow rounded-lg">
                <form className="">
                  <div className="w-full flex justify-center space-x-4">
                    <div className="mb-6 bottom-2 w-1/2">
                      <SelectComponentWithSearchLancer
                        title={"Product"}
                        selected={selectedProduct}
                        setSelected={setSelectedProduct}
                        dataArr={productList}
                        required={true}
                        placeholder={"Select Product"}
                        onType={(inputValue) => {
                          console.log("User typed:", inputValue);
                          setRequest((prevRequest) => ({
                            ...prevRequest,
                            name: inputValue, 
                          }));
                        }}
                      />
                    </div>
                    <div className="mb-6 bottom-2 w-1/3">
                      <SelectComponentWithSearch
                        title={"Batch"}
                        selected={selectedBatch}
                        setSelected={setSelectedBatch}
                        dataArr={batchList}
                        required={true}
                        placeholder={"Select Batch"}
                      />
                    </div>

                    {tenantData.tenant_name == "lancer-footwear" && (
                      <div className="mb-6 bottom-2 w-1/5">
                        <SelectComponentWithSearch
                          title={"Address"}
                          selected={selectedLancerAddress}
                          setSelected={setSelectedLancerAddress}
                          dataArr={lancerAddressList}
                          required={true}
                          placeholder={"Select Branch"}
                        />
                      </div>
                    )}
                    <div className="mb-6 bottom-2 w-1/4">
                      <InputComponent
                        name={"Quantity"}
                        type={"number"}
                        value={qty}
                        setValue={setQty}
                        required={true}
                        placeholder={"Enter Quantity"}
                      />
                    </div>
                  </div>

                  {!QrCodeIsLoading && (
                    <div className="flex justify-end mt-2">
                      <button
                        // type="submit"
                        onClick={(e) => handleGenerate(e)}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      >
                        Generate
                      </button>
                    </div>
                  )}
                </form>
              </div>
              <div>
                {selectedProduct.id === -1 ? (
                  <div className="w-full text-center"> N/A</div>
                ) : (
                  <div className="max-w-3xl my-2 mx-auto flex bg-white  rounded overflow-hidden shadow-lg">
                    <div className="p-0">
                      {/* {JSON.stringify(selectedProduct)} */}
                      {selectedProduct.images && (
                        <img
                          src={
                            selectedProduct.images.length > 0 &&
                            selectedProduct.images[0]
                          }
                          alt={selectedProduct?.name}
                          className="w-full h-24 object-cover"
                        />
                      )}
                      <div className="px-4 py-2">
                        <div className="font-bold text-xl mb-2">
                          {selectedProduct?.name}
                        </div>
                        <p className="text-gray-700 text-base">
                          {selectedProduct?.description}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="px-4 py-2">
                        <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2">
                          {selectedProduct?.category_name}
                        </span>
                        <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700">
                          {selectedProduct?.brand_name}
                        </span>
                      </div>
                      <div className="px-4 py-2">
                        <span className="inline-block capitalize bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2">
                         
                          {selectedProduct?.uom_value}{" "}
                          {selectedProduct?.uom_key}
                          
                        </span>
                        <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700">
                          Qty : {selectedProduct?.qty}
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="px-4 py-2">
                        <p className="text-gray-700 text-sm">
                          Product Code: {selectedProduct?.product_code}
                        </p>
                        <p className="text-gray-700 text-sm">
                          Product colour: {selectedProduct?.color}
                        </p>
                        <p className="text-gray-700 text-sm">
                          Product Size: {selectedProduct?.size}
                        </p>
                        <p className="text-gray-700 text-sm">
                          Status:{" "}
                          {selectedProduct?.status === "1"
                            ? "Active"
                            : "Inactive"}
                        </p>
                        <p className="text-gray-700 text-sm">
                          Points Active:{" "}
                          {selectedProduct?.points_active === "1"
                            ? "Yes"
                            : "No"}
                        </p>
                       
                      </div>
                      <div className="px-4 py-2">
                        <p className="text-gray-700 text-sm">
                          Created by: {selectedProduct?.created_by_name} on{" "}
                          {new Date(
                            selectedProduct?.created_at
                          ).toLocaleDateString()}
                        </p>
                        <p className="text-gray-700 text-sm">
                          Last updated by: {selectedProduct?.updated_by_name} on{" "}
                          {new Date(
                            selectedProduct?.updated_at
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default LancerQrGeneration;
