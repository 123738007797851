import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useGetAppVideoMutation } from "../../../../features/tenant/appVideo/appVideoApi";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import { ToastBar, toast } from "react-hot-toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useFetchAllPushNotificationDumpListMutation } from "../../../../features/tenant/PushNotificationType/pushNotificationTypeAPi";
import CustomExport from "../../../../components/CustomExport/CustomExport";

// function flattenData(data) {
//   let flattened = [];

//   data.forEach((each) => {
//     let flatItem = {
//       id: each.id,
//       mobile: each.mobile,
//       notification_type: each.notification_type,
//       subtitle: each.subtitle,
//       title: each.title,
//       body: each.body,
//       fcm_token: each.fcm_token,
//       status: each.data.success == "1" ? "Success" : "Failure",
//       created_at: each.created_at,
//     };

//     flattened.push(flatItem);
//   });

//   return flattened;
// }

// function downloadCSV(arrayOfObjects, filename = "push_notification_dump.csv") {
//   function convertToCSV(arr) {
//     const array = [Object.keys(arr[0])].concat(
//       arr.map((item) => Object.values(item)),
//     );
//     return array.map((it) => it.toString()).join("\n");
//   }

//   const csvString = convertToCSV(flattenData(arrayOfObjects));
//   const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
//   const link = document.createElement("a");

//   if (link.download !== undefined) {
//     const url = URL.createObjectURL(blob);
//     link.setAttribute("href", url);
//     link.setAttribute("download", filename);
//     link.style.visibility = "hidden";
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   }
// }

const PushNotificationsDumpList = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState();
  const [showModal, setShowModal] = useState(false);

  const [modalData, setmodalData] = useState();

  const handleOpenModal = (data) => {
    setShowModal(true);
    setmodalData(data);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setmodalData(null);
  };

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    name: "",
    mobile: "",
    notification_type: "",
    startDate: "",
    endDate:"",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    console.log("request", request);
    if (
      request.mobile === "" &&
      request.name === "" &&
      request.notification_type === "" &&
      request.startDate === "" &&
      request.endDate === currentDate
    ) {
      getAll();
    }
  }, [request]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [
    getAllPushNotificationDump,
    {
      data: getAllPushNotificationDumpData,
      isLoading: getAllPushNotificationTypeLoading,
      error: getAllPushNotificationDumpError,
    },
  ] = useFetchAllPushNotificationDumpListMutation();

  const [
    getAllPushNotificationDumpForCsv,
    {
      data: getAllPushNotificationDumpCsvData,
      isLoading: getAllPushNotificationCsvLoading,
      error: getAllPushNotificationDumpCSvError,
    },
  ] = useFetchAllPushNotificationDumpListMutation();

  const getAll = () => {
    getAllPushNotificationDump({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const getAllForCsv = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    getAllPushNotificationDumpForCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  useEffect(() => {
    getAll();
  }, [limit, offset]);

  useEffect(() => {
    if (getAllPushNotificationDumpData) {
      console.log(
        "getAllPushNotificationDumpData",
        getAllPushNotificationDumpData,
      );
      setTotalRows(getAllPushNotificationDumpData.body?.total_count ? getAllPushNotificationDumpData.body?.total_count : 0);
      setMyList(getAllPushNotificationDumpData.body?.data ? getAllPushNotificationDumpData.body?.data : []);
      
    }

    if (getAllPushNotificationDumpError) {
      console.log(
        "getAllPushNotificationDumpError",
        getAllPushNotificationDumpError,
      );
    }
  }, [getAllPushNotificationDumpData, getAllPushNotificationDumpError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Notification Type",
      cell: (row) => row.notification_type,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Title",
      cell: (row) => row.title,
      sortable: true,
    },
    // {
    //   name: "Subtitle",
    //   cell: (row) => row.subtitle,
    //   sortable: true,
    // },
    // {
    //   name: "Status",
    //   cell: (row) => (
    //     <span
    //       className={`text-sm p-1 capitalize rounded-sm  font-bold bg-${
    //         row.data.success == "1" ? "blue" : "red"
    //       }-700 text-white animate-pulse duration-0`}
    //     >
    //       {row.data.success == "1" ? "Success" : "Failure"}
    //     </span>
    //   ),
    //   sortable: true,
    // },

    {
      name: "Send At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    // {
    //   name: "",
    //   cell: (row) =>
    //     row?.data && Object.keys(row.data).includes("results") ? (
    //       row.data.failure == "1" && (
    //         <span
    //           className={`text-sm p-1 capitalize rounded-sm  font-bold bg-red-700 text-white animate-pulse duration-0`}
    //         >
    //           {row?.data?.results &&
    //             row?.data?.results.length > 0 &&
    //             row?.data?.results[0].error}
    //         </span>
    //       )
    //     ) : (
    //       <span
    //         className={`text-xs p-1 capitalize rounded-sm font-semibold bg-red-700 text-white animate-pulse duration-0`}
    //       >
    //         {row?.data?.message}
    //       </span>
    //     ),

    //   sortable: true,
    //   width: "200px",
    // },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };


  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    if (
      getAllPushNotificationDumpCsvData &&
      getAllPushNotificationDumpCsvData.body
    ) {
      // console.log(
      //   "getAllPushNotificationDumpCsvData",
      //   getAllPushNotificationDumpCsvData,
      // );
      toast.dismiss();
      setCsvData(getAllPushNotificationDumpCsvData?.body?.data);
      // downloadCSV(
      //   getAllPushNotificationDumpCsvData.body?.data,
      //   "push_notification_dump.csv",
      // );
    }

    if (getAllPushNotificationCsvLoading) {
      toast.dismiss();
      toast.loading("Fetching data...");
    }

    if (getAllPushNotificationDumpCSvError) {
      toast.dismiss();
      setCsvData(null);
      console.log(
        "getAllPushNotificationDumpCSvError",
        getAllPushNotificationDumpCSvError,
      );
      toast.error("Something went wrong.");
    }
  }, [
    getAllPushNotificationDumpCsvData,
    getAllPushNotificationCsvLoading,
    getAllPushNotificationDumpCSvError,
  ]);

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Push Notifications List
          <LinksComponent />
        </div>
        <div className="pl-3 pt-3">

        <div className="">
              <CustomExport fetchData={ getAllForCsv} data={csvData} defaultColumnExport={[
                "mobile"
              ]}
                filename="push_notification"
              />
            </div>
          {/* <button
            className="btn btn-outline-primary "
            onClick={(e) => {
              e.preventDefault();
              getAllForCsv();
            }}
          >
            Export CSV
          </button> */}
        </div>
        <div className="p-3">
          <div className="w-full bg-white shadow rounded-lg">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 justify-end">
                <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    Mobile
                  </label>
                  <input
                    type="search"
                    id="default-search"
                    className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Mobile..."
                    required
                    value={request.category}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return { ...prevState, mobile: newValue };
                      });
                    }}
                  />
                </div>
                
                <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                  Notification Type
                  </label>
                  <input
                    type="search"
                    id="default-search"
                    className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Notification Type..."
                    required
                    value={request.notification_type}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return { ...prevState, notification_type: newValue };
                      });
                    }}
                  />
                </div>
                <div className="w-32 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-44 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                <div className="flex flex-row  gap-2 h-10">
                <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>
                    <button
                      className="btn btn-outline-primary clear-both float-right whitespace-nowrap pdbtn_all"
                      onClick={() => {
                        getAll();
                      }}
                    >
                     Search
                    </button>
                  </div>
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>
                    <button
                      className="btn btn-outline-primary clear-both float-right whitespace-nowrap pdbtn_all"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableComponent
              keyField={(row) => row.SNo}
              columns={columns}
              // title={"Videos"}
              data={
                myList
              }
              // loading={loading}
              totalRows={
                totalRows
              }
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
            ></DataTableComponent>
            {/* <div
              className="fixed bottom-0 right-10 w-16 h-16 mr-10 mb-6 cursor-pointer"
              id="box_btn"
            >
              <div className="text-right">
                <Menu className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      onClick={() => navigate("add")}
                      className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                      style={{ backgroundColor: `${primaryColor["700"]}` }}
                    >
                      <HiOutlinePlus className="w-full h-full p-3" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {showModal && (
        <ModalComponent
          size={"md"}
          data={"nadnandand"}
          show={showModal}
          setShow={setShowModal}
        >
          <div className="w-full"></div>
        </ModalComponent>
      )}
    </div>
  );
};

export default PushNotificationsDumpList;
