import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../../utils/hasPermission";
import {
  useGetSMSLoggingMutation,
  useGetSMSLoggingTotalCountMutation,
  useSmsBalanceCheckMutation,
} from "../../../features/tenant/smsLogging/smsLoggingApi";
import { getVendorName } from "../../../utils/getVendorName";
import toast from "react-hot-toast";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";

function objectsAreEqual(obj1, obj2) {
  console.log("ob1", obj1);
  console.log("ob2", obj2);
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

const LoggingWhatsappAndSMS = () => {
  const navigate = useNavigate();

  let hasViewPermission = true;

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [total, setTotal] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [myList, setMyList] = useState([]);
  const [totatRows, setTotalRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const initialFilters = {
    receiver_mobile_numbers: "",
    startDate: "",
    endDate:"",
    limit: 10,
    offset: 0,
    response_success: true,
  };

  const initialFiltersForCount = {
    receiver_mobile_numbers: "",
    startDate: "",
    endDate:"",
    response_success: true,
  };
  const [request, setRequest] = useState(initialFilters);
  const [requestForCount, setRequestForCount] = useState(
    initialFiltersForCount
  );

  const [
    smsBalanceCheck,
    {
      data: smsBalanceCheckData,
      isLoading: smsBalanceCheckIsLoading,
      isError: smsBalanceCheckIsError,
      error: smsBalanceCheckError,
    },
  ] = useSmsBalanceCheckMutation();
  const [
    getSMSLogging,
    {
      data: getSMSLoggingData,
      isLoading: getSMSLoggingIsLoading,
      isError: getSMSLoggingIsError,
      error: getSMSLoggingError,
    },
  ] = useGetSMSLoggingMutation();

  const [
    getSMSLoggingTotalCount,
    {
      data: getSMSLoggingTotalCountData,
      isLoading: getSMSLoggingTotalCountIsLoading,
      isError: getSMSLoggingTotalCountIsError,
      error: getSMSLoggingTotalCountError,
    },
  ] = useGetSMSLoggingTotalCountMutation();

  const handleSMSBalanceCheck = () => {
    smsBalanceCheck({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  const handleFetchSMSLogging = () => {
    getSMSLogging({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  useEffect(() => {
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    console.log("First userEffect");
    getSMSLoggingTotalCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: requestForCount,
    });
  }, [requestForCount]);

  useEffect(() => {
    if (getSMSLoggingTotalCountData) {
      console.log(getSMSLoggingTotalCountData);
      setTotalRows(
        getSMSLoggingTotalCountData && getSMSLoggingTotalCountData?.body?.total
          ? getSMSLoggingTotalCountData?.body?.total
          : 0
      );
    }
    if (getSMSLoggingTotalCountError) {
    }
  }, [getSMSLoggingTotalCountData, getSMSLoggingTotalCountError]);

  useEffect(() => {
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    handleFetchSMSLogging();

    let tempRequest = { ...request };
    delete tempRequest.limit;
    delete tempRequest.offset;

    // console.log("Request", request);
    // console.log("limit", limit);
    // console.log("offset", offset);
    // console.log("tempRequest", tempRequest);
    // console.log(
    //   "objectsAreEqual(tempRequest , requestForCount) ",
    //   objectsAreEqual(tempRequest, requestForCount)
    // );

    if (!objectsAreEqual(tempRequest, requestForCount)) {
      setRequestForCount(tempRequest);
    }
  }, [request]);

  useEffect(() => {
    if (getSMSLoggingData) {
      toast.dismiss();
      // toast.success(getSMSLoggingData.message, {
      //   id: "getSMSLoggingData",
      // });
      setMyList(
        getSMSLoggingData &&
          getSMSLoggingData.body &&
          getSMSLoggingData.body.records
          ? getSMSLoggingData.body.records
          : []
      );
    }
    if (getSMSLoggingError) {
      toast.dismiss();
      toast.error(getSMSLoggingError.data.message, {
        id: "getSMSLoggingError",
      });
    }
  }, [getSMSLoggingData, getSMSLoggingError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Template Type",
      cell: (row) => row.template_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "Template Text",
      cell: (row) => row.template_text,
      sortable: true,
      width: "120px",
    },
    {
      name: "SMS Response",
      cell: (row) => row.sms_response,
      sortable: true,
    },
    {
      name: "Send By Name",
      cell: (row) => row.send_by_name,
      sortable: true,
    },
    {
      name: "Send At ",
      cell: (row) => new Date(row.send_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Entery At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];

  const handleSearchFilter = () => {
    handleFetchSMSLogging();
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    console.log("new offset: ", pageNumber, newOffset, limit);
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
    setRequest({ ...request, offset: newOffset });
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
    console.log("handlePerRowsChange: ", page);
    setRequest({ ...request, limit: newPerPage });
  };

  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            SMS Logging
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin d-flex gap-2 ">
                      {request.response_success == true ? (
                        <button
                          onClick={() => {
                            setRequest((prevState) => {
                              return {
                                ...prevState,
                                response_success: false,
                              };
                            });
                          }}
                          className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                        >
                          Success
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setRequest((prevState) => {
                              return {
                                ...prevState,
                                response_success: true,
                              };
                            });
                          }}
                          className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
                        >
                          Error
                        </button>
                      )}
                      <button className="flex justify-center items-center rounded bg-yellow-500 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]">
                        total :{" "}
                        {getSMSLoggingTotalCountData &&
                          getSMSLoggingTotalCountData?.body?.total}
                      </button>
                      
                      <button
                          onClick={() => {
                            setOpenModal(true);
                            handleSMSBalanceCheck();
                          }}
                          className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                        >
                          View Balance
                        </button>
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      Mobile
                      <input
                        type="text"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile"
                        required
                        value={request.receiver_mobile_numbers}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              receiver_mobile_numbers: newValue,
                            };
                          });
                          console.log("Mobile");
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      Start Date
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        required
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      End Date
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        required
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row gap-2 h-10">
                    <div className="relative searchin">
                      <button
                        className="btn btn-outline-primary py-2 clear-both float-right "
                        onClick={() => {
                          handleSearchFilter();
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={myList}
                // loading={loading}
                totalRows={totatRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>

              {openModal && (
                <ModalComponent show={openModal} setShow={setOpenModal} >
                  <div className="px-3 py-3 flex w-full justify-between align-center border-b pb-2 border-gray-100">
                    <h2 className="modal-title font-weight-bold text-lg">
                      Balance : {smsBalanceCheckData && smsBalanceCheckData.body && smsBalanceCheckData.body.balance}
                    </h2>
                  </div>
                </ModalComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoggingWhatsappAndSMS;
