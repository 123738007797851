import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import ModalComponent from '../Tenant/ModalComponent/ModalComponent';
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs';
import toast from 'react-hot-toast';
import { hasPermission } from '../../utils/hasPermission';

const CustomExport = ({ fetchData, data, defaultColumnExport, filename = "exported-data.csv" }) => {

  const [isDisabled, setisDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false)

  const [showColumnModal, setShowColumnModal] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(defaultColumnExport);

  const fetchDataFunc = () => {
    setIsLoading(true);
    fetchData();
  }

  const downloadCSV = () => {
    toast.dismiss();
    if (selectedColumns.length == 0) {
      toast.error("please select some columns.", { id: "selectSomeColumns" })
      return;
    }

    const selectedData = data.map(row => {
      const selectedRow = {};
      // selectedColumns.forEach(column => {
      //   if (column === 'status') {
      //     selectedRow[column] = row[column] === '1' ? 'Active' : row[column] === '2' ? 'Inactive' : "Deleted";
      //   } else if(column === 'points_active' ) {
      //     selectedRow[column] = row[column] === '1' ? 'Active' : 'Inactive' 
      //   }else {
      //     selectedRow[column] = row[column];
      //   }
      // });

      selectedColumns.forEach((column) => {
        let cellData = row[column];
        // Check if the cell data is an object
        if (typeof cellData === 'object') {
          // Convert object to string representation
          cellData = JSON.stringify(cellData);
        }
        if (column === 'status' && filename == "cash_transactions") {
          console.log("cash_transactions at export");
          selectedRow[column] =
            cellData === '1'
              ? 'Success'
              : cellData === '2'
                ? 'Pending'
                : cellData === '0' ? 'Error' : cellData;
        }
       else if (column === 'approval_status' && filename == "coupon_request_list") {
          console.log("coupon_request_list at export");
          selectedRow[column] =
            cellData === '1'
              ? 'Pending'
              : cellData === '2'
                ? 'Accepted'
                : cellData === '3' ? 'Rejected' : cellData;
        }
        else if (column === 'approval_status' && filename == "cash_transfer_request_list") {
          console.log("cash_transfer_request_list at export");
          selectedRow[column] =
            cellData === '3'
              ? 'Pending'
              : cellData === '1'
                ? 'Approved'
                : cellData === '2' ? 'Rejected' : cellData;
        }
         else if (column === 'status') {
          selectedRow[column] =
            cellData === '1'
              ? 'Active'
              : cellData === '2'
                ? 'Inactive'
                : cellData === '0' ? 'Deleted' : cellData;
        } else if (column === 'approval_status') {
          selectedRow[column] =
            cellData === '1'
              ? 'Accepted'
              : cellData === '2'
                ? 'Rejected'
                : cellData === '3' ? 'Pending' : cellData;
        }
        else {
          selectedRow[column] = cellData;
        }
      });

      return selectedRow;
    });

    const csv = Papa.unparse(selectedData);
    const name = filename + "-" + new Date().toISOString().replace(/:/g, '-').split('T')[0];
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setShowColumnModal(false);
  };

  // console.log("data", data);
  useEffect(() => {
    toast.dismiss();
    // console.log("data", data, data?.length);
    if (data && data.length > 0) {
      setIsLoading(false);
      setisDisabled(false);
      setShowColumnModal(true);
    }
    else if (data && data.length == 0) {
      toast("No data available", { id: "noData" });
      setIsLoading(false);
    }
  }, [data])

  const handleColumnChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setSelectedColumns([...selectedColumns, name]);
    } else {
      setSelectedColumns(selectedColumns.filter(column => column !== name));
    }
  };

  const downloadCsvFunc = () => {
    if (!showColumnModal) {
      setShowColumnModal(true);
    }
  }

  const handleSelectAll = () => {
    if (data && data.length > 0) {
      const allColumns = Object.keys(data[0]);
      setSelectedColumns(allColumns);
    }
  };

  const handleClearAll = () => {
    setSelectedColumns([]);
  };

  const exportCSVPermission = hasPermission("exportCSV");

  return (
    exportCSVPermission &&
    <div className='flex space-x-3 justify-start items-center '>
      <button className='btn btn-outline-secondary' onClick={() => fetchDataFunc()}>Fetch CSV</button>
      <button className='btn btn-outline-primary' onClick={downloadCsvFunc} disabled={isDisabled}>
        {isLoading ? (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        ) : (
          "Download CSV"
        )}
      </button>

      {showColumnModal && (
        <ModalComponent
          size={"5xl"}
          data={"nadnandand"}
          show={showColumnModal}
          setShow={setShowColumnModal}
        >
          <div className="w-full p-4">
            <div className="w-full m-auto  justify-center ">
              <div className="column-selection-popup">
                <h3 className='font-semibold text-lg pb-2 text-center'>Select Columns to export</h3>
                <div className="flex justify-start my-3 space-x-2">
                  <button
                    className="btn btn-outline-primary"
                    onClick={handleSelectAll}

                  >
                    Select All
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={handleClearAll}

                  >
                    Clear All
                  </button>
                </div>
                <div className='flex justify-start items-center flex-wrap space-x-1'>
                  {data && data.length > 0 ? Object.keys(data[0]).map(column => (
                    <div key={column} className=' w-36 m-auto flex items-center'>
                      <label className={`flex justify-start items-center p-1 rounded-md hover:bg-gray-100 w-full ${selectedColumns.includes(column) ? " bg-gray-100" : "  "} `} >
                        <input
                          type="checkbox"
                          // className='hidden'
                          name={column}
                          checked={selectedColumns.includes(column)}
                          onChange={handleColumnChange}
                        />
                        {/* {selectedColumns.includes(column) ? (
                          <BsCheckCircleFill className="w-3.5 mr-1" />
                        ) : (
                          <BsCircle className="w-3.5 mr-1" />
                        )} */}
                        <span className='ml-0.5'>
                          {column}
                        </span>
                      </label>
                    </div>
                  ))
                    : <h3 className='text-xl font-semibold text-center'>No data to export</h3>
                  }
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-3 space-x-2">
              <button
                className="text-white bg-blue-600 hover:bg-blue-800 focus-ring-4 focus-outline-none focus-ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center"
                onClick={() => {
                  downloadCSV();
                }}
              >
                Download
              </button>

              <button
                className="text-white bg-red-600 hover:bg-red-800 focus-ring-4 focus-outline-none focus-ring-red-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center"
                onClick={() => {
                  setShowColumnModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default CustomExport;
