import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi";
import { useDeleteTenantEmailTemplateMutation, useGetAllTenantEmailTemplatesMutation } from "../../../features/tenant/emailTemplate/emailTemplateApiTenant";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

export default function EmailTemplates() {
  const navigate = useNavigate();
  const location = useLocation();

  const addemailTemplatePermission = hasPermission("addEmailTemplate");
  const editemailTemplatePermission = hasPermission("editEmailTemplate");
  const deleteemailTemplatePermission = hasPermission(
    "deleteEmailTemplate",
  );

  const [tableDataItems, setTableDataItems] = useState([]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [
    fetchAllEmailTemplates,
    { data: emailTemplateData, error: emailTemplateError },
  ] = useGetAllTenantEmailTemplatesMutation();


  const [
    deleteEmailTemplate,
    { data: deleteEmailTemplateData, error: deleteEmailTemplateError },
  ] = useDeleteTenantEmailTemplateMutation();


  useEffect(() => {

    const hasViewPermission = hasPermission("emailTemplate");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchAllEmailTemplates({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      token: sessionStorage.getItem("access_token"),
    });
  }, []);

  useEffect(() => {
    if (emailTemplateData) {
      console.log("email template data", emailTemplateData);
      console.log(emailTemplateData);
      const arr = [...emailTemplateData.body];
      arr.sort((a, b) => a.id - b.id);
      setTableDataItems(arr);
    }

    if (emailTemplateError) {
      console.log("email template error", emailTemplateError);
    }
  }, [emailTemplateData, emailTemplateError]);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Template Name ",
      selector: (row) => row.template_name,
    },
    {
      name: "Template Type",
      selector: (row) => row.template_type,
      // sortable: true,
    },
    {
      name: "Subject ",
      selector: (row) => row.subject,
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            setModalData(r.html_template);
            setShowModal(true);
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (

        editemailTemplatePermission &&
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => navigate("manage", { state: r })}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        deleteemailTemplatePermission && 
        <button
          className="btn btn-outline-danger btn-sm"
            onClick={() => handleDelete(r.id)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleDelete = (id) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                toast.dismiss();
                deleteEmailTemplate({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                  id : id
                })
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteWorkflow",
      },
    );
    return;
  };

  useEffect(() => {
    if(deleteEmailTemplateData){
      fetchAllEmailTemplates({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
      toast.success("Deleted Successfully", {id : "emailTemplateDeleteSuccess"})
    }

    if(deleteEmailTemplateError){
      console.log("delete email template error" , deleteEmailTemplateError);
      toast.error("Something went wrong", {id : "emailTemplateDeleteError"})
    }
  }, [deleteEmailTemplateData , deleteEmailTemplateError])

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Email Templates
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={tableDataItems}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              ></DataTableComponent>
              {
                addemailTemplatePermission &&
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`manage`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              }
            </div>
          </div>

          {showModal && modalData && (
            <ModalComponent
              size={"4xl"}
              data={"Html Template"}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full">
                <div className="w-full flex justify-center ">
                  <div className="mb-6 w-full">
                    <div
                      className=""
                      dangerouslySetInnerHTML={{ __html: modalData }}
                    />
                  </div>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
}
