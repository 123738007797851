import { rootApi } from "../../api/rootApi";

export const kycUpdateApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    
    updateKycOfUser: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/app/kyc/update-kyc/${params.app_user_id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug : params.tenant_id,
          },
          body : params.data
        };
      },
    }),


    clearKycOfUser: builder.mutation({
        query: (params) => {
          return {
            method: "PATCH",
            url: `/app/kyc/remove/${params.app_user_id}`,
            headers: {
              Authorization: "Bearer " + params.token,
              slug : params.tenant_id,
            },
            body : params.data
          };
        },
      }),
  }),
});

export const { useUpdateKycOfUserMutation , useClearKycOfUserMutation} = kycUpdateApi;
