import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useDeleteQrCodeByBatchIdMutation,
  useFetchAllQrCodeMutation,
  useFetchQrCodeByGenerationIDMutMutation,
} from "../../../features/tenant/qrCode/qrCodeApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";

const QrPrint = () => {
  const navigate = useNavigate();


  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    product_code: "",
    product_name: "",
    batch_code: "",
    limit: 10,
    offset: 0,
    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (
      request.product_code === "" &&
      request.product_name === "" &&
      request.batch_code === "" &&
      request.startDate === "" &&
      request.endDate === ""
    ) {
      fetchAllQrCodeFunction();
    }
  }, [request]);

  const [
    fetchAllQrCode,
    {
      data: qrListData,
      isLoading: qrListDataIsLoading,
      isError: qrListDataIsError,
      error: qrListDataError,
    },
  ] = useFetchAllQrCodeMutation();

  useEffect(() => {
    fetchAllQrCodeFunction();
  }, []);

  useEffect(() => {
    if (qrListData) {
      setMyList(qrListData?.body?.batchCodes ? qrListData?.body?.batchCodes : []);
      setTotalRows(qrListData?.body?.total_batchcodes ? qrListData?.body?.total_batchcodes : 0);
    }
  }, [qrListData]);

  const fetchAllQrCodeFunction = () => {
    fetchAllQrCode({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const [
    deleteQrCode,
    {
      data: deleteQrCodeData,
      isLoading: deleteQrCodeIsLoading,
      isError: deleteQrCodeIsError,
      error: deleteQrCodeError,
    },
  ] = useDeleteQrCodeByBatchIdMutation();

  const handleDelete = (batchId) => {
    if (window.confirm("are you sure you want to delete ?")) {
      deleteQrCode({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        batchId: batchId,
      });
    }
    return;
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  useEffect(() => {
    toast.dismiss();

    if (deleteQrCodeData) {
      toast.success("Qr Code Batch deleted", { id: "deleteQrCodeSuccess" });
      fetchAllQrCodeFunction("");
    }

    if (deleteQrCodeError) {
      toast.error("Some error occured", { id: "deleteQrCodeError" });
    }
  }, [deleteQrCodeData, deleteQrCodeIsLoading, deleteQrCodeError]);

  const [
    qrLiCsvDataFun,
    {
      data: qrLiCsvData,
      isLoading: qrLiCsvDataIsLoading,
      error: qrLiCsvDataError,
    },
  ] = useFetchQrCodeByGenerationIDMutMutation();

  useEffect(() => {
    if (qrLiCsvDataIsLoading) {
      toast.dismiss();
      toast.loading("loading ...", {
        id: "delete loading ",
      });
    }
    if (qrLiCsvData) {
      toast.dismiss();
    }
  }, [qrLiCsvDataIsLoading, qrLiCsvData]);

  const qrListDownLoad = (event) => {
    qrLiCsvDataFun({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      generationId: event.id,
    });
  };

  const generateCSV = (data) => {
    if (!data || !data.body || !data.body.qr) {
      return "";
    }

    if (data.body.qr.length === 0) {
      return "";
    }

    const header = [
      "Id",
      "Batch Running Code",
      "Unique Code",
      "Scratch Code",
      "Product Code",
      "MRP",
      "Created At",
      "QR Status"

    ];
    const rows = data.body.qr.map((item, index) => [
      index + 1,
      item.batch_running_code,
      item.unique_code,
      item.scratch_code,
      item.product_code,
      item.mrp,
      item.created_at,
      item.qr_status
    ]);

    const csvContent = [header, ...rows]
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");

    return `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
  };

  useEffect(() => {

    if (qrLiCsvData && qrLiCsvData.body) {
      console.log("qrLiCsvData.body", qrLiCsvData.body);
      let qrList2 = [...qrLiCsvData.body.qr];
      let qrList = qrList2.map(x => ({
        ...x,
        unique_code: `http://gverify.me/?${x.unique_code}`
        // unique_code: `${x.unique_code}`
      }));
      const modifiedBody = {
        ...qrLiCsvData.body,
        qr: qrList // qrList is the modified array
      };
      const newData = { body: modifiedBody }

      if (qrLiCsvData?.body?.qr.length > 0) {
        const csvDataUri = generateCSV(newData);

        const downloadLink = document.createElement("a");
        downloadLink.href = csvDataUri;
        downloadLink.download = "qr_data.csv";
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);
      }
    }
  }, [qrLiCsvData]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Product Name",
      cell: (row) => row.product_name,
      sortable: true,
      width: "130px",
    },
    {
      name: "Batch Code",
      cell: (row) => row.batch_code,
      sortable: true,
    },
    {
      name: "Qty",
      cell: (row) => row.qty,
      sortable: true,
      width: "130px",
    },
    {
      name: "Type",
      cell: (row) => (row.type == "2" ? "Enterprise" : "Standard"),
      sortable: true,
      width: "130px",
    },
    {
      name: "From Date",
      cell: (row) => new Date(row.from_date).toLocaleDateString(),
      sortable: true,
      width: "130px",
    },

    {
      name: "To Date",
      cell: (row) => new Date(row.to_date).toLocaleDateString(),
      sortable: true,
      width: "130px",
    },

    {
      name: "Mapped User",
      cell: (row) => row.details && Object.keys(row.details).length > 0 &&  <div className="flex flex-col space-y-0.5 justify-evenly items-start"> 
        <span>Name : {row?.name} </span>
        <span>Mobile: {row?.mobile}</span>
        <span>User Id: {row?.user_id}</span>
      </div> ,
      sortable: true,
      width: "200px",
    },
    {
      name: "Created By",
      cell: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() =>
              navigate("batch", {
                state: row,
              })
            }
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >
            View
          </button>
        </div>
      ),
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() => qrListDownLoad(row)}
            className="flex justify-center items-center rounded bg-primary px-2 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >
            Download csv
          </button>
        </div>
      ),
      minWidth: "120px",
    },
    {
      name: "",
      cell: (row) =>
        row.product_name === "Mixed Imported Qrs" && (
          <div>
            <button
              onClick={() =>
                navigate("mapping", {
                  state: row,
                })
              }
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            >
              Mapping
            </button>
          </div>
        ),
      minWidth: "100px",
    },

    // {
    //   name: "",
    //   cell: (row) => (
    //     <div>
    //       <button
    //         onClick={() => handleDelete(row.id)}
    //         className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
    //       >
    //         <AiOutlineDelete className="mr-1" />
    //         Delete
    //       </button>
    //     </div>
    //   ),
    //   minWidth: "120px",
    // },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Qr Code Print
            <LinksComponent />
          </div>

          <div className="w-full ">
            <div className="p-3">
              <div className=" bg-white shadow rounded-lg">
                <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                  <div className="flex  space-x-2 justify-between">
                    <div className="w-52 h-10">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          Batch Code
                        </label>
                        <input
                          type="search"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="Batch Code..."
                          required
                          value={request.batch_code}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, batch_code: newValue };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-52 h-10">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          Product Name
                        </label>
                        <input
                          type="search"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="Product Name..."
                          required
                          value={request.product_name}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, product_name: newValue };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Product Code
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Code ..."
                        required
                        value={request.product_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_code: newValue };
                          });
                        }}
                      />
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="flex flex-row gap-2  w-56 h-10">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          &nbsp;
                        </label>
                        <button
                          className="btn btn-outline-primary clear-both float-right pdbtn_all "
                          onClick={() => {
                            fetchAllQrCodeFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          &nbsp;
                        </label>
                        <button
                          className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <DataTableComponent
              keyField={(row) => row.SNo}
                  columns={columns}
                  data={myList}
                  // loading={loading}
                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrPrint;
