import React from "react";
import DataTable from "react-data-table-component";
import noDataImg from "../../../assests/tenant/images/nodata.png";

const DataTableComponent = ({
  columns,
  title,
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  paginationResetDefaultPage=false,
  children,
  selectableRows = false,
  onSelectedRowsChange,
  keyField
}) => {
  return (
    <div className="flex_bt mb-2">
      {children}
      <div>
        {data?.length > 0 ? (
          <DataTable
          keyField={keyField}
            columns={columns}
            title={title}
            data={data}
            responsive
            striped
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight={"500px"}
            progressPending={loading}  // ✅ Fixed loading issue
            pagination
            paginationServer
            paginationTotalRows={totalRows || 0} // ✅ Ensure proper total count
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            selectableRows={selectableRows}
            onSelectedRowsChange={onSelectedRowsChange}
            paginationResetDefaultPage={paginationResetDefaultPage}
          />
        ) : (
          <div className="flex flex-col justify-center items-center py-2">
            <div className="aspect-h-1 aspect-w-1 w-96 overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={noDataImg}
                alt="no-data"
                className="h-full w-full object-cover object-center "
              />
            </div>
            <h2 className="text-lg text-center w-full font-semibold">
              No records to display.
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataTableComponent;