import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { useFetchAllOtpsMutation } from "../../../features/tenant/appOtps/appOtpsApi";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import CustomExport from "../../../components/CustomExport/CustomExport";
import {
  useGetAllMappingDetailsCountMutation,
  useGetAllMappingDetailsMutation,
} from "../../../features/tenant/userMapping/userMappingApi";

const UserMapping = () => {
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [allMappingData,setAllMappingData]=useState([]);
  const [allMappingDataCount,setAllMappingDataCount]=useState(0);
  
  const initialRequest = {
    mapped_app_user_mobile: "",
    app_user_mobile: "",
    limit: 10,
    offset: 0,
    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (
      request.mapped_app_user_mobile === "" &&
      request.app_user_mobile === ""
    ) {
      fetchuserMappingFunction();
    }
  }, [request]);
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const [
    userMapping,
    {
      data: userMappingData,
      error: userMappingError,
      isLoading: userMappingIsLoading,
    },
  ] = useGetAllMappingDetailsMutation();

  const [
    userMappingCount,
    {
      data: userMappingCountData,
      error: userMappingCountError,
      isLoading: userMappingCountIsLoading,
    },
  ] = useGetAllMappingDetailsCountMutation();
  // useGetAllMappingDetailsCountMutation

  useEffect(()=>{

    if(userMappingData?.body?.appUsersList)
    {
      setAllMappingData(userMappingData?.body?.appUsersList)
    }
  },[userMappingData])

  useEffect(()=>{
    if(userMappingCountData)
    {
      setAllMappingDataCount(userMappingCountData?.body?.total)
    }

  },[userMappingCountData])

  const [
    userMappingCsv,
    { data: userMappingCsvData, error: userMappingCsvError, isLoading: userMappingCsvIsLoading },
  ] = useGetAllMappingDetailsMutation();

  useEffect(() => {
    // const hasViewPermission = hasPermission("brandList");
    // if (!hasViewPermission) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }
    fetchuserMappingFunction();
    fetchuserMappingCountFunction();
    // userMappingCsvFunction();
  }, [limit, offset, request]);


  const fetchuserMappingFunction = () => {
    userMapping({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchuserMappingCountFunction = () => {
    userMappingCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchuserMappingCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    userMappingCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (userMappingCsvData && userMappingCsvData?.body?.appUsersList) {
      setCsvData(userMappingCsvData?.body?.appUsersList);
    }

    if (userMappingCsvError) {
      setCsvData(null);
    }
  }, [userMappingCsvData, userMappingCsvError]);

  const columns = [
    {
      name: "S.No",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.app_user_name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.app_user_mobile,
      sortable: true,
    },
    {
      name: "Tagged Person Name",
      cell: (row) => row.mapped_app_user_name,
      sortable: true,
    },
    {
      name: "Tagged Person Mobile",
      cell: (row) => row.mapped_app_user_mobile,
      sortable: true,
    },
    {
      name: "Tagged Person Status",
      cell: (row) => (row.is_scanned ? "true" : "false"),
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            All Users Mapping
            <LinksComponent />
          </div>

          <div className="p-3">
            {/* {otpsCsvData && otpsCsvData?.body?.otp && (
              <ExportCSV
                data={otpsCsvData && otpsCsvData?.body?.otp}
                columnsToExport={[
                  "name",
                  "mobile",
                  "otp",
                  "otp_type",
                  "otp_created_at",
                ]}
              />
            )} */}

            <div className="mb-2">
              <CustomExport
                fetchData={fetchuserMappingCsvFunction}
                data={csvData}
                defaultColumnExport={[
                  "app_user_name",
                  "app_user_mobile",
                  "mapped_app_user_name",
                  "mapped_app_user_mobile",
                  "created_at",
                ]}
                filename="mapping"
              />
            </div>

            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 justify-end">
                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Parent Mobile
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Parent Mobile No..."
                      required
                      value={request.app_user_mobile}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, app_user_mobile: newValue };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Child Mobile
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Child Mobile"
                      required
                      value={request.mapped_app_user_mobile}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            mapped_app_user_mobile: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52 ">
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      From Date
                    </label>
                    <input
                      type="date"
                      id="default-search"
                      className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                      placeholder="Start Date"
                      value={request.startDate}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            startDate: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-52 ">
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      End Date
                    </label>
                    <input
                      type="date"
                      id="default-search"
                      className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                      placeholder="End Date"
                      value={request.endDate}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            endDate: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row  gap-2 h-10">
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>
                    <button
                      className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                      onClick={() => {
                        fetchuserMappingFunction();
                        // fetchOtpsCsvFunction();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>
                    <button
                      className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={allMappingData}
                loading={userMappingIsLoading}
                totalRows={allMappingDataCount}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                striped
                highlightOnHover
                pointerOnHover
                pagination
              ></DataTableComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserMapping;
