import React, { useEffect, useMemo, useState } from "react";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import { useUserPointHistoryForJqrMutation } from "../../../features/tenant/JqrSportsApi/jqrSportsApi";

import toast from "react-hot-toast";
import { IoLocationSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import CustomExport from "../../../components/CustomExport/CustomExport";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useGetAllRemovedAppUserMutation } from "../../../features/tenant/appUsers/appUsersApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

function objectsAreEqual(obj1, obj2) {
  console.log("ob1", obj1);
  console.log("ob2", obj2);
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}


const RemovedUsers = () => {
  const zoneId = getLoginUserData().zone_id;
  const [
    fetchRemovedAppUsers,
    {
      data: RemovedAppUsersData,
      error: RemovedAppUsersError,
      isLoading: RemovedAppUsersIsLoading,
    },
  ] = useGetAllRemovedAppUserMutation();

  const [
    fetchRemovedAppUsersCsv,
    { data: RemovedAppUsersCsvData, error: RemovedAppUsersCsvError },
  ] = useGetAllRemovedAppUserMutation();


  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const initialRequest = {
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate:"",
  };


  const [request, setRequest] = useState(initialRequest);

  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  const navigate = useNavigate();

  useEffect(() => {

    const hasViewPermission = hasPermission("removedUsers");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    if (
      request.mobile === "" &&
      request.startDate === "" &&
      request.endDate === currentDate
    ) {
      fetchRemovedAppUsersFunction();
      // fetchCsvRemovedAppUsersFunction();
    }
  }, [request]);

  // useEffect(() => {
  //   if (limit != 10 || offset != 0) fetchRemovedAppUsersFunction();
  //   // fetchCsvRemovedAppUsersFunction();
  // }, [limit, offset]);

  const fetchRemovedAppUsersFunction = () => {
    fetchRemovedAppUsers({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchCsvPointsScanHistoryFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchRemovedAppUsersCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (RemovedAppUsersCsvData && RemovedAppUsersCsvData.body.data) {
      // console.log("RemovedAppUsersCsvData in", RemovedAppUsersCsvData.body.data);
      setCsvData(RemovedAppUsersCsvData.body.data);
    }

    if (RemovedAppUsersCsvError) {
      setCsvData(null);
    }
  }, [RemovedAppUsersCsvData, RemovedAppUsersCsvError]);

  useEffect(() => {
    if (RemovedAppUsersData && RemovedAppUsersData.body) {
      // console.log("success", RemovedAppUsersData);
      setMyList(RemovedAppUsersData.body.data ? RemovedAppUsersData.body.data : []  );
      setTotalRows(RemovedAppUsersData.body.count ? RemovedAppUsersData.body.count : 0);
    }
  }, [RemovedAppUsersData, RemovedAppUsersError]);


  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const [showModal, setShowModal] = useState(false);

  const [modalData, setModalData] = useState(null)

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Phone",
      cell: (row) => row.mobile,
      sortable: true,
      // width: "120px",
    },
   
    {
      name: "Remarks",
      cell: (row) => row.remarks,
      sortable: true,
      // width: "120px",
    },
   
    {
      name: "Removed At",
      cell: (row) => new Date(row.created_at).toLocaleDateString() + " " + new Date(row.created_at).toLocaleTimeString(),
      sortable: true,
      // width: "150px",
    },
    // {
    //   name: "",
    //   cell: (row) => (
    //     <button
    //       className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
    //       onClick={() => handleShowDetails(row)}
    //     >
    //       View
    //     </button>
    //   ),
    //   sortable: true,
    //   minWidth: "100px",
    // },
  ];


  const handleShowDetails = (data) => {
    console.log("data", data);
    setModalData(data);
    setShowModal(true);
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Removed Users
            <LinksComponent />
          </div>

          <div className="p-3">
            {/* {pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data && (
              <ExportCSV
                data={
                  pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data
                }
                columnsToExport={[
                  "name",
                  "mobile",
                  "user_type",
                  "points",
                  "product_name",
                  "product_code",
                  "qr_id",
                  "batch_running_code",
                  "method",
                ]}
              />
            )} */}

            <div className="mb-2">
              <CustomExport
                fetchData={fetchCsvPointsScanHistoryFunction}
                data={csvData}
                defaultColumnExport={[
                  "mobile",                 
                  "remarks",
                ]}
                filename="removed_users"
              />
            </div>

            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b border-gray-300 bg-gray-100 rounded-t-lg py-3 px-3 w-full">
                <div className="flex space-x-1 justify-evenly  w-full max-w-full">
                
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile ..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                

                  <div className="w-32 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-44 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-56 gap-2 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>

                      <button
                        className="btn btn-outline-primary clear-both float-right  p-2 px-1.5"
                        onClick={() => {
                          fetchRemovedAppUsersFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>
                      <button
                        className="btn btn-outline-primary clear-both float-right  whitespace-nowrap p-2 px-1.5"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                {RemovedAppUsersIsLoading && <CircleLoaderComponent />}
                <DataTableComponent
              keyField={(row) => row.SNo}
                  columns={columns}
                  // title={""}
                  data={
                    myList
                  }
                  // loading={loading}
                  totalRows={
                    totalRows
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                >
                  <div className="flex m-2 space-x-2 justify-end"></div>
                </DataTableComponent>
              </div>
            </div>
          </div>


          {showModal && (
          <ModalComponent
            size={"2xl"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
              <div className="mx-auto max-w-2xl px-2 py-2 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only"> Details </h2>

                <div className="w-full">
                  {JSON.stringify(modalData)}
                </div>
              </div>
            </div>
          </ModalComponent>
        )}
        </div>
      </div>
    </>
  );
};

export default RemovedUsers;
