import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { toast } from "react-hot-toast";
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { hasPermission } from "../../../utils/hasPermission";
import {
  useAcceptCouponsRequestsMutation,
  useGetAllCouponsRequestsMutation,
  useGetTokenMutation,
  useRejectCouponsRequestsMutation,
  useCheckCouponStatusMutation,
  useUpdateCouponStatusMutation,
} from "../../../features/tenant/OzoneAPI/ozoneApi";
import { getVendorName } from "../../../utils/getVendorName";
import CustomExport from "../../../components/CustomExport/CustomExport";

const DynamicCouponRequestList = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [checkStatusModal, setCheckStatusModal] = useState(false);

  const acceptCouponRequestPermission = hasPermission("acceptRequest");
  const rejectCouponRequestPermission = hasPermission("rejectRequest");

  const [selectedCouponRequest, setSelectedCouponRequest] = useState(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [totalRows, setTotalRows] = useState(0);
  const [totalData, setTotalData] = useState([]);

  const [prevPage, setPrevPage] = useState(0);

  const currentDate = new Date().toISOString().split("T")[0];

  const initialRequest = {
    ref_no: "",
    limit: 10,
    offset: 0,
    mobile: "",
    name: "",
    start_date: "",
    end_date:"",
  };
  const [request, setRequest] = useState(initialRequest);

  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const [
    getallCouponRequestList,
    { data: couponRequestListData, error: couponRequestListError, isLoading: couponRequestListIsLoading },
  ] = useGetAllCouponsRequestsMutation();

  const [
    getallCouponRequestListCsv,
    { data: couponRequestListCsvData, error: couponRequestListCsvError },
  ] = useGetAllCouponsRequestsMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("ozoneCouponRequestList");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchCouponRequestListFunction();
  }, [limit, offset]);

  useEffect(() => {
    // console.log("request", request);
    if (
      request.mobile === "" &&
      request.name === "" &&
      request.ref_no === "" &&
      request.start_date === "" &&
      request.end_date === ""
    ) {
      fetchCouponRequestListFunction();
    }
  }, [request]);

  const fetchCouponRequestListFunction = () => {
    getallCouponRequestList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request
    });
  };

  const fetchCouponRequestListCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    getallCouponRequestListCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (couponRequestListCsvData && couponRequestListCsvData?.body?.data) {
      setCsvData(couponRequestListCsvData.body.data);
    }

    if (couponRequestListCsvError) {
      setCsvData(null);
    }
  }, [couponRequestListCsvData, couponRequestListCsvError])

  const [
    rejectCouponRequest,
    { data: rejectCouponRequestData, error: rejectCouponRequestError },
  ] = useRejectCouponsRequestsMutation();

  const [
    acceptCouponRequest,
    { data: acceptCouponRequestData, error: acceptCouponRequestError },
  ] = useAcceptCouponsRequestsMutation();

  const [
    checkCouponStatus,
    {
      data: couponStatusData,
      isLoading: couponStatusIsLoading,
      isError: couponStatusIsError,
      error: couponStatusError,
    },
  ] = useCheckCouponStatusMutation();


  const [
    updateCouponRequestStatus,
    { data: updateCouponRequestStatusData, error: updateCouponRequestStatusError },
  ] = useUpdateCouponStatusMutation();


  useEffect(() => {
    if (couponRequestListData && couponRequestListData.body) {
      // console.log("couponRequestListData", couponRequestListData);
      setTotalData(couponRequestListData.body?.data ? couponRequestListData.body.data : []);
      setTotalRows(couponRequestListData.body?.total ? couponRequestListData.body.total : 0)
    }
    if (couponRequestListError) {
      toast.error("Some Error Occured", {
        id: "brandAddError",
      });
    }
  }, [couponRequestListData, couponRequestListError]);

  const handleReject = (id) => {

    let remarks = window.prompt("Enter Remarks..");
    remarks = remarks && remarks.trim();
    console.log("remarks", remarks?.length);
    // return;
    if (remarks == null) {
      return;
    } else if (remarks?.length == 0) {
      return;
    }
    else {
      rejectCouponRequest({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: id,
        body : {
          remarks : remarks
        }
      });
    }
    return;
  };

  useEffect(() => {
    if (rejectCouponRequestData) {
      toast.success("Request rejected", {
        id: "rejectCouponRequestData",
      });
      setShowModal(false);
      setSelectedCouponRequest(null);
      fetchCouponRequestListFunction();
      setCheckStatusModal(false);
      return;
    }

    if (rejectCouponRequestError) {
      toast.error("Something went wrong", {
        id: "rejectCouponRequestError",
      });
      return;
    }
  }, [rejectCouponRequestData, rejectCouponRequestError]);

  const handleAccept = (id) => {

    let remarks = window.prompt("Enter Remarks..");
    remarks = remarks && remarks.trim();
    console.log("remarks", remarks?.length);
    // return;
    if (remarks == null) {
      return;
    } else if (remarks?.length == 0) {
      return;
    }
    else {
      acceptCouponRequest({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: id,
        body: {
          remarks: remarks
        }
      });

    }
    return;
  };

  useEffect(() => {
    if (acceptCouponRequestData) {
      toast.success("Request Accepted", {
        id: "acceptCouponRequestData",
      });
      setShowModal(false);
      setSelectedCouponRequest(null);
      fetchCouponRequestListFunction();
      return;
    }

    if (acceptCouponRequestError) {
      toast.error(`${acceptCouponRequestError.data?.message}`, {
        id: "acceptCouponRequestError",
      });
      return;
    }
  }, [acceptCouponRequestData, acceptCouponRequestError]);

  const handleShowModal = (data) => {
    console.log("data", data);
    setSelectedCouponRequest(data);
    setShowModal(true);
  };

  const handleShowCheckStatusModal = (data) => {
    // console.log("handleShowCheckStatusModal", data);
    setSelectedCouponRequest(data)
    checkCouponStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      order_id: data.ref_no,
    });
    setCheckStatusModal(true);
  };

  useEffect(() => {

    if (checkStatusModal == false) {
      setSelectedCouponRequest(null);
    }
  }, [checkStatusModal]);


  useEffect(() => {
    if (couponStatusData) {
      console.log("couponStatusData", couponStatusData);
    }

    if (couponStatusError) {
      console.log("couponStatusError", couponStatusError);
    }
  }, [couponStatusData, couponStatusError]);

  const [getAuthToken, { data: authTokenData, error: authTokenError }] =
    useGetTokenMutation();

  useEffect(() => {
    toast.dismiss();
    if (authTokenData) {
      console.log("authTokenData", authTokenData);
      toast.success(authTokenData.message, {
        id: "authTokenSuccess",
      });
      fetchCouponRequestListFunction();
    }

    if (authTokenError) {
      console.log("authTokenError", authTokenError);
      toast.error("Something went wrong", { id: "authTokenError" });
    }
  }, [authTokenData, authTokenError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Email",
      cell: (row) => row.email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Ref No",
      cell: (row) => row.ref_no,
      sortable: true,
      width: "200px",
    },

    {
      name: "Coupon Brand Code",
      cell: (row) => row.brand_product_code,
      sortable: true,
      width: "200px",
    },
    {
      name: "Brand Name ",
      cell: (row) => row.brand_name,
      sortable: true,
    },
    {
      name: "Coupon Value",
      cell: (row) => row.coupon_value,
      sortable: true,
    },
    {
      name: "Denomination",
      cell: (row) => row.denomination,
      sortable: true,
    },
    {
      name: "Remarks ",
      cell: (row) => row.remarks,
      sortable: true,
    },
    {
      name: "Sent on",
      cell: (row) => row.sent_on && new Date(row.sent_on).toLocaleString(),
      sortable: true,
    },
    {
      name: "Coupon Status",
      cell: (row) => (
        <span
          className={`font-bold flex justify-center items-center ${getApprovalStatusColor(
            row,
          )}`}
        >
          {row.approval_status === "1" && "Pending"}
          {row.approval_status === "2" && "Accepted"}
          {row.approval_status === "3" && "Rejected"}
          {row.approval_status === "4" && (
            <button
              onClick={() => handleShowCheckStatusModal(row)}
              className="bg-green-500 rounded  px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal flex justify-center items-center text-white hover:bg-green-600 focus:outline-none focus:ring focus:border-green-400"
            >
              Status
            </button>
          )}
        </span>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleShowModal(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiFillEye className="mr-1" />
            View
          </button>
        </div>
      ),
      minWidth: "100px",
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const handleChangeStatus = (data, approval_status) => {

    // console.log("handleChangeStatus", data, approval_status);
    let remarks = window.prompt("Enter Remarks..");
    remarks = remarks && remarks.trim();
    console.log("remarks", remarks?.length);
    // return;
    if (remarks == null) {
      return;
    } else if (remarks?.length == 0) {
      return;
    }
    // return;
    updateCouponRequestStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: data.id,
      body: {
        approval_status: approval_status,
        remarks : remarks
      },
    })
  }

  useEffect(() => {
    if (updateCouponRequestStatusData) {
      console.log("updateCouponRequestStatusData", updateCouponRequestStatusData)
      setCheckStatusModal(false);
      fetchCouponRequestListFunction();

    }

    if (updateCouponRequestStatusError) {
      console.log("updateCouponRequestStatusError", updateCouponRequestStatusError)
      setCheckStatusModal(false);
      fetchCouponRequestListFunction();
    }
  }, [updateCouponRequestStatusData, updateCouponRequestStatusError])


  // console.log("selectedCOuponRequest", selectedCouponRequest);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Coupon Request List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div class="flex justify-between">
              <div className="flex gap-2 mb-2">
                {/* <div className="mb-2"> */}
                <CustomExport fetchData={fetchCouponRequestListCsvFunction} data={csvData} defaultColumnExport={[
                  "name",
                  "mobile",
                  "email",
                  "ref_no",
                  "user_type",
                  "brand_name",
                  "brand_product_code",
                  "coupon_value",
                  "denomination",
                ]}
                  filename="coupon_request_list"
                />
                {/* </div> */}

                <button
                  className="btn btn-outline-primary clear-both float-right "
                  onClick={() => {
                    getAuthToken({
                      token: sessionStorage.getItem("access_token"),
                      tenant_id: JSON.parse(
                        sessionStorage.getItem("vendorData"),
                      ).tenant_id,
                    });
                  }}
                >
                  Refresh Auth Token
                </button>
              </div>
            </div>

            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={totalData}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                  <div className="flex  space-x-2 justify-end">
                    <div className="w-52 h-10 ">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          Mobile
                        </label>
                        <input
                          type="search"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="Mobile..."
                          required
                          value={request.mobile}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, mobile: newValue };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-52 h-10 ">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          Ref No
                        </label>
                        <input
                          type="search"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="Ref No."
                          required
                          value={request.ref_no}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, ref_no: newValue };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-52 h-10 ">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          Name
                        </label>
                        <input
                          type="search"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="Name..."
                          required
                          value={request.name}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, name: newValue };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-32 h-10 ">
                      <div className="relative searchin">
                        <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          Start Date
                        </label>
                        <input
                          type="date"
                          id="default-search"
                          className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="Start Date"
                          value={request.start_date}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return {
                                ...prevState,
                                start_date: newValue,
                              };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-44 h-10 ">
                      <div className="relative searchin">
                        <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          End Date
                        </label>
                        <input
                          type="date"
                          id="default-search"
                          className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="End Date"
                          value={request.end_date}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return {
                                ...prevState,
                                end_date: newValue,
                              };
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row  gap-2 h-10">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          &nbsp;
                        </label>
                        <button
                          className="btn btn-outline-primary clear-both float-right  p-2"
                          onClick={() => {
                            fetchCouponRequestListFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          &nbsp;
                        </label>
                        <button
                          className="btn btn-outline-primary clear-both float-right p-2"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DataTableComponent>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            size={"2xl"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-3">
              <div className="w-full flex justify-center ">
                <div className="bg-white p-8">
                  <h2 className="text-2xl font-bold mb-4">
                    {selectedCouponRequest.brand_name}
                  </h2>
                  <img
                    className="mb-4 rounded-lg w-full h-32 object-cover"
                    src={selectedCouponRequest.brand_image}
                    alt={selectedCouponRequest.brand_product_code}
                  />
                  <p className="mb-4 text-gray-700">
                    {selectedCouponRequest.descriptions}
                  </p>
                  <div className="mb-2">
                    <strong>Name:</strong> {selectedCouponRequest.name}
                  </div>
                  <div className="mb-2">
                    <strong>Email:</strong> {selectedCouponRequest.email}
                  </div>
                  <div className="mb-2">
                    <strong>Mobile:</strong> {selectedCouponRequest.mobile}
                  </div>
                  <div className="mb-4">
                    <strong>Location:</strong> {selectedCouponRequest.city},{" "}
                    {selectedCouponRequest.state}
                  </div>
                  <div className="mb-2">
                    <strong>Category:</strong> {selectedCouponRequest.category}
                  </div>
                  <div className="mb-2">
                    <strong>Redemption Type:</strong>{" "}
                    {selectedCouponRequest.redemption_type}
                  </div>
                  <div className="mb-2">
                    <strong>Approval Status:</strong>{" "}
                    <span
                      className={`font-bold ${getApprovalStatusColor(
                        selectedCouponRequest,
                      )}`}
                    >
                      {selectedCouponRequest.approval_status === "1" &&
                        "Pending"}
                      {selectedCouponRequest.approval_status === "2" &&
                        "Accepted"}
                      {selectedCouponRequest.approval_status === "3" &&
                        "Rejected"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <strong>Coupon Value:</strong>{" "}
                    {selectedCouponRequest.coupon_value}
                  </div>
                  <div className="mb-2">
                    <strong>Denomination:</strong>{" "}
                    {selectedCouponRequest.denomination}
                  </div>
                  <div className="mt-4 overflow-y-auto max-h-40">
                    <strong>Terms & Conditions:</strong>
                    <p
                      className="overflow-y-auto"
                      dangerouslySetInnerHTML={{
                        __html: selectedCouponRequest.tnc,
                      }}
                    ></p>
                  </div>
                  <div className="flex justify-center mt-4 space-x-4">
                    {acceptCouponRequestPermission &&
                      selectedCouponRequest.approval_status == "1" && (
                        <button
                          onClick={() => handleAccept(selectedCouponRequest.id)}
                          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:ring focus:border-green-400"
                        >
                          Accept
                        </button>
                      )}

                    {rejectCouponRequestPermission &&
                      selectedCouponRequest.approval_status == "1" && (
                        <button
                          onClick={() => handleReject(selectedCouponRequest.id)}
                          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:ring focus:border-red-400"
                        >
                          Reject
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </ModalComponent>
        )}

        {checkStatusModal && (
          <ModalComponent
            size={"lg"}
            data={"nadnandand"}
            show={checkStatusModal}
            setShow={setCheckStatusModal}
          >
            <div className="w-full p-3">
              <div className="w-full flex justify-center ">
                {couponStatusIsLoading ? (
                  <> Please wait </>
                ) : couponStatusIsError ? (
                  <>
                    <div className="space-y-4">
                      <p>Message: {couponStatusError?.data?.message}</p>
                      <p>
                        <p>Voucher Status : Not Granted </p>
                      </p>
                      <div className="flex justify-center space-x-4">
                        <button
                          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                          onClick={() => handleAccept(selectedCouponRequest.id)}
                        >
                          Retry
                        </button>
                        <button
                          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                          onClick={() => handleReject(selectedCouponRequest.id)}
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  </>
                ) : (

                  couponStatusData &&
                  <>
                    <div className="space-y-4">
                      <p>Message: {couponStatusData?.message}</p>
                      <p>
                        Reference Number: {couponStatusData?.body?.reference_num}
                      </p>
                      <p>
                        External Order ID:{" "}
                        {couponStatusData?.body?.external_order_id}
                      </p>
                      {couponStatusData?.body?.voucher_details.map(
                        (voucherDetail, index) => (
                          <div key={index}>
                            <p>Product Name: {voucherDetail?.product_name}</p>
                            {/* <p>Voucher Name: {voucherDetail.voucher_name}</p> */}
                            {/* Rendering vouchers */}
                            {voucherDetail?.vouchers.map((voucher, idx) => (
                              <div key={idx}>
                                {/* <p>End Date: {voucher.end_date}</p>
                              <p>Value: {voucher.value}</p> */}
                                <p>Voucher GUID: {voucher?.voucher_guid}</p>
                                <p>Voucher Number: {voucher?.voucher_no}</p>
                                <p>Voucher Status: {voucher?.voucher_status}</p>
                                <p>Email Status: {voucher?.email_status}</p>
                                <p>SMS Status: {voucher?.sms_status}</p>
                                {/* <p>WhatsApp Status: {voucher.whatsapp_status}</p> */}
                              </div>
                            ))}
                            {/* Buttons for accepting and rejecting */}
                            <div className="flex justify-center space-x-4">
                              <button
                                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                                onClick={() => handleChangeStatus(selectedCouponRequest, '2')}
                              >
                                Resolve
                              </button>
                              {/* <button
                                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                                onClick={() => handleReject(voucherDetail)}
                              >
                                Reject
                              </button> */}
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

const getApprovalStatusColor = (data) => {
  switch (data.approval_status) {
    case "1":
      return "text-yellow-500"; // Pending
    case "2":
      return "text-green-500"; // Accepted
    case "3":
      return "text-red-500"; // Rejected
    case "4":
      return "text-slate-600"; // Rejected
    default:
      return "text-gray-500";
  }
};
export default DynamicCouponRequestList;