import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useFetchAllBatchMutation } from "../../../features/tenant/batch/batchApi";
import { useAddQrCodeMutation } from "../../../features/tenant/qrCode/qrCodeApi";
import { LoaderIcon, toast } from "react-hot-toast";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { useFetchProductsMutation } from "../../../features/tenant/products/productsApi";
import { useFetchSubcategoriesMutation } from "../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import { useFetchUserByUserTypeMutation } from "../../../features/tenant/appUsers/appUsersApi";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";
import { useGetLancerAddressApiMutation } from "../../../features/tenant/Lancer/LancerApi";

const QrGeneration = () => {
  const { zone_id: zoneId, zone_name: zoneName } = getLoginUserData();
  const tenantData = JSON.parse(sessionStorage.getItem("vendorData"));
  const [batchList, setbatchList] = useState([]);
  const [lancerAddressList, setLancerAddressList] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState({
    id: -1,
    name: "",
  });

  const [selectedLancerAddress, setSelectedLancerAddress] = useState({
    id: -1,
    name: "",
  });


  const [user, setUser] = useState(null);

  const [needQrMapping, setNeedQrMapping] = useState(false);

  const [
    fetchAppUserByUserType,
    { data: appUsersData, error: appUsersError, isLoading: appUsersIsLoading },
  ] = useFetchUserByUserTypeMutation();

  const [qrType, setQrType] = useState(
    JSON.parse(sessionStorage.getItem("companyProfile")).qr_type
  );

  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    id: -1,
    name: "",
  });

  const [selectedProductCategory, setSelectedProductCategory] = useState({
    id: -1,
    name: "",
  });

  const [qty, setQty] = useState(null);

  const [allUserTypes, setAllUserTypess] = useState(
    JSON.parse(sessionStorage.getItem("appUserType"))
  );
  const [selectedUserType, setSelectedUserType] = useState({
    name: "Select User Type",
    id: -1,
  });

  const [searchQuery, setSearchQuery] = useState(null);

  const [prevPage, setPrevPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];

  const initialRequest = {
    user_type_id: -1,
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate: "",
  };
  const [request, setRequest] = useState(initialRequest);

  const navigate = useNavigate();

  const [fetchBatchList, { data: batchListData, error: batchListError }] =
    useFetchAllBatchMutation();

  const [
    fetchProducts,
    {
      data: productsData,
      isLoading: productsIsLoading,
      isError: productsIsError,
      error: productsError,
    },
  ] = useFetchProductsMutation();

  const [
    fetchLancer,
    {
      data: lancerData,
      isLoading: lancerIsLoading,
      isError: lancerIsError,
      error: lancerError,
    },
  ] = useGetLancerAddressApiMutation();
  const [
    fetchSubCategories,
    {
      data: subCategoriesData,
      isLoading: subCategoriesIsLoading,
      error: subCategoriesError,
    },
  ] = useFetchSubcategoriesMutation();

  useEffect(() => {
    fetchSubCategories({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
    fetchBatchList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
    fetchProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (lancerData?.body?.addresses) {
      const data = lancerData.body.addresses.map(({id, place_name, print_address1, print_address2 }) => ({
        id:id,
        name: place_name,
        address1: print_address1,
        address2: print_address2
      }));
  
      setLancerAddressList(data);
    }
  }, [lancerData]);

  useEffect(() => {
    if(tenantData.tenant_name == "lancer-footwear")
    {
      fetchLancer({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
  }, []);


  useEffect(() => {
    if (productsData) {
      if (productsData?.body?.products) {
        console.log(productsData.body.products);
        setProductList(productsData.body.products);
      }
    }
  }, [productsData]);

  const [
    addQrCode,
    {
      data: QrCodeData,
      isLoading: QrCodeIsLoading,
      isError: QrCodeIsError,
      error: QrCodeError,
    },
  ] = useAddQrCodeMutation();

  useEffect(() => {
    toast.dismiss();

    if (batchListData?.body?.batches) {
      setbatchList(batchListData?.body?.batches);
      console.log(batchListData);
    }
    if (batchListError) {
      console.log("batch list error", batchListError);
    }
  }, [batchListData, batchListError]);

  const handleGenerate = (e) => {
    e.preventDefault();
    toast.dismiss();
    console.log(selectedProduct, "selected product");
    if (selectedProduct.id === -1) {
      toast.error("select product", { id: "select-product" });
      return;
    }

    if (selectedBatch.id === -1) {
      toast.error("select batch", { id: "select-batch" });
      return;
    }

    if (qty <= 0) {
      toast.error("Quantity should be more than 0", { id: "qty-error" });
      return;
    }

    if (qrType == "2" && selectedProduct.qty === 0) {
      toast.error("Product Qty is Invalid", { id: "invalid-product-qty" });
      return;
    }

    if (qrType == "2" && qty > 0 && qty % selectedProduct.qty !== 0) {
      toast.error(
        "Invalid Qr count, count must be in multiples of product quantity",
        { id: "invalid-qty" }
      );
      return;
    }

    if (needQrMapping) {
      if (selectedUserType.id == -1) {
        toast.error("User type not selected", { id: "usertype-error" });
        return;
      }

      if (!user || user == -1) {
        toast.error("No user selected", { id: "user-error" });
        return;
      }
    }

    console.log("user", user);
    const data = {
      batch_id: selectedBatch.batch_id,
      batch_code: selectedBatch.name,
      product_code: selectedProduct.product_code,
      product_name: selectedProduct.name,
      qty: qty,
    };

    if (needQrMapping) {
      data["app_user_id"] = user.id;
      data["mobile"] = user.mobile;
    }

    if(tenantData.tenant_name == "lancer-footwear")
    {
      if(selectedLancerAddress.id==-1)
        {
          toast.error("Please select a address");
          return;
        }
        data["print_address_id"] = selectedLancerAddress.id;
    }
     
   
    // console.log("data", data);

    addQrCode({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: data,
    });
  };

  useEffect(() => {}, [qty]);

  useEffect(() => {
    // console.log("selected subcategory ", selectedProductCategory);
    if (productsData && selectedProductCategory.id !== -1) {
      if (productsData?.body?.products) {
        // console.log(productsData.body.products);
        const arr = productsData.body.products.filter(
          (product) => product.category_id == selectedProductCategory.id
        );
        // console.log(arr);
        setProductList(arr);
        setSelectedProduct({
          id: -1,
          name: "",
        });
      }
    }
  }, [selectedProductCategory]);

  useEffect(() => {
    const id = "qrGenerate";
    if (QrCodeIsLoading) {
      toast.loading("Generating QR", { id: id });
    }

    if (QrCodeData) {
      toast.success("Generated Successfully", {
        id: id,
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/printQr`);
      }, 200);
    }

    if (QrCodeError) {
      toast.error("Some Error Occurred", {
        id: id,
      });
    }
  }, [QrCodeData, QrCodeError, QrCodeIsLoading]);

  const fetchAppUserByUserTypeFunction = (
    userTypeId,
    limit,
    offset,
    searchQuery
  ) => {
    request["limit"] = limit;
    request["offset"] = offset;
    request["user_type_id"] = userTypeId;

    request["mobile"] = searchQuery;

    fetchAppUserByUserType({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      zoneId: zoneId,
    });
  };

  useEffect(() => {
    if (appUsersData && appUsersData.body) {
      console.log("appUsersData", appUsersData);
      if (
        appUsersData?.body?.appUsersList &&
        appUsersData.body.appUsersList.length > 0
      ) {
        setUser(appUsersData.body.appUsersList[0]);
      } else {
        setUser(-1);
      }
    }
  }, [appUsersData]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const searchHandler = () => {
    console.log("clicked");
    if (selectedUserType.id == -1) {
      toast.error("Select user type", { id: "invalid-user-type" });
    }
    if (searchQuery && searchQuery.length != 10) {
      toast.error("mobile Number is not valid", { id: "invalidMobile" });
      return;
    }
    if (searchQuery && searchQuery.length > 3) {
      if (
        selectedUserType &&
        selectedUserType.id != -1 &&
        searchQuery &&
        searchQuery.length > 3
      ) {
        console.log("selectedUserType", selectedUserType);
        fetchAppUserByUserTypeFunction(
          selectedUserType.user_type_id,
          1,
          0,
          searchQuery
        );
      }
    }
  };

  return (
    <>
      {productsIsLoading && <CircleLoaderComponent />}

      {subCategoriesIsLoading && <CircleLoaderComponent />}

      <>
        <div className="flex w-full bg-gray-200 h-full justify-center">
          <div className="w-full ">
            <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
              Qr Code Generation
              <LinksComponent />
            </div>
            <div className="p-3">
              <div className=" p-3 w-full bg-white shadow rounded-lg">
                <form className="">
                  <div className="w-full flex justify-center space-x-4">
                    {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}
                    <div className="mb-6 bottom-2 w-1/4">
                      <SelectComponentWithSearch
                        title={"Category"}
                        selected={selectedProductCategory}
                        setSelected={setSelectedProductCategory}
                        dataArr={
                          subCategoriesData ? subCategoriesData?.body : []
                        }
                        required={true}
                        placeholder={"Select Category"}
                      />
                    </div>
                    <div className="mb-6 bottom-2 w-1/4">
                      <SelectComponentWithSearch
                        title={"Product"}
                        selected={selectedProduct}
                        setSelected={setSelectedProduct}
                        dataArr={productList}
                        required={true}
                        placeholder={"Select Product"}
                      />
                    </div>
                    <div className="mb-6 bottom-2 w-1/4">
                      <SelectComponentWithSearch
                        title={"Batch"}
                        selected={selectedBatch}
                        setSelected={setSelectedBatch}
                        dataArr={batchList}
                        required={true}
                        placeholder={"Select Batch"}
                      />
                    </div>

                    {tenantData.tenant_name == "lancer-footwear" && (
                      <div className="mb-6 bottom-2 w-1/4">
                        <SelectComponentWithSearch
                          title={"Address"}
                          selected={selectedLancerAddress}
                          setSelected={setSelectedLancerAddress}
                          dataArr={lancerAddressList}
                          required={true}
                          placeholder={"Select Batch"}
                        />
                      </div>
                    )}
                    <div className="mb-6 bottom-2 w-1/4">
                      <InputComponent
                        name={"Quantity"}
                        type={"number"}
                        value={qty}
                        setValue={setQty}
                        required={true}
                        placeholder={"Enter Quantity"}
                      />
                    </div>
                  </div>

                  <div className="w-full mb-4">
                    <div className=" flex justify-start items-center">
                      <input
                        type="checkbox"
                        id="needQrMapping"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5 p-2.5  "
                        value={needQrMapping}
                        onChange={(e) => setNeedQrMapping(!needQrMapping)}
                      />
                      <span className="font-bold"> Need Qr Mapping</span>
                    </div>
                  </div>

                  {needQrMapping && (
                    <div className="w-full flex justify-start space-x-4">
                      <div className=" bottom-2 w-1/4">
                        <SelectComponentWithSearch
                          title={"Select User Type"}
                          selected={selectedUserType}
                          setSelected={setSelectedUserType}
                          dataArr={allUserTypes ? allUserTypes : []}
                          placeholder={"Select User Type"}
                        />
                      </div>

                      <div className=" bottom-2 w-1/4">
                        <>
                          <div className="w-full relative">
                            <label
                              htmlFor="Enter Mobile"
                              className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-0"
                            >
                              Enter Mobile
                            </label>
                            <input
                              type="text"
                              id="Enter Mobile"
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-12" // Add padding-right for the button
                              placeholder="Enter Mobile"
                              required={true}
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button
                              type="button"
                              className="absolute -mt-5 right-1 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-600 text-white p-1.5 rounded-md"
                              onClick={() => searchHandler()}
                            >
                              Search
                            </button>
                          </div>
                        </>
                      </div>

                      <div className="mt-6 w-1/4 bg-gray-50 hover:bg-gray-200 rounded-lg flex px-2">
                        {appUsersIsLoading && (
                          <LoaderIcon className="w-5 h-5 mt-2.5" />
                        )}
                        {user ? (
                          user != -1 ? (
                            // user.map((item) => (
                            <div className="w-full flex items-center space-x-2 ">
                              <div className="rounded-full w-6 h-6 border border-gray-300 flex items-center justify-center">
                                {true && (
                                  <div className="bg-blue-500 w-3 h-3 rounded-full"></div>
                                )}
                              </div>
                              <span className="text-gray-800 flex justify-center items-center font-medium capitalize">
                                {user.name}
                              </span>
                            </div>
                          ) : (
                            // ))
                            <h3 className="text-sm flex max-w-xs justify-start items-center font-semibold text-gray-500 mb-2 text-center">
                              <svg
                                class="h-8 w-8 m-auto text-gray-300 text-center"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                {" "}
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                <circle cx="12" cy="7" r="4" />
                              </svg>
                              User Not Found
                            </h3>
                          )
                        ) : (
                          <> </>
                        )}
                      </div>
                    </div>
                  )}
                  {!QrCodeIsLoading && (
                    <div className="flex justify-end mt-2">
                      <button
                        // type="submit"
                        onClick={(e) => handleGenerate(e)}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      >
                        Generate
                      </button>
                    </div>
                  )}
                </form>
              </div>
              <div>
                {selectedProduct.id === -1 ? (
                  <div className="w-full text-center"> N/A</div>
                ) : (
                  <div className="max-w-3xl my-2 mx-auto flex bg-white  rounded overflow-hidden shadow-lg">
                    <div className="p-0">
                      {/* {JSON.stringify(selectedProduct)} */}
                      {selectedProduct.images && (
                        <img
                          src={
                            selectedProduct.images.length > 0 &&
                            selectedProduct.images[0]
                          }
                          alt={selectedProduct?.name}
                          className="w-full h-24 object-cover"
                        />
                      )}
                      <div className="px-4 py-2">
                        <div className="font-bold text-xl mb-2">
                          {selectedProduct?.name}
                        </div>
                        <p className="text-gray-700 text-base">
                          {selectedProduct?.description}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="px-4 py-2">
                        <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2">
                          {selectedProduct?.category_name}
                        </span>
                        <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700">
                          {selectedProduct?.brand_name}
                        </span>
                      </div>
                      <div className="px-4 py-2">
                        <span className="inline-block capitalize bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 mr-2">
                          {selectedProduct?.unit_name} (
                          {selectedProduct?.uom_value}{" "}
                          {selectedProduct?.uom_key})
                        </span>
                        <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700">
                          Qty : {selectedProduct?.qty}
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="px-4 py-2">
                        <p className="text-gray-700 text-sm">
                          Product Code: {selectedProduct?.product_code}
                        </p>
                        <p className="text-gray-700 text-sm">
                          Status:{" "}
                          {selectedProduct?.status === "1"
                            ? "Active"
                            : "Inactive"}
                        </p>
                        <p className="text-gray-700 text-sm">
                          Points Active:{" "}
                          {selectedProduct?.points_active === "1"
                            ? "Yes"
                            : "No"}
                        </p>
                        <p className="text-gray-700 text-sm">
                          Warranty Activation Period:{" "}
                          {selectedProduct?.warranty_activation_period} days
                        </p>
                      </div>
                      <div className="px-4 py-2">
                        <p className="text-gray-700 text-sm">
                          Created by: {selectedProduct?.created_by_name} on{" "}
                          {new Date(
                            selectedProduct?.created_at
                          ).toLocaleDateString()}
                        </p>
                        <p className="text-gray-700 text-sm">
                          Last updated by: {selectedProduct?.updated_by_name} on{" "}
                          {new Date(
                            selectedProduct?.updated_at
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default QrGeneration;
