import { rootApi } from "../../api/rootApi";

export const reassignWarrantyApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllReassignWarranty: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/reassign-warranty`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),

    fetchAllReassignWarrantyCount: builder.mutation({
        query: (params) => {
          return {
            method: "POST",
            url: `/tenant/reassign-warranty/count`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
            body:params.body
          };
        },
      }),

  }),
});

export const { useFetchAllReassignWarrantyMutation,useFetchAllReassignWarrantyCountMutation, } =
reassignWarrantyApi;
