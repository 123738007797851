import React, { useEffect, useState } from "react";
import {
  useAddHeirarchyMutation,
  useUpdateHeirarchyByIdMutation,
  useDeletedHeirarchyByIdMutation,
} from "../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { toast } from "react-hot-toast";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import InputComponent from "../InputComponent/InputComponent";
import ModalComponent from "../ModalComponent/ModalComponent";
import { hasPermission } from "../../../utils/hasPermission";

const Node = ({ node, onDelete, onAddNode, handleShowModal }) => {
  const addProductHeirarchy = hasPermission("addProductHierarchy");
  const deleteProductHeirarchy = hasPermission("deleteProductHierarchy");

  return (
    <div className="ml-5 mt-2 py-1">
      <div className="flex w-full justify-between items-center border-l-2 border-black ">
      <span className="inline-flex pl-2">{node.id}.</span>
        <span className="w-1/2 pl-2">{node.name}</span>

        <div className="w-1/2 flex justify-end ">
          {node.master_id === 0
            ? ""
            : deleteProductHeirarchy && (
                <button
                  // className="px-2 py-1 mr-1 border border-gray-400"
                  onClick={() => onDelete(node)}
                  className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] mr-1"
                >
                  <AiOutlineDelete className="mr-1" />
                  Delete
                </button>
              )}
          {addProductHeirarchy && (
            <button
              onClick={() => handleShowModal(node.id)}
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] mr-1"
            >
              <AiOutlineEdit className="mr-1" />
              Add
            </button>
          )}
        </div>
      </div>

      {node.children && node.children.length > 0 && (
        <ul>
          {node.children.map((childNode) => (
            <li key={childNode.id}>
              <Node
                node={childNode}
                onDelete={onDelete}
                onAddNode={onAddNode}
                handleShowModal={handleShowModal}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const convertToTree = (data) => {
  const tree = {};

  data.forEach((item) => {
    const { id, name, master_id, is_master } = item;
    if (!tree[id]) {
      tree[id] = { id, name, children: [], is_master, master_id };
    } else {
      tree[id].name = name;
      tree[id].is_master = is_master;
      tree[id].master_id = master_id;
    }

    if (master_id === 0) {
      return;
    }

    if (!tree[master_id]) {
      tree[master_id] = {
        id: master_id,
        children: [tree[id]],
        is_master: true,
        master_id: master_id,
      };
    } else {
      tree[master_id].children.push(tree[id]);
      tree[master_id].is_master = true;
    }
  });

  return Object.values(tree).filter((node) => node.id !== 0);
};

const HeirarchyComponent = ({ heirarchyData, fetchHeirarchy }) => {
  const [data, setData] = useState([]);
  const treeData = convertToTree(data);
  // console.log("In Heirarchy Component ", treeData, heirarchyData);

  const [
    addHeirarchy,
    {
      data: addHeirarchyData,
      isLoading: addHeirarchyIsLoading,
      isError: addHeirarchyIsError,
      error: addHeirarchyError,
    },
  ] = useAddHeirarchyMutation();

  const [
    updateHeirarchy,
    {
      data: updateHeirarchyData,
      isLoading: updateHeirarchyIsLoading,
      isError: updateHeirarchyIsError,
      error: updateHeirarchyError,
    },
  ] = useUpdateHeirarchyByIdMutation();

  const [
    deleteHeirarchy,
    {
      data: deleteHeirarchyData,
      isLoading: deleteHeirarchyIsLoading,
      isError: deleteHeirarchyIsError,
      error: deleteHeirarchyError,
    },
  ] = useDeletedHeirarchyByIdMutation();

  useEffect(() => {
    if (heirarchyData) {
      // console.log("hereeee", heirarchyData.length);
      // if (heirarchyData.length === 0) {
      //   addRoot();
      // }
      setData([...heirarchyData]);
    }
  }, [heirarchyData]);
  const [showModal, setShowModal] = useState(false);
  const [nodeName, setNodeName] = useState(null);
  const [modalData, setModalData] = useState(null);

  const handleShowModal = (parentId) => {
    console.log("Parent ID", parentId);
    setShowModal(true);
    setModalData(parentId);
  };

  const handleAddNode = (parentId) => {
    // const name = prompt("Enter name");
    if (!nodeName) {
      toast.error("Please enter name" , {id : "noNameNode"});
      return;
    }
    const newNodeId = Math.max(...data.map((item) => item.id)) + 1;
    const newNode = {
      id: newNodeId,
      name: `${nodeName}`,
      master_id: parentId,
      is_sub: 1,
      is_master: 0,
    };
    setData([...data, newNode]);

    const body = {
      name: newNode.name,
      is_sub: `${newNode.is_sub}`,
      master_id: `${newNode.master_id}`,
      is_master: `${newNode.is_master}`,
    };

    addHeirarchy({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: body,
    });

    updateHeirarchy({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: newNode.master_id,
      body: {
        is_master: "1",
      },
    });

    setNodeName(null);

    // fetchHeirarchy({
    //   token: sessionStorage.getItem("access_token"),
    //   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    // });
  };

  useEffect(() => {
    toast.dismiss();
    if (addHeirarchyData) {
      console.log("add Heirarchy data", addHeirarchyData);
      toast.success("Added Successfully", { id: "add_heirarchy_success" });
      fetchHeirarchy({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
      setShowModal(false);
      setNodeName(null);
    }

    if (addHeirarchyError) {
      toast.error("Something went wrong", { id: "add_heirarchy_success" });

      // console.log("add heirarchy errror", addHeirarchyError);
    }
  }, [addHeirarchyData, addHeirarchyError]);

  useEffect(() => {
    if (updateHeirarchyData) {
      console.log("update Heirarchy data", updateHeirarchyData);
      setNodeName(null);
    }

    if (updateHeirarchyError) {
      // console.log("update heirarchy errror", updateHeirarchyError);
    }
  }, [updateHeirarchyData, updateHeirarchyError]);

  useEffect(() => {
    toast.dismiss();
    if (deleteHeirarchyData) {
      console.log("delete Heirarchy data", deleteHeirarchyData);
      toast.success("Deleted Successfully", { id: "delete_heirarchy_success" });
      fetchHeirarchy({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }

    if (deleteHeirarchyError) {
      console.log("delete heirarchy errror", deleteHeirarchyError);
      toast.error("Something went wrong", { id: "delete_heirarchy_error" });

      if (deleteHeirarchyError.status === 409) {
        toast.error(deleteHeirarchyError.data.message, {
          id: "deleteError",
        });
      }
    }
  }, [deleteHeirarchyData, deleteHeirarchyError]);

  const getParentid = (id) => {
    let parentId = -1;
    for (let i = 0; i < heirarchyData.length; i++) {
      if (id == heirarchyData[i].id) {
        parentId = heirarchyData[i].master_id;
      }
    }
    return parentId;
  };

  const handleDeleteNode = (nodeToDelete) => {
    toast.dismiss();
    if (nodeToDelete.children && nodeToDelete.children.length > 0) {
      // Check if node has children, prevent deletion
      toast.error("Cannot delete a catgeory with subcategory." , {id : "nodeWithChildren"});
      return;
    }
    // const updatedData = data.filter((node) => node.id !== nodeToDelete.id);
    // setData(updatedData);

    deleteHeirarchy({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: nodeToDelete.id,
    });

    updateHeirarchy({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: Number(getParentid(nodeToDelete.id)),
      body: {
        is_master: "0",
      },
    });
  };


  const handleDeleteWrapper = (nodeToDelete) => {
    toast.dismiss();
    toast(
      (t) => (
        <span>
          <h6>
            Are you sure you want to <b>delete this ?</b>
          </h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                handleDeleteNode(nodeToDelete);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "logout",
      },
    );
    return;
  };

  return (
    <div className="flex w-full bg-gray-100 p-2 h-full justify-center ">
      <div className="w-full">
        {/* {treeData.map((node) => (
        <Node key={node.id} node={node} onDelete={handleDeleteWrapper} onAddNode={handleAddNode} />
      ))} */}
        {treeData && treeData.length > 0 ? (
          <Node
            node={treeData[0]}
            onDelete={handleDeleteWrapper}
            onAddNode={handleAddNode}
            handleShowModal={handleShowModal}
          />
        ) : (
          ""
        )}
      </div>
      {showModal && (
        <ModalComponent
          size={"md"}
          data={modalData}
          show={showModal}
          setShow={setShowModal}
        >
          <div className="w-full p-4">
            <div className="w-full flex justify-center ">
              <div className="mb-6 w-full">
                <InputComponent
                  name={"Name"}
                  type={"text"}
                  value={nodeName}
                  placeholder={modalData == 1 ? "Enter Category" : "Enter Subcategory"}
                  setValue={setNodeName}
                  required={true}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                onClick={(e) => handleAddNode(modalData)}
              >
                Add
              </button>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default HeirarchyComponent;
