import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    useDeleteQrCodeByBatchIdMutation,
    useFetchAllQrCodeMutation,
    useFetchQrCodeByGenerationIDMutMutation,
} from "../../../features/tenant/qrCode/qrCodeApi";
import toast from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import CSVReader from "../../../components/ImportCsv/CSVReader";
import { useBulkImportQrsMutation } from "../../../features/tenant/JqrSportsApi/jqrSportsApi";
import { downloadCSV } from "../../../utils/downloadCSV";

const transformData = (data) => {
    const groupedData = {};

    data.forEach((item) => {
        const {
            invoice_no,
            shipment_no,
            invoice_date,
            distributor_code,
            serial_no,
            serial_type,
            qr_string,
            item_no,
        } = item;

        if (!groupedData[invoice_no]) {
            groupedData[invoice_no] = {
                invoice_no,
                shipment_no,
                invoice_date,
                distributor_code,
                qrs: [],
            };
        }

        groupedData[invoice_no].qrs.push({
            serial_no,
            serial_type,
            qr_string,
            item_no,
        });
    });

    return Object.values(groupedData);
};

const QrDistributorMapping = () => {
    const navigate = useNavigate();

    const [showUploadModal, setShowUploadModal] = useState(false);

    const [myList, setMyList] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const [CSVData, setCSVData] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [finalArray, setFinalArray] = useState([]);

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [prevPage, setPrevPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const currentDate = new Date().toISOString().split("T")[0];
    const initialRequest = {
        product_code: "",
        product_name: "",
        batch_code: "",
        limit: 10,
        offset: 0,
        startDate: "",
        endDate:"",
        invoice_no: ""
    };
    const [request, setRequest] = useState(initialRequest);
    const clearFilters = () => {
        setRequest(initialRequest);
    };

    useEffect(() => {
        if (
            request.product_code === "" &&
            request.product_name === "" &&
            request.batch_code === "" &&
            request.startDate === "" &&
            request.endDate === currentDate
        ) {
            fetchAllQrCodeFunction();
        }
    }, [request]);

    const [
        fetchAllQrCode,
        {
            data: qrListData,
            isLoading: qrListDataIsLoading,
            isError: qrListDataIsError,
            error: qrListDataError,
        },
    ] = useFetchAllQrCodeMutation();


    const [
        fetchAllQrCodeCsv,
        {
            data: qrListDataCsv,
            isLoading: qrListDataCsvIsLoading,
            isError: qrListDataCsvIsError,
            error: qrListDataCsvError,
        },
    ] = useFetchAllQrCodeMutation();

    const [
        bulkImportQrs,
        {
            data: importQrData,
            isLoading: importQrIsLoading,
            isError: importQrIsError,
            error: importQrError,
        },
    ] = useBulkImportQrsMutation();

    useEffect(() => {
        const hasViewPermission = hasPermission("qrDistributorMapping");

        if (!hasViewPermission) {
            navigate(`/genefied/${getVendorName()}/`);
        }

        fetchAllQrCodeFunction();
    }, []);

    const viewQrMappingPermission = hasPermission("viewQrMapping");
    const deleteQrMappingPermission = hasPermission("deleteQrMapping");
    const addQrMappingPermission = hasPermission("addQrMapping");

    useEffect(() => {
        if (qrListData) {
            setMyList(
                qrListData?.body?.batchCodes
                    ? qrListData?.body?.batchCodes
                    : [],
            );
            setTotalRows(
                qrListData?.body?.total_batchcodes
                    ? qrListData?.body?.total_batchcodes
                    : 0,
            );
        }
    }, [qrListData]);

    const fetchAllQrCodeFunction = () => {
        fetchAllQrCode({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                .tenant_id,
            body: request,
        });
    };

    const [
        deleteQrCode,
        {
            data: deleteQrCodeData,
            isLoading: deleteQrCodeIsLoading,
            isError: deleteQrCodeIsError,
            error: deleteQrCodeError,
        },
    ] = useDeleteQrCodeByBatchIdMutation();

    const handleDelete = (batchId) => {
        if (window.confirm("are you sure you want to delete ?")) {
            deleteQrCode({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                batchId: batchId,
            });
        }
        return;
    };

    const handlePageChange = (pageNumber) => {
        const newOffset = (pageNumber - 1) * limit;
        setOffset(newOffset);
        setRequest({ ...request, offset: newOffset });
        setPrevPage(pageNumber - 1);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setRequest({ ...request, limit: newPerPage });
        setLimit(newPerPage);
    };

    useEffect(() => {
        toast.dismiss();

        if (deleteQrCodeData) {
            toast.success("Qr Code Batch deleted", {
                id: "deleteQrCodeSuccess",
            });
            fetchAllQrCodeFunction("");
        }

        if (deleteQrCodeError) {
            toast.error("Some error occured", { id: "deleteQrCodeError" });
        }
    }, [deleteQrCodeData, deleteQrCodeIsLoading, deleteQrCodeError]);

    const [
        qrLiCsvDataFun,
        {
            data: qrLiCsvData,
            isLoading: qrLiCsvDataIsLoading,
            error: qrLiCsvDataError,
        },
    ] = useFetchQrCodeByGenerationIDMutMutation();

    useEffect(() => {
        if (qrLiCsvDataIsLoading) {
            toast.dismiss();
            toast.loading("loading ...", {
                id: "delete loading ",
            });
        }
        if (qrLiCsvData) {
            toast.dismiss();
        }
    }, [qrLiCsvDataIsLoading, qrLiCsvData]);

    const qrListDownLoad = (event) => {
        qrLiCsvDataFun({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                .tenant_id,
            generationId: event.id,
        });
    };

    const generateCSV = (data) => {
        if (!data || !data.body || !data.body.qr) {
            return "";
        }

        if (data.body.qr.length === 0) {
            return "";
        }

        const header = [
            "Id",
            "Batch Running Code",
            "Unique Code",
            "Scratch Code",
            "Product Code",
            "MRP",
            "Created At",
            "QR Status",
        ];
        const rows = data.body.qr.map((item, index) => [
            index + 1,
            item.batch_running_code,
            item.unique_code,
            item.scratch_code,
            item.product_code,
            item.mrp,
            item.created_at,
            item.qr_status,
        ]);

        const csvContent = [header, ...rows]
            .map((row) => row.map((cell) => `"${cell}"`).join(","))
            .join("\n");

        return `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
    };

    useEffect(() => {
        if (qrLiCsvData && qrLiCsvData.body) {
            console.log("qrLiCsvData.body", qrLiCsvData.body);
            let qrList2 = [...qrLiCsvData.body.qr];
            let qrList = qrList2.map((x) => ({
                ...x,
                unique_code: `http://gverify.me/?${x.unique_code}`,
            }));
            const modifiedBody = {
                ...qrLiCsvData.body,
                qr: qrList, // qrList is the modified array
            };
            const newData = { body: modifiedBody };

            if (qrLiCsvData?.body?.qr.length > 0) {
                const csvDataUri = generateCSV(newData);

                const downloadLink = document.createElement("a");
                downloadLink.href = csvDataUri;
                downloadLink.download = "qr_data.csv";
                downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);

                downloadLink.click();

                document.body.removeChild(downloadLink);
            }
        }
    }, [qrLiCsvData]);

    const columns = [
        {
            name: "SNo",
            cell: (row, idx) => offset + Number(idx + 1),
            sortable: true,
            width: "80px",
        },
        {
            name: "Invoice Number",
            cell: (row) => row?.details?.invoice_no,
            sortable: true,
            width: "130px",
        },
        {
            name: "Distributor Code",
            cell: (row) => row?.details?.user_id,
            sortable: true,
        },
        {
            name: "Product Name",
            cell: (row) => row.product_name,
            sortable: true,
            width: "130px",
        },
        {
            name: "Batch Code",
            cell: (row) => row.batch_code,
            sortable: true,
        },
        {
            name: "Qty",
            cell: (row) => row.qty,
            sortable: true,
            width: "130px",
        },
        {
            name: "Type",
            cell: (row) => (row.type == "2" ? "Enterprise" : "Standard"),
            sortable: true,
            width: "130px",
        },

        {
            name: "From Date",
            cell: (row) => new Date(row.from_date).toLocaleDateString(),
            sortable: true,
            width: "130px",
        },

        {
            name: "To Date",
            cell: (row) => new Date(row.to_date).toLocaleDateString(),
            sortable: true,
            width: "130px",
        },
        {
            name: "Created By",
            cell: (row) => row.created_by_name,
            sortable: true,
        },
        {
            name: "Created At",
            cell: (row) => new Date(row.created_at).toLocaleString(),
            sortable: true,
        },
        {
            name: "",
            cell: (row) => (
                <div>
                    <button
                        onClick={() =>
                            navigate(
                                `/genefied/${getVendorName()}/printQr/batch`,
                                {
                                    state: row,
                                },
                            )
                        }
                        className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
                    >
                        View
                    </button>
                </div>
            ),
            minWidth: "100px",
        },
        {
            name: "",
            cell: (row) => (
                <div>
                    <button
                        onClick={() => qrListDownLoad(row)}
                        className="flex justify-center items-center rounded bg-primary px-2 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
                    >
                        Download csv
                    </button>
                </div>
            ),
            minWidth: "120px",
        },
        {
            name: "",
            cell: (row) =>
                row.product_name === "Mixed Imported Qrs" && (
                    <div>
                        <button
                            onClick={() =>
                                navigate("mapping", {
                                    state: row,
                                })
                            }
                            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
                        >
                            Mapping
                        </button>
                    </div>
                ),
            minWidth: "100px",
        },

        // {
        //   name: "",
        //   cell: (row) => (
        //     <div>
        //       <button
        //         onClick={() => handleDelete(row.id)}
        //         className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
        //       >
        //         <AiOutlineDelete className="mr-1" />
        //         Delete
        //       </button>
        //     </div>
        //   ),
        //   minWidth: "120px",
        // },
    ];

    useEffect(() => {
        if (CSVData && CSVData?.parsed) {
            // console.log("transformedData", CSVData?.parsed);
            const transformedData = transformData(CSVData?.parsed);
            if (transformedData.length > 1) {
                // console.log("transformedData", transformedData);
                setFinalArray(transformedData[0]);
            } else {
                setFinalArray(transformedData[0]);
            }
        }
    }, [CSVData]);

    const handleOnSubmit = (e) => {
        toast.loading("Processing your data...");

        e.preventDefault();

        // console.log("finalArray", finalArray);

        bulkImportQrs({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                .tenant_id,
            body: finalArray,
        });
    };

    useEffect(() => {
        if (importQrData) {
            toast.dismiss();
            toast.success("Data imported successfully", { id: "qrImportData" });
            fetchAllQrCodeFunction();
            setShowUploadModal(false);
            // console.log("importQrData", importQrData)
        }
        if (importQrError) {
            toast.dismiss();
            toast.error(
                importQrError?.data?.message
                    ? importQrError.data?.message
                    : "Some error occcured",
                { id: "qrImportError" },
            );
            console.log("importQrError", importQrError);
        }
    }, [importQrData, importQrError]);


    const fetchAllQrListBatchData = () => {
        const modifiedRequest = {
            ...request,
            limit: null,
            offset: null,
        };
        fetchAllQrCodeCsv({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                .tenant_id,
            body: modifiedRequest,
        });
    };

    useEffect(() => {
        if (qrListDataCsv && qrListDataCsv.body && qrListDataCsv.body.batchCodes) {
            downloadCustomCSV(qrListDataCsv?.body?.batchCodes);
        }

        if (qrListDataCsvError) {
            toast.error("Something went wrong" , {id : "qrListDataCsvError"})
        }
    }, [qrListDataCsv, qrListDataCsvError])

    const downloadCustomCSV = (data) => {

        // Function to extract the value from the data row based on the key (dot notation support)
        const getCellValue = (row, key) => key.split('.').reduce((obj, field) => obj && obj[field], row);
      
        // Generate CSV rows based on the custom mappings
        const csvRows = data.map((row, idx) => {
          return mappings.map(col => {
            if (col.transform) {
              // Apply transformation if specified in the mapping
              return col.transform(row, idx);
            }
            // Fetch the cell value based on the key or return an empty string if not found
            return getCellValue(row, col.key) || '';
          }).join(',');
        });
      
        // Generate the CSV header row from the mapping names
        const headers = mappings.map(col => col.name).join(',');
      
        // Combine headers and rows
        const csvContent = [headers, ...csvRows].join('\n');
      
        // Create a CSV Blob and initiate download using Papa Parse
        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const csvUrl = window.URL.createObjectURL(csvBlob);
      
        // Create a download link and trigger the download
        const link = document.createElement('a');
        link.href = csvUrl;
        link.setAttribute('download', `qr-mapping-data-${new Date().toISOString().replace(/:/g, '-')}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      
      };
      

    return (
        <>
            <div className="flex w-full bg-gray-200 h-full justify-center">
                <div className="w-full ">
                    <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
                        Qr Distrubutor Mapping
                        <LinksComponent />
                    </div>

                    <div className="w-full ">


                        <div className="p-3">

                            <div class="flex justify-between">
                                <div className="flex justify-between items-start">

                                    <div>
                                    <button
                                            className="btn btn-outline-primary clear-both float-right  mb-2"
                                            onClick={() => {
                                                fetchAllQrListBatchData();
                                            }}
                                        >
                                            Download Csv
                                        </button>
                                    </div>
                                </div>
                                {addQrMappingPermission && (
                                    <div className="flex gap-2">
                                        <button
                                            className="btn btn-outline-primary clear-both float-right  mb-2"
                                            onClick={() => {
                                                setShowUploadModal(true);
                                            }}
                                        >
                                            Upload Qrs Csv
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className=" bg-white shadow rounded-lg">
                                <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                                    <div className="flex  space-x-2 justify-between">
                                        <div className="w-52 h-10">
                                            <div className="relative searchin">
                                                <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                    Invoice No
                                                </label>
                                                <input
                                                    type="search"
                                                    id="default-search"
                                                    className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                                                    placeholder="Invoice No..."
                                                    required
                                                    value={request.invoice_no}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        setRequest((prevState) => {
                                                            return { ...prevState, invoice_no: newValue };
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div> */}
                                        <div className="flex flex-row gap-2  w-56 h-10">
                                            <div className="relative searchin">
                                                <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                    &nbsp;
                                                </label>
                                                <button
                                                    className="btn btn-outline-primary clear-both float-right pdbtn_all "
                                                    onClick={() => {
                                                        fetchAllQrCodeFunction();
                                                    }}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                            <div className="relative searchin">
                                                <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                    &nbsp;
                                                </label>
                                                <button
                                                    className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                                                    onClick={() => {
                                                        clearFilters();
                                                    }}
                                                >
                                                    Clear Filters
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <DataTableComponent
              keyField={(row) => row.SNo}
                                    columns={columns}
                                    data={myList}
                                    // loading={loading}
                                    totalRows={totalRows}
                                    handlePerRowsChange={handlePerRowsChange}
                                    handlePageChange={handlePageChange}
                                ></DataTableComponent>
                            </div>

                            {showUploadModal && (
                                <ModalComponent
                                    size={"sm"}
                                    data={"nadnandand"}
                                    show={showUploadModal}
                                    setShow={setShowUploadModal}
                                >
                                    <div className="w-full p-3">
                                        <div className="container mx-auto p-2">
                                            {/* <h1 className="text-lg w-full text-center font-semibold mb-4">Upload CSV</h1> */}
                                            <div className=" w-full flex justify-center mb-3">
                                                <button
                                                    type="submit"
                                                    className="mb-2 text-white bg-blue-500 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center "
                                                    onClick={() =>
                                                        downloadCSV(
                                                            sampleJqrQrUpload,
                                                            "sampleJqrQrUpload.csv",
                                                        )
                                                    }
                                                >
                                                    Sample Csv : Qr Csv Upload
                                                </button>
                                            </div>
                                            <div className="w-full flex items-center justify-evenly flex-col ">
                                                <label className="block text-gray-700 text-sm mb-2">
                                                    Upload Excel/CSV File:
                                                </label>

                                                <CSVReader
                                                    setCSVData={setCSVData}
                                                    setFileName={setFileName}
                                                />
                                            </div>

                                            <div className="flex justify-center mt-4">
                                                <button
                                                    onClick={(e) =>
                                                        handleOnSubmit(e)
                                                    }
                                                    className="bg-blue-600 disabled:bg-blue-300 hover:bg-blue-500 rounded-sm text-white font-bold py-2 px-4 mr-2"
                                                >
                                                    {/* <HiOutlineTicket className="mr-2 inline" /> */}
                                                    Submit
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setShowUploadModal(
                                                            false,
                                                        );
                                                        setCSVData(null);
                                                        setFinalArray([]);
                                                        // setShowPrinterModal(false);
                                                    }}
                                                    className="bg-gray-500 hover:bg-gray-400 rounded-sm text-white font-bold py-2 px-4"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ModalComponent>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QrDistributorMapping;

let sampleJqrQrUpload = [
    {
        "invoice_no": "JSP/24-25/00881",
        "shipment_no": "PSS-FCTN/24-25/00881",
        "invoice_date": "6/25/24",
        "distributor_code": "MBDB-0078",
        "CTN Serial No.": "5000556543",
        "CTN Item No.": "JSP0157-1-6G9-2442",
        "serial_no": "1007368743",
        "serial_type": "Pair",
        "qr_string": "PRSAJSP0376M-2-C3|B1007368743|C27-04-24|DNINJA",
        "item_no": "JSP0376M-2-C3"
    },
    {
        "invoice_no": "JSP/24-25/00881",
        "shipment_no": "PSS-FCTN/24-25/00881",
        "invoice_date": "6/25/24",
        "distributor_code": "MBDB-0078",
        "CTN Serial No.": "5000556543",
        "CTN Item No.": "JSP0157-1-6G9-2442",
        "serial_no": "1017754190",
        "serial_type": "Pair",
        "qr_string": "PRSAJSP0376M-2-C3|B1017754190|C27-04-24|DNINJA",
        "item_no": "JSP0376M-2-C3"
    },
    {
        "invoice_no": "JSP/24-25/00881",
        "shipment_no": "PSS-FCTN/24-25/00881",
        "invoice_date": "6/25/24",
        "distributor_code": "MBDB-0078",
        "CTN Serial No.": "5000556543",
        "CTN Item No.": "JSP0157-1-6G9-2442",
        "serial_no": "1022186866",
        "serial_type": "Pair",
        "qr_string": "PRSAJSP0152-1-G7|B1022186866|C07-06-24|DRTGS",
        "item_no": "JSP0152-1-G7"
    }
]


const mappings = [
    { name: "SNo", key: "sno", transform: (row, idx) => idx + 1 },
    { name: "Invoice Number", key: "details.invoice_no" },
    { name: "Distributor Code", key: "details.user_id" },
    { name: "Product Name", key: "product_name" },
    { name: "Batch Code", key: "batch_code" },
    { name: "Qty", key: "qty" },
    { name: "Type", key: "type", transform: (row) => row.type === "2" ? "Enterprise" : "Standard" },
    { name: "From Date", key: "from_date", transform: (row) => new Date(row.from_date).toLocaleDateString() },
    { name: "To Date", key: "to_date", transform: (row) => new Date(row.to_date).toLocaleDateString() },
    { name: "Created By", key: "created_by_name" },
    { name: "Created At", key: "created_at", transform: (row) => new Date(row.created_at).toLocaleString() }
  ];