import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useFetchQrCodeByGenerationIDMutMutation,
  useFetchQrCodeByGenerationIDQuery,
} from "../../../features/tenant/qrCode/qrCodeApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import ViewQr from "../../../components/Tenant/ViewQR/ViewQr";
import QRCode from "react-qr-code";
import { getVendorName } from "../../../utils/getVendorName";
import { toast } from "react-hot-toast";
import { Menu } from "@headlessui/react";
import { HiPrinter } from "react-icons/hi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import {
  useFetchAllPrintersMutation,
  usePrintQrCodesMutation,
} from "../../../features/tenant/qrPrinter/qrPrinterApi";
import axios from "axios";
import ViewBarcode from "../../../components/Tenant/ViewQR/ViewBarcode";


const QrByBatch = () => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Printed",
      cell: (row) => row.is_printed == true ? <span className="text-green-600">Yes</span> : <span className="text-red-600">No</span>,
      sortable: true,
      width: "90px",
    },
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "100px",
    },
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_running_code,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "130px",
    },
    {
      name: "Qr Code",
      cell: (row) => row.unique_code,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Scratch Code",
      cell: (row) => row.scratch_code,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => (
        <span
          className={`text-sm p-1 capitalize rounded-sm  font-bold bg-${row.parent_code === null
            ? "gray"
            : row.parent_code === "false"
              ? "red"
              : "blue"
            }-500 text-white animate-pulse duration-1000`}
        >
          {row.parent_code === null
            ? "Child"
            : row.parent_code === "false"
              ? "Parent"
              : "Child"}
        </span>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "MRP",
      cell: (row) => row.mrp,
      sortable: true,
      width: "100px",
    },
    {
      name: "Qr Status",
      cell: (row) => (
        <span
          className={`text-sm p-1 capitalize rounded-sm  font-bold bg-${row.qr_status == "2" ? "red" : "blue"
            }-700 text-white animate-pulse duration-0`}
        >
          {row.qr_status == "2" ? "Scanned" : "Not Scanned"}
        </span>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            className="flex justify-center items-center rounded bg-red-600 px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white animate-blinking-button shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-red-700 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-red-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            onClick={() =>
              navigate(`/genefied/${getVendorName()}/tracking`, { state: row })
            }
          >
            Track
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            onClick={() => handleShowQr(row)}
          >
            GVerify Qr
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            onClick={() => handleWebGenefiedQr(row)}
          >
            Web Qr
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            onClick={() => handleBatchShowQr(row)}
          >
            View Qr
          </button>
        </div>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
            onClick={() => handleShowBar(row)}
          >
            View Bar
          </button>
        </div>
      ),
      sortable: true,
    },
  ];


  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [childOrParent, setChildOrParent] = useState("all");

  const [generationId, setGenerationId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const Id = location.state.id;
    setGenerationId(Id);
  }, [location.pathname]);

  // const {
  //   data: qrListData,
  //   isLoading: qrListDataIsLoading,
  //   error: qrListDataError,
  // } = useFetchQrCodeByGenerationIDQuery({
  //   token: sessionStorage.getItem("access_token"),
  //   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //   generationId: generationId || location.state.id,
  //   limit: limit,
  //   offset: offset,
  // });

  const [
    qrListDataFun,
    {
      data: qrListData,
      isLoading: qrListDataIsLoading,
      error: qrListDataError,
    },
  ] = useFetchQrCodeByGenerationIDMutMutation();


  const [
    qrLiCsvDataFun,
    {
      data: qrLiCsvData,
      isLoading: qrLiCsvDataIsLoading,
      error: qrLiCsvDataError,
    },
  ] = useFetchQrCodeByGenerationIDMutMutation();

  useEffect(() => {

    if (qrLiCsvDataError) {
      console.log("qrLiCsvDataError", qrLiCsvDataError);
      toast.error("Something went wrong", { id: "qrLiCsvDataError" });
    }
  }, [qrLiCsvDataError]);

  const qrListDownLoad = () => {
    qrLiCsvDataFun({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      generationId: generationId,
    });
  };

  const fetchQRCodeList = () => {
    qrListDataFun({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      generationId: generationId,
      limit: limit,
      offset: offset,
    });
  };

  const generateQRCodePDF = () => {
    if (qrLiCsvData?.body?.qr?.length > 0) {
      const timestamp = new Date().toISOString().replace(/[-:.]/g, ""); // Add this line for timestamp
      const printWindow = window.open("", "_blank");

      if (printWindow) {
        printWindow.document.open();

        let pdfContent = `
          <html>
            <head>
              <title>QR Code PDF</title>
              <style>
                .qr-container {
                  display: flex;
                  flex-wrap: wrap;
                }
                .qr-code {
                  margin: 10px;
                }
                .qr-data {
                  margin: auto;
                  justify-content: center;
                  text-align: center;
                  font-size:12px;
                }
              </style>
            </head>
            <body>
              <h1>Qr Codes</h1>
              <div class="qr-container">`;

        qrLiCsvData.body.qr.forEach((data, index) => {
          const qrCodeImage = ReactDOMServer.renderToStaticMarkup(
            <QRCode
              value={`${process.env.REACT_APP_QR_URL}genefied/verifyQr?qrCode=${data.unique_code}`}
              size={64}
            />,
          );

          pdfContent += `
          <div>
            <div class="qr-code"> 
              ${qrCodeImage}
            </div>
            <p class="qr-data " >
            ${data.batch_running_code}
            </p>
          </div>
          `;
        });

        pdfContent += `
              </div>
            </body>
          </html>
        `;

        printWindow.document.write(pdfContent);
        printWindow.document.close();

        printWindow.print();
        printWindow.close();
      }
    }
  };

  useEffect(() => {
    if (qrLiCsvDataIsLoading) {
      // toast.dismiss();
      // toast.loading("Fetching list ...", {
      //   id: "delete loading ",
      // });

    }
    // if (qrLiCsvData) {
    //   toast.dismiss();
    // }
  }, [qrLiCsvDataIsLoading, qrLiCsvData]);

  useEffect(() => {
    if (generationId) {
      fetchQRCodeList();
    }
  }, [generationId, limit, offset]);

  useEffect(() => {
    if (generationId) {
      qrListDownLoad();
    }
  }, [generationId]);

  useEffect(() => {

    if (qrListData && qrListData.body) {
      toast.dismiss();
      // console.log("qrListData", qrListData.body);
      if (qrListData.body.length == 0) {
        toast("No Qrs found ⚠️", { id: "fetchNoQrs" })
      } else if (qrListData.body.qr) {
        //  console.log("qrListData", qrListData);
        // const arr = [...qrListData.body.qr];
        // arr.sort((a, b) => a.id - b.id);
        setData(qrListData.body.qr);
        setTotalRows(qrListData && qrListData.body.total ? qrListData.body.total : 0);
      }
    }


    if (qrListDataError) {
      toast.dismiss();
      console.log("qr list error batch ", qrListDataError);
      toast.error("Some error occurred", { id: "fetchQrsError" })
    }

    if (qrListDataIsLoading) {
      toast.dismiss();
      toast.loading("Fetching qrs...", { id: "fetchingQrs" })
      // setLoading(qrListDataIsLoading);
    }
  }, [qrListData, qrListDataIsLoading, qrListDataError]);

  const [showQr, setShowQr] = useState(false);
  const [showBar, setShowBar] = useState(false);
  const [qrData, setQrData] = useState(null);

  const handleShowQr = (data) => {
    console.log(data);
    const obj = {
      link: `http://gverify.me/?${data.unique_code}`,
      ...data,
    };

    setQrData(obj);
    setShowQr(true);
  };

  const handleBatchShowQr = (data) => {


    let obj = {
      link: `${data.unique_code}`,
      ...data,
    };

    // Ultimatrue Specific Changes
    if (JSON.parse(sessionStorage.getItem("vendorData")).tenant_id == 17) {
      obj.link = `${process.env.REACT_APP_QR_URL}genefied/verifyQr?qrCode=${data.unique_code}`
    }

    setQrData(obj);
    setShowQr(true);
  };

  const handleWebGenefiedQr = (data) => {
    

    let obj = {
      link: `${process.env.REACT_APP_QR_URL}genefied/verifyQr?qrCode=${data.unique_code}`,
      // link: `${data.unique_code}`,
      ...data,
    };


    setQrData(obj);
    setShowQr(true);
  };

  const handleShowBar = (data) => {
    console.log(data);
    const obj = {
      link: `${data.unique_code}`,
      ...data,
    };

    setQrData(obj);
    setShowBar(true);
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleRowSelected = React.useCallback((state) => {
    if (printAllCheckBox) {
      setPrintAllCheckBox(false);
    }
    setSelectedRows(state.selectedRows);
  }, []);

  const [fetchAllPrinters, { data: printerListData, error: printerListError }] =
    useFetchAllPrintersMutation();

  useEffect(() => {
    fetchAllPrinters({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  const [printQrCodeFunc, { data: printQrCodeData, error: printQrCodeError }] =
    usePrintQrCodesMutation();

  useEffect(() => {
    if (printQrCodeData && printQrCodeData.body) {
      
      //Tibcon Spefic Changes
      if ( JSON.parse(sessionStorage.getItem("vendorData")).tenant_id == 15) {
        if (printQrCodeData.body.printed_count > 0) {
          toast.error(`Qrs already printed.`, {
            id: "alreadyPrintQrCodeData"
          });
          return;
        }else {
          toast.success("Prinitng " + printQrCodeData.body.total_qrs + " Qrs", {
            id: "printQrCodeData"
          });
          handleSendToPrinter(printQrCodeData.body.data);
          return;
        }
      }
      else if (printQrCodeData.body.printed_count > 0) {
        if (window.confirm(`${printQrCodeData.body.printed_count} Qrs are already printed .Do you want to continue ?`)) {
          console.log("printQrCodeData", printQrCodeData);
          toast.success("Prinitng " + printQrCodeData.body.total_qrs + " Qrs", {
            id: "printQrCodeData"
          });
          handleSendToPrinter(printQrCodeData.body.data);
        }
      } else {
        console.log("printQrCodeData", printQrCodeData);
        toast.success("Prinitng " + printQrCodeData.body.total_qrs + " Qrs", {
          id: "printQrCodeData"
        });
        handleSendToPrinter(printQrCodeData.body.data);
      }
      
    }

    if (printQrCodeError) {
      console.log("printQrCodeError", printQrCodeError);
      toast.error("Something went wrong", { id: "printQrCodeError" });
    }
  }, [printQrCodeData, printQrCodeError])


  const [printAllCheckBox, setPrintAllCheckBox] = useState(false);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [showPrinterModal, setShowPrinterModal] = useState(false);

  const handlePrintAll = () => {
    if (printAllCheckBox) {
      setPrintAllCheckBox(!printAllCheckBox);
      setSelectedRows([]);
    } else {
      setPrintAllCheckBox(!printAllCheckBox);
      setSelectedRows([{ type: "all" }]);
    }
  };

  const [selectedPrinter, setSelectedPrinter] = useState(null);

  const handleSubmit = () => {
    if (selectedRows.length > 0) {
      console.log("selectedPrinter", selectedPrinter);

      //print all in batch
      if (selectedRows[0]?.type) {
        const body = {
          printer: selectedPrinter,
          type: "2",
          generation_id: location.state.id,
          childOrParent: childOrParent,
        };
        printQrCodeFunc({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
          body: body,
        });
      }
      //print only selected
      else {
        const qrIds = [];

        selectedRows.map((each) => {
          qrIds.push(each.id);
        });

        const body = {
          printer: selectedPrinter,
          type: "1",
          qr_ids: qrIds,
          childOrParent: childOrParent,
        };

        printQrCodeFunc({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
          body: body,
        });
      }
    }
  };

  const handleSendToPrinter = async (postData) => {
    console.log("handleSendToPrinter", postData);

    if (!selectedPrinter || !selectedPrinter.local_ip) {
      toast.error("Invalid Local IP", { id: "unknownPrinterType" });
      return;
    }

    try {
      const response = await axios.post(`${selectedPrinter.local_ip}/index.php`, postData);
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Qr Code Print Batch
            <LinksComponent />
          </div>

          <div className="p-3">
            <h4 className="clear-both  text-right mb-2">
              <button
                onClick={() => generateQRCodePDF()}
                className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
              >
                Download pdf
              </button>
            </h4>
            <div className=" bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-sky-50 rounded-t-md p-3">
                <div className="flex justify-between gap-2">
                  <h4>
                    <span className="text-gray-400 text-sm  block">
                      Generation Id :
                    </span>
                    <span className="flex text-black text-sm  gap-1 ">
                      {location.state.id}
                    </span>
                  </h4>

                  <h4>
                    <span className="text-gray-400 text-sm  block">
                      Batch Code :
                    </span>
                    <span className="flex text-black text-sm  gap-1">
                      {location.state.batch_code}
                    </span>
                  </h4>

                  <h4>
                    <span className="text-gray-400 text-sm  block">
                      Created By :
                    </span>
                    <span className="flex text-black text-sm  gap-1">
                      {location.state.created_by_name}
                    </span>
                  </h4>
                  <h4>
                    <span className="text-gray-400 text-sm  block">
                      Created At :
                    </span>
                    <span className="flex text-black text-sm  gap-1 ">
                      {location.state.created_at.slice(0, 10)}
                    </span>
                  </h4>

                  <h4>
                    <span className="text-gray-400 text-sm  block">
                      Total Qty :
                    </span>
                    <span className="flex text-black text-sm  gap-1">
                      {" "}
                      {location.state.qty}{" "}
                    </span>
                  </h4>
                </div>

                <div className="w-full flex justify-start">

                  <div className="mt-2 w-1/6">
                    <h4>
                      <span className="text-gray-400 text-sm block">
                        Print All In Batch
                      </span>
                      <span className="flex text-black text-sm  gap-1 ">
                        <input
                          type="checkbox"
                          className=""
                          checked={printAllCheckBox}
                          onChange={() => handlePrintAll()}
                        />
                      </span>
                    </h4>
                  </div>

                  <div className="mt-2 w-1/6">
                    <h4>
                      <span className="text-gray-400 text-sm block">
                        All
                      </span>
                      <span className="flex text-black text-sm  gap-1 ">
                        <input
                          type="checkbox"
                          className=""
                          checked={childOrParent === "all" ? true : false}
                          onChange={() => setChildOrParent("all")}
                        />
                      </span>
                    </h4>
                  </div>
                  <div className="mt-2 w-1/6">
                    <h4>
                      <span className="text-gray-400 text-sm block">
                        Only Child
                      </span>
                      <span className="flex text-black text-sm  gap-1 ">
                        <input
                          type="checkbox"
                          className=""
                          checked={childOrParent === "child" ? true : false}
                          onChange={() => setChildOrParent("child")}
                        />
                      </span>
                    </h4>
                  </div>

                  <div className="mt-2 w-1/6">
                    <h4>
                      <span className="text-gray-400 text-sm block">
                        Only Parent
                      </span>
                      <span className="flex text-black text-sm  gap-1 ">
                        <input
                          type="checkbox"
                          className=""
                          checked={childOrParent === "parent" ? true : false}
                          onChange={() => setChildOrParent("parent")}
                        />
                      </span>
                    </h4>
                  </div>
                </div>

              </div>

              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={data}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                selectableRows={true}
                onSelectedRowsChange={handleRowSelected}
              ></DataTableComponent>

              {selectedRows.length > 0 && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => setShowPrinterModal(true)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiPrinter className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>

          {showPrinterModal && (
            <ModalComponent
              size={"md"}
              data={"nadnandand"}
              show={showPrinterModal}
              setShow={setShowPrinterModal}
            >
              <div className="w-full p-3">
                <div className="container mx-auto p-4">
                  <h1 className="text-lg font-semibold mb-4">Choose Printer</h1>

                  <ul className="space-y-2">
                    {printerListData &&
                      printerListData.body &&
                      printerListData.body.data.map((printer, index) => (
                        <li
                          key={index}
                          onClick={() => setSelectedPrinter(printer)}
                          className="bg-white border rounded-lg p-2 flex items-center justify-between transition-transform transform hover:scale-105"
                        >
                          <div>
                            <h2 className="text-base mb-1">{printer.name}</h2>
                          </div>
                          <div className="rounded-full w-6 h-6 border border-gray-300 flex items-center justify-center">
                            {selectedPrinter?.id === printer.id && (
                              <div className="bg-blue-500 w-3 h-3 rounded-full"></div>
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>

                  <div className="flex justify-end mt-4">
                    <button
                      onClick={() => handleSubmit()}
                      disabled={selectedPrinter == null ? true : false}
                      className="bg-blue-600 disabled:bg-blue-300 hover:bg-blue-500 rounded-sm text-white font-bold py-2 px-4 mr-2"
                    >
                      <HiPrinter className="mr-2 inline" />
                      Print
                    </button>
                    <button
                      onClick={() => {
                        setSelectedPrinter(null);
                        setShowPrinterModal(false);
                      }}
                      className="bg-gray-500 hover:bg-gray-400 rounded-sm text-white font-bold py-2 px-4"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
        {showQr && <ViewQr data={qrData} show={showQr} setShow={setShowQr} />}
        {showBar && <ViewBarcode data={qrData} show={showBar} setShow={setShowBar} />}
      </div>
    </>
  );
};

export default QrByBatch;
