import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Menu } from "@headlessui/react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import {
  useBulkUpdateAppUsersMutation,
  useFetchUserByUserTypeCountMutation,
  useFetchUserByUserTypeMutation,
  useRemoveAppUserMutation,
  useUpdateAppUserStatusMutation,
  useUpdateKycStatusMutation,
} from "../../../features/tenant/appUsers/appUsersApi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import { HiOutlinePlus } from "react-icons/hi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useGetFormTemplateMutation } from "../../../features/tenant/formTemplate/formTemplateApi";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useUploadSingleFileMutation } from "../../../features/admin/imageUpload/imageUploadApi";
import { useGetLocationByPincodeMutation } from "../../../features/tenant/pincode/pincodeApi";
import {
  useRegisterUserMutation,
  useBulkRegisterUserMutation,
} from "../../../features/tenant/AddUser/AddUserApi";
import {
  useCreateUserMappingMutation,
  useCreateUserMappinginBulkMutation,
  useDeleteUserMappingMutation,
  useFetchUserMappingByAppUserIdAndMappedUserTypeMutation,
  useFetchUserMappingByUserTypeAndMappedUserTypeMutation,
} from "../../../features/tenant/userMapping/userMappingApi";
import { downloadSampleCSV } from "../../../utils/downloadSampleCsv";
import { useBulkUserPointsUpdateMutation } from "../../../features/tenant/rewardify/pointsScanHistory/pointsScanHistoryApi";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";
import { useAddbanUnbanMutation } from "../../../features/tenant/banUnban/banUnbanApi";
import CustomExport from "../../../components/CustomExport/CustomExport";
import { downloadCSV } from "../../../utils/downloadCSV";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { getVendorName } from "../../../utils/getVendorName";

const mappedForArray = [
  {
    id: 1,
    name: "gift_redemption",
  },
  // {
  //   id : 2,
  //   name : "coupon_redemption",
  // }
];

function addCheckedKey(arr) {
  return arr.map((item) => ({ ...item, checked: false }));
}

function objectsAreEqual(obj1, obj2) {
  console.log("ob1", obj1);
  console.log("ob2", obj2);
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

function createUserMappingArray(parentUser, mappingArray) {
  const rows = [];

  for (let i = 0; i < mappingArray.length; i++) {
    const newObj = {
      user_type: parentUser.user_type,
      user_type_id: parentUser.user_type_id,
      app_user_id: parentUser.id,
      app_user_name: parentUser.name,
      app_user_mobile: parentUser.mobile,

      mapped_user_type: mappingArray[i].user_type,
      mapped_user_type_id: mappingArray[i].user_type_id,
      mapped_app_user_id: mappingArray[i].id,
      mapped_app_user_name: mappingArray[i].name,
      mapped_app_user_mobile: mappingArray[i].mobile,
    };

    rows.push(newObj);
  }

  return rows;
}

//remove already mapped users for this app user id
function removeDuplicatesByMobile(primaryArray, secondaryArray) {
  const mobilesInSecondaryArray = new Set();

  secondaryArray.forEach((item) => {
    mobilesInSecondaryArray.add(item.mapped_app_user_mobile);
  });

  return primaryArray.filter(
    (item) => !mobilesInSecondaryArray.has(item.mobile)
  );
}

//remove already mapped user so that it cannot be mapped again
function removeAlreadyMappedUsers(primaryArray, secondaryArray) {
  const idsInSecondary = new Set();

  secondaryArray.forEach((item) => {
    idsInSecondary.add(item.mapped_app_user_mobile);
  });

  return primaryArray.filter((item) => !idsInSecondary.has(item.mobile));
}

const currentDate = new Date().toISOString().split("T")[0];
const JqrUserList = () => {
  const { zone_id: zoneId, zone_name: zoneName } = getLoginUserData();
  const tenantData = JSON.parse(sessionStorage.getItem("vendorData"));

  const { userType } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal2, setShowModal2] = useState(false);
  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [mappedForType, setMappedForType] = useState({
    id: 0,
    name: "point_sharing",
  });

  const [
    fetchAppUserByUserType,
    { data: appUsersData, error: appUsersError, isLoading: appUsersIsLoading },
  ] = useFetchUserByUserTypeMutation();
  const [
    bulkRegisterUserFunc,
    {
      data: bulkRegisterUserData,
      error: bulkRegisterUserError,
      isLoading: bulkRegisterUserIsLoading,
    },
  ] = useBulkRegisterUserMutation();


  const [
    bulkUpdateAppUser,
    {
      data: bulkUpdateAppUserData,
      error: bulkUpdateAppUserError,
      isLoading: bulkUpdateAppUserIsLoading,
    },
  ] = useBulkUpdateAppUsersMutation();

  const [
    bulkUserPointsUpdateFunc,
    {
      data: bulkUserPointsUpdateData,
      error: bulkUserPointsUpdateError,
      isLoading: bulkUserPointsUpdateIsLoading,
    },
  ] = useBulkUserPointsUpdateMutation();
  const [
    fetchAppUsersListFunc,
    { data: appUsersListData, error: appUsersListError },
  ] = useFetchUserByUserTypeMutation();

  const [
    fetchUserByUserTypeCount,
    {
      data: fetchUserByUserTypeCountData,
      error: fetchUserByUserTypeCountError,
    },
  ] = useFetchUserByUserTypeCountMutation();

  const [
    fetchAppUserByUserTypeCsv,
    { data: appUsersCsvData, error: appUsersCsvError },
  ] = useFetchUserByUserTypeMutation();
  const updateStatusPermission = hasPermission(`update${userType}Status`);


  const removeAccountPermission = hasPermission(`remove${userType}Account`);

  const [
    updateAppUserStatus,
    {
      data: updateAppUserStatusData,
      error: updateAppUserStatusError,
      isLoading: updateAppUserStatusIsLoading,
      isError: updateAppUserStatusIsError,
    },
  ] = useUpdateAppUserStatusMutation();
  const [
    AddbanUnban,
    {
      data: AddbanUnbanData,
      error: AddbanUnbanError,
      isLoading: AddbanUnbanIsLoading,
      isError: AddbanUnbanIsError,
    },
  ] = useAddbanUnbanMutation();

  const [
    updateKycStatus,
    {
      data: updateKycStatusData,
      error: updateKycStatusError,
      isLoading: updateKycStatusIsLoading,
      isError: updateKycStatusIsError,
    },
  ] = useUpdateKycStatusMutation();

  const [
    removeAppUser,
    {
      data: removeAppUserData,
      error: removeAppUserError,
      isLoading: removeAppUserIsLoading,
      isError: removeAppUserIsError,
    },
  ] = useRemoveAppUserMutation();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [userMappingModal, setUserMappingModal] = useState(false);
  const [prevPage, setPrevPage] = useState(0);
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [userTypeId, setUserTypeId] = useState(0);
  const [kycOptions, setKycOptions] = useState([]);
  const [checkBox, setCheckBox] = useState([]);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal1, setShowModal1] = useState(false);

  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false);

  const [row, setRow] = useState(false);
  const [remarks, setRemarks] = useState();
  const [templateData, setTemplateData] = useState(null);

  const initialRequest = {
    user_type_id: userTypeId,
    state: "",
    limit: 20,
    offset: 0,
    mobile: "",
    name: "",
    startDate: "",
    endDate: currentDate,
    is_scanned: "",
    status: ""
  };

  const initialRequestCount = {
    user_type_id: userTypeId,
    state: "",
    mobile: "",
    name: "",
    is_scanned: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);
  const [requestCount, setRequestCount] = useState(initialRequestCount);
  const [file, setFile] = useState();
  const [mappingFile, setMappingFile] = useState();
  const [imagesData, setImagesData] = useState([]);

  const [bulkUpdatefile, setBulkUpdateFile] = useState();
  const [isFormValid, setIsFormValid] = useState(false);
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnBulkUserUpdateChange = (e) => {
    setBulkUpdateFile(e.target.files[0]);
  };

  const handleOnChangeCsv = (e) => {
    setMappingFile(e.target.files[0]);
  };
  //   function parseCSV(string) {
  //     const csvHeader = string
  //       .slice(0, string.indexOf("\n"))
  //       .replace(/\s/g, "")
  //       .split(",");

  //     const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
  // console.log("csvRows: " , csvRows.length , csvRows);
  //     const array = csvRows
  //       .map((row) => row.split(","))
  //       .filter((values) => values.length === csvHeader.length)
  //       .map((values) => {
  //         const obj = csvHeader.reduce((object, header, index) => {
  //           object[header] = values[index].trim();
  //           return object;
  //         }, {});
  //         return obj;
  //       });

  //       console.log("array", array);
  //     return array;
  //   }

  useEffect(() => {
    fetchUserByUserTypeCount({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: requestCount,
      zoneId: zoneId,
    });
  }, [requestCount]);

  function parseCSV(string) {
    const csvHeader = string
      .slice(0, string.indexOf("\n"))
      .replace(/\s/g, "")
      .split(",");

    const csvRows = string.slice(string.indexOf("\n") + 1).split(/\r?\n/);

    // console.log("csvRows: ", csvRows.length, csvRows);

    const array = csvRows
      .map((row) => {
        const values = [];
        let insideQuotes = false;
        let currentField = "";

        for (let char of row) {
          if (char === "," && !insideQuotes) {
            values.push(currentField.trim());
            currentField = "";
          } else if (char === '"') {
            insideQuotes = !insideQuotes;
          } else {
            currentField += char;
          }
        }

        values.push(currentField.trim());
        return values;
      })
      .filter((values) => values.length === csvHeader.length)
      .map((values) => {
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index].trim();
          return object;
        }, {});
        return obj;
      });

    return array;
  }

  const checkArrayForNullEmpty = (arr, keysArray) => {

    // let onlyAlphabetsRegex = /^[a-zA-Z]+$/;

    // let onlySpaceAlphabetsRegex = /^[a-zA-Z\s]+$/;

    // let alphabetsNumberAmpersandUnderscoreRegex = /^[a-zA-Z0-9\s._&()/'']+$/;
    let dontTakeCommaRegex = /^[^,]+$/;


    // console.log(regex.test(str1));
    console.log("keys array", keysArray);

    const mobileKey = "mobile";
    let mobileCheck = true;

    const nameKey = "name";
    let nameCheck = true;

    let pincodeCheck = false;
    const pincodeKey = "pincode";

    const stateKey = "state";
    let stateCheck = false;

    const districtKey = "district";
    let districtCheck = false;

    keysArray.forEach((each) => {
      if (each.name === "pincode") {
        pincodeCheck = true;
      }
      else if (each.name === "state") {
        stateCheck = true;
      }
      else if (each.name === "district") {
        districtCheck = true;
      }
    })



    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];


      if (mobileCheck && (
        !obj.hasOwnProperty(mobileKey) ||
        obj[mobileKey] === "" ||
        obj[mobileKey].length != 10 || isNaN(obj[mobileKey]))
      ) {
        toast.error(`Issue at row ${i + 1} in field "${mobileKey}" with value "${obj[mobileKey]}"`, {
          id: mobileKey
        });
        return false;
      }


      if (pincodeCheck && (
        !obj.hasOwnProperty(pincodeKey) ||
        obj[pincodeKey] === "" ||
        obj[pincodeKey].length != 6 || isNaN(obj[pincodeKey]))
      ) {
        toast.error(`Issue at row ${i + 1} in field "${pincodeKey}" with value "${obj[pincodeKey]}"`, {
          id: pincodeKey
        });
        return false;
      }

      if (nameCheck && (
        !obj.hasOwnProperty(nameKey) ||
        obj[nameKey] === "" ||
        obj[nameKey].trim().length == 0 || !dontTakeCommaRegex.test(obj[nameKey].trim()))) {
        toast.error(`Issue at row ${i + 1} in field "${nameKey}" with value "${obj[nameKey]}"`, {
          id: nameKey
        });
        return false;
      }


      if (stateCheck && (
        !obj.hasOwnProperty(stateKey) ||
        obj[stateKey] === "" ||
        obj[stateKey].trim().length == 0 || !dontTakeCommaRegex.test(obj[stateKey].trim()))) {
        toast.error(`Issue at row ${i + 1} in field "${stateKey}" with value "${obj[stateKey]}"`, {
          id: stateKey
        });
        return false;
      }

      if (districtCheck && (
        !obj.hasOwnProperty(districtKey) ||
        obj[districtKey] === "" ||
        obj[districtKey].trim().length == 0 || !dontTakeCommaRegex.test(obj[districtKey].trim()))) {
        toast.error(`Issue at row ${i + 1} in field "${districtKey}" with value "${obj[districtKey]}"`, {
          id: stateKey
        });
        return false;
      }
    }
    return true;
  };

  const checkArrayForNullEmptyBulkUserUpdate = (arr) => {

  }

  const applyAdditionalLogic = (dataItem) => {
    let data = {};
    const vendorData = JSON.parse(sessionStorage.getItem("vendorData"));

    if (vendorData) {
      if (
        vendorData.approval_flow_options.AutoApproval.users
          .toString()
          .includes(userType)
      ) {
        data["is_approved_needed"] = false;
      }

      if (
        vendorData.approval_flow_options.Manual.users
          .toString()
          .includes(userType)
      ) {
        data["is_approved_needed"] = true;
      }
    }

    data["user_type"] = userType;
    data["user_type_id"] = userTypeId;
    data["added_through"] = "panel";

    const userTenant = JSON.parse(sessionStorage.getItem("userTenant"));
    if (userTenant) {
      data["added_by_name"] = userTenant.name;
      data["added_by_id"] = userTenant.id;
    }

    Object.assign(dataItem, data);
  };

  const handleOnSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!file) {
      toast.error("Please select a file");
      return;
    }
    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        let datas = parseCSV(text);
        datas = datas.map((dataItem) => ({
          ...dataItem,
          user_type: userType,
          user_type_id: userTypeId,
        }));
        if (templateData && templateData?.length > 0) {
          const keysArray = templateData.map((item) => ({
            name: item.name,
            maxlength: item.maxLength,
          }));
          const res = checkArrayForNullEmpty(datas, keysArray);
          if (res === true) {
            datas.forEach((dataItem) => {
              applyAdditionalLogic(dataItem);
            });
            bulkRegisterUserFunc({
              body: { users: datas },
              tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                .tenant_id,
            });
          } else {
            return;
          }
        }
      };

      fileReader.readAsText(file);
    }
  };


  const handleBulkUserUpdate = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!bulkUpdatefile) {
      toast.error("Please select a file");
      return;
    }
    const fileReader = new FileReader();
    if (bulkUpdatefile) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        let datas = parseCSV(text);
        datas = datas.map((dataItem) => ({
          ...dataItem,
        }));
        const res = true
        checkArrayForNullEmptyBulkUserUpdate(datas);
        if (res === true) {

          if (window.confirm(`Are you sure you want to update ${datas.length} Users?`)) {
            // console.log("datas", datas)
            bulkUpdateAppUser({
              body: { users: datas },
              token: sessionStorage.getItem("access_token"),
              tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                .tenant_id,
            });
          }
        } else {
          return;
        }
      };

      fileReader.readAsText(bulkUpdatefile);
    }
  };


  useEffect(() => {
    toast.dismiss();
    if (bulkUpdateAppUserData) {
      console.log("bulkUpdateAppUserData", bulkUpdateAppUserData);
    }

    if (bulkUpdateAppUserIsLoading) {
      console.log("bulkUpdateAppUserIsLoading", bulkUpdateAppUserIsLoading);
    }

    if (bulkUpdateAppUserError) {
      console.log("bulkUpdateAppUserError", bulkUpdateAppUserError);
    }
  }, [bulkUpdateAppUserData, bulkUpdateAppUserIsLoading, bulkUpdateAppUserError])


  const handleOnSubmitUserPoints = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!file) {
      toast.error("Please select a file");
      return;
    }
    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        let datas = parseCSV(text);
        datas = datas.map((dataItem) => ({
          ...dataItem,
          user_type: userType,
          user_type_id: userTypeId,
        }));
        bulkUserPointsUpdateFunc({
          token: sessionStorage.getItem("access_token"),
          body: { users: datas },
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        });
      };

      fileReader.readAsText(file);
    }
  };

  const fetchAddbanUnbanFunction = (row) => {
    console.log(row, "Row HERE");
    if (row) {
      AddbanUnban({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: {
          appUserId: row?.id,
          userTypeId: row?.user_type_id,
          userType: row?.user_type,
          userName: row?.name,
          userMobile: row?.mobile,
          status: "0",
          remarks,
        },
      });
    }
  };

  useEffect(() => {
    if (bulkRegisterUserData) {
      console.log(bulkRegisterUserData, "Here is Register Data");
      toast.success("Users Added Successfully!");
      return;
    }
    if (bulkRegisterUserError) {
      console.log(bulkRegisterUserError, "Error in Registor Data");
      toast.error("Error in Addin User !");
      return;
    }
  }, [bulkRegisterUserData, bulkRegisterUserError]);
  const [
    uploadImages,
    {
      data: uploadImagesData,
      error: uploadImagesError,
      isError: uploadImagesIsError,
      isLoading: uploadImagesIsLoading,
    },
  ] = useUploadSingleFileMutation();

  useEffect(() => {
    if (uploadImagesData) {
      const arr = [];
      // for (let i = 0; i < uploadImagesData.body.length; i++) {
      //   const element = uploadImagesData.body[i];
      //   arr.push(element.filename);
      // }
      console.log("success image upload", uploadImagesData.body);
      arr.push(uploadImagesData.body.fileLink);

      const updatedArray = templateData.map((obj, i) => {
        if (obj.name == "profile_pic") {
          return { ...obj, value: arr[0] };
        }
        return obj;
      });
      setImagesData(arr);
      setTemplateData(updatedArray);
    }

    if (uploadImagesError) {
      console.log("Something went wrong while uploading image");
      toast.error("Something went wrong while uploading image", {
        id: "uploadImagesError",
      });
    }
  }, [uploadImagesData, uploadImagesError]);

  const [
    getFormTemplate,
    { data: formTemplateData, error: formTemplateError },
  ] = useGetFormTemplateMutation();

  const fetchFormTemplate = () => {
    getFormTemplate({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      form_type: "7",
      user_type: userType,
    });
  };

  useEffect(() => {
    // console.log("fetching form data", userType);
    fetchFormTemplate();
  }, [userType]);

  useEffect(() => {
    if (
      formTemplateData &&
      formTemplateData.body &&
      formTemplateData.body.template
    ) {
      const arr = Object.values(formTemplateData.body.template);
      const data = [];

      let mobilePresnt = false;
      let namePresent = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].name == "mobile") {
          mobilePresnt = true;
        }

        if (arr[i].name == "name") {
          namePresent = true;
        }

        if (arr[i].type.trim().toLowerCase() == "file") {
          const element = {
            ...arr[i],
            type: "file",
            value: "",
          };
          data.push(element);
        } else {
          const element = { ...arr[i], value: "" };
          data.push(element);
        }
      }

      if (mobilePresnt && namePresent) {
        console.log("valid ");
        setIsFormValid(true);
      }
      setTemplateData(data);
    } else {
      console.log("Form Template Not Found");
      setTemplateData(null);
    }

    if (formTemplateError) {
      console.log("formTemplateError", formTemplateError);
    }
  }, [formTemplateData, formTemplateError]);

  const handleFormTemplateValueChange = (idx, type, value) => {
    const updatedArray = templateData.map((obj, i) => {
      if (i === idx) {
        return { ...obj, value: value };
      }
      return obj;
    });
    console.log(updatedArray);
    setTemplateData(updatedArray);
  };

  const [
    getLocationByPincode,
    {
      data: GetLocationByPincodeData,
      error: GetLocationByPincodeError,
      isError: GetLocationByPincodeIsError,
      isLoading: GetLocationByPincodeIsLoading,
    },
  ] = useGetLocationByPincodeMutation();

  useEffect(() => {
    if (GetLocationByPincodeData) {
      toast.dismiss();
      toast.success(GetLocationByPincodeData.message, {
        id: "GetLocationByPincodeData",
      });

      if (GetLocationByPincodeData && GetLocationByPincodeData.body) {
        const updatedArray = templateData.map((obj, i) => {
          if (obj.name == "state") {
            return { ...obj, value: GetLocationByPincodeData.body[0].state };
          }
          if (obj.name == "district") {
            return { ...obj, value: GetLocationByPincodeData.body[0].district };
          }
          return obj;
        });
        setTemplateData(updatedArray);
      }
    }
    if (GetLocationByPincodeError) {
      toast.dismiss();
      toast.error(GetLocationByPincodeError.data.message, {
        id: "GetLocationByPincodeError",
      });
    }
  }, [GetLocationByPincodeData, GetLocationByPincodeError]);

  useMemo(() => {
    console.log("userType changed:", userType);
    for (let index = 0; index < appUserTypes.length; index++) {
      const element = appUserTypes[index];
      if (
        element.user_type.toLocaleLowerCase() === userType.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: element.user_type_id,
        }));
        break;
      }
    }
    let tempKycOptions = [];
    // console.log("tenantdata", tenantData);
    if (tenantData && tenantData.kyc_options) {
      Object.keys(tenantData.kyc_options).map((x) => {
        tenantData.kyc_options[x].users.map((e) => {
          if (e == userType) {
            tempKycOptions.push(x.toLowerCase());
          }
        });
      });
    }
    setKycOptions([...tempKycOptions]);
  }, [userType]);

  // useEffect(() => {
  //   fetchAppUserByUserTypeFunction();

  //   let tempRequest = { ...request };
  //   delete tempRequest.limit;
  //   delete tempRequest.offset;

  //   if (!objectsAreEqual(tempRequest, requestCount)) {
  //     setRequestCount(tempRequest);
  //   }
  //   // setCsvData(null);
  //       // fetchAppUserByUserTypeCsvFunction();
  // }, [location.pathname, limit, offset, userType]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
    setRequest({ ...request, offset: newOffset });
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
    setRequest({ ...request, limit: newPerPage });
  };

  const fetchAppUserByUserTypeFunction = () => {
    request["limit"] = limit;
    request["offset"] = offset;

    // console.log("zoneId---> at fetch", zoneId);
    fetchAppUserByUserType({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      zoneId: zoneId,
    });
  };

  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    if (appUsersCsvData && appUsersCsvData?.body?.appUsersList) {
      setCsvData(appUsersCsvData?.body.appUsersList);
    }
    if (appUsersCsvError) {
      setCsvData(null);
    }
  }, [appUsersCsvData, appUsersCsvError]);

  const fetchAppUserByUserTypeCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchAppUserByUserTypeCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      zoneId: zoneId,
    });
  };
  const clearFilters = () => {
    setRequest(initialRequest);
  };
  useEffect(() => {

    if (JSON.parse(sessionStorage.getItem("vendorData")).tenant_name !== "jqr") {
      navigate(`/genefied/${getVendorName()}/`);
    }
    if (
      request.mobile === "" &&
      request.user_type_id === userTypeId &&
      request.name === "" &&
      request.state === "" && request.startDate === "" && request.endDate === currentDate
    ) {
      setCsvData(null);
      // fetchAppUserByUserTypeCsvFunction();
    }
    fetchAppUserByUserTypeFunction();

    let tempRequest = { ...request };
    delete tempRequest.limit;
    delete tempRequest.offset;

    if (!objectsAreEqual(tempRequest, requestCount)) {
      setRequestCount(tempRequest);
    }
  }, [request, location.pathname, userType, limit, offset]);

  useEffect(() => {
    if (appUsersData) {
      console.log("app users data ", appUsersData);
      setCheckBox(appUsersData.body.appUsersList);
      setMyList(
        appUsersData && appUsersData.body && appUsersData.body.appUsersList
          ? appUsersData.body.appUsersList
          : []
      );
    }

    if (appUsersError) {
      console.log("app users error", appUsersError);
    }
  }, [appUsersData, appUsersError]);

  const handleStatuChange = (row, status) => {
    console.log("row data ", row);
    updateAppUserStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: Number(row.id),
      data: {
        status: status,
      },
    });
  };

  const handlebanChange = (row, status) => {
    if (!remarks) {
      toast.error("Please enter Some remarks", {
        id: "remarksError",
      });
      return;
    }
    fetchAddbanUnbanFunction(row);
  };
  useEffect(() => {
    if (AddbanUnbanData) {
      if (row) {
        updateAppUserStatus({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
          id: Number(row.id),
          data: {
            status: "0",
          },
        });
      }
    }
    if (AddbanUnbanError) {
      toast.error("Error in Banning User");
    }
  }, [AddbanUnbanData, AddbanUnbanError]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [selectedParentUser, setselectedParentUser] = useState(null);

  console.log("checked box", checkBox);

  useEffect(() => {
    if (updateAppUserStatusData && !updateAppUserStatusIsError) {
      toast.success("successfully update status ");
      let userTypeId = 0;
      const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
      for (let index = 0; index < appUserTypes.length; index++) {
        const element = appUserTypes[index];
        if (
          element.user_type.toLocaleLowerCase() === userType.toLocaleLowerCase()
        ) {
          userTypeId = element.user_type_id;
        }
      }
      fetchAppUserByUserType({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: request,
        zoneId: zoneId,
      });
    }
  }, [updateAppUserStatusData, updateAppUserStatusIsError]);


  useEffect(() => {
    if (updateKycStatusData) {
      toast.dismiss();
      toast.success("updated successfully", {
        id: "updateKycStatusData",
      });
    }
    if (updateKycStatusError) {
      toast.dismiss();
      toast.error(updateKycStatusError.data.message, {
        id: "updateKycStatusError",
      });
    }
  }, [updateKycStatusData, updateKycStatusError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Registration Date",
      cell: (row) => (
        <div className="flex">
          <span className="mr-2 w-fit">
            {new Date(row.enrollment_date).toLocaleDateString()}
          </span>

          {/* <span className="w-fit">
            {new Date(row.created_at).toLocaleTimeString()}
          </span> */}
        </div>
      ),
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "State",
      cell: (row) => row.state,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "District",
      cell: (row) => row.district,
      sortable: true,
      minWidth: "120px",
    },

    {
      name: "City",
      cell: (row) => row.city,
      sortable: true,
      minWidth: "120px",
    },

    {
      name: "Zone Name",
      cell: (row) => row?.zone_name,
      sortable: true,
      minWidth: "200px",
    },

    // {
    //   name: "User Id",
    //   cell: (row) => row.user_id,
    //   sortable: true,
    //   minWidth: "200px",
    // },

    {
      name: "Name",
      cell: (row) => (
        <div>
          <h2
            onClick={() =>
              navigate(`${row.id}`, {
                state: row,
              })
            }
            className=" underline text-blue-600"
          >
            {row.name}
          </h2>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },

    {
      name: "Email",
      cell: (row) => row.email,
      sortable: true,
      width: "150px",
    },
    {
      name: "Shop Name",
      cell: (row) => row.firm_name,
      sortable: true,
      width: "120px",
    },
    // {
    //   name: "Shop Id",
    //   cell: (row) => row.firm_id,
    //   sortable: true,
    // },
    {
      name: "Pincode",
      cell: (row) => row.pincode,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) =>
        updateStatusPermission &&
        (row.status == "1" ? (
          <>
            <button
              onClick={() => {
                handleStatuChange(row, "2");
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Active
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              handleStatuChange(row, "1");
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            InActive
          </button>
        )),
    },
    {
      name: "Dealer/Distributor Name",
      cell: (row) => row.dealer_name,
      sortable: true,
      minWidth: "220px",
    },
    {
      name: "Dealer/Distributor Mobile",
      cell: (row) => row.dealer_mobile,
      sortable: true,
      minWidth: "220px",
    },

    {
      name: "Lat ",
      cell: (row) => row.lat,
      sortable: true,
    },
    {
      name: "Log ",
      cell: (row) => row.log,
      sortable: true,
      width: "120px",
    },
    {
      name: "Address ",
      cell: (row) => row.address1,
      sortable: true,
    },
    {
      name: "L1 Manager",
      cell: (row) => row.l1_manager,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "L1 Manager Mobile",
      cell: (row) => row.l1_manager_mobile,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "L1 Manager Email",
      cell: (row) => row.l1_manager_email,
      sortable: true,
      minWidth: "200px",
    },


    {
      name: "Scanned Status",
      cell: (row) => (row.is_scanned ? "True" : "False"),
      sortable: true,
      minWidth: "200px",
    },

    {
      name: "Last Login",
      cell: (row) =>
        row.login_time && (
          <div className="flex">
            <span className="mr-2 w-fit">
              {new Date(row.login_time).toLocaleDateString()}
            </span>

            <span className="w-fit">
              {new Date(row.login_time).toLocaleTimeString()}
            </span>
          </div>
        ),
      sortable: true,
      minWidth: "180px",
    },

    {
      name: "App Version",
      cell: (row) => row.app_version,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Ban User",
      cell: (row) =>
        updateStatusPermission && (
          <>
            <button
              onClick={() => {
                setShowModal1(true);
                setRow(row);
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Ban
            </button>
          </>
        ),
    },
    {
      name: "User Mapping",
      cell: (row) => (
        <button
          onClick={() => {
            setselectedParentUser(row);
            setUserMappingModal(true);
          }}
          className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        >
          <AiOutlineUsergroupAdd className="mr-1" />
          {/* Map Users */}
        </button>
      ),
      sortable: true,
      // minWidth: "150px",
    },
    {
      name: "Remove",
      cell: (row) => (
        (row.is_scanned ? <> </> : (removeAccountPermission && <button
          onClick={() => {
            setShowRemoveModal(true);
            setRemoveRemarks("");
            setSelectedUser(row);
          }}
          className="flex justify-center items-center rounded bg-red-500 px-2.5 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#f87171] transition duration-150 ease-in-out hover:bg-red-600 hover:shadow-[0_8px_9px_-4px_rgba(248,113,113,0.3),0_4px_18px_0_rgba(248,113,113,0.2)] focus:bg-red-600 focus:shadow-[0_8px_9px_-4px_rgba(248,113,113,0.3),0_4px_18px_0_rgba(248,113,113,0.2)] focus:outline-none focus:ring-0 active:bg-red-700 active:shadow-[0_8px_9px_-4px_rgba(248,113,113,0.3),0_4px_18px_0_rgba(248,113,113,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(248,113,113,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(248,113,113,0.2),0_4px_18px_0_rgba(248,113,113,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(248,113,113,0.2),0_4px_18px_0_rgba(248,113,113,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(248,113,113,0.2),0_4px_18px_0_rgba(248,113,113,0.1)]"

        >
          {/* <MdRemove className="mr-1" /> */}
          Remove
        </button>))
      ),
    },
  ];

  const [
    registerUserFunc,
    {
      data: registerUserData,
      error: registerUserError,
      isLoading: registerUserIsLoading,
    },
  ] = useRegisterUserMutation();

  const handleRegistration = (e) => {
    e.preventDefault();

    console.log("form valid", isFormValid);
    if (!isFormValid) {
      toast.error("Form is not valid", { id: "formNotValid" });
      return;
    }

    let data = {};

    Object.values(templateData).map((x, index) => {
      console.log(x, index);

      if (
        x.name == "mobile" &&
        (!x.value || x.value.length < 10 || x.value.length > 10)
      ) {
        console.log("mobile not valid", x);
        toast.error("Mobile is not valid");
        return;
      }
      if (x.name == "name" && (!x.value || x.value.length < 2)) {
        toast.error("Please enter name", { id: "noName" });
        return;
      }

      data[x.name] = x.value;
    });

    if (
      JSON.parse(sessionStorage.getItem("vendorData"))
        .approval_flow_options.AutoApproval.users.toString()
        .includes(userType)
    ) {
      data["is_approved_needed"] = false;
    }
    if (
      JSON.parse(sessionStorage.getItem("vendorData"))
        .approval_flow_options.Manual.users.toString()
        .includes(userType)
    ) {
      data["is_approved_needed"] = true;
    }

    data["user_type"] = userType;
    data["user_type_id"] = userTypeId;
    data["added_through"] = "panel";
    data["added_by_name"] = JSON.parse(
      sessionStorage.getItem("userTenant")
    ).name;
    data["added_by_id"] = JSON.parse(sessionStorage.getItem("userTenant")).id;

    console.log("data", data);
    // return;
    registerUserFunc({
      body: data,
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  useEffect(() => {
    if (registerUserData) {
      console.log("registerUserData", registerUserData);
      setShowModal(false);
      toast.success("User Added Successfully", { id: "userAddSuccess" });
    }

    if (registerUserError) {
      console.log("registerUserError", registerUserError);
      if (registerUserError.status == 409) {
        toast.error("User Already Registered", { id: "userAddError" });
      } else if (registerUserError.status == 400) {
        toast.error("Bad Request , Fields Missing", { id: "userAddError" });
      } else {
        toast.error("Something went wrong", { id: "userAddError" });
      }
    }
  }, [registerUserData, registerUserError]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleOptionSelect = (option) => {
    console.log("optionn", option);

    let userType = "";

    appUserTypes.map((user) => {
      if (user.user_type_id == option) {
        userType = user.user_type;
      }
    });

    fetchMappedUsersByAppUserId({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      app_user_id: selectedParentUser.id,
      mapped_user_type: userType,
    });

    fetchMappedUsersByUserType({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      user_type: selectedParentUser.user_type,
      mapped_user_type: userType,
    });

    fetchUserAccordingToUserType(option, initialRequest);

    setSelectedOption(option);
  };

  const fetchUserAccordingToUserType = (user_type_id, request) => {
    console.log("user_type_id", user_type_id);
    const newRequest = request;

    newRequest["user_type_id"] = user_type_id;
    newRequest["limit"] = 20;
    newRequest["offset"] = 0;

    // if(newRequest.name == "" || newRequest.name.length < 3){
    //   return;
    // }
    console.log("request for mapping", newRequest);
    fetchAppUsersListFunc({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: newRequest,
      zoneId: zoneId,
    });
  };

  const [allUserOptions, setAllUserOptions] = useState([]);
  const [allUserOptionsCsv, setAllUserOptionsCsv] = useState([]);

  const [
    fetchMappedUsersByUserType,
    { data: mappedUserDataByUserType, error: mappedUserErrorByUserType },
  ] = useFetchUserMappingByUserTypeAndMappedUserTypeMutation();

  useEffect(() => {
    if (mappedUserDataByUserType && mappedUserDataByUserType.body) {
      console.log("mappedUserDataByUserType", mappedUserDataByUserType);
    }

    if (mappedUserErrorByUserType) {
      console.log("mappedUserErrorByUserType", mappedUserErrorByUserType);
    }
  }, [mappedUserDataByUserType, mappedUserErrorByUserType]);

  useEffect(() => {
    if (fetchUserByUserTypeCountData) {
      console.log("mappedUserDataByUserType", mappedUserDataByUserType);
      setTotalRows(fetchUserByUserTypeCountData && fetchUserByUserTypeCountData?.body?.total
        ? fetchUserByUserTypeCountData?.body?.total : 0)
    }

    if (fetchUserByUserTypeCountError) {
      console.log("mappedUserErrorByUserType", mappedUserErrorByUserType);
    }
  }, [fetchUserByUserTypeCountData, fetchUserByUserTypeCountError]);

  let [
    fetchMappedUsersByAppUserId,
    { data: mappedUserData, error: mappedUserError },
  ] = useFetchUserMappingByAppUserIdAndMappedUserTypeMutation();

  const [mappedUserDataByAppUserId, setMappedUserDataByAppUserId] = useState(
    []
  );

  useEffect(() => {
    if (mappedUserData && mappedUserData.body) {
      console.log("mappedUserData", mappedUserData.body);
      setMappedUserDataByAppUserId(mappedUserData.body);
    }

    if (mappedUserError) {
      console.log("mappedUserError", mappedUserError);
    }
  }, [mappedUserData, mappedUserError]);

  useEffect(() => {
    if (
      appUsersListData &&
      appUsersListData?.body?.appUsersList &&
      mappedUserData &&
      mappedUserData.body &&
      mappedUserDataByUserType &&
      mappedUserDataByUserType.body
    ) {
      console.log("appUsersListData", appUsersListData);
      console.log("mappedUserByUSerType", mappedUserDataByUserType);

      const tempArr = removeDuplicatesByMobile(
        appUsersListData.body.appUsersList,
        mappedUserData.body
      );

      const finalArr = removeAlreadyMappedUsers(
        tempArr,
        mappedUserDataByUserType.body
      );
      console.log("finalArr ", finalArr);
      setAllUserOptions(addCheckedKey(finalArr));
    }

    if (appUsersListError) {
      console.log("appUsersListError", appUsersListError);
    }
  }, [
    appUsersListData,
    appUsersListError,
    mappedUserData,
    mappedUserDataByUserType,
  ]);

  const handleItemToggle = (
    itemToToggle,
    isMultiple = false,
    mobileNumbers = []
  ) => {
    if (isMultiple && mobileNumbers.length > 0) {
      const newArray = allUserOptions.map((item) => {
        if (mobileNumbers.includes(item.mobile)) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });

      setAllUserOptions(newArray);
    } else {
      const newArray = allUserOptions.map((item) => {
        if (item.id === itemToToggle.id) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
      setAllUserOptions(newArray);
    }
  };

  // console.log("selectedddddd", allUserOptions);

  const [
    createUserMappingFunc,
    { data: createUserMappingData, error: createUserMappingError },
  ] = useCreateUserMappingMutation();

  const [
    createUserMappingBulkFunc,
    { data: createUserMappingBulkData, error: createUserMappingBulkError },
  ] = useCreateUserMappinginBulkMutation();

  useEffect(() => {
    if (createUserMappingBulkData) {
      toast.success("Mapping created successfully", {
        id: "createUserMappingData",
      });
    }

    if (createUserMappingBulkError) {
      toast.error("Something went wrong", { id: "createUserMappingError" });
    }
  }, [createUserMappingBulkData, createUserMappingBulkError]);

  const handleCreateUserMappingBulk = () => {
    console.log("creatdfsdfd", mappedForType);

    const fileReader = new FileReader();
    if (mappingFile) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        let datas = parseCSV(text);
        const newMappingFile = datas.map((x) => {
          return {
            ...x,
            mapped_for: mappedForType.name,
          };
        });
        console.log("datas", datas);
        console.log("newMappingFile", newMappingFile);
        createUserMappingBulkFunc({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
          body: {
            rows: newMappingFile,
          },
        });
      };
      fileReader.readAsText(mappingFile);
    }
  };

  const handleCreateUserMapping = () => {
    const userMapping = allUserOptions.filter((item) => item.checked === true);
    // console.log("selected users ", userMapping);

    const finalArr = createUserMappingArray(selectedParentUser, userMapping);

    console.log("finalArr ", finalArr);

    createUserMappingFunc({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        rows: finalArr,
      },
    });
  };

  const [userMappingCsv, setUserMappingCsv] = useState(null);

  const handleUploadUserMappinCsv = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!e.target.files[0]) {
      toast.error("Please select a file");
      return;
    }
    const fileReader = new FileReader();
    if (e.target.files[0]) {
      setUserMappingCsv(e.target.files[0]);
      fileReader.onload = function (event) {
        const text = event.target.result;
        let datas = parseCSV(text);

        console.log(datas, "Datas parsed");
        const mobiles = datas.map((each) => each.mobile);
        handleItemToggle(null, true, mobiles);
      };

      fileReader.readAsText(e.target.files[0]);
    }
  };

  // console.log("userMappingCsv", userMappingCsv);

  useEffect(() => {
    if (createUserMappingData) {
      console.log("createUserMappingData", createUserMappingData);
      closeMappingModal(false);
      toast.success("Mapping created successfully", {
        id: "createUserMappingData",
      });
    }

    if (createUserMappingError) {
      console.log("createUserMappingError", createUserMappingError);

      toast.error("Something went wrong", { id: "createUserMappingError" });
    }
  }, [createUserMappingData, createUserMappingError]);

  function closeMappingModal(flag) {
    console.log("called close modal", flag);
    if (flag === false) {
      setSearchQuery("");
      setAllUserOptions([]);
      setUserMappingModal(false);
      setSelectedOption(null);
      setselectedParentUser(null);
      setMappedUserDataByAppUserId([]);
      setUserMappingCsv(null);
    }
  }

  const handleDeleteMapping = (data) => {
    if (window.confirm("are you sure you want to delete this")) {
      deleteMapping({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: data.id,
      });
    }
    return;
  };

  const [
    deleteMapping,
    { data: deleteMappingData, error: deleteMappingError },
  ] = useDeleteUserMappingMutation();

  useEffect(() => {
    if (deleteMappingData) {
      console.log("deleteMappingData", deleteMappingData);
      toast.success("Deleted Successfully", { id: "deleteMappingData" });
      closeMappingModal(false);
    }

    if (deleteMappingError) {
      console.log("deleteMappingError", deleteMappingError);
      toast.dismiss();
      toast.error("Something went wrong", { id: "deleteMappingError" });
    }
  }, [deleteMappingData, deleteMappingError]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryMobile, setSearchQueryMobile] = useState("");
  // const filteredOptions = allUserOptions.filter((item) =>
  //   item.name.toLowerCase().includes(searchQuery.toLowerCase()),
  // );
  let filteredOptions = allUserOptions;

  // console.log("filtered option", filteredOptions);

  useEffect(() => {
    if (searchQuery.length > 2) {
      console.log("searchQuery", searchQuery);
      const newRequest = initialRequest;

      newRequest["name"] = searchQuery;
      fetchUserAccordingToUserType(selectedOption, newRequest);
    }
    if (searchQueryMobile.length > 2) {
      console.log("searchQueryMobile", searchQueryMobile);
      const newRequest = initialRequest;
      newRequest["mobile"] = searchQueryMobile;
      fetchUserAccordingToUserType(selectedOption, newRequest);
    }
  }, [searchQuery, searchQueryMobile]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchMobileInputChange = (event) => {
    setSearchQueryMobile(event.target.value);
  };


  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeRemarks, setRemoveRemarks] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);


  const removeAppUserFunc = () => {

    if (!removeRemarks || removeRemarks.length == 0) {
      toast.error("Message is required.")
      return;
    }
    removeAppUser({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        message: removeRemarks,
      },
      id: selectedUser.id
    });
  };


  useEffect(() => {
    if (removeAppUserData) {
      console.log("removeAppUserData", removeAppUserData);
      setShowRemoveModal(false);
      setRemoveRemarks("");
      setSelectedUser(null);
      fetchAppUserByUserTypeFunction();
    }

    if (removeAppUserError) {
      console.log("removeAppUserError", removeAppUserError);
      setShowRemoveModal(false);
      setRemoveRemarks("");
      setSelectedUser(null);
    }
  }, [removeAppUserData, removeAppUserError])


  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {userType.charAt(0).toUpperCase() + userType.slice(1)} List
            <LinksComponent />
          </div>

          <div className="p-3 ">
            <div class="flex justify-between">
              <div className="flex justify-between items-start">
               
                <div>
                  <CustomExport
                    fetchData={fetchAppUserByUserTypeCsvFunction}
                    data={csvData}
                    defaultColumnExport={[
                      "id",
                      "state",
                      "user_id",
                      "district",
                      "city",
                      "zone_name",
                      "name",
                      "mobile",
                      "email",
                      "firm_name",
                      "firm_id",
                      "pincode",
                      "status",
                      "dealer_name",
                      "dealer_mobile",
                      "pan",
                      "aadhar",
                      "address1",
                      "l1_manager",
                      "l1_manager_mobile",
                      "l1_manager_email",
                      "created_at",
                      "point_earned"
                      ]}
                    filename="appUsers"
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <button
                  className="btn btn-outline-primary clear-both float-right  mb-2"
                  onClick={() => {
                    setShowModal3(true);
                  }}
                >
                  Add {userType.charAt(0).toUpperCase() + userType.slice(1)}{" "}
                  Points Csv
                </button>
                <button
                  className="btn btn-outline-primary clear-both float-right  mb-2"
                  onClick={() => {
                    setShowModal2(true);
                  }}
                >
                  Add {userType.charAt(0).toUpperCase() + userType.slice(1)} Csv
                </button>
                <button
                  className="btn btn-outline-primary clear-both mb-2"
                  onClick={() => {
                    setShowBulkUpdateModal(true);
                  }}
                >
                  Bulk Update {userType.charAt(0).toUpperCase() + userType.slice(1)}
                </button>

                <button
                  className="btn btn-outline-primary clear-both float-right  mb-2"
                  onClick={() => {
                    setShowModal4(true);
                  }}
                >
                  Add Mapping Csv
                </button>
              </div>
            </div>

            <div className="w-full bg-white shadow rounded-lg">
              {appUsersIsLoading && <CircleLoaderComponent />}
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={myList}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                  <div className="flex  space-x-2 justify-end">
                    <div className="w-52 h-10 ">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          Mobile
                        </label>
                        <input
                          type="search"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="Mobile..."
                          required
                          value={request.mobile}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, mobile: newValue };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-52 h-10 ">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          Name
                        </label>
                        <input
                          type="search"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="Name..."
                          required
                          value={request.name}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, name: newValue };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-52 h-10 ">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          State
                        </label>
                        <input
                          type="search"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="State..."
                          required
                          value={request.state}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, state: newValue };
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="w-52 h-10">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          User Scanned
                        </label>
                        <select
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          name="User Scanned"
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, is_scanned: newValue };
                            });
                          }}
                          id=""
                        >
                          <option selected value={true}>
                            Scans Active
                          </option>
                          <option value={false}>Scans Inactive</option>
                        </select>
                      </div>
                    </div>


                    <div className="w-52 h-10">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          User Status
                        </label>
                        <select
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          name="User Scanned"
                          //   value={request.status}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRequest((prevState) => {
                              return { ...prevState, status: newValue };
                            });
                          }}
                          id=""
                        >
                          <option selected value={""}>
                            All
                          </option>
                          <option value={"1"}>
                            Active
                          </option>
                          <option value={"2"}> Inactive</option>
                        </select>
                      </div>
                    </div>

                    <div className="w-32 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-44 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>


                    <div className="flex flex-row  gap-2 h-10">
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          &nbsp;
                        </label>
                        <button
                          className="btn btn-outline-primary clear-both float-right  p-2"
                          onClick={() => {
                            fetchAppUserByUserTypeFunction();
                            // fetchAppUserByUserTypeCsvFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          &nbsp;
                        </label>
                        <button
                          className="btn btn-outline-primary clear-both float-right p-2"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DataTableComponent>

              {templateData && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => setShowModal(true)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showModal && templateData && (
          <ModalComponent
            size={"md"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-3 px-5">
              <h1 className="text-xl text-center capitalize font-semibold">
                Add {userType}
              </h1>
              {templateData &&
                templateData.map((element, idx) => {
                  return element.type === "select" ? (
                    <div className="col-md-12 my-3" key={idx}>
                      {/* <SelectComponent
                        title={element.label}
                        selected={{ id: 0, name: `Select ${element.label}` }}
                        // setSelected={setselectedBrandName}
                        dataArr={element.options ? element.options : []}
                      /> */}
                      <label htmlFor={element.label}>
                        {" "}
                        {element.label} {element.required ? "*" : ""}
                      </label>
                      <select
                        id=""
                        name={element.label}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5"
                        onChange={(e) => {
                          handleFormTemplateValueChange(
                            idx,
                            element.label,
                            e.target.value
                          );
                        }}
                      >
                        <option value="-1" disabled>
                          Select {element.label}
                        </option>
                        {element.options &&
                          element.options.map((op) => (
                            <option
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full "
                              value={op}
                            >
                              {" "}
                              {op}
                            </option>
                          ))}
                      </select>
                    </div>
                  ) : element.type === "file" ? (
                    <div className="w-full col-md-12 my-2 flex flex-col justify-center ">
                      <div className="flex flex-col items-start justify-center w-full">
                        {element.label} {element.required ? "*" : ""}
                        <label
                          for="dropzone-file"
                          className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                        >
                          <div className="flex flex-col items-center justify-center">
                            <svg
                              aria-hidden="true"
                              className="w-20 h-20 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                strokeLinejoin="round"
                                stroke-width="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                              or drag and drop
                            </p>
                            <p className="text-xs text-gray-500">
                              PNG or JPG (800x400px)
                            </p>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={(e) => {
                              if (e.target.files.length > 0) {
                                const uploadFile = new FormData();

                                uploadFile.append("image", e.target.files[0]);
                                uploadImages({
                                  body: uploadFile,
                                });
                                // handleFormTemplateValueChange(
                                //   idx,
                                //   element.label,
                                //   e,
                                // );
                              }
                            }}
                          />
                        </label>
                      </div>

                      <div
                        className="d-flex justify-content-center align-items-center h-100"
                        style={{ width: "150px" }}
                      >
                        {imagesData.length > 0 &&
                          imagesData.map((img, index) => (
                            <div
                              key={index}
                              className="w-100 overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 "
                            >
                              <img
                                src={img}
                                alt=""
                                srcset=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : element.name == "pincode" ? (
                    <div className="col-md-12 my-3" key={idx}>
                      <InputComponent
                        name={element.label}
                        type={element.type}
                        maxLength={element.maxLength}
                        placeholder={element.label}
                        required={element.required}
                        value={element.value}
                        setValue={(e) => {
                          handleFormTemplateValueChange(idx, element.label, e);

                          if (e.length == 6) {
                            console.log(e);
                            getLocationByPincode({
                              pincode: e,
                              token: sessionStorage.getItem("access_token"),
                              tenant_id: JSON.parse(
                                sessionStorage.getItem("vendorData")
                              ).tenant_id,
                            });
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div className="col-md-12 my-3" key={idx}>
                      <InputComponent
                        name={element.label}
                        type={element.type}
                        maxLength={element.maxLength}
                        placeholder={element.label}
                        required={element.required}
                        value={element.value}
                        setValue={(e) =>
                          handleFormTemplateValueChange(idx, element.label, e)
                        }
                      />
                    </div>
                  );
                })}

              <div className="flex justify-center mt-4 mb-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={handleRegistration}
                >
                  Submit
                </button>
              </div>
            </div>
          </ModalComponent>
        )}

        {userMappingModal && (
          <ModalComponent
            size={"3xl"}
            data={"nadnandand"}
            show={userMappingModal}
            setShow={closeMappingModal}
          >
            <div className="w-full ">
              <div className="bg-gray-100 rounded-t-md p-4">
                <h1 className="text-xl text-center capitalize font-semibold mb-3">
                  Add Mapping for {selectedParentUser.name}
                </h1>
                <div className="relative block">
                  <select
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  h-auto"
                    onChange={(e) => handleOptionSelect(e.target.value)}
                    value={selectedOption || ""}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {appUserTypes &&
                      appUserTypes.map(
                        (option) =>
                          userType != option.user_type && (
                            <option
                              key={option.user_type_id}
                              value={option.user_type_id}
                              className="capitalize"
                            >
                              {option.user_type}
                            </option>
                          )
                      )}
                  </select>
                </div>
                <p className="text-xs">
                  * This is Parent ➡️ Child mapping , You'll map Children to
                  this Parent
                </p>
              </div>

              <div className="flex px-4 py-3 gap-5">
                {selectedOption && (
                  <div className="mt-3 flex-1">
                    <h3 className="text-lg font-medium text-gray-800 mb-3 border-b capitalize  pb-2">
                      Select items for{" "}
                      {appUserTypes &&
                        appUserTypes.map(
                          (user) =>
                            user.user_type_id == selectedOption && (
                              <p key={user.user_type_id}> {user.user_type}</p>
                            )
                        )}
                    </h3>
                    {/* <div className="flex items-center justify-center w-full">
                      <label
                        for="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                      >
                        <div className="flex flex-col items-center justify-center ">
                          <svg
                            aria-hidden="true"
                            className="w-20 h-20 mb-3 text-gray-400 mt-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              strokeLinejoin="round"
                              stroke-width="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 text-center">
                            {userMappingCsv ? (
                              userMappingCsv.name
                            ) : (
                              <>
                                <span className="font-semibold">
                                  Choose CSV file
                                </span>
                                <div>Click to upload or drag and drop</div>
                              </>
                            )}
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={handleUploadUserMappinCsv}
                        />
                      </label>
                    </div> */}
                    <div className="overflow-auto hmsl">
                      <input
                        type="text"
                        placeholder="Type name to search..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        className="mb-2 px-3 w-full py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
                      />
                      <input
                        type="text"
                        placeholder="Type mobile to search..."
                        value={searchQueryMobile}
                        onChange={handleSearchMobileInputChange}
                        className="mb-2 px-3 w-full py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
                      />
                      {filteredOptions.length > 0 ? (
                        filteredOptions.map((item) => (
                          <SelectableItem
                            key={item.id}
                            item={item}
                            isSelected={item.checked}
                            onToggle={handleItemToggle}
                          />
                        ))
                      ) : (
                        <h3 className="text-lg font-semibold text-gray-300 mb-2 text-center">
                          <svg
                            class="h-12 w-12 m-auto text-gray-300 text-center"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            {" "}
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                            <circle cx="12" cy="7" r="4" />
                          </svg>
                          No Users For Mapping
                        </h3>
                      )}
                    </div>

                    {allUserOptions?.length > 0 && (
                      <div className="flex justify-center mt-3">
                        <button
                          type="submit"
                          className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                          onClick={handleCreateUserMapping}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <div className="mt-3 flex-1 flex-col space-y-4">
                  <h3 className="text-xl font-semibold text-gray-800 border-b pb-2">
                    Mapped Users
                  </h3>
                  <div className="overflow-auto hmsl">
                    {mappedUserDataByAppUserId &&
                      mappedUserDataByAppUserId.length > 0 ? (
                      mappedUserDataByAppUserId.map((item, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center pb-2 mt-2 border-b border-gray-100  transition-colors duration-200 "
                        >
                          <span className="text-gray-800 w-2/4 truncate capitalize pr-4">
                            {item.mapped_app_user_name}
                          </span>
                          <div className="flex w-2/4 items-center space-x-4">
                            <span className="w-11/12 text-gray-600 truncate">
                              {item.mapped_app_user_mobile}
                            </span>
                            <button
                              onClick={() => handleDeleteMapping(item)}
                              className="text-white  bg-red-600 p-1 rounded-sm transition-colors duration-150 focus:outline-none"
                              aria-label="Delete User"
                            >
                              <MdDeleteForever />
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center justify-center ">
                        <h3 className="text-lg font-semibold text-gray-300 mb-2 text-center">
                          <svg
                            class="h-12 w-12 m-auto text-gray-300 text-center"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            {" "}
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                            <circle cx="12" cy="7" r="4" />
                          </svg>
                          No Mapped Users
                        </h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalComponent>
        )}
        {showModal2 && (
          <ModalComponent show={showModal2} setShow={setShowModal2} size="md">
            <div className="w-full p-4">
              {templateData && templateData?.length > 0 && (
                <div className="flex justify-center mt-3 ">
                  <button
                    type="submit"
                    className="mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() => downloadSampleCSV(templateData)}
                  >
                    Sample Csv Download
                  </button>
                </div>
              )}
              <div className="w-full m-auto  justify-center ">
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        <span className="font-semibold">Choose CSV file</span>
                        <div>Click to upload or drag and drop</div>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleOnChange}
                    />
                  </label>
                </div>
              </div>
              {templateData && templateData?.length > 0 ? (
                <div className="flex justify-center mt-3">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={(e) => handleOnSubmit()}
                  >
                    Add
                  </button>
                </div>
              ) : (
                <div className="text-red-500 rounded-lg text-sm px-5 py-2.5 text-center ">
                  No Template Found !
                </div>
              )}
            </div>
          </ModalComponent>
        )}

        {showModal3 && (
          <ModalComponent show={showModal3} setShow={setShowModal3} size="md">
            <div className="w-full p-4">
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={() =>
                    downloadSampleCSV([
                      { name: "mobile" },
                      {
                        name: "point_earned",
                      },
                      {
                        name: "point_redeemed",
                      },
                      {
                        name: "point_pending",
                      },
                      {
                        name: "point_balance",
                      },
                      {
                        name: "point_reserved",
                      },
                      {
                        name: "tds_deducted",
                      },
                      {
                        name: "cash_redeemed",
                      },
                      {
                        name: "point_expired",
                      },
                    ])
                  }
                >
                  Sample Csv Download
                </button>
              </div>
              <div className="w-full m-auto  justify-center ">
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        <span className="font-semibold">Choose CSV file</span>
                        <div>Click to upload or drag and drop</div>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleOnChange}
                    />
                  </label>
                </div>
              </div>

              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleOnSubmitUserPoints()}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}

        {showModal4 && (
          <ModalComponent show={showModal4} setShow={setShowModal4} size="md">
            <div className="w-full p-4">
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={() =>
                    downloadSampleCSV([
                      { name: "app_user_mobile" },
                      { name: "mapped_app_user_mobile" },
                    ])
                  }
                >
                  Sample Csv Download
                </button>
              </div>
              <div className="w-full m-auto  justify-center ">
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        <span className="font-semibold">Choose CSV file</span>
                        <div>Click to upload or drag and drop</div>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleOnChangeCsv}
                    />
                  </label>
                </div>
              </div>
              <div className="flex-col slc_dvd topslt">
                <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                  Mapped For
                </label>
                <div className="relative searchin">
                  <SelectComponent
                    className="capitalize"
                    //title={"User Types"}
                    selected={mappedForType}
                    setSelected={setMappedForType}
                    dataArr={mappedForArray}
                  />
                </div>
              </div>

              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleCreateUserMappingBulk()}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}

        {showBulkUpdateModal && (
          <ModalComponent show={showBulkUpdateModal} setShow={setShowBulkUpdateModal} size="md">
            <div className="w-full p-4">
              <div className="flex justify-center mt-2">
                <button
                  type="submit"
                  className="mb-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={() =>
                    downloadCSV(
                      sampleUserUpdate,
                      "sampleUserUpdate.csv",
                    )
                  }
                >
                  Sample Csv Download
                </button>
              </div>
              <div className="w-full m-auto  justify-center ">
                <div className="flex items-center mt-4 justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        <span className="font-semibold">Choose CSV file</span>
                        <div>Click to upload or drag and drop</div>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleOnBulkUserUpdateChange}
                    />
                  </label>
                </div>
              </div>
              <p className="w-full text-center text-xs">
                <b>id</b> column is mandatory. Please provide only the columns you wish to update.
              </p>
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleBulkUserUpdate()}
                >
                  Update
                </button>
              </div>


            </div>
          </ModalComponent>
        )}

        {showModal1 && (
          <ModalComponent
            size={"xl"}
            data={"nadnandand"}
            show={showModal1}
            setShow={setShowModal1}
          >
            <div className="w-full p-4">
              <div className="w-full m-auto  justify-center ">
                <div className="flex gap-1 ">
                  <div className="pb-1  bottom-2 w-full mb-2">
                    <InputComponent
                      name={"Remarks"}
                      type={"text"}
                      value={remarks}
                      setValue={setRemarks}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-3">
                <button
                  className="text-white bg-blue-700 hover-bg-blue-800 focus-ring-4 focus-outline-none focus-ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={() => {
                    setShowModal1(false);
                    handlebanChange(row, "0");
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}



        {showRemoveModal && (
          <ModalComponent
            size={"md"}
            data={"nadnandand"}
            show={showRemoveModal}
            setShow={setShowRemoveModal}
          >
            <div className="w-full p-3">
              <div className="w-full flex justify-center ">
                <div className="mb-6 w-full">

                  <InputComponent
                    name={"Message"}
                    type={"textarea"}
                    value={removeRemarks}
                    placeholder={"Enter Message"}
                    setValue={setRemoveRemarks}
                    required={true}
                  />
                  <span className="text-xs mt-1 ">* This change cannot be reverted.</span>
                </div>
              </div>
              <div className="flex justify-center">

                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => removeAppUserFunc(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default JqrUserList;

const SelectableItem = ({ item, isSelected, onToggle }) => {
  return (
    <div
      className={`flex justify-between items-center px-3 py-2 border border-gray-300 rounded-md mb-2 transition-colors duration-150 ease-in-out 
        ${isSelected
          ? "bg-blue-100 border-l-4 border-blue-500"
          : "hover:bg-gray-50"
        }`}
      onClick={() => onToggle(item)}
    >
      <span className="text-gray-800 font-medium capitalize">
        {item.name} : {item.mobile}
      </span>
      <div className="rounded-full w-6 h-6 border border-gray-300 flex items-center justify-center">
        {isSelected && <div className="bg-blue-500 w-3 h-3 rounded-full"></div>}
      </div>
    </div>
  );
};



const sampleUserUpdate = [
  {
    "id": "943",
    "name": "Ganesh Kolkata",
    "profile_pic": "https://genefied-saas-partner-staging.s3.ap-south-1.amazonaws.com/1721286798658-957549098rn_image_picker_lib_temp_5333f998-69ea-4a0c-9afb-ba2745526737.jpg",
    "user_id": "retailer_943",
    "email": "null",
    "password": "615442",
    "mobile": "7417417417",
    "whatsapp_number": "null",
    "zone_id": "0",
    "zone_name": "",
    "dob": "null",
    "gender": "null",
    "address1": "Genefied",
    "address2": "null",
    "state": "West Bengal",
    "district": "KOLKATA",
    "city": "Kolkata",
    "pincode": "700011",
    "pan": "null",
    "aadhar": "null",
    "gstin": "null",
    "jwt_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTQzLCJtb2JpbGUiOiI3NDE3NDE3NDE3IiwidXNlcl90eXBlX2lkIjoyLCJ1c2VyX3R5cGUiOiJyZXRhaWxlciIsImxvZ2luX3R5cGUiOiIxIiwic3RhdHVzIjoiMSIsImlhdCI6MTcyMTI4NjkxNH0.B2f1j0RwlXszpEUX00lJZlayOWaBPtz4IPs37UsiXo0",
    "pan_image": "null",
    "aadhar_image": "null",
    "gstin_image": "null",
    "status": "Active",
    "referral_code": "B6KHhMq",
    "referred_by_code": "null",
    "profession": "null",
    "added_through": "self",
    "firm_name": "null",
    "firm_id": "null",
    "dealer_name": "null",
    "dealer_mobile": "null",
    "salesteam_name": "null",
    "salesteam_mobile": "null",
    "business_unit": "null",
    "department": "null",
    "l1_manager": "null",
    "l1_manager_email": "null",
    "l1_manager_mobile": "null",
    "fsr": "null",
    "asm": "null",
    "l2_manager": "null",
    "l2_manager_email": "null",
    "l2_manager_mobile": "null"
  },
  {
    "id": "942",
    "name": "Ganesh Odisha",
    "profile_pic": "https://genefied-saas-partner-staging.s3.ap-south-1.amazonaws.com/1721286530487-977086834rn_image_picker_lib_temp_00df28ec-f4f7-4b32-ae46-63b894393d34.jpg",
    "user_id": "retailer_942",
    "email": "null",
    "password": "012142",
    "mobile": "9658749658",
    "whatsapp_number": "null",
    "zone_id": "0",
    "zone_name": "",
    "dob": "null",
    "gender": "null",
    "address1": "Genefied test",
    "address2": "null",
    "state": "Odisha",
    "district": "KHORDA",
    "city": "Odisha",
    "pincode": "752102",
    "pan": "null",
    "aadhar": "null",
    "gstin": "null",
    "jwt_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTQyLCJtb2JpbGUiOiI5NjU4NzQ5NjU4IiwidXNlcl90eXBlX2lkIjoyLCJ1c2VyX3R5cGUiOiJyZXRhaWxlciIsImxvZ2luX3R5cGUiOiIxIiwic3RhdHVzIjoiMSIsImlhdCI6MTcyMTI4NjkwNX0.DYbCtnDQkJo-z7dU0lUSUEhmRwehNCkTBvceDsCZhsg",
    "pan_image": "null",
    "aadhar_image": "null",
    "gstin_image": "null",
    "status": "Active",
    "referral_code": "XNIiIjM",
    "referred_by_code": "null",
    "profession": "null",
    "added_through": "self",
    "firm_name": "null",
    "firm_id": "null",
    "dealer_name": "null",
    "dealer_mobile": "null",
    "salesteam_name": "null",
    "salesteam_mobile": "null",
    "business_unit": "null",
    "department": "null",
    "l1_manager": "null",
    "l1_manager_email": "null",
    "l1_manager_mobile": "null",
    "fsr": "null",
    "asm": "null",
    "l2_manager": "null",
    "l2_manager_email": "null",
    "l2_manager_mobile": "null"
  },

];
