import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { toast } from "react-hot-toast";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { hasPermission } from "../../../utils/hasPermission";
import { useGetAllDreamGiftsMutation } from "../../../features/DreamGift/DreamGiftApi";
import { useGetAllZoneMutation } from "../../../features/tenant/zones/zonesApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { getVendorName } from "../../../utils/getVendorName";
import { formatter } from "../../../utils/numberFormatter";


function getDreamGiftStatus(idx) {
  const statusArr = [
    { status: "Rejected", color: "red", tooltip: "The dream gift was redeemed and redemption request was rejected from panel." },
    { status: "Active", color: "blue", tooltip: "The dream gift is currently active." },
    { status: "Achieved", color: "green", tooltip: "The dream gift has been achieved." },
    { status: "Redeemed", color: "purple", tooltip: "The dream gift has been redeemed. More details can be found at Dream Gift Redeem Request Page." },
  ];
  return statusArr[Number(idx)];
}



const DreamGiftsStatus = () => {

  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);


  const [showAddressModal, setshowAddressModal] = useState(false);

  const [giftDetailPopUpToggle, setGiftDetailPopUpToggle] = useState(false);
  const [giftDetails, setGiftDetails] = useState(null);

  const [balancePopupModal, setBalancePopupModal] = useState(false);
  const [balanceDetails, setBalanceDetails] = useState(null);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const navigate = useNavigate();

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const [allZones, setAllZones] = useState([]);
  const [userZone, setUserZone] = useState({
    name: "All Zones",
    id: 0,
  });
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType")) || [];
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    name: "",
    mobile: "",
    user_type_id: userTypeId,
    limit: 10,
    offset: 0,
    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
    setUserZone({
      name: "All Zones",
      id: 0,
    });
    setUserTypesArray({
      name: "All",
      id: null,
    })
  };

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  const [
    getAllZone,
    {
      data: getAllZoneData,
      isLoading: getAllZoneIsLoading,
      isError: getAllZoneIsError,
      error: getAllZoneError,
    },
  ] = useGetAllZoneMutation();

  useEffect(() => {
    getAllZone({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (getAllZoneData) {
      const allZone = getAllZoneData.body.map((x) => {
        return {
          id: x.id,
          name: x.zone_name,
        };
      });

      setAllZones(allZone);
    }
    if (getAllZoneError) {
      toast.dismiss();
      toast.error(getAllZoneError.data.message, {
        id: "errorAtGetallzonedata",
      });
    }
  }, [getAllZoneData, getAllZoneError]);

  const [fetchDreamGifts, { data: dreamGiftsData, error: dreamGiftsError, isLoading: dreamGiftsIsLoading }] =
    useGetAllDreamGiftsMutation();


  useEffect(() => {
    const hasViewPermission = hasPermission("dreamGiftsStatus");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    fetchDreamGiftsFunc();

  }, [request, limit, offset]);

  const fetchDreamGiftsFunc = (e) => {
    fetchDreamGifts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      zoneId: userZone.id,
    });
  };


  useEffect(() => {
    if (dreamGiftsData && dreamGiftsData.body) {

      setMyList(dreamGiftsData.body && dreamGiftsData.body.data ? dreamGiftsData.body.data : []);
      setTotalRows(dreamGiftsData.body && dreamGiftsData.body.total ? dreamGiftsData.body.total : 0);
      // toast.success(dreamGiftsData.message, {
      //   id: "dreamGiftsData",
      // });
    }

    if (dreamGiftsError) {
      toast.error("Something went wrong.", {
        id: "dreamGiftsError",
      });
    }
  }, [dreamGiftsData, dreamGiftsError]);


  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => (offset) + Number(idx + 1),
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "User Type",
      cell: (row) => <span className="capitalize">{row.user_type}</span>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Value",
      cell: (row) => formatter.format(row.value),
      sortable: true,
      minWidth: "120px",
    },

    {
      name: "Progress",
      cell: (row) => (
        row.perc &&
        <div className="w-full bg-gray-200 rounded-full h-4 relative">
          <div
            className={`h-4 rounded-full ${Math.floor(row.perc) === 100 ? "bg-green-500" : "bg-blue-500"
              }`}
            style={{ width: `${Math.floor(row.perc)}%` }}
          ></div>
          <span className="absolute inset-0 text-center text-xs font-medium text-black">
            {Math.floor(row.perc)}%
          </span>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Start Date",
      cell: (row) => row.start_date,
      sortable: true,
      width: "120px",
    },
    {
      name: "End Date",
      cell: (row) => row.end_date,
      sortable: true,
      width: "120px",
    },

    {
      name: "Status",
      cell: (row) => {
        const { status, color, tooltip } = getDreamGiftStatus(row.status);
        return (
          <div className="relative group cursor-pointer">
            <span style={{ color: color, fontWeight: "bold" }}>{status}</span>
            {/* Tooltip */}
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max px-2 py-1 text-sm text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity z-10">
              {tooltip}
            </div>
          </div>
        );
      },
    },

    // {
    //   name: "",
    //   cell: (row) => {
    //     const handleNavigation = () => {
    //       navigate(`/genefied/${getVendorName()}/dream-gifts-redemption-history`);
    //     };
    //     return (row.status === "0" || row.status === "3") ? (
    //       <button
    //         onClick={handleNavigation}
    //         className="text-blue-500 hover:underline"
    //       >
    //         Details
    //       </button>
    //     ) : null;
    //   },
    //   sortable: true,
    // },

    {
      name: "",
      cell: (row) => {
        if (row.gift) {
          return (
            <div>
              <button
                onClick={() => {
                  // console.log("giftssss", row.gift);
                  setGiftDetailPopUpToggle(true);
                  setGiftDetails([row.gift]);
                }}
                className="flex justify-center items-center rounded bg-green-900 px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                View gifts
              </button>
            </div>
          );
        } else {
          return "";
        }
      },
      sortable: true,
    },

    {
      name: "",
      cell: (row) => {
        if (row.gift) {
          return (
            <div>
              <button
                onClick={() => {
                  // console.log("giftssss", row.gift);
                  setBalancePopupModal(true);
                  setBalanceDetails({
                    point_earned: row.point_earned,
                    point_balance: row.point_balance,
                    point_redeemed: row.point_redeemed,
                  });
                }}
                className="flex justify-center items-center rounded bg-blue-900 px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Balance
              </button>
            </div>
          );
        } else {
          return "";
        }
      },
      sortable: true,
    },

    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
    },
    {
      name: "Updated At",
      cell: (row) => new Date(row.updated_at).toLocaleString(),
    },

  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Dream Gifts
            <LinksComponent />
          </div>

          <div className="w-full p-3">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 ">
                <div className="w-52">
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Name
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Name..."
                      required
                      value={request.name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52">
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Mobile
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Mobile No..."
                      required
                      value={request.mobile}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, mobile: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52">
                  <div className="relative searchin topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Zones
                    </label>
                    <div className="relative searchin">
                      <SelectComponent
                        placeholder={"Select Zone*"}
                        selected={userZone}
                        setSelected={setUserZone}
                        dataArr={allZones}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="w-full  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.startDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          startDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="w-full  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.endDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          endDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 ">
                  <div className="flex-col slc_dvd topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      User Types
                    </label>
                    <div className="relative searchin">
                      {appUsers && appUsers.length > 0 && (
                        <SelectComponent
                          className="capitalize"
                          //title={"User Types"}
                          selected={userTypesArray}
                          setSelected={setUserTypesArray}
                          dataArr={appUsers}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    &nbsp;
                  </label>
                  <div className="relative searchin gap-2 flex">
                    <button
                      className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                      onClick={() => {
                        fetchDreamGiftsFunc();
                        // handleFetchRedemptionHistoryCsv();
                      }}
                    >
                      Search
                    </button>

                    <button
                      className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={myList}
                loading={dreamGiftsIsLoading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
              </DataTableComponent>
              {showAddressModal && Object.keys(showAddressModal).length > 0 && (
                <ModalComponent
                  size={"md"}
                  show={
                    showAddressModal && Object.keys(showAddressModal).length > 0
                      ? true
                      : false
                  }
                  setShow={setshowAddressModal}
                >
                  <div className="w-full">
                    <div className="w-full">
                      <div className="p-3 bg-white rounded-lg shadow-md  mx-auto">
                        <h2 className="text-lg text-center font-bold mb-4">
                          Address Details
                        </h2>
                        <div className="mb-2">
                          <span className="font-semibold">Address: </span>
                          {showAddressModal?.address ? showAddressModal.address : showAddressModal?.data?.address}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">Pincode: </span>
                          {showAddressModal.pincode}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">District: </span>
                          {showAddressModal.district}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">City: </span>
                          {showAddressModal.city}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">State: </span>
                          {showAddressModal.state}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">Country: </span>
                          {showAddressModal.country}
                        </div>


                      </div>
                    </div>
                  </div>
                </ModalComponent>
              )}

              {giftDetailPopUpToggle && (
                <ModalComponent
                  size={"md"}
                  show={giftDetailPopUpToggle}
                  setShow={setGiftDetailPopUpToggle}
                >
                  <div className="w-full ">
                    <div className="w-full p-4">
                      {giftDetails &&
                        giftDetails.map((x) => {
                          return (
                            <div className="flex gap-3 items-center bg-gray-50 border border-gray-100 rounded-sm overflow-hidden p-2 mb-2">
                              <div className="w-1/5 border bg-white border-gray-100 rounded-sm overflow-hidden p-2">
                                <img
                                  src={x.images[0]}
                                  alt={"error loading image"}
                                />
                              </div>
                              <div className="w-4/5">
                                <span className="capitalize block">
                                  <span className="text-gray-400">Name</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.gift_name}
                                </span>
                                <span className="capitalize block">
                                  <span className="text-gray-400">Brand</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.brand}
                                </span>
                                <span className="capitalize block">
                                  <span className="text-gray-400">Point</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.points}
                                </span>
                                {/* <span className="capitalize block">
                                  <span className="text-gray-400">Value</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.value}
                                </span> */}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </ModalComponent>
              )}


              {balancePopupModal && (
                <ModalComponent
                  size={"md"}
                  show={balancePopupModal}
                  setShow={setBalancePopupModal}
                >
                  <div className="w-full ">
                    <div className="w-full p-4">
                      <div className="flex items-center bg-gray-50 border border-gray-100 rounded-sm overflow-hidden p-2 mb-2">

                        <div className="w-full">
                          <h2 className="text-lg text-center font-bold mb-2">
                            Point Details
                          </h2>
                          <div className="">
                            <span className="capitalize block">
                              <span className="text-black">Point Earned</span>
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {formatter.format(balanceDetails.point_earned)}
                            </span>
                            <span className="capitalize block">
                              <span className="text-black">Point Balance</span>
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {formatter.format(balanceDetails.point_balance)}
                            </span>
                            <span className="capitalize block">
                              <span className="text-black">Point Redeemed</span>
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {formatter.format(balanceDetails.point_redeemed)}
                            </span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DreamGiftsStatus;
