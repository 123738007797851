import { rootApi } from "../../api/rootApi";

export const warrantyApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllWarranty: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/warranty/?user_type_id=${params.user_type_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
    fetchWarrantyById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/warranty/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updateWarrantyById: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/warranty/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),

    reassignNewQrWarranty: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/warranty/newwarranty`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
  }),
});

export const { useFetchAllWarrantyMutation, useFetchWarrantyByIdMutation , useUpdateWarrantyByIdMutation,useReassignNewQrWarrantyMutation } =
  warrantyApi;
