import { rootApi } from "../../api/rootApi";

export const userPointsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserPointsByAppUserId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/userPoints/?user_id=${params.app_user_id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const { useGetUserPointsByAppUserIdMutation } = userPointsApi;
