import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiEye, HiOutlinePlus } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetchPaymentTransferByBatchIDCSVMutation, useFetchPaymentTransferByBatchIDMutation } from "../../../features/tenant/paymentTransfer/paymentTransferApi";
import { months } from "./PaymentTransferList";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";

const paymentStatus = ["failure", "success", "pending", "Not Initiated"];
const PaymentTransferListByBatchId = () => {


  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Emp ID ",
      selector: (row) => row.emp_id,
      sortable: true,
    },
    {
      name: "Mobile ",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Name ",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Reporting Manager ",
      selector: (row) => row.l1_manager,
      sortable: true,
      width: "100px",
    },
    {
      name: "Transfer Id  ",
      selector: (row) => row.transfer_id,
      sortable: true,
      width: "200px",
    },
    {
      name: "Reference Id",
      selector: (row) => row.reference_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Amount ",
      selector: (row) => row.amount,
      sortable: true,
    },

    {
      name: "Month",
      selector: (row) => months[row.month],
      sortable: true,
    },

    {
      name: "Year",
      selector: (row) => row.year,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => (
        <span
          className={`text-sm p-1 capitalize rounded-md font-bold bg-${
            row.payment_status == "0"
              ? "red"
              : row.payment_status == "1"
              ? "blue"
              : "slate"
          }-700 text-white animate-pulse duration-100`}
        >
          {paymentStatus[row.payment_status]}
        </span>
      ),
      sortable: true,
      width: "170px",
    },
    {
      name: "Active Transfer Mode",
      selector: (row) => row.active_tm,
      sortable: true,
      width: "170px",
    },
    {
      name: "Active bank details ",
      selector: (row) => {
        return (
          <>
            {row.active_tm == "upi" && (
              <>
                {" "}
                Beneficiary Name : {row.active_bd.bene_name} <br /> UPI :{" "}
                {row.active_bd.upi_id}{" "}
              </>
            )}
            {row.active_tm == "banktransfer" && (
              <>
                {" "}
                Beneficiary Name : {row.active_bd.bene_name} <br /> Account NO :{" "}
                {row.active_bd.bank_account} <br /> IFSC :{" "}
                {row.active_bd.ifsc}{" "}
              </>
            )}
          </>
        );
      },
      width: "200px",
    },
    {
      name: "Payout Transfer Mode",
      selector: (row) => row.transfer_mode,
      sortable: true,
      width: "170px",
    },
    {
      name: "Payout bank details ",
      selector: (row) => {
        return (
          <>
            {row.transfer_mode == "upi" && (
              <>
                {" "}
                Beneficiary Name : {row.bene_details.name} <br /> UPI :{" "}
                {row.bene_details.vpa}{" "}
              </>
            )}
            {row.transfer_mode == "banktransfer" && (
              <>
                {" "}
                Beneficiary Name : {row.bene_name} <br /> Account NO :{" "}
                {row.bene_details.bankAccount} <br /> IFSC :{" "}
                {row.bene_details.ifsc}{" "}
              </>
            )}
          </>
        );
      },
      width: "200px",
    },
    {
      name: "Payout Status",
      selector: (row) => {
        return row.cttb_status == "1" ? (
          <div className=" text-green-600 capitalize ">Success</div>
        ) : row.cttb_status == "2" ? (
          <div className=" text-gray-600  capitalize ">Pending</div>
        ) : row.cttb_status == "0" ? (
          <div className=" text-red-600  capitalize ">Error</div>
        ) : (
          <div>Not Initiated</div>
        );
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Payout Response Message",
      selector: (row) => row.response_message,
      sortable: true,
      width: "170px",
    },
    {
      name: "Is Bank Added ",
      selector: (row) => row.is_active_bank ? "Yes" : "No" ,
      sortable: true,
      width: "170px",
    },
    {
      name: "Is KYC Done ",
      selector: (row) => row.is_kyc_done ?  "Yes" : "No" ,
      sortable: true,
      width: "170px",
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [totalKycDone, setTotalKycDone] = useState(0);
  const [totalKycNotDone, setTotalKycNotDone] = useState(0);
  const [totalBankAddedDone, setTotalBankAddedDone] = useState(0);
  const [totalBankNotAddedDone, setBankNotAddedDone] = useState(0);

  const initialRequest = {
    name: "",
    limit: 10,
    offset: 0,
    mobile: "",
    status : ""
  };
  const [request, setRequest] = useState(initialRequest);

  const clearFilters = () => {
    setRequest(initialRequest);
  };
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [batchID, setbatchID] = useState(null);

  useEffect(() => {
    console.log("outer");
    if (
      location.state &&
      location.state.batch_id &&
      request.name === "" &&
      request.mobile === ""
    ) {
      console.log("inner");

      fetchPaymentTrasnferListFunc(location.state.batch_id);
      fetchPaymentTrasnferListForCsvFunc(location.state.batch_id);
    }
  }, [request, limit, offset]);

  useEffect(() => {
    console.log("batchID", location.state);
    if (location.state) {
      setbatchID(location.state.batch_id);
      // fetchPaymentTransferByBatchID({
      //   token: sessionStorage.getItem("access_token"),
      //   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      //   batch_id: location.state.batch_id,
      //   body: request,
      // });

      fetchPaymentTrasnferListFunc(location.state.batch_id);
      fetchPaymentTrasnferListForCsvFunc(location.state.batch_id);
    }
  }, [location]);

  const fetchPaymentTrasnferListFunc = (batch_id) => {
    fetchPaymentTransferByBatchID({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      batch_id: batch_id,
      body: request,
    });
  };

  const fetchPaymentTrasnferListForCsvFunc = (batch_id) => {
    fetchPaymentTransferByBatchIDCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      batch_id: batch_id,
      body: request,
    });
  };

  const [
    fetchPaymentTransferByBatchID,
    {
      data: paymentTransferListData,
      error: paymentTransferListError,
      isLoading: ppaymentTransferListisLoading,
    },
  ] = useFetchPaymentTransferByBatchIDMutation();
  const [
    fetchPaymentTransferByBatchIDCsv,
    {
      data: paymentTransferListCsvData,
      error: paymentTransferListCsvError,
      isLoading: ppaymentTransferListCsvisLoading,
    },
  ] = useFetchPaymentTransferByBatchIDCSVMutation();

  useEffect(()=>{
    if(paymentTransferListCsvData){
      let countKycDone =0;
      let countBankAdded = 0;
      paymentTransferListCsvData.body.data.map(x=>{
        console.log("x",x);
        console.log("x",x.is_kyc_done);
        console.log("x",x.is_active_bank);
        if(x.is_kyc_done){
          countKycDone++;
        }
        if(x.is_active_bank){
          countBankAdded++;
        }
      })

      setTotalBankAddedDone(countBankAdded);
      setBankNotAddedDone(paymentTransferListCsvData.body.data.length - countBankAdded);
      setTotalKycNotDone(paymentTransferListCsvData.body.data.length - countKycDone);
      setTotalKycDone(countKycDone);
    }
     
  },[paymentTransferListCsvData])

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;

    setRequest((prevState) => {
      return { ...prevState, offset: newOffset };
    });
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest((prevState) => {
      return { ...prevState, limit: newPerPage };
    });
    setLimit(newPerPage);
  };

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Payment Transfer List By Batch Id
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className="p-3 w-full bg-white shadow rounded-lg">
            {paymentTransferListCsvData &&
              paymentTransferListCsvData?.body?.data && (
                <ExportCSV
                  data={
                    paymentTransferListCsvData &&
                    paymentTransferListCsvData?.body.data
                  }
                  columnsToExport={[
                    "emp_id",
                    "mobile",
                    "name",
                    "l1_manager",
                    "amount",
                    "month",
                    "year",
                    "transfer_id",
                    "reference_id",
                    "upi_mobile",
                    "batch_id",
                    "active_tm",
                    "active_bd",
                    "transfer_mode",
                    "bene_details",
                    "cttb_status",
                    "response_message",
                    "is_active_bank",
                    "is_kyc_done",
                  ]}
                />
              )}
              <div className="flex gap-2">
                <div className="bg-blue-100 text-black p-2">
                   Total Bank added :  {totalBankAddedDone}
                </div>
                &nbsp;
                <div className="bg-blue-100 text-black p-2" >
                   Total Bank Not added :  {totalBankNotAddedDone}
                </div>
                &nbsp;
                <div className="bg-blue-100 text-black p-2" >
                  Total Kyc Done : {totalKycDone}
                </div>
                &nbsp;
                <div className="bg-blue-100 text-black p-2" >
                  Total Kyc Not Done : {totalKycNotDone}
                </div>
                
              </div>
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 justify-end">
                <div className="relative searchin">
                  <input
                    type="search"
                    id="default-search"
                    className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Mobile..."
                    required
                    value={request.category}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return { ...prevState, mobile: newValue };
                      });
                    }}
                  />
                </div>
                <div className="relative searchin">
                  <input
                    type="search"
                    id="default-search"
                    className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Name..."
                    required
                    value={request.name}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return { ...prevState, name: newValue };
                      });
                    }}
                  />
                </div>
                <div className="relative searchin">
                  <input
                    type="search"
                    id="default-search"
                    className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Name..."
                    required
                    value={request.name}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return { ...prevState, name: newValue };
                      });
                    }}
                  />
                </div>
                <div className="relative searchin">
                <select 
                  id="default-search"
                  className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                  placeholder="Name..."
                  onChange={(e) => {

                      const newValue = e.target.value;
                      if(newValue != "-1"){

                        setRequest((prevState) => {
                          return { ...prevState, status: newValue };
                        });
                      }
                    }} >
                    <option  value="-1">all</option>
                    <option value="1">success</option>
                    <option value="2">pending</option>
                    <option value="0">error</option>

                    </select>
                </div>


                <div className="flex flex-row  gap-2 h-10">
                  <div className="relative">
                    {location.state && location.state.batch_id && (
                      <button
                        className="btn btn-outline-primary clear-both float-right "
                        onClick={() => {
                          fetchPaymentTrasnferListFunc(location.state.batch_id);
                        }}
                      >
                        Search
                      </button>
                    )}
                  </div>
                  <div className="relative searchin">
                    <button
                      className="btn btn-outline-primary clear-both float-right whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableComponent
              keyField={(row) => row.SNo}
              columns={columns}
              data={
                paymentTransferListData ? paymentTransferListData.body.data : []
              }
              loading={ppaymentTransferListisLoading}
              totalRows={
                paymentTransferListData && paymentTransferListData?.body.total
              }
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
            ></DataTableComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentTransferListByBatchId;
