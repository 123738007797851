import React, { Fragment, useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import CustomExport from "../../../components/CustomExport/CustomExport";
import { useGetAllCheckoutDataCountMutation, useGetAllCheckoutDataMutation } from "../../../features/tenant/Ultimatrue/ultimatrueApi";

const UltimatrueSellDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [
    FetchCheckout,
    {
      data: FetchCheckoutData,
      error: FetchCheckoutError,
      isLoading: FetchCheckoutIsLoading,
      isError: FetchCheckoutIsError,
    },
  ] = useGetAllCheckoutDataMutation();

  const [
    CountCheckout,
    {
      data: CountCheckoutData,
      error: CountCheckoutError,
      isLoading: CountCheckoutIsLoading,
      isError: CountCheckoutIsError,
    },
  ] = useGetAllCheckoutDataCountMutation();

  const [
    FetchCsvCheckout,
    {
      data: FetchCsvCheckoutData,
      error: FetchCsvCheckoutError,
      isLoading: FetchCsvCheckoutIsLoading,
      isError: FetchCsvCheckoutIsError,
    },
  ] = useGetAllCheckoutDataMutation();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [prevPage, setPrevPage] = useState(0);
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [Checkout, setCheckout] = useState([]);


  const initialRequest = {
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);

  useEffect(() => {
    if (FetchCheckoutData?.body) {
      setCheckout(FetchCheckoutData.body);
    }
  }, [FetchCheckoutData]);



  useEffect(() => {
    fetchFetchCheckoutFunction();
    fetchCountCheckoutFunction();
    // fetchFetchCsvCheckoutFunction();
  }, [location.pathname, limit, offset]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
    setRequest({ ...request, offset: newOffset });
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
    setRequest({ ...request, limit: newPerPage });
  };

  useEffect(() => {}, [request]);
  const fetchFetchCheckoutFunction = (row) => {
    FetchCheckout({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchCountCheckoutFunction = (row) => {
    const modifiedRequest = {
        ...request,
        limit: null,
        offset: null,
      };
    CountCheckout({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const fetchFetchCsvCheckoutFunction = (row) => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    FetchCsvCheckout({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };
  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (FetchCsvCheckoutData && FetchCsvCheckoutData.body) {
      setCsvData(FetchCsvCheckoutData.body);
    }

    if (FetchCsvCheckoutError) {
      setCsvData(null);
    }
  }, [FetchCsvCheckoutData, FetchCsvCheckoutError]);

  // FetchCsvCheckout

  const clearFilters = () => {
    setRequest(initialRequest);
  };
  useEffect(() => {
    if (
      request.mobile === "" &&
      request.startDate === "" &&
      request.endDate === currentDate
    ) {
      fetchFetchCheckoutFunction();
      // fetchFetchCsvCheckoutFunction();
    }
  }, [request]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => <div>{row.app_user_name}</div>,
      sortable: true,
    },
    {
        name: "Mobile",
        cell: (row) => row.mobile,
        sortable: true,
        width: "100px",
      },
    {
        name: "UserType",
        cell: (row) => row.user_type,
        sortable: true,
      },

    {
        name: "Sell to Name",
        cell: (row) => <div>{row.sell_to_app_user_name}</div>,
        sortable: true,
        width: "100px",
      },

    {
      name: "Sell to Mobile",
      cell: (row) => row.sell_to_mobile,
      sortable: true,
      width: "100px",
    },
    {
      name: "Sell to UserType",
      cell: (row) => row.sell_to_type,
      sortable: true,
      width: "100px",
    },
    {
      name: "Order Id",
      cell: (row) => row.order_id,
      sortable: true,
    },

    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "300px",
    },
    {
        name: "Product Name",
        cell: (row) => row.product_name,
        sortable: true,
        width: "300px",
      },
      {
        name: "Batch Running Code ",
        cell: (row) => row.batch_running_code,
        sortable: true,
        width: "300px",
      },
    // {
    //   name: "Status",
    //   cell: (row) => (row.status == "1" ? <>Blocked</> : <>unBlocked</>),
    // }
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Checkout Data
            <LinksComponent />
          </div>

          <div className="p-3 ">
            <div className="flex justify-between items-start">
              {/* {FetchCsvCheckoutData && FetchCsvCheckoutData?.body?.feedbackList && (
                <ExportCSV
                  data={FetchCsvCheckoutData && FetchCsvCheckoutData?.body?.feedbackList}
                  columnsToExport={[
                    "name",
                    "mobile",
                    "user_type",
                    "rating",
                    "feedback"
                  ]}
                />
              )} */}

              <div className="mb-2">
                <CustomExport
                  fetchData={fetchFetchCsvCheckoutFunction}
                  data={csvData}
                  defaultColumnExport={[
                    "app_user_name",
                    "mobile",
                    "user_type",
                    "sell_to_app_user_name",
                    "sell_to_mobile",
                    "sell_to_type",
                    "order_id",
                    "product_code",
                    "product_name",
                    "batch_running_code",
                    
                  ]}
                  filename="app_checkout_data"
                />
              </div>
            </div>

            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 ">
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile No
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Mobile..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        From Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>

                    <div className="flex flex-row  gap-2 ">
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right  pdbtn_all"
                          onClick={() => {
                            fetchFetchCheckoutFunction();

                            // fetchFetchCsvCheckoutFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-3">
                <DataTableComponent
              keyField={(row) => row.SNo}
                  columns={columns}
                  data={Checkout.length && Checkout}
                  totalRows={
                    CountCheckoutData && CountCheckoutData.body.count
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UltimatrueSellDetails;
