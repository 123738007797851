import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { HiOutlinePlus } from "react-icons/hi";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { hasPermission } from "../../../../utils/hasPermission";
import { getVendorName } from "../../../../utils/getVendorName";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";
import {
  useAddCouponProviderMutation,
  useDeleteCouponProviderMutation,
  useFetchAllCouponProviderMutation,
  useUpdateCouponProviderMutation,
} from "../../../../features/tenant/couponProvider/couponProviderApi";
import { toast } from "react-hot-toast";

const providersType = [
  {
    id: "1",
    name: "Static",
  },
  {
    id: "2",
    name: "Dynamic",
  },
];

const ManageCouponProvider = () => {
  const navigate = useNavigate();

  const [
    fetchCouponProviders,
    {
      data: couponProviderList,
      isLoading: couponProviderIsLoading,
      error: couponProviderError,
    },
  ] = useFetchAllCouponProviderMutation();

  const [
    addCouponProvider,
    {
      data: addCouponProviderData,
      isLoading: addCouponProviderIsLoading,
      error: addCouponProviderError,
    },
  ] = useAddCouponProviderMutation();

  const [
    updateCouponProvider,
    {
      data: updateCouponProviderData,
      isLoading: updateCouponProviderIsLoading,
      error: updateCouponProviderError,
    },
  ] = useUpdateCouponProviderMutation();

  const [
    deleteCouponProvider,
    {
      data: deleteCouponProviderData,
      isLoading: deleteCouponProviderIsLoading,
      error: deleteCouponProviderError,
    },
  ] = useDeleteCouponProviderMutation();

  const hasAddCouponProviderPermission = true;

  useEffect(() => {
    const hasViewPermission = hasPermission("couponProviders");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchCouponProviders({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      type: "",
    });
  }, []);

  useEffect(() => {
    if (couponProviderList) {
      console.log("Provider List ", couponProviderList);
    }
  }, [couponProviderList, couponProviderError]);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "20%",
      textAlign: "left",
    },
    {
      name: "Provider",
      selector: (row) => row.name,
      sortable: true,
      width: "30%",
    },
    {
      name: "Type",
      selector: (row) => <h1 className="capitalize"> {row.type} </h1>,
      sortable: true,
      width: "15%",
    },
    {
      name: "",
      selector: (row) => (
        <button
          className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          onClick={() => handleShowModal("edit", row)}
        >
          Edit
        </button>
      ),
      sortable: true,
      width: "15%",
    },
    {
      name: "",
      selector: (row) => (
        <button
          onClick={() => handleDeleteProvider(row.id)}
          className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
        >
          <AiOutlineDelete className="mr-1" />
          Delete
        </button>
      ),
      width: "20%",
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [brandName, setBrandName] = useState(null);
  const [selectedProviderType, setSelectedProviderType] = useState({
    id: -1,
    name: "Select Provider",
  });
  const [selectedCouponProvider, setselectedCouponProvider] = useState(-1);

  const handleAddProvider = (e) => {
    e.preventDefault();
    if (selectedProviderType.id == -1) {
      toast.error("Select provider type", { id: "seletecProviderTypeError" });
      return;
    }
    if (!brandName) {
      toast.error("Enter provider name", { id: "seletecProviderNameError" });
      return;
    }

    addCouponProvider({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        provider_name: brandName,
        type: selectedProviderType.name,
      },
    });
  };

  const handleUpdateProvider = (e) => {
    e.preventDefault();

    if (selectedCouponProvider == -1) {
      return;
    }

    if (selectedProviderType.id == -1) {
      toast.error("Select provider type", { id: "seletecProviderTypeError" });
      return;
    }
    if (!brandName) {
      toast.error("Enter provider name", { id: "seletecProviderNameError" });
      return;
    }

    updateCouponProvider({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: selectedCouponProvider,
      body: {
        provider_name: brandName,
        type: selectedProviderType.name,
      },
    });
  };

  const handleDeleteProvider = (id) => {
    if (window.confirm("Are you sure you want to delete")) {
      deleteCouponProvider({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: id,
      });
    }
    return;
  }

  useEffect(() => {
    toast.dismiss();
    if (addCouponProviderData) {
      console.log("added provider", addCouponProviderData);
      toast.success("Provider added ", { id: "addCouponProviderSuccess" });
      fetchCouponProviders({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        type: "",
      });
      setShowModal(false);
    }

    if (addCouponProviderError) {
      console.log("added provider errorr", addCouponProviderError);
      toast.error("Some error occcured ", { id: "addCouponProviderError" });
    }
  }, [addCouponProviderData, addCouponProviderError]);

  useEffect(() => {
    toast.dismiss();
    if (updateCouponProviderData) {
      console.log("updated provider", updateCouponProviderData);
      toast.success("Provider Updated ", { id: "updateCouponProviderSuccess" });
      fetchCouponProviders({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        type: "",
      });
      setShowModal(false);
    }

    if (updateCouponProviderError) {
      console.log("update provider errorr", updateCouponProviderError);
      toast.error("Some error occcured ", { id: "updateCouponProviderError" });
    }
  }, [updateCouponProviderData, updateCouponProviderError]);

  useEffect(() => {
    toast.dismiss();
    if(deleteCouponProviderData){
      toast.success("Deleted ", { id: "deleteCouponProviderSuccess" });
      fetchCouponProviders({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        type: "",
      });
    }
    if(deleteCouponProviderError){
      console.log(deleteCouponProviderError);
      toast.error( deleteCouponProviderError.status === 409 ? deleteCouponProviderError.data.message : "Some Error Occured ", { id: "deleteCouponProviderFailure" });
    }
  }, [deleteCouponProviderData , deleteCouponProviderError])


  const handleShowModal = (type, data) => {
    setselectedCouponProvider(-1);
    if (type === "add") {
      setShowModal(true);
      setBrandName(null);
      setSelectedProviderType({
        id: -1,
        name: "Select Provider",
      });
    } else if (type === "edit") {
      setselectedCouponProvider(Number(data.id));
      setBrandName(data.name);
      console.log("object---", data);
      if (data.type == "static") {
        setSelectedProviderType({
          id: "1",
          name: "Static",
        });
      } else if (data.type == "dynamic") {
        setSelectedProviderType({
          id: "2",
          name: "Dynamic",
        });
      }
      setShowModal(true);
    }
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Coupon Provider
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={couponProviderList && couponProviderList.body}
                loading={couponProviderIsLoading}
                totalRows={couponProviderList && couponProviderList.body.length}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              >
               
              </DataTableComponent>
              {hasAddCouponProviderPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => handleShowModal("add")}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showModal && (
          <ModalComponent
            size={"md"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-4">
              <div className="w-full flex flex-col justify-center ">
                <div className="mb-6 w-full">
                  <InputComponent
                    name={"Coupon Provider"}
                    type={"text"}
                    value={brandName}
                    placeholder={"Enter Coupon Provider"}
                    setValue={setBrandName}
                    required={true}
                  />
                </div>
                <div className="mb-6 w-full">
                  <SelectComponent
                    title={"Select Provider Type"}
                    selected={selectedProviderType}
                    setSelected={setSelectedProviderType}
                    dataArr={providersType}
                    required={true}
                  />
                </div>
              </div>
              <div className="flex justify-center">
                {selectedCouponProvider === -1 ? (
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={(e) => handleAddProvider(e)}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={(e) => handleUpdateProvider(e)}
                  >
                   Update
                  </button>
                )}
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default ManageCouponProvider;
