import React, { useEffect, useState } from 'react'
import { useAqualiteQrSyncStatsMutation } from '../../../features/tenant/Aqualite/aqualiteApi';
import { CircleLoaderComponent } from '../../../components/Loader/CircleLoaderComponent';
import DataTableComponent from '../../../components/Tenant/DataTableComponent/DataTableComponent';
import LinksComponent from '../../../components/Tenant/LinksComponent/LinksComponent';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const date = new Date();
// const defaultStartDate = `${date.getFullYear()}-01-01`;
const defaultStartDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-01`;
const currentDate = date.toISOString().split("T")[0];

const QrSyncStats = () => {
    const navigate = useNavigate();

    const [
        aqualiteQrSyncStats,
        {
            data: aqualiteQrSyncStatsData,
            error: aqualiteQrSyncStatsError,
            isLoading: aqualiteQrSyncStatsIsLoading,
        },
    ] = useAqualiteQrSyncStatsMutation();


    const [myList, setMyList] = useState([])
    const [totalRows, setTotalRows] = useState(0);

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);


    const initialRequest = {
        start_date: defaultStartDate,
        end_date:currentDate,
    };


    const [request, setRequest] = useState(initialRequest);
    // const [csvData, setCsvData] = useState(null);


    const clearFilters = () => {
        setRequest(initialRequest);
    };


    useEffect(() => {

        if (
            request.start_date === defaultStartDate &&
            request.end_date ===currentDate
        ) {
            fetchAqualiteQrSyncStatsFunc();
        }

    }, [request]);

    useEffect(() => {
        fetchAqualiteQrSyncStatsFunc();
    }, [limit, offset])


    const fetchAqualiteQrSyncStatsFunc = () => {

        const queryParams = new URLSearchParams({
            limit: limit,
            offset: offset,
            from_date: request.start_date,
            to_date: request.end_date
        }).toString();

        aqualiteQrSyncStats({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
            queryParams: queryParams
            //   body: request,
        });
    };

    useEffect(() => {
        toast.dismiss();
        if (aqualiteQrSyncStatsData && aqualiteQrSyncStatsData.body) {
            setMyList(aqualiteQrSyncStatsData.body.data ? aqualiteQrSyncStatsData.body.data : []);
            setTotalRows(aqualiteQrSyncStatsData.body.total_count ? aqualiteQrSyncStatsData.body.total_count : 0)
        }

        if (aqualiteQrSyncStatsError) {
            toast.error("Something went wrong", { id: "aqualiteQrSyncStatsError" })
        }
    }, [aqualiteQrSyncStatsData, aqualiteQrSyncStatsError]);



    const handlePageChange = (pageNumber) => {
        const newOffset = (pageNumber - 1) * limit;
        setOffset(newOffset);
        // setRequest({ ...request, offset: newOffset });
        // setPrevPage(pageNumber - 1);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        // setRequest({ ...request, limit: newPerPage });
        setLimit(newPerPage);
    };

    const columns = [
        {
            name: "SNo",
            cell: (row, idx) => offset + Number(idx + 1),
            sortable: true,
            width: "80px",
        },

        {
            name: "Batch No",
            cell: (row) => row.batch_no,
            sortable: true,
            width: "150px",
        },
        {
            name: "Total Qrs",
            cell: (row) => row.total_qrs,
            sortable: true,
            width: "150px",
        },
        {
            name: "Failed Qrs",
            cell: (row) => (
                <div style={{ color: "red", fontWeight: "bold" }}>{row.failed_qrs}</div>
            ),
            sortable: true,
            width: "150px",
        },
        {
            name: "Success Qrs",
            cell: (row) => (
                <div style={{ color: "green", fontWeight: "bold" }}>{row.success_qrs}</div>
            ),
            sortable: true,
            width: "150px",
        },
        {
            name: "Processing Qrs",
            cell: (row) => (
                <div style={{ color: "orange", fontWeight: "bold" }}>{row.processing_qrs}</div>
            ),
            sortable: true,
            width: "150px",
        },
        {
            name: "Created At",
            cell: (row) => new Date(Number(row.batch_no)).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
            sortable: true,
            width: "200px",
        },
        {
            name: "",
            cell: (row) => (
              <div>
                <button
                  onClick={() =>
                    navigate(row.batch_no, {
                      state: row,
                    })
                  }
                  className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
                >
                  View
                </button>
              </div>
            ),
            minWidth: "100px",
          },
    ];

    return (
        <>
            <div className="flex w-full bg-gray-200 h-full justify-center">
                <div className="w-full ">
                    <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
                        Qr Sync Stats
                        <LinksComponent />
                    </div>

                    <div className="p-3">

                        <div className=" w-full bg-white shadow rounded-lg">
                            <div className="border-b border-gray-300 bg-gray-100 rounded-t-lg py-3 px-3 w-full">
                                <div className="flex space-x-1 justify-end  w-full max-w-full">
                                    <div className="w-36 h-10 ">
                                        <div className="relative searchin">
                                            <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                Start Date
                                            </label>
                                            <input
                                                type="date"
                                                id="default-search"
                                                className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                                                placeholder="Start Date"
                                                value={request.start_date}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setRequest((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            start_date: newValue,
                                                        };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-36 h-10 mr-4 ">
                                        <div className="relative searchin">
                                            <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                End Date
                                            </label>
                                            <input
                                                type="date"
                                                id="default-search"
                                                className="w-full px-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                                                placeholder="End Date"
                                                value={request.end_date}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setRequest((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            end_date: newValue,
                                                        };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-56 gap-2 h-10">
                                        <div className="relative searchin">
                                            <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                &nbsp;
                                            </label>

                                            <button
                                                className="btn btn-outline-primary clear-both float-right  p-2 px-1.5"
                                                onClick={() => {
                                                    fetchAqualiteQrSyncStatsFunc();
                                                }}
                                            >
                                                Search
                                            </button>
                                        </div>
                                        <div className="relative searchin">
                                            <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                                                &nbsp;
                                            </label>
                                            <button
                                                className="btn btn-outline-primary clear-both float-right  whitespace-nowrap p-2 px-1.5"
                                                onClick={() => {
                                                    clearFilters();
                                                }}
                                            >
                                                Clear Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {aqualiteQrSyncStatsIsLoading && <CircleLoaderComponent />}
                                <DataTableComponent
              keyField={(row) => row.SNo}
                                    columns={columns}
                                    data={myList}
                                    totalRows={totalRows}
                                    handlePerRowsChange={handlePerRowsChange}
                                    handlePageChange={handlePageChange}
                                >
                                    <div className="flex m-2 space-x-2 justify-end"></div>
                                </DataTableComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QrSyncStats