import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { useGetAllBankDetailMutation } from "../../../features/tenant/appUserBankDetails/appUserBankApi";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import CustomExport from "../../../components/CustomExport/CustomExport";

const BankList = () => {
  const { zone_id: zoneId, zone_name: zoneName } = getLoginUserData();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [prevPage, setPrevPage] = useState(0);
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState(appUserTypes[0]);
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const initialRequest = {
    user_type_id: userTypeId,
    limit: 10,
    offset: 0,
    bene_mobile: "",
    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);

  const [
    fetchAllBankDetails,
    {
      data: allBankDetailsData,
      isLoading: allBankDetailsIsLoading,
      isError: allBankDetailsIsError,
      error: allBankDetailsError,
    },
  ] = useGetAllBankDetailMutation();

  const [
    fetchAllBankDetailsCsv,
    {
      data: allBankDetailsCsvData,
      isLoading: allBankDetailsCsvIsLoading,
      isError: allBankDetailsCsvIsError,
      error: allBankDetailsCsvError,
    },
  ] = useGetAllBankDetailMutation();

  const fetchAllBankDetailsFun = () => {
    fetchAllBankDetails({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchAllBankDetailsCsvFun = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchAllBankDetailsCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  useEffect(() => {
    if(limit!=10||offset!=0)
    fetchAllBankDetailsFun();
    fetchAllBankDetailsCsvFun();
  }, [limit, offset, userTypesArray]);
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.bene_name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.bene_mobile,
      sortable: true,
    },
    {
      name: "Bank Name",
      cell: (row) => row.bene_bank,
      sortable: true,
      minWidth: "200px",
    },

    {
      name: "Bank Account",
      cell: (row) => row?.bene_details?.bank_account,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "IFSC Code",
      cell: (row) => row?.bene_details?.ifsc,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Upi Id",
      cell: (row) => row?.bene_details?.upi_id,
      sortable: true,
      minWidth: "120px",
    },
  ];
  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Bank List
          <LinksComponent />
        </div>

        <div className="p-3">
        <div className="mb-2">
              <CustomExport
                fetchData={fetchAllBankDetailsCsvFun}
                data={ allBankDetailsCsvData?.body?.bankDetailsList}
                defaultColumnExport={[
                  "bene_name",
                  "bene_mobile",
                  "bene_bank",
                  "bene_details"
                ]}
                filename="bank_list"
              />
            </div>
          <div className=" bg-white shadow rounded-lg ">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 ">
                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    From Date
                  </label>
                  <input
                    type="date"
                    value={request.startDate}
                    className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          startDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.endDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          endDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 ">
                <div className="flex-col slc_dvd topslt">
                <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">User Types</label>
                    <div className="relative searchin">
                    {appUsers && appUsers.length > 0 && (
                      <SelectComponent
                        className="capitalize"
                       // title={"User Types"}
                        selected={userTypesArray}
                        setSelected={setUserTypesArray}
                        dataArr={appUsers}
                      />
                    )}
                  </div></div>
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    Mobile No
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Enter Mobile No"
                    value={request.bene_mobile}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          bene_mobile: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 ">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    &nbsp;
                  </label>
                  <button
                    className="btn btn-outline-primary clear-both  pdbtn_all w-full"
                    onClick={() => {
                      fetchAllBankDetailsFun();
                      fetchAllBankDetailsCsvFun();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className=" p-3">
              <div className="p-3 w-full bg-white shadow rounded-lg">
                <DataTableComponent
              keyField={(row) => row.SNo}
                  columns={columns}
                  data={
                    allBankDetailsData &&
                    allBankDetailsData.body.bankDetailsList
                  }
                  totalRows={allBankDetailsData?.body?.total_records}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankList;
