import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useParams } from "react-router-dom";
import { useCountQrScanHistoryMutation, useFetchQrScanHistoryMutation } from "../../../features/tenant/appUserQrScan/appUserQrScanApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import CustomExport from "../../../components/CustomExport/CustomExport";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";

const UserScanHistory = () => {
  const { userType } = useParams();
  const location = useLocation();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const allUserTypes = JSON.parse(
    sessionStorage.getItem("appUserType") || "[]"
  );
  const [userTypeId, setUserTypeId] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const initialRequest = {
    scanned_by_type_id: +userTypeId,
    batch_code: "",
    limit: 10,
    offset: 0,
    mobile: "",
    scanned_by_name: "",
    unique_code: "",
    batch_running_code: "",
    product_code: "",
    product_name: "",
    startDate: "",
    endDate:"",
  };
  const [request, setRequest] = useState(initialRequest);
  useMemo(() => {
    for (let index = 0; index < allUserTypes.length; index++) {
      const element = allUserTypes[index];
      if (
        element.user_type.toLocaleLowerCase() === userType.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          scanned_by_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userType]);

  const clearFilters = () => {
    setRequest(initialRequest);
  };
  useEffect(() => {
    if (
      request.batch_code === "" &&
      request.mobile === "" &&
      request.scanned_by_type_id === +userTypeId &&
      request.scanned_by_name === "" &&
      request.unique_code === "" &&
      request.batch_running_code === "" &&
      request.product_code === "" &&
      request.product_name === "" &&
      request.startDate === "" &&
      request.endDate === currentDate
    ) {
      fetchUserQrScanFunction();
      // fetchUserQrScanCsvFunction();
    }
  }, [request]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const [fetchUserQrScan, { data: qrScanData, error: qrScanError , isLoading : qrScanIsLoading }] =
    useFetchQrScanHistoryMutation();

    const [countUserQrScan, { data: countqrScanData, error: countqrScanError , isLoading : countqrScanIsLoading }] =
    useCountQrScanHistoryMutation();

  const [fetchUserQrCsv, { data: qrCsvData, error: qrCsvError }] =
    useFetchQrScanHistoryMutation();

  useEffect(() => {
    fetchUserQrScanFunction();
    countUserQrScanFunction();
    // fetchUserQrScanCsvFunction();
  }, [location.pathname, limit, offset, userType]);

  const fetchUserQrScanFunction = () => {
    // if (userType.toLocaleLowerCase() === "consumer") {
    //   userTypeId = 1;
    // } else if (userType.toLocaleLowerCase() === "retailer") {
    //   userTypeId = 2;
    // } else if (userType.toLocaleLowerCase() === "distributor") {
    //   userTypeId = 3;
    // }
    fetchUserQrScan({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const countUserQrScanFunction = () => {
    // if (userType.toLocaleLowerCase() === "consumer") {
    //   userTypeId = 1;
    // } else if (userType.toLocaleLowerCase() === "retailer") {
    //   userTypeId = 2;
    // } else if (userType.toLocaleLowerCase() === "distributor") {
    //   userTypeId = 3;
    // }
    countUserQrScan({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchUserQrScanCsvFunction = () => {
    // if (userType.toLocaleLowerCase() === "consumer") {
    //   userTypeId = 1;
    // } else if (userType.toLocaleLowerCase() === "retailer") {
    //   userTypeId = 2;
    // } else if (userType.toLocaleLowerCase() === "distributor") {
    //   userTypeId = 3;
    // }
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchUserQrCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };


  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (qrCsvData && qrCsvData?.body?.qrs) {
      setCsvData(qrCsvData.body.qrs);
    }

    if (qrCsvError) {
      setCsvData(null);
    }
  }, [qrCsvData, qrCsvError])

  useEffect(() => {
    if (qrScanData) {
      console.log("qr scan success ----> ", qrScanData);
      setMyList(qrScanData.body.qrs)
    }

    if (qrScanError) {
      console.log("qr scan error ----->", qrScanError);
    }
  }, [qrScanData, qrScanError]);

  useEffect(() => {
    if (countqrScanData) {
      console.log("qr scan success ----> ", qrScanData);
      setTotalRows(countqrScanData?.body?.total_scans)
    }

    if (countqrScanError) {
      console.log("qr scan error ----->", countqrScanError);
    }
  }, [countqrScanData, countqrScanError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      minWidth: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.scanned_by_name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Batch Code",
      cell: (row) => row.batch_code,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_running_code,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Product Name",
      cell: (row) => row.product_name,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
    },
    {
      name: "Qr Code",
      cell: (row) => row.unique_code,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Scan Type",
      cell: (row) => row.scan_type,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Scanned At",
      cell: (row) => new Date(row.scanned_at).toLocaleString(),
      sortable: true,
      minWidth: "200px",

    },

    {
      name: "City",
      cell: (row) => row.scan_city,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Pincode",
      cell: (row) => row.scan_pincode,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "District",
      cell: (row) => row.scan_district,
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "State",
      cell: (row) => row.scan_state,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Address",
      cell: (row) => row.scan_address,
      sortable: true,
      minWidth: "400px",
    },
    {
      name: "App Version",
      cell: (row) => row.app_version,
      sortable: true,
      minWidth: "120px",
    },

    // {
    //   name: "Scanned By Type",
    //   cell: (row) => row.scanned_by_type,
    //   sortable: true,
    // },
    // {
    //   name: "Location",
    //   cell: (row) => "Location",
    //   sortable: true,
    // },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold capitalize">
            {userType} Scan History
            <LinksComponent />
          </div>

          <div className="p-3">
            {/* {qrCsvData && qrCsvData?.body?.qrs && (
              <ExportCSV
                data={qrCsvData && qrCsvData?.body?.qrs}
                columnsToExport={[
                  "product_name",
                  "product_code",
                  "batch_running_code",
                  "unique_code",
                  "mobile",
                  "scanned_by_name",
                ]}
              />
            )} */}

<div className="mb-2">
              <CustomExport fetchData={fetchUserQrScanCsvFunction} data={csvData} defaultColumnExport={[
                  "product_name",
                  "product_code",
                  "batch_running_code",
                  "unique_code",
                  "mobile",
                  "scanned_by_name",
              ]}
                filename="qr_scan_history"
              />
            </div>


            <div className=" w-full bg-white shadow rounded-lg">
              {qrScanIsLoading && <CircleLoaderComponent/> }
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                // title={""}
                data={myList}
                // loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >  <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-end">
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Mobile</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.scanned_by_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, scanned_by_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Qr Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Qr Code..."
                        required
                        value={request.unique_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, unique_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">B-R-Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Batch Running Code..."
                        required
                        value={request.batch_running_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              batch_running_code: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">P-Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Code..."
                        required
                        value={request.product_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              product_code: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">P-Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Name..."
                        required
                        value={request.product_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              product_name: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className=" h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Start Date</label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className=" h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">End Date</label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row w-52 gap-2 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right mb-2 p-2"
                        onClick={() => {
                          fetchUserQrScanFunction();
                          // fetchUserQrScanCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right mb-2 p-2 whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div></div>
              </DataTableComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserScanHistory;
