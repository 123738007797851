import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";


import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiFillEye,
  AiFillEyeInvisible,
} from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";
import {  useDeleteTenantEmailCredentialMutation, useGetAllTenantEmailCredentialMutation, useUpdateTenantEmailCredentialMutation } from "../../../features/tenant/emailCredential/emailCredentialApiTenant";
import { getVendorName } from "../../../utils/getVendorName";


const EmailCredentials = () => {
  const addemailCredentialPermission = hasPermission("addEmailCredential");
  const editemailCredentialPermission = hasPermission("editEmailCredential");
  const deleteemailCredentialPermission = hasPermission(
    "deleteEmailCredential",
  );

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [showPassword, setShowPassword] = useState(-1);

  const [tableDataItems, setTableDataItems] = useState([]);

  const [
    fetchAllEmailCredentials,
    {
      data: emailCredentialData,
      isLoading: emailCredentialIsLoading,
      error: emailCredentialError,
    },
  ] = useGetAllTenantEmailCredentialMutation();

  const [
    deleteEmailCredentials,
    {
      data: deleteEmailCredentialData,
      isLoading: deleteEmailCredentialIsLoading,
      error: deleteEmailCredentialError,
    },
  ] = useDeleteTenantEmailCredentialMutation();

  const [
    updateEmailCredential,
    {
      data: updateEmailCredentialData,
      error: updateEmailCredentialError,
      isLoading: updateEmailCredentialisLoading,
    },
  ] = useUpdateTenantEmailCredentialMutation();

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "User Id ",
      selector: (row) => row.user_id,
      sortable: true,
    },
    {
      name: "Password",
      selector: (row) =>
        showPassword === row.id ? (
          <input type="text" value={row.password} readOnly />
        ) : (
          <input type="password" value={row.password} readOnly />
        ),
      width: "150px",
    },
    // {
    //   name: "Belongs To ",
    //   selector: (row) =>
    //     row.tenant_id == "0" ? "Admin" : `Tenant ${row.tenant_id}`,
    //   width: "120px",
    // },
    {
      name: "Status ",
      selector: (row) =>
        row.status == "1" ? (
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => handleUpdateStatus(row)}
          >
            {" "}
            Active
          </button>
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => handleUpdateStatus(row)}
          >
            InActive{" "}
          </button>
        ),
      width: "120px",
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      selector: (row) =>
        showPassword === row.id ? (
          <button div onClick={() => setShowPassword(-1)}>
            <AiFillEye />
          </button>
        ) : (
          <div div onClick={() => setShowPassword(row.id)}>
            <AiFillEyeInvisible />
          </div>
        ),
      width: "70px",
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-success btn-sm"
          onClick={() => 
            navigate(`/genefied/${getVendorName()}/email-templates` , { state: r })}
        >
          View Templates
        </button>
      ),
      width: "130px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        editemailCredentialPermission && 
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => navigate("manage", { state: r })}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "",
      cell: (r) => (
        deleteemailCredentialPermission && 
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDelete(r.id)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleUpdateStatus = (r) => {
    // console.log(r);
    toast.dismiss();
    if (r.tenant_id !== JSON.parse(sessionStorage.getItem("vendorData")).tenant_id) {
      toast.error("Cannot Updated Status");
      return;
    }

    let status = "1";

    if (r.status === "1") {
      status = "2";
    } else {
      status = "1";
    }

    updateEmailCredential({
      token: sessionStorage.getItem("access_token"),
      id: r.id,
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        status: status,
      },
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const hasViewPermission = hasPermission("emailCredential");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    fetchAllEmailCredentials({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (emailCredentialData && emailCredentialData.body) {
      console.log(emailCredentialData);
      const arr = [...emailCredentialData.body.data];
      arr.sort((a, b) => a.id - b.id);
      setTableDataItems(arr);
    }

    if (emailCredentialError) {
      console.log("emailCredentialError", emailCredentialError);
      toast.error("Something went wrong", {
        id: "emailCredentialError",
      });
    }
  }, [emailCredentialData, emailCredentialError]);

  useEffect(() => {
    if(updateEmailCredentialData){
      fetchAllEmailCredentials({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
      toast.success("Status updated", {id : "emailCredentialUpdateSuccess"})
    }

    if(updateEmailCredentialError){
      toast.error("Something went wrong", {id : "emailCredentialUpdateError"})
    }
  }, [updateEmailCredentialData , updateEmailCredentialError])

  useEffect(() => {
    if(deleteEmailCredentialData){
      fetchAllEmailCredentials({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
      toast.success("Deleted Successfully", {id : "emailCredentialDeleteSuccess"})
    }

    if(deleteEmailCredentialError){
      console.log("delete email credential error" , deleteEmailCredentialError);
      toast.error("Something went wrong", {id : "emailCredentialDeleteError"})
    }
  }, [deleteEmailCredentialData , deleteEmailCredentialError])
  

  const handleDelete = (id) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                toast.dismiss();
                deleteEmailCredentials({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                  id : id
                })
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteWorkflow",
      },
    );
    return;
  };



  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Email Credentials
            <LinksComponent />
          </div>

          

          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <DataTableComponent
              keyField={(row) => row.SNo}
                columns={columns}
                data={tableDataItems}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
              {
              addemailCredentialPermission && 
              (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`manage`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailCredentials;
