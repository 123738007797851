import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useGroupedQrCodeByProductCodeAndGenerationIdMutation,
  useUpdateQrCodeByProductCodeAndGenerationIdMutation,
} from "../../../features/tenant/qrCode/qrCodeApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useFetchProductsMutation } from "../../../features/tenant/products/productsApi";
import { toast } from "react-hot-toast";

const QrMapping = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const location = useLocation();

  useEffect(() => {
    console.log("location", location.state);
    if (location.state) {
      fetchGroupedQrByGenerationFunction();
    }
  }, []);

  const [
    fetchAllQrCode,
    {
      data: groupedQrData,
      isLoading: qrListDataIsLoading,
      error: qrListDataError,
    },
  ] = useGroupedQrCodeByProductCodeAndGenerationIdMutation();

  const [
    updateQrCodes,
    {
      data: updateQrCodeData,
      isLoading: updateQrCodeIsLoading,
      error: updateQrCodeError,
    },
  ] = useUpdateQrCodeByProductCodeAndGenerationIdMutation();

  useEffect(() => {
    fetchGroupedQrByGenerationFunction();
  }, []);

  const fetchGroupedQrByGenerationFunction = () => {
    fetchAllQrCode({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      generationId: location.state.id,
    });
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Generation Id",
      cell: (row) => row.generation_id,
      sortable: true,
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
    },
    {
      name: "Qty",
      cell: (row) => row.total_count,
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() => {
              setShowModal(true);
              setModalData(row);
            }}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >Map new Product</button>
        </div>
      ),
      minWidth: "100px",
    },
  ];

  const [filteredProducts, setFilteredProducts] = useState([]);

  const initialRequest = {
    product_code: "",
    name: "",
    limit: 20,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);

  const [
    fetchProducts,
    { data: productsData, isLoading: productsIsLoading, error: productsError },
  ] = useFetchProductsMutation();

  const fetchProductsFunction = () => {
    fetchProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };
  useEffect(() => {
    console.log(request);
    if (request.product_code.length > 2) {
      fetchProductsFunction();
    }

    if (request.name.length > 2) {
        fetchProductsFunction();
      }
    if (request.product_code == "" && request.name == "") {
      setFilteredProducts([]);
    }
  }, [request]);

  useEffect(() => {
    if (productsData) {
      console.log("productsData", productsData);

      setFilteredProducts(productsData?.body?.products);
    }

    if (productsError) {
      toast.error("something went wrong", {
        id: "productsError",
      });
      console.log("productsError", productsError);

    }
  }, [productsData, productsError, productsIsLoading]);

  const handleProductSelected = (product) => {
    
    console.log("update", modalData , product);

    
    if (window.confirm(`Are you sure you map ${modalData.total_count} qrs to this product`)) {
        updateQrCodes({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
            generationId: location.state.id,
            productCode: modalData.product_code,
            body: {
              new_product_code: product.product_code,
              new_mrp: product.mrp,
            },
          });
      }else {
        return;
      }
    
  };

  useEffect(() => {
    if (updateQrCodeData ) {
      console.log("updateQrCodeData", updateQrCodeData); 
      if(updateQrCodeData && updateQrCodeData.body){
        toast.success(`Successfully updated ${updateQrCodeData.body} Qrs` , {
            id : "updateQrCodeData"
        })
        setRequest(initialRequest);
        setShowModal(false);
        setModalData(null);
        fetchGroupedQrByGenerationFunction(); 
      }
    }

    if (updateQrCodeError) {
      toast.error("something went wrong", {
        id: "updateQrCodeError",
      });
      console.log("updateQrCodeError", updateQrCodeError);
    }
  }, [updateQrCodeData, updateQrCodeError, updateQrCodeIsLoading])

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Qr Code Mapping
            <LinksComponent />
          </div>

          <div className="w-full ">
            <div className="p-3">
              <div className=" bg-white shadow rounded-lg">
                <DataTableComponent
              keyField={(row) => row.SNo}
                  columns={columns}
                  data={groupedQrData?.body}
                  // loading={loading}
                  totalRows={groupedQrData?.body.length}
                  // handlePerRowsChange={handlePerRowsChange}
                  // handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent show={showModal} setShow={setShowModal} size="2xl">
            <div className="w-full p-2">
              <div className="flex w-full justify-evenly items-center mx-2 my-3">
                <div className="w-1/2 h-10 ">
                  <div className="">
                    <input
                      type="search"
                      
                      className="w-full p-2 border border-gray-300 rounded"
                      placeholder="Enter Product Code ..."
                      required
                      value={request.product_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, product_code: newValue };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-1/2 h-10">
                  <div className="">
                    <input
                      type="search"
                      className="w-full p-2 border border-gray-300 rounded"
                      placeholder="Enter Product Name ..."
                      required
                      value={request.name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              {filteredProducts.length === 0 ? (
                <p className="text-center mb-4 text-xl text-gray-800 font-semibold">
                No products found
              </p>
              ) : (
                <ul className="flex justify-evenly items-center w-full flex-wrap">
                  {filteredProducts.map((product) => (
                    <li
                      key={product.product_id}
                      className="bg-white w-64 border border-red-200 p-1 m-1 my-2 rounded shadow-md transition transform hover:scale-95"
                      onClick={() => handleProductSelected(product)}
                    >
                      <div className="w-full flex justify-evenly">
                        <div className="w-20 flex">
                          {product.images &&
                          product.images.length > 0 &&
                          product.images[0].length > 0 ? (
                            <img
                              src={
                                
                                product.images[0]
                              }
                              alt={product.name}
                              className="w-fit object-cover mb-4"
                            />
                          ) : (
                            <div>No Image</div>
                          )}
                        </div>

                        <div>
                          <div className="mb-1 text-gray-800 font-semibold">
                            Brand : {product.brand_name}
                          </div>
                          <div className="text-gray-600">
                            Category : {product.category_name}
                          </div>
                          <div className="text-gray-600">
                            Code : {product.product_code}
                          </div>
                        </div>
                      </div>

                      <div className="mt-1 text-sm font-semibold">
                        Name : {product.name}
                      </div>
                      <div className="mt-1 text-gray-700">
                        MRP: ${product.mrp}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default QrMapping;
