import { rootApi } from "../../api/rootApi";

export const inventorySBApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getReturnList: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/supply-beam/inventory/returnList`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    getReturnInfo: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/supply-beam/inventory/returnInfo`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const { useGetReturnListMutation, useGetReturnInfoMutation } = inventorySBApi;
